
import { Component, Mixins, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';

import AddressFormFields from '@/components/AddressFormFields.vue';
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import { Lock, LockHandler } from '@/mixins/LockHandler';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Store } from '@/models/Store';
import BrandingModal from "@/components/Store/BrandingModal.vue";
import TextMessageTemplatesField from "@/components/Configuration/TextMessageTemplatesField.vue";

import Multiselect from 'vue-multiselect';
import { ValidValues } from '@/data/ValidValues';
import { User } from '@/models/User';

@Component({
    name: "StoreDetailsPage",
    components: {
        BrandingModal,
        AddressFormFields,
        NewClearSaveButtons,
        SearchComponent,
        Multiselect,
        TextMessageTemplatesField
    },
})
export default class StoreDetailsPage extends Mixins(LockHandler, DisableAutocompleteMixin) {
    private store: Store = new Store();
    private storeLock: Lock | null = null;
    private newMode = false;
    protected capabilitiesOptions = Store.flagOptions;
    private stateOptions = [{ label: "All States", states: ValidValues.addressStates }];
    private brandingImageSrc: string | null = "";

    get routeId(): number {
        let res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get disableFields(): boolean {
        if (this.newMode) return false;

        const haveLock = !!this.storeLock;
        const storeGood = !!(this.store && this.store.id);
        return !haveLock || !storeGood;
    }

    get disablePrinter(): boolean {
        return !this.isAdmin;
    }

    get isAdmin(): boolean {
        return this.$user?.isAdmin;
    }

    protected buttonTitles: NewClearSaveTitles = {
        new: "New Store",
        clear: "Clear",
        save: "Save Changes",
        cancel: "",
    };

    created() {
        if (this.routeId) {
            this.newMode = false;
            this.fetchStoreById(this.routeId);
        }
        this.loadUsers();
        this.loadHasInventoryLocations();
    }

    onStoreUpdate(store: Store) {
        this.fetchStore(store);
    }

    async showConfirmationModal() {
        try {
            const value = await this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            });

            if (!value) return;
            this.clearForm();
        } catch (err) {
            console.log("Error caught in showConfirmationModal()");
            console.error(err);
        }
    }

    clearForm() {
        if (this.storeLock && this.storeLock.refreshURL) {
            this.releaseLockAtURL(this.storeLock.refreshURL);
            this.storeLock = null;
        }
        this.store = new Store();
        this.brandingImageSrc = null;
        this.newMode = false;
    }

    fetchStoreById(id: number) {
        const store = new Store();
        store.id = id;
        this.fetchStore(store);
    }

    async fetchStore(value: Store) {
        try {
            const result = await this.addLockWithURL(`/Store/${value.id}/lock`, 60000);
            this.storeLock = result;
        } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status == 418) {
                // already locked
                const lockData = err.response.data;
                const lockedBy = lockData.lockedBy;
                const expires = lockData.expires;
                this.$bvModal.msgBoxOk(`The Store is locked by ${lockedBy} until ${expires}.`);
            }
        }

        try {
            const response = await axios.get(`/Store/${value.id}`, { headers: { 'Cache-Control': 'no-cache' } });
            this.store = new Store(response.data);
        } catch (error) {
            console.warn(error);
        }

        try {
            this.brandingImageSrc = await this.loadBrandingImage();
        } catch {
            this.brandingImageSrc = null;
        }
    }

    createStore() {
        this.newMode = true;
        this.store = new Store();
    }

    @Watch('$route.params.id')
    onParamChange(value: string, oldValue: string) {
        if (value != oldValue) {
            this.fetchStoreById(Number(value));
        }
    }

    @Watch('store')
    onStoreChanged(value: Store | null, _oldValue: Store | null) {
        if (!value) return;
        if (value.id && value.id != -1 && value.id != this.routeId) {
            this.$router.push({ name: 'Store', params: { id: value.id.toString() } });
        }
    }

    async submitInformation() {
        try {
            const response = await axios.post('/Store/', this.store);
            this.store = new Store(response.data);
            this.$notification(NotificationOptions.successSaveNotificationPreset("Store"));
        } catch (error) {
            console.warn(error);
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Store", error as AxiosError));
        }
    }

    get validForm(): boolean {
        return !!(this.store?.name);
    }

    async makeMyselfPharmacistOfRecord() {
        try {
            const response = await axios.post(`/Store/${this.store.id}/MakeSelfPharmacistOfRecord`)
            this.store = new Store(response.data);
            this.$notification(NotificationOptions.notificationOptionsPreset("You have been made the Pharmacist of Record", NotificationOptions.NotificationTypes.success));
        } catch (error) {
            console.warn(error);
            this.$notification(NotificationOptions.notificationOptionsPreset("There was an error making you the Pharmacist of Record", NotificationOptions.NotificationTypes.danger));
        }
    }

    async loadBrandingImage(): Promise<string> {
        if (!this.store?.brandingImageId) return Promise.reject("no branding image id");

        try {
            const blob = await axios.get(`/api/Image/${this.store.brandingImageId}`, { responseType: "blob" });
            const objectUrl = URL.createObjectURL(blob.data);
            return objectUrl;
        } catch (err) {
            return Promise.reject("failed to load branding image");
        }
    }

    private users: User[] = [];

    async loadUsers() {
        try {
            const resp = await axios.get<User[]>("/user");
            this.users = resp.data;
        } catch {

        }
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }
}

