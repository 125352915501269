import { render, staticRenderFns } from "./VendorDetailsPage.vue?vue&type=template&id=3a547196&scoped=true"
import script from "./VendorDetailsPage.vue?vue&type=script&lang=ts"
export * from "./VendorDetailsPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a547196",
  null
  
)

export default component.exports