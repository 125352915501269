
import { Component, Vue } from 'vue-property-decorator';
import CasesListComponent from '@/components/Cases/CasesListComponent.vue';

@Component({
    name: "OpenedCasesPage",
    components: { CasesListComponent },
})
export default class OpenedCasesPage extends Vue {
    created() {
        console.log("%c Created OpenedCasesPage", "color: green");
    }

    beforeDestroy() {
        console.log('%c Destroying OpenedCasesPage', 'color: red');
    }

}
