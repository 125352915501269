import { Person } from './Person'

export interface Vendor extends Person {
    name: string;
    emailAddress: string;
    phoneNumber: string;
    faxNumber: string;

    sftpInventoryHost : string | null
    sftpBaseDir : string | null
    sftpPort : number | null
    sftpUsername : string | null
    sftpPassword : string | null
    receiverId : string | null;

    note: string;
}

export class Vendor extends Person {
    constructor(obj?: Vendor) {
        super();
        if (obj){
            Object.assign(this, obj);
        }
    }
    toString(): string {
        if(this.id && this.name)
            return `ID: ${this.id} ${this.name}`;
        else return '';
    }

}
