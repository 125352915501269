
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { Person } from '@/models/Person';
    import { AccessLog } from '@/models/AccessLog';
    import axios from 'axios';
    import { DatePicker } from 'element-ui';

    import moment from 'moment';

    @Component({
        name: "AccessLogComponent",
        components: {
            [DatePicker.name]: DatePicker,
        },
    })
    export default class AccessLogComponent extends Vue {
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
            // hotkeys on
        }

        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
            //hotkeys off
        }
        private object: Person = new Person(); // The selected object of the search type (Patient/Prescriber/etc..)
        private objects: Person[] = []; // The list of objects returned from the search

        private fromDate: Date = moment().subtract(7, 'd').toDate();
        private toDate: Date = new Date;

        private searchTypes: string[] = ["Patient", "Prescriber", "Vendor", "Store", "Prescription",];
        private searchType: string = this.searchTypes[0];
        private accessLogs: AccessLog[] = [];

        private accessLogFields = [
            { key: 'viewedBy', sortable: true },
            { key: 'viewed', label: 'Date', formatter: this.formattedDate, sortable: true }
        ];

        optionLabel(option: any) {
            if (option.name != null) return option.name;
            if (option.storeID) return `Store-Rx-Rf:${option.storeID}-${option.rxNumber}-${option.rfNumber} Patient:${option.patientID}`;
            if(option instanceof Person)
                return option.displayNamesForPerson();
        }

        formattedDate(value: Date): string {
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY [At: ]hh:mm:ss a");
        }

        @Watch('object') onObjectUpdate(value: any, oldValue: any) {
            if (value) {
                this.getAccessLogForObject(value);
            }
        }
        @Watch('fromDate') onFromDateUpdate(value: any, oldValue: any) {
            if (this.object) {
                this.getAccessLogForObject(this.object);
            }
        }
        @Watch('toDate') onToDateUpdate(value: any, oldValue: any) {
            if (this.object) {
                this.getAccessLogForObject(this.object);
            }
        }

        clearClicked() {
            this.object = new Person();
            this.accessLogs = [];
        }

        getAccessLogForObject(value: any) {
            if (value && (value.id || value.storeID)) {
                const id = value.id ? value.id : `${value.storeID}-${value.rxNumber}-${value.rfNumber}`;
                const searchPath = `/${this.searchType}/${id}/access`;
                const config = {
                    params: {
                        fromDate: moment(this.fromDate).startOf('day').utc().toDate(),
                        toDate: moment(this.toDate).endOf('day').utc().toDate()
                    },
                };

                axios.get(searchPath, config)
                    .then(response => {
                        const accessLogs = response.data as AccessLog[];
                        this.accessLogs = accessLogs;
                    })
                    .catch(error=>{
                        console.warn(error);
                    })
                    .finally(() => {
                        // empty
                    });
            }
        }

        searchValueUpdated(value: string) {
            if (value.length) {
                const searchPath = `/${this.searchType}`;
                const config = {
                    params: { searchTerm: value },
                };
                axios.get(searchPath, config)
                    .then(response => {
                        //this.objects = response.data;
                        this.objects = response.data.map((p:Person) => Object.assign(new Person(), p));
                    })
                    .catch(error => {
                        console.warn(error);
                    })
                    .finally(() => {
                        // empty
                    });
            }
        }
    }

