import HasID from './HasID'
import HasLabel from "./HasLabel";

export interface DrugManufacturer extends HasID{
    companyName:string
    companyNameShort:string
    labelerCode:number
    mvx:string
}

export class DrugManufacturer extends HasLabel {
    constructor(obj?: DrugManufacturer) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (this.id)
            return `${this.id} - ${this.companyName}`
        else return '';
    }
}
