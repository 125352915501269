
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Lock, LockHandler } from "@/mixins/LockHandler";
import { Prescription } from "@/models/Prescription";
import { DeliveryCode } from "@/models/DeliveryCode";
import { Printer } from "@/models/Printer";
import { Store } from "@/models/Store";
import { Order, OrderOTCItem, OrderStatus } from "@/models/Order";
import OrdersListComponent from "@/components/Order/OrdersListComponent.vue";
import { Payment, StripeCard } from "@/models/Payment";
import { Patient } from "@/models/Patient";
import { OTCItem } from "@/models/OTCItem";
import { DatePicker, Table, TableColumn } from "element-ui";
import EnumSelector from "@/components/EnumSelector.vue";
import axios from "axios";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import moment from "moment";
import SearchComponent from "@/components/SearchComponent.vue";
import NewClearSaveButtons from "@/components/NewClearSaveButtons.vue";
import PrescriptionsListComponent from "@/components/Prescription/PrescriptionsListComponent.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import StripeVueCard from "@/components/Payments/StripeVueCardcomponent.vue";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { Program } from "@/models/Program";
import { AcceptanceConfiguration, AcceptanceConfigurationStatus } from "@/models/AcceptanceConfiguration";
import { AcceptanceRecord } from "@/models/AcceptanceRecord";
import { BvTableFieldArray } from "bootstrap-vue";
import CardReader from "@/components/Cards/CardReader.vue";
import OrderAuditDetails from "@/pages/Order/OrderAuditDetails.vue";
import DateTimeInput from "@/components/Inputs/dateTimeInput.vue";
import PromisedTimeSummary from "@/components/Order/PromisedTimeSummaryComponent.vue";

Component.registerHooks(["beforeRouteEnter"]);

@Component({
    computed: {
        OrderAuditDetails() {
            return OrderAuditDetails;
        },
    },
    components: {
        CardReader,
        SearchComponent,
        NewClearSaveButtons,
        [DatePicker.name]: DatePicker,
        PrescriptionsListComponent,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        StripeVueCard,
        OrdersListComponent,
        EnumSelector,
        Checkbox,
        DateTimeInput,
        PromisedTimeSummary,
    },
})
export default class OrderDetailsPage extends Mixins(LockHandler) {
    beforeRouteEnter(_to: any, from: any, next: any) {
        next((vm: any) => (vm.from = from));
    }

    private order: Order = new Order();
    private orderLock: Lock | null = null;
    private patient: Patient = new Patient();
    private selectedStore: Store = new Store();
    private orderStatus = OrderStatus;
    private availableToOrder: Prescription[] = [];
    protected orders: Order[] = [];
    private showCancelled = false;
    private showShipped = false;
    protected deliveryCodes = DeliveryCode;
    private from: any = null;

    private dataPrescriptions: Array<Prescription> = [];
    private dataOrderItems: Array<OrderOTCItem> = [];

    private selectedPayments: Array<Payment> = [];

    private stripeCardList: Array<StripeCard> = [];
    private selectedStripeCards: Array<StripeCard> = [];

    private shippingOptions: Array<string> = ["HIPAADocsInBox"];
    private printers: Array<Printer> = [];
    private selectedPrinter: Printer = {} as Printer;

    private collectedByPartner = false;
    protected isSubmitting = false;

    protected cancelReasonSelection : string | null = null;
    protected cancelReasonOptions: any[] | null = null;

    protected availableRxFields = [
        { key: "productNameShort", label: "Drug" },
        { key: "rxId", label: "Rx" },
        { key: "fillDate", formatter: (d: Date) => moment(d).format("L") },
        {
            key: "patientPay",
            label: "Copay",
            formatter: (copay: any) => `$${copay == null ? "0.00" : copay.toFixed(2)}`,
        },
        { key: "programName", label: "Program" },
        { key: "storeName", label: "Store" },
        { key: "verifyButton", label: "" },
    ];

    private buttonTitles: any = {
        new: "",
        clear: "Clear",
        save: "Save Changes",
        cancel: "Back to Dashboard",
    };

    private selectedStoreId: string = "";
    private unfilteredAvailableToOrder: Prescription[] = [];
    private selectedProgram: Program = new Program();
    private haveSelectedPrescriptions = false;
    private isProgramWithCopayCollectionFlag: boolean = false;
    private isProgrammaticSelect = false;
    private disableDetermineDeliveryCode = true;
    protected isPrinting: boolean = false;
    private modeOfPayment = "card";
    private checkNumber: string = "";
    protected acceptanceConfigurations: AcceptanceConfiguration[] = [];
    private loadingAcceptanceConfigurations = false;

    private currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    protected paymentTableFields: BvTableFieldArray = [
        { key: "brand", label: "Card" },
        { key: "cardNumber", label: "Number" },
        {
            key: "exp",
            label: "Expiration Date",
            formatter: (_value, _key, item) => `${item.expMonth}/${item.expYear}`,
        },
        { key: "amount", formatter: this.currencyFormatter.format },
        { key: "amountCaptured", label: "Captured", formatter: this.currencyFormatter.format },
        { key: "amountRefunded", label: "Refunded", formatter: this.currencyFormatter.format, class: "text-danger" },
    ];

    formatDate(date: any) {
        return moment.utc(date).local().format("LLL");
    }

    get disableStoreSelection() {
        return !!(this.disableFields || (this.selectedStore?.id && this.dataPrescriptions?.length));
    }

    get disableProgramSelection() {
        return !!(
            this.disableFields ||
            (this.selectedProgram?.id && this.dataPrescriptions?.length)
        );
    }

    get missingNeededAudit() {
        return this.order.requiresAudit && !this.order.auditId;
    }

    get isAllVerified() {
        if (this.missingNeededAudit) return false;

        let allVerified = true;
        this.availableToOrder.forEach((rx) => {
            if (!rx.pV2ID) allVerified = false;
        });
        return allVerified;
    }

    get disableClear() {
        const isProcessing = this.order.status == OrderStatus.Processing;
        const isInvalidOrder = !(this.order && this.order.id);

        return isProcessing || isInvalidOrder || this.hipaaRequired || this.order.wasShipped;
    }

    get disableSave() {
        const editNotValid = ((!this.isFormValid || this.isOrderVerified) && this.routeMode == 'edit');
        const verifyNotValid = (!this.isAllVerified && this.routeMode == 'verify');
        const pickupNotValid = (!this.isFormValid && this.routeMode == 'pickup');
        const acceptanceNotValid = this.loadingAcceptanceConfigurations || this.acceptanceConfigurations.some(ac => (!ac.checked && ac.mandatory));
        return this.hipaaRequired || editNotValid || verifyNotValid || pickupNotValid || acceptanceNotValid;
    }

    get isFormValid() {
        let isStoreSelected = this.selectedStore?.id;
        const isPaid = this.amountOwed == this.totalSelected;
        const collectedByPartner = this.isProgramWithCopayCollectionFlag && this.collectedByPartner;
        const pickupPayment = (this.order.deliveryCode == DeliveryCode.WillCall && (this.routeMode != "pickup" || this.modeOfPayment != "card"));
        const isDeliveryMethodSelected = !!(
            this.order.deliveryCode || this.order.deliveryCode == DeliveryCode.Standard
        );
        const isPrescriptionsSelected = !!this.dataPrescriptions?.length;
        return (
            isStoreSelected &&
            isPrescriptionsSelected &&
            isDeliveryMethodSelected &&
            !this.disableFields &&
            (isPaid || collectedByPartner || pickupPayment)
        );
    }

    get hipaaRequired() {
        return (
            this.routeMode == "verify" &&
            this.patient.hipaaDocsReceived == null &&
            !this.shippingOptions.includes("HIPAADocsInBox")
        );
    }

    get isOrderCancelled(): boolean {
        return this.order?.status == this.orderStatus.Canceled;
    }

    get isOrderVerified(): boolean {
        return !!this.order.verified && this.order.deliveryCode != DeliveryCode.WillCall;
    }

    get disableFields(): boolean {
        const isVerifying = this.routeMode == "verify";
        return (
            !(this.orderLock || (this.order && !this.order.id)) ||
            isVerifying ||
            this.isOrderCancelled ||
            this.order.wasShipped
        );
    }

    get disablePromisedDate(): boolean {
        return this.disableFields;
    }

    get hasPromisedDate(): boolean {
        return !!this.order.promisedDate;
    }

    get disableEditPayment(): boolean {
        return this.disableFields || (this.routeMode == "pickup" && this.modeOfPayment != "card");
    }

    get StoreAndRxSelected() {
        return this.haveSelectedPrescriptions && this.selectedStoreId != "";
    }

    get rxTable(): any & { selectRow: (index: any) => void } {
        return this.$refs.rxTable as any & { selectRow: (index: any) => void };
    }

    get AddonTotalSum(): string {
        let total: number;
        total = this.dataOrderItems
            .map((item) => {
                const price = item.price;
                const qty = item.quantity;
                const tax = item.tax ? +item.tax : 0;
                return price * qty + tax;
            })
            .reduce((itemTotal, grandTotal) => {
                return itemTotal + grandTotal;
            }, 0);
        return total.toFixed(2);
    }

    get routeOrderId(): number {
        const res = parseInt(this.$route.params.orderId);
        return isNaN(res) ? 0 : res;
    }

    get routeMode(): string {
        if (this.$router.currentRoute.name == "OrderVerification") return "verify";
        else if (this.$router.currentRoute.name == "OrderPickup") return "pickup";
        else return "edit";
    }

    get patientId(): number {
        let res = parseInt(this.$route.params.patientId);
        return isNaN(res) ? this.order.patientID || 0 : res;
    }

    get patientName() {
        let res = "";

        if (this.patient.id) res = this.patient.displayNamesForPerson();

        return res;
    }

    get pendingToBeVerified() {
        return this.dataPrescriptions.some((rxRow) => (rxRow.pV2ID || 0) == 0);
    }

    get totalSelected() {
        let total = 0;
        for (let i = 0; i < this.selectedStripeCards.length; i++) {
            const selectedCard = this.selectedStripeCards[i];
            total += selectedCard.amount - selectedCard.amountRefunded;
        }
        return total;
    }

    get totalSelectedPreview() {
        let total = 0;
        for (let i = 0; i < this.stripeCardList.length; i++) {
            const card = this.stripeCardList[i];
            total += card.amount - card.amountRefunded;
        }
        return total;
    }

    get amountOwed() {
        let balance = 0.0;
        for (let i = 0; i < this.dataPrescriptions.length; i++) {
            balance += this.dataPrescriptions[i].patientPay;
        }

        let addonTotal = this.dataOrderItems
            .map((item) => {
                const price = item.price;
                const qty = item.quantity;
                const tax = item.tax ? +item.tax : 0;
                return price * qty + tax;
            })
            .reduce((itemTotal, grandTotal) => {
                return itemTotal + grandTotal;
            }, 0);
        balance = balance + addonTotal;

        return Number(balance.toFixed(2));
    }

    get acceptanceConfigurationFields(): BvTableFieldArray {
        return [
            {
                key: "id",
                label: "Item",
                formatter: (_value: number, _key: string, item: AcceptanceConfiguration) => item.toString(),
            },
            {
                key: "status",
                label: "Status",
                formatter: (value: AcceptanceConfigurationStatus) => AcceptanceConfigurationStatus[value],
            },
            { key: "imageId", label: "Document" },
            { key: "checked", label: "Accepted" },
        ];
    }

    created() {
        if (this.routeMode == "verify") {
            this.buttonTitles.cancel = "Back to Verification Queue";
            this.buttonTitles.save = "Print Label";
            this.loadPrinters();
        } else if (this.routeMode == "pickup") {
            this.buttonTitles.save = "Pick Up";
        } else {
            this.fetchAvailableRx();
        }

        if (this.routeOrderId) {
            this.addLockWithURL(`/Order/${this.routeOrderId}/lock`, 60000)
                .then((result) => {
                    this.orderLock = result;
                })
                .catch((error) => {
                    if (error.response && error.response.status == 418) {
                        // Order locked
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(
                            `The Order is locked by ${lockedBy} until ${expires}.`,
                        );
                    }
                })
                .finally(() => {
                    return this.loadOrder();
                });
        } else {
            this.initObject();
            this.loadPatient();
        }
    }

    loadOrder() {
        return axios
            .get(`/Order/${this.routeOrderId}`)
            .then((response) => {
                this.order = new Order(response.data);
                this.dataOrderItems = this.order.items;
                this.selectedStore = new Store();
                this.selectedStore.id = this.order.storeID;
                this.selectedStore.name = this.order.storeName;

                this.dataPrescriptions = this.order?.prescriptions || new Array<Prescription>();
                if (this.dataPrescriptions.length == 1) {
                    let script = this.dataPrescriptions[0];
                    if (
                        this.from?.name == "VerificationQueue" &&
                        (script.pV2ID || 0) == 0 &&
                        (script.dispenseErrorId || 0) == 0
                    ) {
                        this.$router.push({
                            name: "PV2",
                            params: {
                                orderID: response.data.id.toString(),
                                storeID: script.storeID.toString(),
                                rxNumber: script.rxNumber.toString(),
                                rfNumber: (script.rfNumber || 0).toString(),
                            },
                        });
                    }
                }
                this.buttonTitles.clear = this.order?.id ? "Cancel Order" : "";
                this.getCancelReasonOptionsFromConfiguration();

                this.selectedPayments = this.order.payments;
                const chargeIdList = this.selectedPayments.map((payment) => payment.chargeId);
                if (chargeIdList?.length) {
                    axios
                        .get(`/stripe/charges/${this.routeOrderId}`)
                        .then((response) => {
                            this.selectedStripeCards = response.data;
                            this.updateStripeCardList();
                        })
                        .catch((error) => {
                            this.$notification(NotificationOptions.error(error));
                            let errorMsg = "Error while loading payments.";
                            this.$notification(
                                NotificationOptions.errorSaveNotificationPreset(errorMsg),
                            );
                            console.error(errorMsg, { error, response: error?.response });
                        });
                }
                this.loadPatient();
                this.fetchProgramDrugsFlags();
            })
            .catch((error) => {
                this.$notification(NotificationOptions.error(error));
                let errorMsg = "Error while loading order";
                console.error(errorMsg, { error, response: error?.response });
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        errorMsg,
                        NotificationOptions.NotificationTypes.danger,
                    ),
                );
            })
            .finally(() => {
                this.fetchAvailableRx();
            });
    }

    loadOrders(patientId?: string | number) {
        patientId = patientId || this.patient.id;
        this.selectedStore = new Store();
        this.selectedStore.id = this.order.storeID;
        this.selectedStore.name = this.order.storeName;
        //Get patient's prescriptions and format the data to be shown in the grid.
        axios
            .get<Order[]>(
                `/Patient/${patientId}/orders/${this.showCancelled}/${this.showShipped}/${this.selectedStore.id}`,
            )
            .then((response) => {
                this.orders = response.data.filter((o) => o.id != this.order.id);
                this.orders.sort((a: Order, b: Order) => {
                    return new Date(b.created).getTime() - new Date(a.created).getTime();
                });
            })
            .catch((error) => {
                console.error("Problem loading patient orders", {
                    error,
                    response: error?.response,
                });
            });
    }

    protected mergeOrder: Order = {} as Order;

    patientOrderMerge(item: Order) {
        if (item.storeID != this.order.storeID) {
            this.$notification(NotificationOptions.error("Cannot merge orders with different store locations."));
            return;
        }
        this.mergeOrder = item;
        this.$bvModal.show('merge-modal');


    }

    async completeMerge() {
        const targetOrderID = this.order.id;
        const sourceOrderID = this.mergeOrder.id;
        try {
            const response = await Axios.post<Order>(`/order/merge-orders/${sourceOrderID}/${targetOrderID}`);
            this.order = new Order(response.data);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
        this.mergeOrder = {} as Order;
        this.loadOrders(this.patientId);
        await this.loadOrder();
    }

    @Watch("patientId")
    patientIdChange(newVal?: number | string, _oldVal?: number | string) {
        this.loadOrders(newVal);
    }

    @Watch("selectedStore.id")
    storeIdChange(val: any, oldVal: any) {
        if (val == oldVal) return;

        this.selectedStoreId = val?.toString();
        this.filterAvailableToOrder(undefined, false);
    }

    @Watch("selectedProgram.id")
    selectedProgramIdChange(val: any, oldVal: any) {
        if (val == oldVal) return;

        this.filterAvailableToOrder(undefined, false);
        this.setDefaultDeliveryCode();
    }

    initObject() {
        this.order.patientID = this.patientId;
        this.order.prescriptions = [];
        this.order.items = [];
    }

    async loadPrinters() {
        try {
            const response = await Axios.get<Printer[]>('/Printer');
            this.printers = response.data;
            this.selectedPrinter = JSON.parse(localStorage.getItem("CPHubVerifyPrinter") as string) as Printer ?? this.printers[0];
        } catch (error) {
            console.warn(error);
        }
    }

    loadPatient() {
        if (this.patientId) {
            axios
                .get(`/Patient/${this.patientId}`)
                .then((response) => {
                    this.patient = Object.assign(new Patient(), response.data);
                    this.loadPatientPaymentMethods();

                    if (
                        this.patient.signatureRequired &&
                        !this.shippingOptions.includes("SignatureRequired")
                    ) {
                        this.shippingOptions.push("SignatureRequired");
                    }
                })
                .catch((error) => {
                    console.warn("Error while loading the Patient", {
                        error,
                        response: error?.response,
                    });
                });
        }
    }

    fetchAvailableRx() {
        if (this.patientId) {
            axios
                .get(`/Prescription/AutoFillAvailableToOrder/${this.patientId}`)
                .then((response) => {
                    this.unfilteredAvailableToOrder = response.data.concat(
                        this.order.prescriptions,
                    );
                    this.filterAvailableToOrder();
                })
                .catch((err) => {
                    console.error("Problem while pulling available prescriptions to order", {
                        err,
                        response: err?.response,
                    });
                });
        }
    }

    async fetchProgramDrugsFlags() {
        const rxWithProgram = this.dataPrescriptions?.find((rx) => rx.programID);

        if (!rxWithProgram?.programID) {
            this.selectedProgram = new Program();
            this.isProgramWithCopayCollectionFlag = false;
            return;
        }

        try {
            const res = await axios.get(`/Program/${rxWithProgram.programID}`, {
                params: {
                    includeFlags: true,
                    includeDrugs: true,
                },
            });

            this.selectedProgram.id = Number(rxWithProgram.programID);
            this.selectedProgram.flags = res.data.flags;
            this.selectedProgram.drugs = res.data.drugs;

            this.isProgramWithCopayCollectionFlag = !!this.selectedProgram?.flags?.some(
                (f) => f.name.includes("Allow Collected By Partner") && f.value,
            );
        } catch (err) {
            const error = err as any;
            console.error("Error while pulling program drugs and flags", {
                error,
                response: error?.response,
            });
        }
    }

    async prescriptionsSelected(selectedRows: Prescription[]) {
        this.dataPrescriptions = selectedRows;

        // set selected program
        const rx = this.dataPrescriptions?.find((rx) => rx.programID);
        if (rx) {
            this.selectedProgram.id = Number(rx.programID);
            this.selectedProgram.name = rx.programName;
            await this.fetchProgramDrugsFlags();
        } else {
            this.selectedProgram = new Program();
            this.isProgramWithCopayCollectionFlag = false;
        }

        if (selectedRows?.length) this.filterAvailableToOrder(selectedRows);

        this.haveSelectedPrescriptions = selectedRows.length > 0;

        if (this.isProgrammaticSelect || !this.routeOrderId) {
            this.isProgrammaticSelect = false;
            this.setDefaultDeliveryCode();
        }

        this.fetchAcceptanceConfigurations(selectedRows);
    }

    fetchAcceptanceConfigurations(selectedRows: Prescription[]) {
        this.loadingAcceptanceConfigurations = true;
        const config = {
            params: {
                rxIds: selectedRows.map(rx => new Prescription(rx).rxID),
            },
        } as AxiosRequestConfig;
        axios.get<AcceptanceConfiguration[]>("/api/AcceptanceConfiguration/Active", config)
            .then(resp => {
                const configs = resp.data;
                this.acceptanceConfigurations = configs.map(ac => {
                    const config = new AcceptanceConfiguration(ac);
                    config.checked = this.order.acceptanceRecords.some(ar => ar.configurationId == config.id);
                    return config;
                });

            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(() => {
                this.loadingAcceptanceConfigurations = false;
            });
    }

    async openDocument(item: AcceptanceConfiguration) {
        if (!item?.imageId) return;
        try {
            const imageBlob = await axios.get(`/api/Image/${item.imageId}`, { responseType: "blob" });
            const objectUrl = URL.createObjectURL(imageBlob.data);
            window.open(objectUrl, "_blank");
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    filterAvailableToOrder(
        selectedRows: Array<Prescription> = new Array<Prescription>(),
        selectRows = true,
    ) {
        //Set selected store from selected rx
        if (selectedRows?.length > 0) {
            const selectedRowStoreId = selectedRows[0].storeID;
            if (selectedRowStoreId != this.selectedStore?.id) {
                this.selectedStore = new Store();
                this.selectedStore.id = selectedRows[0].storeID.toString();
            }
        }

        //Filter available Rx in table based on selected rx and program.
        const storeId = this.selectedStore?.id;
        let programId = this.selectedProgram?.id;
        const routeMode = this.routeMode;
        if (storeId || programId) {
            this.availableToOrder = this.unfilteredAvailableToOrder.filter(
                (itm) =>
                    itm.storeID == (storeId ?? itm.storeID) &&
                    (itm.programID == (programId ?? itm.programID) ||
                        routeMode == "verify" ||
                        routeMode == "pickup"),
            );
        } else this.availableToOrder = this.unfilteredAvailableToOrder;

        if (this.routeMode == "verify" || this.routeMode == "pickup")
            this.availableToOrder = this.availableToOrder.filter((el) =>
                this.order.prescriptions.includes(el),
            );

        if (this.rxTable) {
            this.$nextTick(() => {
                if (!selectedRows?.length) {
                    if (this.order.prescriptions && selectRows) {
                        this.order.prescriptions.forEach((rx) => {
                            const idx = this.availableToOrder.indexOf(rx);
                            if (idx != -1) {
                                this.isProgrammaticSelect = true;
                                this.rxTable.selectRow(idx);
                            }
                        });
                    }
                } else {
                    selectedRows.forEach((rx) => {
                        const idx = this.availableToOrder.indexOf(rx);
                        if (idx != -1) {
                            this.isProgrammaticSelect = true;
                            this.rxTable.selectRow(idx);
                        }
                    });
                }
            });
        }
    }

    selectPrinter(printer: Printer) {
        localStorage.setItem("CPHubVerifyPrinter", JSON.stringify(printer));
        this.selectedPrinter = printer;
    }

    loadPatientPaymentMethods() {
        if (this.patient.stripeCustomerId) {
            axios
                .get(`/stripe/cards/${this.patient.stripeCustomerId}`)
                .then((response) => {
                    this.stripeCardList = response.data;
                })
                .catch((error) => {
                    console.error("Error while getting Payment Cards from Patient", {
                        error,
                        response: error?.response,
                    });
                });
        }
    }

    newCardAdded(stripeResponse: StripeCard) {
        this.patient.stripeCustomerId = stripeResponse.customerId;
        this.loadPatientPaymentMethods();
        if (this.selectedStripeCards && this.selectedStripeCards.length > 0)
            this.updateStripeCardList();
        //this.stripeCardList.push(response.data);
    }

    rxsWereChanged(): boolean {
        const selectedRxs = this.dataPrescriptions
            ?.map((rx) => Object.assign(new Prescription(), rx))
            .map((rx) => rx.rxID)
            .sort()
            .join(",");
        const orderRxs = this.order.prescriptions
            ?.map((rx) => Object.assign(new Prescription(), rx))
            .map((rx) => rx.rxID)
            .sort()
            .join(",");

        return selectedRxs != orderRxs;
    }

    /**
     * Delivery Code Priority Determination for the order:
     * 1. Drug/Programs inside the selected program
     * 2. Program Delivery Code
     * 3. Prescriptions Delivery Codes.
     */
    setDefaultDeliveryCode() {
        //in edit mode, Automatic determination of delivery code is blocked until rxs are changed.
        if (this.disableDetermineDeliveryCode && this.rxsWereChanged() && this.routeOrderId)
            this.disableDetermineDeliveryCode = false;

        if (this.disableDetermineDeliveryCode && this.routeOrderId) return;

        const deliveryCodeCandidates: Array<DeliveryCode | null> = [];

        if (this.selectedProgram?.drugs?.length) {
            const programDrugsInOrder = this.selectedProgram.drugs.filter((dr) =>
                this.dataPrescriptions.map((rx) => rx.packageID).includes(dr.packageID),
            );
            deliveryCodeCandidates.push(...new Set(programDrugsInOrder.map((i) => i.deliveryCode)));
        }

        deliveryCodeCandidates.push(this.selectedProgram?.deliveryMethod ?? null);

        if (this.dataPrescriptions.some((rx) => Object.assign(new Prescription(), rx).isCII)) {
            deliveryCodeCandidates.push(DeliveryCode.TwoDay);
        }

        deliveryCodeCandidates.push(...new Set(this.dataPrescriptions.map((p) => p.deliveryCode)));

        if (!deliveryCodeCandidates?.length) {
            this.order.deliveryCode = DeliveryCode.Standard;
            return;
        }

        const deliveryCodeImportanceRank = [
            DeliveryCode.WillCall,
            DeliveryCode.Overnight,
            DeliveryCode.TwoDay,
            DeliveryCode.StandardOvernight,
            DeliveryCode.USPSPriority,
            DeliveryCode.FirstClass,
            DeliveryCode.Delivery,
        ];

        const defaultCode = deliveryCodeImportanceRank.find(dc => deliveryCodeCandidates.includes(dc)) ?? DeliveryCode.Standard;
        this.order.deliveryCode = defaultCode;
    }

    @Watch("dataOrderItems")
    updateAddonPricing(addons: Array<OrderOTCItem>) {
        if (addons?.length && this.routeMode != "verify") {
            axios
                .post(`/OTCItem/CalculateOTCTax/${this.patientId}/${this.selectedStoreId}/`, addons)
                .then((response) => {
                    this.dataOrderItems.forEach((DOI) => {
                        DOI.tax =
                            response.data.lineItems[
                                response.data.lineItems.findIndex(
                                    (item: any) => item.id === DOI.otcItemId,
                                )
                                ].taxAmount;
                    });
                })
                .catch((error: AxiosError) => {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            error,
                            NotificationOptions.NotificationTypes.danger,
                        ),
                    );
                    console.error(error.response?.data);
                });
        }
    }

    addSelectedItem(selItem: OTCItem) {
        let isAlreadyAdded = this.dataOrderItems.some((item) => item.otcItemId == selItem.id);

        if (!isAlreadyAdded) {
            const item = {
                orderId: this.order.id as number,
                otcItemId: selItem.id as number,
                quantity: 1,
                otcItemName: selItem.name,
                price: selItem.defaultPrice,
                tax: 0,
            };

            this.dataOrderItems.push(item);
        }
    }

    removeItem(itemId: number) {
        if (itemId) {
            this.dataOrderItems = this.dataOrderItems.filter((item) => item.otcItemId != itemId);
            this.updateAddonPricing(this.dataOrderItems);
        }
    }

    private recalculateSelectedPayments() {
        this.selectedPayments = [] as Payment[];

        if (this.modeOfPayment == "check") {
            let payment: Payment = {
                id: 0,
                orderId: this.order.id as number,
                patientId: this.patientId as number,
                authorizedAmount: this.amountOwed as number,
                chargeId: `check_${this.checkNumber}`,
            } as Payment;
            this.selectedPayments.push(payment);
        } else if (this.modeOfPayment == "cash") {
            let payment: Payment = {
                id: 0,
                orderId: this.order.id as number,
                patientId: this.patientId as number,
                authorizedAmount: this.amountOwed as number,
                chargeId: `cash`,
            } as Payment;
            this.selectedPayments.push(payment);
        } else {
            for (let i = 0; i < this.selectedStripeCards.length; i++) {
                let payment = {
                    id: 0,
                    orderId: this.order.id as number,
                    patientId: this.patientId as number,
                    authorizedAmount: this.selectedStripeCards[i].amount as number,
                    StripeCardId: this.selectedStripeCards[i].id,
                } as Payment;
                this.selectedPayments.push(payment);
            }
        }
    }

    save() {
        if (this.routeMode == "pickup") {
            let data = this.order;
            data.status = OrderStatus.ReadyForPickup;
            data.storeID = Number(this.selectedStore.id);
            data.prescriptions = this.dataPrescriptions;
            data.items = this.dataOrderItems;

            this.recalculateSelectedPayments();
            data.payments = this.selectedPayments;
            data.stripeCustomerId = this.patient.stripeCustomerId;

            this.isSubmitting = true;
            axios
                .post("/Order/", data)
                .then((response) => {
                    this.order = new Order(response.data);
                    return axios
                        .post(`/Shipping/PickedUp/${this.order.id}`)
                        .then(_resp => {
                            this.$notification(NotificationOptions.successSaveNotificationPreset("Pick Up"));
                            this.backToPreviousPage();
                        })
                        .catch((err) => {
                            console.warn("shipping picked up error: ", err);
                            this.$notification(NotificationOptions.error(err));
                        });
                })
                .catch((error: AxiosError) => {
                    this.$notification(NotificationOptions.error(error));
                    return this.loadOrder();
                })
                .finally(() => {
                    this.isSubmitting = false;
                });

            return;
        }

        if (this.routeMode == "edit") {
            let data = this.order;
            data.status = OrderStatus.Created;
            data.storeID = Number(this.selectedStore.id);
            data.prescriptions = this.dataPrescriptions;
            data.items = this.dataOrderItems;
            this.recalculateSelectedPayments();
            data.payments = this.selectedPayments;
            data.stripeCustomerId = this.patient.stripeCustomerId;

            this.isSubmitting = true;
            console.log("save order data: ", data);


            this.acceptanceConfigurations.forEach(ac => {
                if (!ac.checked) {
                    console.warn("Attempting to save without having checked all acceptance configurations", ac);
                    return;
                }
                if (data.acceptanceRecords.some(ar => ar.configurationId == ac.id)) {
                    console.info("skip creating a new acceptance record when one already exists for this configuration", ac);
                    return;
                }
                let record = new AcceptanceRecord();
                record.configurationId = ac.id;
                record.patientId = this.patientId;
                if (this.order.id) record.orderId = +this.order.id;
                data.acceptanceRecords.push(record);
            });

            axios
                .post<Order>("/api/Order", data)
                .then((response) => {
                    if (response.data?.id) {
                        this.order = new Order(response.data);
                        this.$notification(
                            NotificationOptions.successSaveNotificationPreset("Order"),
                        );
                        this.backToPreviousPage();
                    }
                })
                .catch((error: AxiosError) => {
                    console.error("Error saving the order", { error, response: error?.response });
                    this.$notification(NotificationOptions.error(error));
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        }

        if (this.routeMode == "verify") {
            let data = this.order;
            data.printerAddress = this.selectedPrinter.ipAddress;
            data.overridePriority = this.shippingOptions.includes("OverridePriority");
            data.saturdayDelivery = this.shippingOptions.includes("SaturdayDelivery");
            data.overrideAddress = this.shippingOptions.includes("OverrideAddress");
            data.signatureRequired = this.shippingOptions.includes("SignatureRequired");
            data.disableOneRate = this.shippingOptions.includes("DisableOneRate");
            data.hipaaDocsInBox = true; //!this.shippingOptions.includes("HIPAADocsInBox")

            this.isPrinting = true;
            axios
                .post<Order>("/Shipping/CreateLabel", data)
                .then((_response) => {
                    // if (response.data?.shipmentId > 0) {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            "Printed!",
                            NotificationOptions.NotificationTypes.success,
                        ),
                    );
                    this.backToPreviousPage();
                    // }
                })
                .catch((error: AxiosError) => {
                    let errorMsg = "Printing Failed. ";
                    let responseMsg = "";

                    let errors: Array<any> = [];
                    if (error.response?.data?.message) responseMsg = error.response?.data.message;
                    else if (typeof error.response?.data === "string")
                        responseMsg = error.response?.data;
                    else if (error.response?.data.length && error.response?.data[0]) {
                        errors = error.response?.data;
                        responseMsg = errors
                            .map((err) => {
                                let msg = err?.originalAddress?.companyName
                                    ? "Pharmacy: "
                                    : "Patient: ";
                                msg += err.messages?.map((msg: any) => msg.message).join(", ");
                                return msg;
                            })
                            .join(" ");
                    }
                    if (
                        responseMsg.toString().substring(0, 200) ==
                        "There are prescriptions on this order that are not verified, if you wish to continue your order will be split"
                    ) {
                        this.$bvModal
                            .msgBoxConfirm(responseMsg.toString().substring(0, 200), {
                                title: "Printing Failed.",
                                size: "sm",
                                buttonSize: "sm",
                                okVariant: "danger",
                                footerClass: "p-2",
                                hideHeaderClose: false,
                                centered: true,
                            })
                            .then((ok) => {
                                // ok == true when ok is clicked
                                // ok == false when cancel is clicked
                                // ok == null when modal is closed without clicking either ok or cancel
                                if (ok) {
                                    // resend the print request, with the param to split order
                                    let axiosConfig: AxiosRequestConfig = {
                                        params: {
                                            shouldSplit: ok,
                                        },
                                    };
                                    // This still needs to handle it's .then and .catch blocks and such.
                                    axios
                                        .post<Order>("/Shipping/CreateLabel", data, axiosConfig)
                                        .then((_resp) => {
                                            this.$notification(
                                                NotificationOptions.notificationOptionsPreset(
                                                    "Printed!",
                                                    NotificationOptions.NotificationTypes.success,
                                                ),
                                            );
                                            this.backToPreviousPage();
                                        })
                                        .catch((err) => {
                                            this.$notification(
                                                NotificationOptions.errorSaveNotificationPreset(
                                                    "Shipping label",
                                                    err,
                                                ),
                                            );
                                        });
                                } else {
                                }
                            })
                            .catch((rejected) => {
                                console.warn("catching msgBoxConfirm: ", rejected);
                            });
                    } else {
                        if (responseMsg) {
                            //If we have detailed reasons why it failed, a message box is used
                            this.$bvModal.msgBoxOk(responseMsg.toString().substring(0, 200), {
                                title: errorMsg,
                                size: "sm",
                                buttonSize: "sm",
                                okVariant: "danger",
                                footerClass: "p-2",
                                hideHeaderClose: false,
                                centered: true,
                            });
                        } else {
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset(
                                    responseMsg,
                                    NotificationOptions.NotificationTypes.danger,
                                ),
                            );
                        }
                    }
                })
                .finally(() => {
                    this.isPrinting = false;
                });
        }
    }

    clearOrCancel() {
        if (this.order?.id) {
            this.$bvModal.show("cancel-order-modal");
            return;
        }

        // Clear Form
        if (this.orderLock && this.orderLock.refreshURL) {
            this.releaseLockAtURL(this.orderLock.refreshURL);
            this.orderLock = null;
        }

        this.order = new Order();
        this.selectedStore = new Store();
        this.dataPrescriptions = [];
        this.dataOrderItems = [];
        this.selectedProgram = new Program();
        this.initObject();
        (this.$refs.rxTable as any).clearSelected();
        this.filterAvailableToOrder(undefined);
        this.isProgramWithCopayCollectionFlag = false;
        this.collectedByPartner = false;
    }

    cancelOrder(e : any) {
        e.preventDefault();

        if (this.cancelReasonOptions?.length && !this.cancelReasonSelection) {
            this.$notification(NotificationOptions.error("You must select a cancellation reason."));
            return;
        }

        this.order.cancellationReason = this.cancelReasonSelection;

        axios
                .post(`/Order/cancel/`, this.order)
                .then((response) => {
                    this.order = new Order(response.data);
                    if (this.order.status == OrderStatus.Canceled) {
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(
                                "Order was cancelled.",
                                NotificationOptions.NotificationTypes.success,
                            ),
                        );
                        this.$bvModal.hide("cancel-order-modal");
                    }
                })
                .catch((error) => {
                    let errorMsg = "Error while loading order";
                    console.error(errorMsg, { error, response: error?.response });
                    this.$notification(NotificationOptions.error(error));
                });
    }

    async getCancelReasonOptionsFromConfiguration() {
        try {
            const response = await this.$http.get<Array<String>>("/Configuration/OrderCancellationReasons");
            if (!response.data?.length) return;
            this.cancelReasonOptions = [
                { value: null, text: 'Select a reason' },
                ...response.data
            ];
        } catch {
            // ignore
        }
    }

    backToPreviousPage() {
        let patientId = this.patientId;
        if (this.routeMode == "verify") {
            this.$router.push({
                name: "VerificationQueue",
                params: { storeId: (this.order?.storeID || 0).toString() },
            });
        } else if (patientId)
            this.$router.push({ name: "PatientDashboard", params: { id: patientId.toString() } });
    }

    otcItemLabel(object: OTCItem) {
        let res = "";
        if (object.name) res = `${object.name}`;

        return res;
    }

    savePayments() {
        this.selectedStripeCards = [];
        for (let i = 0; i < this.stripeCardList.length; i++) {
            if (this.stripeCardList[i].amount > 0) {
                this.selectedStripeCards.push(this.stripeCardList[i]);
            }
        }
    }

    getVerifyActionButtonsClass(rxRow: Prescription) {
        return {
            "text-info": (rxRow.pV2ID || 0) == 0,
            "text-success": (rxRow.pV2ID || 0) > 0,
            "text-warning": (rxRow.dispenseErrorId || 0) > 0,
        };
    }

    getRowClass(rxRow: Prescription) {
        if (!this.disableFields) return "";

        rxRow = Object.assign(new Prescription(), rxRow);
        const isInOrder = this.order?.prescriptions
            .map((rx) => Object.assign(new Prescription(), rx))
            .map((rx) => rx.rxID)
            .some((id) => id == rxRow.rxID);
        return isInOrder ? "table-success" : "";
    }

    acceptanceClass(ac: AcceptanceConfiguration): string {
        if (ac.requiresSignature) return "text-danger font-weight-bold";
        return "";
    }

    getPrescriptionVerificationRoute(rxRow: Prescription) {
        return {
            name: "PV2",
            params: {
                orderID: this.order.id,
                storeID: rxRow.storeID,
                rxNumber: rxRow.rxNumber,
                rfNumber: rxRow.rfNumber || 0,
            },
        };
    }

    tooltipText(rxRow: Prescription) {
        let text: string;
        if ((rxRow.dispenseErrorId || 0) > 0) text = "Error reported";
        else if ((rxRow.pV2ID || 0) > 0) text = "Verified By " + rxRow.pv2By;
        else text = "Verify";

        return text;
    }

    updateStripeCardList() {
        for (let i = 0; i < this.selectedStripeCards.length; i++) {
            const hasRefund = !!this.selectedStripeCards[i].amountRefunded;

            const selectedCardIndex = this.stripeCardList.findIndex(
                (item) => item.id == this.selectedStripeCards[i].id,
            );

            if (selectedCardIndex < 0) continue;

            if (!hasRefund) {
                this.stripeCardList[selectedCardIndex].amount = this.selectedStripeCards[i].amount;
            }

            this.stripeCardList[selectedCardIndex].pendingDelete = false;
        }
    }

    get stripeCardListGetter(): StripeCard[] {
        return this.stripeCardList.filter((c) => !c.pendingDelete);
    }

    textAcceptanceConfiguration(_item: AcceptanceConfiguration) {
        let phone = this.patient.cell.internationalFormat;
        if (phone == "") phone = this.patient.phone.internationalFormat;
    }

    emailAcceptanceConfiguration(_item: AcceptanceConfiguration) {
        let email = this.patient.emailAddress;
    }

    handlePromisedTimeCheck(checked: any) {
        const hourFromNow = moment().add(1, "hours");
        const currentMinutes = hourFromNow.minutes();
        const minutesToAdd = 15 - (currentMinutes % 15);
        const roundedDate = hourFromNow.add(minutesToAdd, 'minutes');

        this.order.promisedDate = (checked ? roundedDate.toDate() : undefined);
    }

    async handlePromisedDateChange(value: any) {
        this.order.promisedDate = value;

        await this.$nextTick();

        if (moment(this.order.promisedDate).isBefore()) {
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    "Promised time cannot be in the past",
                    NotificationOptions.NotificationTypes.warning,
                ),
            );
            this.handlePromisedTimeCheck(true);
        }
    }

    get availableToOrderRequirePV1() {
        return this.unfilteredAvailableToOrder?.filter(i => i.isPV1Required);
    }

}
