
import { Component, Vue } from "vue-property-decorator";
import SearchComponent from "@/components/SearchComponent.vue";
import { Prescriber } from "@/models/Prescriber";
import axios from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import DoctorFormComponent from "@/components/Doctor/DoctorFormComponent.vue";
import NotesForm from "@/components/Note/NotesForm.vue";
import NotesList from "@/components/Note/NotesList.vue";
import AddressFormFields from "@/components/AddressFormFields.vue";
import { EntityType } from "@/models/EntityType";

@Component({
    name: "DoctorMergePage",
    components: { AddressFormFields, NotesList, NotesForm, DoctorFormComponent, SearchComponent },
})
export default class DoctorMergePage extends Vue {
    protected sourcePrescriber: Prescriber = new Prescriber();
    protected targetPrescriber: Prescriber = new Prescriber();

    private doctorEntityType = EntityType.Prescriber;

    get disableSubmit(): boolean {
        return !this.sourcePrescriber?.id || !this.targetPrescriber?.id;
    }

    get userCanMerge() {
        return this.$user?.isAdmin;
    }

    created() {
        console.log("%c Created DoctorMergePage", "color: green");
    }

    beforeDestroy() {
        console.log('%c Destroying DoctorMergePage', 'color: red');
    }

    swap() {
        [this.sourcePrescriber, this.targetPrescriber] = [this.targetPrescriber, this.sourcePrescriber];
    }

    async submit() {
        try {
            const body = { source: this.sourcePrescriber, target: this.targetPrescriber };
            let resp = await axios.post<Prescriber>("/api/Prescriber/Merge", body);
            this.sourcePrescriber = new Prescriber();
            this.targetPrescriber = new Prescriber(resp.data);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    refreshNotesList() {
        if (this.$refs.sourceNotesList instanceof NotesList) this.$refs.sourceNotesList.loadNotes();
        if (this.$refs.targetNotesList instanceof NotesList) this.$refs.targetNotesList.loadNotes();
    }

}
