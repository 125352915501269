

    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { Table, TableColumn } from 'element-ui'
    import IngredientSelectModalComponent from '@/components/Compound/IngredientSelectModalComponent.vue';
    import { CompoundIngredients } from '@/models/Drug/CompoundDrug';
    import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
    import Axios from "axios";
    import { Drug } from "@/models/Drug/Drug";
    import { BTable } from "bootstrap-vue";

    @Component({
        name: "IngredientComponent",
        components: {
            IngredientSelectModalComponent,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        }
    })
    export default class IngredientComponent extends Vue {

        @Prop() private compoundId!: number;
        @Prop() private ingredients!: CompoundIngredients [];

        @Ref('ingredients-table') private ingredientsTable!: BTable;

        private showForm: boolean = false;
        private upsertId: number = 0;
        private upsertDrugSource: number = 0;
        private upsertDrugId: number = 0;
        private upsertQuantity: number = 0;
        private upsertUnitOfMeasure: string = "";
        private upsertIngredientRow: CompoundIngredients = new CompoundIngredients;
        private alreadyShipped: boolean = false;

        private fields = [
            // "id",
            { key: "drugSource", formatter: this.drugSourceFormatter },
            { key: "drugName", formatter: this.drugNameFormatter },
            "quantity",
            "unitOfMeasure",
            { key: "actions", label: "" },
        ]

        drugNameFormatter(value: number, _: string, item: CompoundIngredients) {
            const drugId = item.drugID;
            if (!drugId) return 'Invalid drug.';
            if (item.drugName) return item.drugName;
            Axios.get<DrugProduct>(`/Drug/${drugId}`, { params: { drugSource: item.drugSource } })
                .then(drugDetailResp => {
                    const drugName = drugDetailResp.data.productNameShort;
                    item.drugName = drugName;
                    return drugName;
                })
                .catch(err => {
                    console.warn("err: ", err);
                    const drugName = 'Error Getting Name';
                    item.drugName = drugName;
                    return drugName;
                })
                .finally(() => {
                    if (this.ingredientsTable && this.ingredientsTable.refresh) this.ingredientsTable.refresh();
                });
            return 'Loading...';
        }

        drugSourceFormatter(drugSource: DrugSource) {
            return DrugSource[drugSource].toString();
        }

        linkForItem(item: CompoundIngredients) {
            let name = '';
            if (item.drugSource == DrugSource.GsddFederal) name = 'Drug';
            if (item.drugSource == DrugSource.NonGsddProduct) name = 'Non-GSDD-Drug';
            return { name, params: { id: item.drugID } };
        }

        reset() {
            this.showForm = false;
            this.upsertId = 0;
            this.upsertDrugSource = 0;
            this.upsertDrugId = 0;
            this.upsertQuantity = 0;
            this.upsertUnitOfMeasure = "";
            this.upsertIngredientRow = new CompoundIngredients();
            this.alreadyShipped = false;
            console.log("created");
        }

        onContinue() {
            this.submitUpsert();
            this.$emit('continue');
        }

        submitUpsert() {
            if (this.upsertQuantity < 0.0001) {
                this.$bvModal.msgBoxOk('Quantity can not be zero or less.');
                return;
            }
            this.upsertIngredientRow = new CompoundIngredients();
            this.upsertIngredientRow.id = this.upsertId || 0;
            this.upsertIngredientRow.compoundID = this.compoundId;
            this.upsertIngredientRow.drugSource = this.upsertDrugSource || 0;
            this.upsertIngredientRow.drugID = this.upsertDrugId!;
            this.upsertIngredientRow.quantity = this.upsertQuantity!;
            this.upsertIngredientRow.unitOfMeasure = this.upsertUnitOfMeasure!;

            if (this.ingredients.map(item => item.id).indexOf(this.upsertIngredientRow.id) > -1)
                this.ingredients.splice(this.ingredients.map(item => item.id).indexOf(this.upsertIngredientRow.id), 1);
            this.ingredients.push(this.upsertIngredientRow);
            this.closeForm();
        }

        updateIngredient(item: CompoundIngredients) {
            this.onIngredientAddUpdDelClicked()
            if (!this.alreadyShipped) {
                this.showForm = true;
                this.upsertId = item.id;
                this.upsertDrugSource = item.drugSource;
                this.upsertDrugId = item.drugID;
                this.upsertQuantity = item.quantity;
                this.upsertUnitOfMeasure = item.unitOfMeasure;
            } else this.closeForm();
        }

        deleteConfirmDialog(item: CompoundIngredients) {
            this.onIngredientAddUpdDelClicked();
            if (!this.alreadyShipped) {
                this.$bvModal.msgBoxConfirm(`This compound Ingredient will get deleted. Are you sure?`, {
                    title: 'Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(value => {
                        if (!value) return;
                        this.deleteIngredient(item);
                    })
                    .catch(err => {
                        console.error("Error caught on Delete button.", err);
                    });
            } else this.closeForm();
        }

        deleteIngredient(item: CompoundIngredients) {
            if (!item) return;

            const itemIdType = typeof (item.id);
            if (itemIdType != "number") return;

            if (this.ingredients.map(item => item.id).indexOf(item.id) < 0) return;
            this.ingredients.splice(this.ingredients.map(ci => ci.id).indexOf(item.id), 1);
            this.$emit('delete');
        }

        onNewIngredient() {
            this.onIngredientAddUpdDelClicked();
            this.clearForm();
            if (!this.alreadyShipped) this.$bvModal.show("IngredientDrugSelect");
        }

        openFrom() {
            this.showForm = true;
        }

        closeForm() {
            this.clearForm();
            this.showForm = false;
        }

        clearForm() {
            this.upsertId = Math.min(...this.ingredients.map(item => item.id)) - 1;
            if (this.upsertId > 0) this.upsertId = 0;
            this.upsertDrugSource = 0;
            this.upsertDrugId = 0;
            this.upsertQuantity = 0;
            this.upsertUnitOfMeasure = ""
        }

        onCancelClick() {
            this.closeForm();
            this.$emit('cancel');
        }

        onIngredientSelected(parm1: any) {
            //console.log(parm1);
            var drugSrcId = parm1.split("_", 4)
            this.upsertDrugSource = Number(drugSrcId[0]);
            this.upsertDrugId = Number(drugSrcId[1]);
            this.upsertQuantity = Number(drugSrcId[2]);
            this.upsertUnitOfMeasure = drugSrcId[3];
            this.$bvModal.hide("IngredientDrugSelect");
            var duplicate = this.ingredients.some(obj => obj.drugSource == this.upsertDrugSource && obj.drugID == this.upsertDrugId)
            if (duplicate) {
                this.$bvModal.msgBoxOk('Duplicate DrugSource/DrugId selected.');
                return;
            }
            this.submitUpsert();
            this.$emit('continue');
        }

        onIngredientCanceled() {
            this.$bvModal.hide("IngredientDrugSelect");
        }

        async onIngredientAddUpdDelClicked() {
            let shippedSearchResults: number | null = null;
            let sc: number = 0;
            let scGood: number[] = [200, 204];
            await this.fetchShippedSearch(this.compoundId!)
                .then(value => {
                    if (value.length == null) sc = value;
                    else {
                        sc = value[0];
                        shippedSearchResults = value[1];
                    }
                });
            if (!scGood.includes(sc)) {
                this.$bvModal.msgBoxOk(`Database error occurred while attempting to search if compound has a prescription shipped`);
                return false;
            }
            if (shippedSearchResults != null && shippedSearchResults != "") {
                this.$bvModal.msgBoxOk(`Ingredients can't be changed as compound already has a shipped prescription`);
                this.alreadyShipped = true;
            } else this.alreadyShipped = false;
        }

        fetchShippedSearch(compoundId: number): Promise<[number, number | null] | any> {
            return this.$http.get(`/Compound/prescription-shipped-search?compoundid=${compoundId.toString()}`)
                .then(response => {
                    const shippedFound: number | null = response.data;
                    const sc: number = Number(response.status);
                    return [sc, shippedFound];
                })
                .catch(error => {
                    console.error('Error while searching if compound has a shipped prescription order.', {
                        error,
                        response: error?.response
                    });
                    return error.response?.status;
                })
        }

    }
