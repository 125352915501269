import {ProductivityStatsBase} from '@/models/ProductivityStatsBase'

export interface PharmacistProductivity extends ProductivityStatsBase {
    pv2 : number
    cancelled : number
    consultations : number
    transfers : number
    pv1 : number
}

export class PharmacistProductivity extends ProductivityStatsBase{
    constructor(){
        super();
        this.pv2 = 0;
        this.cancelled = 0;
        this.consultations = 0;
        this.transfers = 0;
        this.pv1 = 0;
    }
}
