

    import { Component, Vue } from 'vue-property-decorator';
    import CasesListComponent from '@/components/Cases/CasesListComponent.vue'

    @Component({
        name: "ClosedCasesPage",
        components: { CasesListComponent },
    })
    export default class ResolvedCasesPage extends Vue {
        created() {
        }

        beforeDestroy() {
        }
    }
