
    import { Vue, Component, Prop } from "vue-property-decorator";
    import { Store } from '@/models/Store';
    import { Prescriber } from '@/models/Prescriber';
    import { Patient } from '@/models/Patient';
    import { FaxForm, Prescription } from '@/models/Prescription';
    import { PatientDocument } from "@/models/PatientDocument";
    import { FaxType } from "@/models/PrescriberFollowUpCase";
    import { Drug } from "@/models/Drug/Drug";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import PrescriptionDetailsSummary from '@/components/Prescription/PrescriptionDetailsSummary.vue'
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "FaxToPhysicianFormModal",
        components: { PrescriptionDetailsSummary, SearchComponent }
    })
    export default class FaxToPhysicianFormModal extends Vue {
        @Prop({ default: () => new Store() }) private store!: Store;
        @Prop({ default: () => new Prescriber() }) private prescriber!: Prescriber;
        @Prop() private prescription!: Prescription;
        @Prop() private patient!: Patient;
        @Prop({ default: () => new Drug() }) private drug!: Drug;
        @Prop({ default: "" }) private modalId!: string;
        @Prop({ default: FaxType.RefillAuthorization }) private formType!: FaxType;

        private faxFormData : FaxForm = new FaxForm();
        private isSending: boolean = false;

        private selectedPrescriber : Prescriber = new Prescriber();
        private selectedDrug : Drug = new Drug();

        protected formTypeEnum = FaxType;

        private storeFilter: Store = new Store();

        get lastStore(): any {
            const objStr = localStorage.getItem('printQueue_lastStore');
            if (objStr) return JSON.parse(objStr);
            else return null;
        }

        get title():string {
            if(this.formType == FaxType.RefillAuthorization) return 'Refill Authorization Request';
            if(this.formType == FaxType.RxClarification) return 'RX Clarification Request';
            if(this.formType == FaxType.NewRxRequest) return 'New RX Request';
            if(this.formType == FaxType.AlternateRxRequest) return 'Alternate RX Request';

            return '';
        }

        get endpointURI():string {
            let res = '';
            if(this.formType == FaxType.RefillAuthorization) res = 'request-refill-authorization';
            if(this.formType == FaxType.RxClarification) res = 'request-rx-clarification';
            if(this.formType == FaxType.NewRxRequest) res = 'request-new-rx';

            return res;
        }

        get prescriberToShow() : Prescriber{
            return this.prescriber?.id ? this.prescriber : this.selectedPrescriber;
        }

        get drugToShow() : Drug{
            return this.drug?.packageID ? this.drug : this.selectedDrug;
        }

        show(){}

        send() {
            this.faxFormData.fax = this.prescriberToShow.faxNumber;

            if (this.faxFormData.verbalTransfer == null) {
                this.faxFormData.verbalTransfer = false;
            }

            if (this.formType == FaxType.NewRxRequest) {
                this.faxFormData.prescriberID = Number(this.selectedPrescriber.id);
                this.faxFormData.patientID = Number(this.patient.id);
                this.faxFormData.packageID = Number(this.selectedDrug.drugId);
                this.faxFormData.drugSource = Number(this.selectedDrug.source);
                this.faxFormData.storeID = Number(this.storeFilter.id);
            } else {
                this.faxFormData.rxID = this.prescription.rxID;
                this.faxFormData.storeID = Number(this.store.id);
            }

            this.isSending = true;
            this.$http.post<PatientDocument>(`prescription/${this.endpointURI}`, this.faxFormData)
                .then(res => {
                    if (res.data.id) {
                        let msg = this.title + " sent successfully and saved as a new patient document. A new Follow-Up case was created.";
                        if (!(this.$user?.isManager || this.$user?.isPharmacist) && this.formType == FaxType.RxClarification) {
                            msg = this.title + " was saved as a new patient document and new Follow-Up case was created for review.";
                        }
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(msg, NotificationOptions.NotificationTypes.success)
                        );
                        this.$emit('saved');
                    }
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    let errorMsg = `Error while sending ${this.title}.`;
                    console.error(errorMsg, {err, response: err?.response})
                })
                .finally(() => this.isSending = false)
        }

        close(){
            this.$bvModal.hide(this.modalId);
            this.selectedDrug = new Drug();
            this.selectedPrescriber = new Prescriber();
        }

        clearForm(){
            this.faxFormData = new FaxForm();
        }

        get isFormValid():boolean{
            let validSelectedFields = this.formType == FaxType.NewRxRequest && !!this.selectedPrescriber.id && !!this.selectedDrug.drugId
                    || this.formType != FaxType.NewRxRequest;

            return !!this.prescriberToShow.faxNumber && validSelectedFields;
        }

        get showInvalidFormAlert():boolean {
            return !this.isFormValid && this.formType != FaxType.NewRxRequest;
        }

        mounted() {
            this.storeFilter = new Store(this.lastStore) ?? new Store();
        }
    }
