
    import { Component, Vue } from "vue-property-decorator";
    import axios from "axios";
    import PlaidLink from "vue-plaid-link";


    @Component({
        name: "PlaidPage",
        components: {
            PlaidLink,
        }
    })
    export default class PlaidPage extends Vue {

        private linkToken: string = "";
        private patientId: number = 3003;

        async mounted() {
            let linkInfo = await axios.get(`/api/Plaid/Link/Info`);
            console.log("resp: ", linkInfo.data);

            let linkTokenResponse = await axios.get(`api/Plaid/Link/Token/${this.patientId}`);
            let linkToken = linkTokenResponse.data;
            console.log("linkToken: ", linkToken);
            let token = linkToken.link_token;
            console.log("token: ", token);
            this.linkToken = token;
        }

        get haveToken(): boolean {
            return this.linkToken != "";
        }

        onLoad(...args: any) {
            console.log("args: ", args);
        }

        onExit(...args: any) {
            console.log("args: ", args);
        }

        onEvent(...args: any) {
            console.log("args: ", args);
        }

        async onSuccess(token: string) {
            console.log("onSuccess token: ", token);
            let resp = await axios.post(`api/Plaid/Link/Token`, { publicToken: token, patientId: this.patientId });
            console.log("success response: ", resp.data);
        }

        linkToPlaid() {

        }
    }
