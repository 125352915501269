
import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import Axios from 'axios';
import moment from 'moment';
import { RxTransfer, transferStatus } from '@/models/RxTransfer';
import { BvTableFieldArray } from "bootstrap-vue";
import { Row } from "element-ui";

@Component({
    name: "RxTransferQueuePage",
    computed: {
        Row() {
            return Row
        }
    },
    components: {},
})

export default class RxTransferQueuePage extends Vue {
    protected unfinishedTransfers: Array<RxTransfer> = [];

    created() {
        this.loadUnfinishedRxTransfers();
    }

    get fields(): BvTableFieldArray {
        return [
            { key: "timeStamp", label: "Entered", formatter: this.convertDate },
            { key: "inbound", label: "In/Out", formatter: value => value ? "Inbound" : "Outbound" },
            { key: "patientName", },
            { key: "patientDOB", label: "DOB", formatter: this.convertDate },
            { key: "patientState", },
            { key: "transferStatus", label: "Status", formatter: this.getEnum },
            { key: "drugName", },
            { key: "programName", },
            { key: "rxTransferReasonName", label: "Reason", },
        ];
    }

    loadUnfinishedRxTransfers() {
        Axios.get(`/RxTransfer/GetUnfinished`)
            .then(response => {
                this.unfinishedTransfers = response.data
            })
            .catch(error => {
                console.warn(error);
            });
    }

    convertDate(date: string) {
        return moment.utc(date).format("L");
    }

    getEnum(status: number) {
        return transferStatus[status];
    }

    gotoPatient(row: any) {
        this.$router.push({ name: 'PatientDashboard', params: { id: row.patientId.toString() } });
    }
}

