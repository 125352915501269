import moment from 'moment';

export interface EligibilityRequest {
    controlNumber: string
    effectiveDate: Date
    requestorName: string
    npiNumber: string
    lastName: string
    firstName: string
    middleName: string
    prefix: string
    suffix: string
    dateOfBirth: Date
    gender: string
    address1: string
    address2: string
    city: string
    state: string
    zip: string
}

export interface Effective {
    startDate: Date
    endDate: Date
}

export interface Coverage {
    type: string
    status: string
}

export interface EligibilityResponse {
    controlNumber: string
    errorMessage: string
    actionCode: string
    payerName: string
    effectiveDates: Effective
    requestorName: string
    npiNumber: string
    lastName: string
    firstName: string
    middleName: string
    prefix: string
    suffix: string
    dateOfBirth: Date
    gender: string
    address1: string
    address2: string
    city: string
    state: string
    zip: string
    demographicsChanged: boolean
    cardholderId: string
    memberId: string
    healthPlanName: string
    bin: string
    pcn: string
    groupNumber: string
    personCode: string
    coverageTypes: Coverage[]
    surescriptsPayerId: string
}

export class EligibilityRequest {
    constructor(item?: EligibilityRequest) {
        if (item) Object.assign(this, item);
    }

    get formattedName(): string {
        let suffix = "";
        if (this.suffix) {
            suffix = ` ${this.suffix}`;
        }
        return `${this.lastName}${suffix}, ${this.firstName} ${this.middleName ?? ''}`
    }

    get dob(): string {
        if (!this.dateOfBirth) return "";
        return moment(this.dateOfBirth).format("L");
    }

    get formattedAddress(): string {
        let address2 = "";
        if (this.address2) {
            address2 = `, ${this.address2}`;
        }
        return `${this.address1}${address2}, ${this.city}, ${this.state} ${this.zip}`
    }
}

export class EligibilityResponse {
    constructor(item?: EligibilityResponse) {
        if (item) Object.assign(this, item);
    }
    get formattedName(): string {
        let suffix = "";
        if (this.suffix) {
            suffix = ` ${this.suffix}`;
        }
        return `${this.lastName}${suffix}, ${this.firstName} ${this.middleName ?? ''}`
    }

    get dob(): string {
        if (!this.dateOfBirth) return "";
        return moment(this.dateOfBirth).format("L");
    }

    get formattedAddress(): string {
        let address1 = "";
        if (this.address1) {
            address1 = `${this.address1}, `;
        }
        let address2 = "";
        if (this.address2) {
            address2 = `${this.address2}, `;
        }
        return `${address1}${address2}${this.city}, ${this.state} ${this.zip}`
    }
}

export interface EligibilityResults {
    request: EligibilityRequest
    responses: EligibilityResponse[]
}

export class EligibilityResults {
    public constructor(data: any = null) {
        this.responses = [];
        if (data) {
            this.request = new EligibilityRequest(data.request);
            this.responses = data.responses.map((r: any) => new EligibilityResponse(r));
        }
    }

    get formattedRequestName(): string {
        return `${this.request.lastName}`;
    }
}
