
import Component from "vue-class-component";
import SearchComponent from "@/components/SearchComponent.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";
import { Team, Voicemail } from "@/models/Voicemail";
import { Watch } from "vue-property-decorator";
import moment from "moment";
import { Patient } from "@/models/Patient";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import DateRangeFilter from "@/components/DateRangeFilter.vue";
import TranscriptionViewer from "@/components/TranscriptionViewer.vue";

@Component({
  components: {
    SearchComponent,
    EnumSelector,
    DateRangeFilter,
    TranscriptionViewer,
  },
})
export default class VoicemailsPage extends Vue {
  private patient: Patient = new Patient();
  private selectedTeam: Team = Team.untriaged;
  private triageLocation: Number = 0;
  private teams = Team;
  private voicemails: Voicemail[] = [];
  private pharmacist: string = '';
  private selectedView: string = 'Incomplete';
  private selectedVoicemail: number = 0;
  private loading = false;

  private createdDateRangeFilter: any = { startDate: moment().subtract(90, "days").toDate(), endDate: new Date() };
  private counts = { incomplete: 0, complete: 0 };

  get routeVoicemailId(): number {
    let res = 0;
    res = parseInt(this.$route.params.id);
    if (isNaN(res)) return 0;
    return res;
  }

  mounted() {
    this.getOnCallPharmacist();
    this.getCounts();

    if (this.routeVoicemailId) {
      this.getSingleVoicemail();
      return;
    }

    this.getVoicemails();
  }

  relativeTime(item: Voicemail): string {
    return moment.utc(item.received).fromNow();
  }

  toggleInfo(item: Voicemail) {
    this.selectedVoicemail = item.id;
    console.log("toggle info", item);
    const idToExpand = `accordion-${item.id}`;

    (this.$refs[idToExpand] as any)[0].show = false;

    this.$root.$emit("bv::toggle::collapse", idToExpand);
  }

  get filteredVoicemails() {
    if (this.loading) return [];
    
    if (this.selectedView === "Incomplete" || !this.createdDateRangeFilter.startDate) {
      return this.voicemails;
    }

    return this.voicemails.filter(i => {
      let received = i.received;
      if (!received) return false;
      received = moment(i.received).toDate();

      return (received >= moment(this.createdDateRangeFilter.startDate).toDate()) &&
        (received < moment(this.createdDateRangeFilter.endDate).add(1, "day").toDate());
    })
  }

  async getSingleVoicemail() {
    try {
      const response = await Axios.get<Voicemail>(`/Voicemail/${this.routeVoicemailId}`);
      const voicemail = Object.assign(new Voicemail(), response.data);
      this.voicemails = [voicemail];
      console.log(this.voicemails);
      this.$nextTick(() => {
        this.toggleInfo(voicemail);
      });
    } catch (error) {
      console.error("Problem loading voicemail information by route Id", {
        error,
        response: (error as any)?.response
      });
    }
  }

  @Watch("selectedTeam")
  @Watch("selectedView")
  async getVoicemails() {
    try {
      this.loading = true;

      let response: AxiosResponse<Voicemail[]>;

      if (this.selectedView === "Incomplete") {
        response = await Axios.get<Voicemail[]>(`/Voicemail/Incomplete/${this.selectedTeam}`);
      } else {
        response = await Axios.get<Voicemail[]>(`/Voicemail/Completed`);
      }

      this.voicemails = response.data?.map(value => new Voicemail(value));
    } catch (error) {
      let errorMessage = "Error Fetching Voicemails";
      const err = error as any;
      if (err && err?.response?.data) {
        errorMessage += ` - ${err.response.data}`;
      }
      this.$notification(NotificationOptions.notificationOptionsPreset(errorMessage, NotificationOptions.NotificationTypes.danger));
    } finally {
      this.loading = false;
    }
  }

  async triageVoicemail(item: Voicemail) {
    try {
      await Axios.post(`/Voicemail/Triage/${item.id}/${this.triageLocation}`);
      await this.getVoicemails();
    } catch (error) {
      this.$notification(NotificationOptions.errorSaveNotificationPreset("Voicemail Triage", error as any));
    }
  }

  async completeVoicemail(item: Voicemail) {
    const patientId = Number(this.patient?.id);
    if (!Number.isNaN(patientId)) {
      item.patientId = patientId;
    }

    try {
      await Axios.post(`Voicemail/Complete`, item);
      this.patient = new Patient();
      await this.getVoicemails();
    } catch (error) {
      this.$notification(NotificationOptions.errorSaveNotificationPreset("Voicemail Complete", error as any));
    }
  }

  async downloadVoicemail(itemId: number) {
    try {
      const config: AxiosRequestConfig = {
        responseType: 'blob'
      }
      var response = await Axios.get(`/Voicemail/DownloadAudio/${itemId}`, config);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "audio/wav" }));
      let sourceItem: any = document.getElementById(`source-${itemId}`);
      sourceItem.src = url;
      let audioItem: any = document.getElementById(`audio-${itemId}`);
      audioItem.hidden = false;
      audioItem.pause();
      audioItem.load();
      audioItem.play();
      console.log(response);
    } catch (err) {
      const error = err as any;
      let errorMessage = "Unable To Download Audio for Voicemail";
      if (error && error?.response?.data) {
        errorMessage += ` - ${error.response.data}`;
      }
      this.$notification(NotificationOptions.notificationOptionsPreset(errorMessage, NotificationOptions.NotificationTypes.danger));
    }

  }

  transcriptClicked(voicemailId:number, startTime:any){
    var timespan = moment.duration(startTime);

      let audioItem: any = document.getElementById(`audio-${voicemailId}`);
      if(audioItem.getAttribute('hidden')){
        this.downloadVoicemail(voicemailId);
      }
      audioItem.currentTime = timespan.seconds();
  }

  async getOnCallPharmacist() {
    const response = await Axios.get(`/Voicemail/OnCallPharmacist`);
    this.pharmacist = response.data;
    console.log(this.pharmacist);
  }

  async getCounts() {
    const response = await Axios.get("/Voicemail/IncompleteCompleteCounts");
    this.counts = response.data;
  }

  get selectViewOptions() {
    return [
      { text: this.counts.incomplete ? `Incomplete (${this.counts.incomplete})` : "Incomplete", value: 'Incomplete' },
      { text: this.counts.complete ? `Complete (${this.counts.complete})` : "Complete", value: 'Complete' }
    ];
  }
}
