
    import {Component, Emit, Prop, Ref, VModel, Vue, Watch} from "vue-property-decorator";
    import Axios, {AxiosRequestConfig} from "axios";
    import {NotificationOptions} from "@/util/NotificationOptionsPresets";
    import {BModal, BvModalEvent} from "bootstrap-vue";
    import {
        AllowsAdditionalText,
        ElectronicPriorAuthorization,
        ElectronicPriorAuthStatus,
        EpaAnswer,
        EpaQuestion
    } from "@/models/ElectronicPriorAuthorization";
    import PatientDocumentsForm from "@/components/Patient/PatientDocumentsForm.vue";
    import {Patient} from "@/models/Patient";
    import {Prescription} from "@/models/Prescription";
    import {DatePicker} from "element-ui";
    import NotificationTypes = NotificationOptions.NotificationTypes;
    import EnumSelector from "@/components/EnumSelector.vue";
    import TextUtils from "@/util/TextUtils";
    import {PatientDocument} from "@/models/PatientDocument";
    import axios from "axios";
    import NotesList from "../Note/NotesList.vue";
    import NotesForm from '@/components/Note/NotesForm.vue';
    import { EntityType } from "@/models/EntityType";

    @Component({
        components: {
            EnumSelector,
            PatientDocumentsForm,
            [DatePicker.name]: DatePicker,
            NotesList,
            NotesForm,
        }
    })
    export default class ElectronicPriorAuthorizationModal extends Vue {
        @Prop({default: "electronic-prior-authorization-details-modal"}) public modalId!: string;
        @VModel() private electronicPriorAuthorization?: ElectronicPriorAuthorization;
        @Ref('epa-cancel-modal') private epaCancelModal!: BModal;
        private patient: Patient | null = null;
        private epaDetails: ElectronicPriorAuthorization | null = null;
        private selectedCancelReason: string | null = null;
        private selectedDocument: number | null = null;
        private questions? = [] as EpaQuestion[];
        private multiSelected = [];
        private appealText = "";
        private isLoadingDetails = false;
        private questionFields = [
            {key: "id"},
            {key: "text"},
            {key: "choices",},
            // {key: "answer"},
            // {key: "clear"},
            // {key: "show_details"}
        ];
        private noteEntityType = EntityType.ElectronicPriorAuthorization;

        private cancelCodes = [
            {value: null, text: 'Select an option'},
            {value: "BY", text: "Other"},
            {value: "AC", text: "Patient no longer under Provider care"},
            {value: "BW", text: "Change to a different medication"}
        ]

        get epaId(){
            return this.electronicPriorAuthorization?.id;
        }

        get isAppeal() {
            const canAppeal = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.RejectedCanAppeal
            return canAppeal || this.electronicPriorAuthorization?.appealSupported;
        }

        get isComplete() {
            const isApproved = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.Approved;
            const isDenied = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.Denied;
            const isCanceled = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.Canceled
                || this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.CancelApproved
                || this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.CancelRejected;
            return isApproved || isDenied || isCanceled;
        }

        get needsAnswers() {
            const needAnswers = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.QuestionsNeedAnswers;
            const submittedAnswers = this.electronicPriorAuthorization?.status == ElectronicPriorAuthStatus.AnswersSubmitted;
            return this.epaDetails && this.epaDetails.questionSet && (needAnswers || submittedAnswers);
        }

        get authorizationNumber() {
            return this.epaDetails?.authorizationNumber;
        }

        get epaNote() {
            console.log("epaNote: ", this.epaDetails?.note);
            return this.epaDetails?.note;
        }

        get rxId() {
            return `${this.epaDetails?.storeId}-${this.epaDetails?.rxNumber}-${this.epaDetails?.rfNumber}`;
        }

        get shouldShowDocumentsForm() {
            return true;
        }

        get filter() {
            const nextQuestionIds = this.questions?.filter(q => q.answer.text || q.answer.choiceId).flatMap(q => q.nextQuestionId) ?? [];
            console.log("this.questions: ", this.questions);
            const choiceNextQuestionIds = this.questions?.flatMap(q =>
                q.choices.filter(c => {
                    if (c.id == q.answer.choiceId) return true;
                    if (!c.nextQuestionId) return false;
                    if (!c.lowerBoundComparisonValue) return c.id == "NumericType";

                    const nAnswer = Number(q.answer.choiceId);
                    let lowerComparisonPass = true;
                    if (c.lowerBoundComparisonOperator == "GE") {
                        lowerComparisonPass = nAnswer >= c.lowerBoundComparisonValue;
                    } else if (c.lowerBoundComparisonOperator == "GT") {
                        lowerComparisonPass = nAnswer > c.lowerBoundComparisonValue;
                    } else if (c.lowerBoundComparisonOperator == "EQ") {
                        lowerComparisonPass = nAnswer == c.lowerBoundComparisonValue;
                    } else if (c.lowerBoundComparisonOperator == "NE") {
                        lowerComparisonPass = nAnswer != c.lowerBoundComparisonValue;
                    }

                    if (!c.upperBoundComparisonValue || !lowerComparisonPass) return lowerComparisonPass;
                    let upperComparisonPass = true;
                    if (c.upperBoundComparisonOperator == "LE") {
                        upperComparisonPass = nAnswer <= c.upperBoundComparisonValue;
                    } else if (c.upperBoundComparisonOperator == "LT") {
                        upperComparisonPass = nAnswer < c.upperBoundComparisonValue;
                    }
                    return upperComparisonPass;
                })
                    .map(c => c.nextQuestionId)
            ) ?? [];

            let questionIdsToShow = nextQuestionIds.concat(choiceNextQuestionIds).filter(s => s != null);
            const initialQuestion = this.questions?.find(q => q.sequenceNumber == 0 || q.sequenceNumber == 1)?.id as string;
            if (initialQuestion) questionIdsToShow.push(initialQuestion);

            return questionIdsToShow;
        }

        get prescriptionDetailsRoute() {
            return {
                name: 'PrescriptionDetails'
                , params: {
                    storeID: this.epaDetails?.storeId,
                    rxNumber: this.epaDetails?.rxNumber,
                    rfNumber: (this.epaDetails?.rfNumber || 0)
                }
            };
        }

        get patientId() {
            if (this.patient == null) {
                this.loadPatient();
                return 0;
            }
            return this.patient.id;
        }

        formatStatus(status: ElectronicPriorAuthStatus) {
            return TextUtils.camelCaseToNormal(ElectronicPriorAuthStatus[status]);
        }

        resetAllChoices() {
            const answers = this.questions?.flatMap(q => q.answer) ?? [];
            for (const answer of answers) {
                answer.text = null;
                answer.choiceId = null;
            }
        }

        clearAnswer(item: EpaAnswer) {
            item.text = null;
            item.choiceId = null;
        }

        filterFunc(item: EpaQuestion, filter: string[]) {
            return filter.includes(item.id as string);
        }

        @Watch('multiSelected')
        choiceUpdate(item: string[]) {
            if (!item) return;
            console.log("multiSelected: ", item)
            if (!this.questions) return;
            this.questions.forEach(question => {
                let wasReset = false;
                question.choices.forEach(c => {
                    if (question && question.answer) {
                        if (item.includes(c.id as string)) {
                            if (!wasReset) {
                                question.answer.choiceId = "";
                                wasReset = true;
                            }
                            if (question.answer?.choiceId != null) {
                                if (question.answer.choiceId.length > 0) question.answer.choiceId += ", ";
                                question.answer.choiceId += c.id;
                            }
                        }
                    }
                })
            });
        }

        canChooseMultiple(item: EpaQuestion) {
            return item.selectMultiple == true;
        }

        @Watch("shouldShowDocumentsForm")
        loadDocuments() {
            if (!this.electronicPriorAuthorization?.storeId) return;
            if (this.shouldShowDocumentsForm && this.patient == null) {
                this.loadPatient();
            }
        }

        loadPatient() {
            if (this.electronicPriorAuthorization?.storeId == undefined) return;
            this.isLoadingDetails = true;
            const rxId = `${this.electronicPriorAuthorization?.storeId}-${this.electronicPriorAuthorization?.rxNumber}-${this.electronicPriorAuthorization?.rfNumber}`;
            Axios.get<Prescription>(`/Prescription/${rxId}`)
                .then(resp => {
                    let rx = new Prescription(resp.data);
                    Axios.get<Patient>(`/Patient/${rx.patientID}`)
                        .then(ptResp => {
                            this.patient = new Patient(ptResp.data);
                        })
                        .catch(err => {
                            this.$notification(NotificationOptions.error(err));
                        })
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                })
                .finally(() => {
                    this.isLoadingDetails = false;
                });
        }

        reset() {
            this.patient = null;
            this.epaDetails = null;
            this.selectedCancelReason = null;
            this.selectedDocument = null;
            this.questions = [];
            this.multiSelected = [];
            this.appealText = "";
            this.isLoadingDetails = false;
        }

        @Emit()
        hidden() {
            this.reset();
        }

        @Emit()
        show() {
            console.log("showing.isAppeal?", this.isAppeal)
            this.loadDocuments();
        }

        @Watch('selectedDocument')
        selectedDocumentUpdate(val: any, _oldVal: any) {
            console.log("Selected Document change: ", val);
        }

        @Watch('electronicPriorAuthorization.id')
        epaChange(newValue: string) {
            console.log("new value for epa.id", newValue);
            if (!newValue) return;

            this.isLoadingDetails = true;
            Axios.get<ElectronicPriorAuthorization>(`/PriorAuthorization/Electronic/${newValue}`)
                .then(response => {
                    console.log("epaDetails", response.data);
                    this.epaDetails = response.data;
                    this.questions = this.epaDetails?.questionSet?.questions ?? null;
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                })
                .finally(() => this.isLoadingDetails = false);
        }

        choiceTableId(data: any) {
            const id = `choiceTable${data.item.id}`;
            console.log("choiceTableId: ", id);
            return id;
        }

        shouldShowDateInput(item: EpaQuestion) {
            return item.choices.some(c => c.id == "DateType");
        }

        shouldShowTextInput(item: EpaQuestion) {
            if (this.shouldShowDateInput(item)) return false;
            return item.choices.some(c => c.allowsAdditionalText == AllowsAdditionalText.Required
                || c.allowsAdditionalText == AllowsAdditionalText.Optional);
        }

        shouldShowNumericInput(item: EpaQuestion) {
            return item.choices.some(c => c.id == "NumericType");
        }

        acknowledgeEpa() {
            Axios.post(`PriorAuthorization/Electronic/${this.epaDetails?.id}/Acknowledge`)
            .then(acknowledgeResponse => {
                this.$bvModal.hide(this.modalId);
            })
            .catch(err=>{
                this.$notification(NotificationOptions.error(err));
            });
        }

        cancelEpa(event: BvModalEvent) {
            if (!event) return;
            event.preventDefault();
            const config: AxiosRequestConfig = {params: {reason: this.selectedCancelReason}};
            Axios.post(`PriorAuthorization/Electronic/${this.epaDetails?.id}/Cancel`, null, config)
                .then(epaCancelResp => {
                    console.log("epaCancelResp: ", epaCancelResp);
                    this.$notification(NotificationOptions.notificationOptionsPreset("Electronic Prior Authorization Cancelled.", NotificationTypes.success));
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                })
                .finally(() => {
                    if (event.componentId) this.$bvModal.hide(event.componentId);
                    this.patient = null;
                    this.epaDetails = null;
                });
        }

        cancelClicked(event: BvModalEvent) {
            if (!this.epaDetails?.id) return;
            if (!this.questions || this.questions == []) return;
            if (!event.componentId) {
                this.$notification(NotificationOptions.error("Unknown error cancelling Electronic Prior Authorization"));
                return;
            }

            event.preventDefault();

            this.epaCancelModal.show();
        }

        okClicked(event: BvModalEvent) {
            if (!this.epaDetails?.id) return;
            if (!this.questions || this.questions == []) return;
            if (!event.componentId) {
                this.$notification(NotificationOptions.error("Unknown error saving Electronic Prior Authorization"));
                return;
            }

            if (this.selectedDocument) this.epaDetails.attachmentId = this.selectedDocument;

            let method: ((event: BvModalEvent) => Promise<void>) | undefined = undefined;

            if (this.isAppeal) method = this.finishAppeal;
            if (this.needsAnswers) method = this.finishAnswers;

            if (method) {
                event.preventDefault();
                console.log("attempting normal event");
                method(event)
                    .catch(err => {
                        this.$notification(NotificationOptions.error(err));
                    })
                    .finally(() => {
                        this.patient = null;
                        this.epaDetails = null;
                    });
            }
        }

        finishAppeal(event: BvModalEvent) {
            if (this.epaDetails) {
                this.epaDetails.appealNote = this.appealText;
            }
            return Axios.post(`PriorAuthorization/Electronic/${this.epaDetails?.id}/Appeal`, this.epaDetails)
                .then(epaAppealResp => {
                    console.log("epaAppealResp: ", epaAppealResp)
                    if (event.componentId) this.$bvModal.hide(event.componentId);
                });
        }

        finishAnswers(event: BvModalEvent) {
            return Axios.post(`PriorAuthorization/Electronic/${this.epaDetails?.id}/Answer`, this.epaDetails)
                .then(epaAnswerResp => {
                    console.log("epaAnswerResp: ", epaAnswerResp);
                    if (event.componentId) this.$bvModal.hide(event.componentId);
                });
        }

        openDocument(pd: PatientDocument) {
            axios.get(`image/${pd.imageId}`, {responseType: "blob"})
                .then(res => {
                    const objectUrl = URL.createObjectURL(res.data);
                    window.open(objectUrl, '_blank');
                })
                .catch(err => {
                    console.error("Error while getting image to show.", {err, response: err.response});
                })
        }
        refreshNotesList() {
            if (this.$refs.epaNotesList instanceof NotesList)
                (this.$refs.patientNotesList as any).loadNotes();
        }
    }
