import HasID from "./HasID";
import TrackedObject from "./TrackedObject";

export interface PatientDocument extends HasID, TrackedObject{
    patientId: number;
    imageId: number;
    fileName: string;
    select: boolean;
}

export class PatientDocument {
    constructor(obj?: PatientDocument) {
        if (obj) Object.assign(this, obj);
        this.select = false;
    }
}
