import { PatientInsuranceUpdateBase } from "./PatientInsuranceUpdateBase";

export interface PatientInsuranceUpload extends PatientInsuranceUpdateBase {
    patStoreid: number,
    imageURLs: string[],
    uploaded: Date,
}

export class PatientInsuranceUpload {
    
    constructor(obj?:PatientInsuranceUpload){
        if(obj) Object.assign(this, obj);
    }
}
