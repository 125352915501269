export class ValidatorGroup {
    public validators:Array<ValidatorGroup.Validator>

    constructor(arr?:Array<ValidatorGroup.Validator>){
        this.validators = arr ?? new Array<ValidatorGroup.Validator>();
    }

    evaluate():boolean | undefined{
        return this.validators.map(vv => vv.value).reduce((val1, val2) => val1 && val2)
    }

    listValidatorNames(value:boolean):Array<string>{
        return this.validators.filter(vv=>vv.value == value).map(vv=>vv.name);
    }

    concatValidatorNames(value:boolean):string{
        const arr = this.listValidatorNames(value);
        const res = arr.reduce((nm1, nm2) => `${nm1}, ${nm2}`);
        return res;
    }
}

export namespace ValidatorGroup {

    export class Validator {
        name:string;
        value?:boolean;
        constructor(name?:string, value?:boolean){
            this.name = name ?? '';
            this.value = value;
        }
    }
}