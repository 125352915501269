import TrackedObject from "./TrackedObject";
import HasID from "./HasID";
import { Prescription } from "@/models/Prescription";
import { DeliveryCode } from "@/models/DeliveryCode";
import { Payment } from "@/models/Payment";
import { Patient } from "@/models/Patient";
import { Shipment } from "@/models/Shipment";
import { AcceptanceRecord } from "@/models/AcceptanceRecord";
import moment from "moment";

export interface Order extends HasID, TrackedObject {
    storeID: number;
    patientID: number;
    status: OrderStatus;
    storeName: string;
    rxRfs: string;
    printerAddress: string;
    overridePriority: boolean;
    saturdayDelivery: boolean;
    overrideAddress: boolean;
    disableOneRate: boolean;
    signatureRequired: boolean;
    hipaaDocsInBox: boolean;
    stripeCustomerId: string;
    shipmentId: number;
    requiresAudit: boolean;
    auditId: number;
    partnerId: string;
    verified?: Date;
    verifiedBy?: string;

    prescriptions: Array<Prescription>;
    items: Array<OrderOTCItem>;
    payments: Array<Payment>;
    deliveryCode: DeliveryCode | null;
    patient: Patient;
    shipment?: Shipment;

    acceptanceRecords: AcceptanceRecord[];

    promisedDate?: Date | null;
    cancellationReason?: string | null;
}

export enum OrderStatus {
    Error,
    Created,
    Processing,
    Shipped,
    Canceled,
    PartialPayment,
    ReadyForPickup,
    AwaitingPriorAuthorization,
    ReturnToSender
}

export const isPromisedApplicable = (orderStatus: number, promisedDate?: Date | null) : boolean => {
    return [1, 2, 5].includes(orderStatus) && !!promisedDate;
}

export class Order {
    constructor(obj?: Order) {
        if (obj) {
            Object.assign(this, obj);
            this.patient = new Patient(obj.patient);
            this.prescriptions = obj.prescriptions?.map(rx => new Prescription(rx)) ?? new Array<Prescription>();
            this.items = obj.items ?? new Array<OrderOTCItem>();
            this.payments = obj.payments ?? new Array<Payment>();
            this.shipment = new Shipment(obj.shipment);
            if (obj.updated) this.updated = moment(obj.updated).toDate();
            this.acceptanceRecords = obj.acceptanceRecords?.map(ar => new AcceptanceRecord(ar)) ?? new Array<AcceptanceRecord>();
            this.promisedDate = this.promisedDate ?? null;
        } else {
            this.patient = new Patient();
            this.prescriptions = new Array<Prescription>();
            this.items = new Array<OrderOTCItem>();
            this.payments = new Array<Payment>();
            this.acceptanceRecords = new Array<AcceptanceRecord>();
            this.deliveryCode = null;
            this.promisedDate = null;
        }
    }

    get wasShipped(): boolean {
        return this.status == OrderStatus.Shipped;
    }

    get trackingNumber(): string {
        return this.shipment?.trackingNumber ?? "";
    }

    get shipDate(): string {
        return this.shipment?.shipped.toString() ?? "";
    }

    get isPromisedApplicable() : boolean {
        return isPromisedApplicable(this.status, this.promisedDate);
    }
}

export interface OrderOTCItem {
    orderId: number;
    otcItemId: number;
    quantity: number;
    otcItemName: string;
    otcItemUPC?: string;
    price: number;
    tax: number;
}

export interface OrderPrescription extends TrackedObject {
    orderId: number;
    storeID: number;
    rxNumber: number;
    rfNumber: number;
}
