import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { Person } from "./Person";
import moment from "moment";
import { PhoneNumber } from "@/models/PhoneNumber";
import { OptStatus, PhoneOptStatus } from "@/models/OptStatus";
import { SpeciesCode } from "@/models/SpeciesCode";
import HasSearchHelp from "./HasSearchHelp";

export interface Patient extends Person {
    id:number;

    dateOfBirth: Date;
    sex: string;

    emailAddress: string;
    phoneNumber: string;
    cellNumber: string;
    maritalStatus: string;
    height: number; // inches
    weight: number; // lbs
    residenceCode: string;
    pregnant?: Date;
    lactating?: Date;
    smoker?: Date;
    licenseState: string;
    licenseNumber: string;
    headOfHousehold?: number;
    primaryLanguage?: number; // some kind of code?? Backend has this as an int.
    alert: string; // Note to popup for patient anytime it is viewed
    childCaps: boolean;
    signatureRequired: boolean;
    nkda: boolean;
    defaultDelivery: number; // some kind of code? probably want to make an enum
    carriers: string[];
    hipaaDocsReceived: Date;
    species: string;
    speciesCode: SpeciesCode;
    programs: Array<PatientProgram>;
    stripeCustomerId: string;
    ssn: string;
    cellId: number;
    phoneId: number;
    phone: PhoneNumber;
    cell: PhoneNumber;
    phoneOptStatus: OptStatus;
    cellOptStatus: OptStatus;
    patientAppUserName: string;
    primaryPrescriberId?: number;
}

export enum Language {
    English,
    Spanish,
    Russian,
    Somali,
    Arabic,
    ChineseSimplified,
    Vietnamese,
    Farsi,
    Korean,
    Romanian,
    Swahili,
    Burmese,
    Nepali,
    Amharic,
    Pashto,
}

export class Patient extends Person implements HasSearchHelp {
    constructor(obj?: Patient) {
        super();
        //this.id = 0;
        this.childCaps = true;
        this.signatureRequired = false;
        this.residenceCode = "0";
        this.primaryLanguage = 0;
        this.speciesCode = SpeciesCode.Human;

        if (obj) {
            Object.assign(this, obj);
        }
    }

    getSearchHelpHtml() : string {
        return "<p>Search for patients using any of the following formats:</p>" +
        "<ul>" +
        "<li>Phone Number: \"+1XXX...\"</li>" +
        "<li>Patient Name: \"LastName, FirstName\"</li>" +
        "<li>Date of Birth: \"6/17/2010\"</li>" +
        "<li>Partner Fill Number: \"P-1426732\"</li>" +
        "<li>DOB + Name: \"1/1/1970 Jacobson\"</li>" +
        "<li>Email: \"john@domain.com\"</li>" +
        "</ul>";
    }

    toString(): string {
        let label = "";
        if (this.id && this.lastName) {
            const dobString =
                this && this.dateOfBirth
                    ? new Date(this.dateOfBirth).toLocaleDateString()
                    : "No DOB";
            label =
                "<b>" +
                this.displayNamesForPerson() +
                "</b> - " +
                dobString +
                " - " +
                this.address1 +
                `, ${this.addressCity ?? ""}, ${this.addressState ?? ""}`+
                                " <small>(ID: " +
                this.id+")</small>"
;
        }
        if (label == '') {
            label = '<div class="font-weight-light">DOB/Name/Phone/Email</div>';
        }
        return label;
    }

    get age(): number | null {
        if (this.dateOfBirth) return moment().diff(this.dateOfBirth, "years");
        else return null;
    }

    get languageName(): string {
        if (this.primaryLanguage === undefined || this.primaryLanguage === null)
            return "";

        return Language[this.primaryLanguage];
    }

    get phoneDisplay(): string {
        return (
            this.phone?.nationalFormat ??
            this.phoneNumber ??
            this.phone?.originalInput
        );
    }

    get cellDisplay(): string {
        return (
            this.cell?.nationalFormat ??
            this.cellNumber ??
            this.cell?.originalInput
        );
    }

    get optInOptOutPhoneStatus(): PhoneOptStatus {
        return this.optStatus(this.phoneOptStatus.optIn, this.phoneOptStatus.optOut);
    }

    get optInOptOutCellPhoneStatus(): PhoneOptStatus {
        return this.optStatus(this.cellOptStatus.optIn, this.cellOptStatus.optOut);
    }

    private optStatus(optIn: Date | null, optOut: Date | null): PhoneOptStatus {
        if (optIn != null) optIn = moment(optIn).toDate();
        if (optOut != null) optOut = moment(optOut).toDate();

        if (optIn != null && optOut != null) return optOut > optIn ? PhoneOptStatus.optOut : PhoneOptStatus.optIn;
        if (optIn != null) return PhoneOptStatus.optIn;
        if (optOut != null) return PhoneOptStatus.optOut;
        return PhoneOptStatus.unknown;
    }

    get phoneOptDateInfo() : string {
        if (this.optInOptOutPhoneStatus !== PhoneOptStatus.optOut) return "";
        return `Opt Out Date: ${this.formatOptDate(this.phoneOptStatus.optOut)}`;
    }

    get cellOptDateInfo() : string {
        if (this.optInOptOutCellPhoneStatus !== PhoneOptStatus.optOut) return "";
        return `Opt Out Date: ${this.formatOptDate(this.cellOptStatus.optOut)}`;
    }

    private formatOptDate(value: Date | null) : string {
        if (!value) return "";
        const momentDate = moment(value);
        return `${momentDate.format("LL")} (${momentDate.fromNow()})`;
    }
}

export enum ResidenceCode {
    NotSpecified,
    PatientsHome,
    SkilledNursing,
    Nursing,
    AssistedLiving,
    CustodialCare,
    GroupHome,
    InpatientPsychiatric,
    Psychiatric,
    IntermediateCare,
    SubstanceAbuseTreatment,
    Hospice,
    PsychiatricTreatment,
    ComprehensiveRehab,
    HomelessShelter,
    CorrectionalInstitution,
}

export interface PatientProgram extends HasID, TrackedObject {
    programID: number;
    patientID: number;
    programName: string;
    identifier: string;
}
