import HasID from "./HasID";
import HasLabel from "./HasLabel";
import TrackedObject from "./TrackedObject";

export interface Payment extends HasID, TrackedObject {
    id: number
    chargeId: string
    patientId: number
    authorizedAmount: number
    orderId: number
    StripeCardId: string
}

export interface CustomerInfo {
    id: string
    name: string
    patientId: string | number
}

export interface StripeCard {
    id: string;
    brand: string;
    cardNumber: string;
    expMonth: number;
    expYear: number;
    customerId: string;
    amount: number;
    amountCaptured: number;
    amountRefunded: number;
    pendingDelete: boolean;
    fingerprint: string;
    authorized: Date;
    transactionId: string;
}

export interface StripeLocation extends HasID {
    id: string;
    displayName: string;
}

export class StripeLocation extends HasLabel {
    constructor(obj?: StripeLocation) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (this.displayName) {
            return `${this.displayName} - ${this.id}`;
        }
        return "";
    }
}

export interface StripeReader extends HasID {
    id: string;
    label: string;
    serialNumber: string;
    location: StripeLocation;
    status: string;
}

export class StripeReader extends HasLabel {
    constructor(obj?: StripeReader) {
        super();
        if (obj) {
            Object.assign(this, obj);
            this.location = new StripeLocation(obj.location);
        }
    }

    toString(): string {
        let display = "";
        if (this.location?.displayName) {
            display += this.location.displayName;
        }
        if (this.label) {
            if (display.length > 0) display += " - ";
            display += `${this.label} - ${this.serialNumber}`;
        }
        if (this.status == "offline") {
            display = "❌ " + display;
        }
        if (this.status == "online") {
            display = "✔️ " + display;
        }
        return display;
    }
}
