export class DashboardShipmentSummaryRecord {
    programId = 0;
    programName = '';
    totalRevenue = 0;
    totalPlanPaid = 0;
    totalVoucherPaid = 0;
    patientCopay = 0;
    costOfGoods = 0;
    grossMargin = 0;
    consignedDispenseFee = 0;
    newFills = 0;
    refills = 0;
    totalFills = 0;
    averagePrice = 0;
    brand = 0;
    generic = 0;
    totalTax = 0;
    storeName = '';
    marginPercentage = 0;
    fillString = '';
    netMarginPercentage = 0;
    netMargin = 0;
    isConsignment = 0;
}

export class DashboardFinances
{
    totalRevenue = 0;
    totalPlanPaid = 0;
    totalVoucherPaid = 0;
    totalCopay = 0;
    costOfGoods = 0;
    consignedDispenseFee = 0;
    taxCollected = 0;
    grossMargin = 0;
    netMargin = 0;
}
export class DashboardFillInfo {
    totalNewRx = 0;
    totalRefills = 0;
    totalFills = 0;
    averagePrice  = 0;
}
export class DashboardBrandVsGeneric
{
    brand = 0;
    generic = 0;
    constructor(obj?:DashboardBrandVsGeneric){
        if(obj) {
            Object.assign(this, obj);
        }
    }
    get brandPercentage():number {
        if(this.brand+this.generic == 0) return 0;

        return this.brand/(this.brand+this.generic);
    }
    get genericPercentage():number {
        if(this.brand+this.generic == 0) return 0;

        return this.generic/(this.brand+this.generic);
    }
}

export class DashboardShipmentSummary{
    records : Array<DashboardShipmentSummaryRecord> = new Array<DashboardShipmentSummaryRecord>();
    finances : DashboardFinances = new DashboardFinances();
    fillInfo : DashboardFillInfo = new DashboardFillInfo();
    brandVsGeneric : DashboardBrandVsGeneric = new DashboardBrandVsGeneric();
    constructor(obj?: DashboardShipmentSummary){
        this.records = new Array<DashboardShipmentSummaryRecord>();
        this.finances = new DashboardFinances();
        this.fillInfo = new DashboardFillInfo();
        this.brandVsGeneric = new DashboardBrandVsGeneric();
        if(obj){
            Object.assign(this, obj);
            this.brandVsGeneric = new DashboardBrandVsGeneric(obj.brandVsGeneric);
        }
    }
}
