export class DashboardTotalQuantities{
    scriptsTodayCount  = 0;
    revenueToday  = 0;
    openOrdersCount  = 0;
    shippedTodayCount  = 0;
    returnToSenderTodayCount = 0;

    constructor(obj?:any){
        if(obj) Object.assign(this, obj);
    }
}
