import HasID from "./HasID";
import TrackedObject from "./TrackedObject";

export interface DrugDocument extends HasID, TrackedObject{
    drugId : number;
    drugSource : number;
    imageId : number;
    fileName : string;
    isChecked: boolean;
}

export class DrugDocument { 
    constructor(obj?:DrugDocument){
        if(obj) Object.assign(this, obj);
    }
}