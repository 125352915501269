
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import axios from 'axios';
import { Patient } from '@/models/Patient';
import { RxTransfer, RxTransferContents, RxTransferReason, transferStatus } from '@/models/RxTransfer';
import { DatePicker } from 'element-ui'
import { Note } from '@/models/Note';
import NotesList from '@/components/Note/NotesList.vue'
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import moment from 'moment'
import SearchComponent from "@/components/SearchComponent.vue";
import { PharmacyRecord } from "@/models/Pharmacy";
import { PharmacistRecord } from "@/models/Pharmacist";
import PharmacyDetailPage from "@/pages/Pharmacy/PharmacyDetailPage.vue";
import PharmacistDetailPage from "@/pages/Pharmacist/PharmacistDetailPage.vue";

@Component({
    name: "RxTransferModalComponent",
    components: {
        PharmacistDetailPage,
        PharmacyDetailPage,
        SearchComponent,
        [DatePicker.name]: DatePicker,
        NotesList
    }
})

export default class RxTransferModalComponent extends Vue {
    @Prop() patient!: Patient;
    @Prop() TransferIdDetails!: number;
    public startingContents: RxTransferContents[] = [];
    protected notes: Note[] = [];
    private transfer: RxTransfer = { contents: [...this.startingContents], notes: [...this.notes], inbound: undefined } as RxTransfer;
    private newDrugName: string = "";
    private newDrugRx: number | null = null;
    private newNote: Note = null as unknown as Note;
    private pharmacy: PharmacyRecord = new PharmacyRecord();
    private pharmacist: PharmacistRecord = new PharmacistRecord();
    private transferReason: RxTransferReason = new RxTransferReason();
    @Ref("xferReason") xferReason?: SearchComponent;

    protected sendFax: boolean = false;

    protected fields: any[] = [
        { key: "medicationName", label: "Drug" },
        "rxNumber",
        { key: "remove", label: "" },
    ];

    @Watch('startingContents', { deep: true })
    startingContentsUpdate(_newVal: any, _oldVal: any) {
        this.populateWithStartingContents();
    }

    populateWithStartingContents() {
        if (!this.transfer.contents) this.transfer.contents = [];
        for (const startingContent of this.startingContents) {
            this.newDrugName = startingContent.medicationName;
            this.newDrugRx = startingContent.rxNumber;
            this.addToTransferContents();
        }
        if (this.transfer.inbound === undefined) {
            this.transfer.inbound = false;
        }    
    }

    addToTransferContents() {
        const contents: RxTransferContents = {
            medicationName: this.newDrugName,
            rxNumber: this.newDrugRx
        } as RxTransferContents;

        this.transfer.contents.push(contents);
        this.newDrugRx = null;
        this.newDrugName = "";
    }

    created() {
        if (this.TransferIdDetails) {
            this.getExistingTransferDetails()
        }
    }

    getExistingTransferDetails() {
        axios.get(`/RxTransfer/${this.TransferIdDetails}/GetByTransferId`)
            .then(response => {
                this.transfer = response.data;
            })
    }

    removeDrugFromTransfer(drug: RxTransferContents) {
        this.transfer.contents = this.transfer.contents.filter(function (el) {
            return el.medicationName != drug.medicationName
        })
    }

    get isFormComplete(): boolean {
        if (!this.transfer) {
            return false;
        }
        if (this.transfer.contents.length == 0) {
            return false;
        }
        if (!this.pharmacy?.name?.length) {
            return false;
        }
        if (!this.transfer.externalPharmacyName || this.transfer.externalPharmacyName == "") {
            return false;
        }
        if (!this.transfer.externalPharmacyPhone || this.transfer.externalPharmacyPhone == "") {
            return false;
        }
        // if (!this.pharmacist?.lastName?.length) {
        //     return false;
        // }
        // if (!this.transfer.externalPharmacistName || this.transfer.externalPharmacistName == "") {
        //     return false;
        // }
        return this.transfer.inbound != undefined;
    }

    get disableFax() {
        return !(this.transfer.inbound && this.pharmacy?.faxNumber?.length > 0);
    }
    get editingDisabled() {
        return !!this.TransferIdDetails;
    }

    saveNote() {
        if (this.newNote) {
            axios.post(`/RxTransfer/${this.TransferIdDetails}/SaveNote/${this.newNote}`).then(_response => {
                this.getExistingTransferDetails();
                this.newNote = null as unknown as Note;
            }).catch(error => {
                this.$notification(NotificationOptions.error(error));
            });
        }
    }

    pharmacySelected() {
        this.transfer.externalPharmacyName = this.pharmacy.name;
        this.transfer.externalPharmacyPhone = this.pharmacy.phoneNumber;
    }

    pharmacistSelected() {
        if (isNaN(this.pharmacy.id)) return;
        this.transfer.externalPharmacistName = `${this.pharmacist?.lastName}, ${this.pharmacist?.firstName}`;
        if (!this.pharmacist) this.transfer.externalPharmacistName = "";
    }

    rxTransferReasonSelected() {
        if (isNaN(this.transferReason.id)) return;
        this.transfer.rxTransferReasonId = this.transferReason.id;
    }

    submitRxTransfer() {
        if (this.newNote) {
            const addedNote: Note = { body: this.newNote } as unknown as Note;
            this.transfer.notes.push(addedNote);
        }
        // this.transfer.inbound = this.transfer.inbound as unknown as string === 'true';
        this.transfer.rxTransferReasonId = this.transferReason.id;
        this.transfer.externalPharmacyFax = this.pharmacy.faxNumber;
        this.transfer.patientId = Number(this.patient.id);

        axios.post(`/api/RxTransfer/${this.patient.id}/AddToRxTransferQueue`,
            this.transfer,
            { params: { sendFax: this.transfer.inbound && this.sendFax } })
            .then(_response => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Transfer Created Successfully", NotificationOptions.NotificationTypes.success));
                this.$emit("complete");
            })
            .catch((err) => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Create Transfer", NotificationOptions.NotificationTypes.danger));
                this.$notification(NotificationOptions.error(err));
            });
    }

    changeRxTransferStatus(statusId: number, reason: string | null) {
        if (!reason) {
            reason = null;
        }
        axios.post(`/RxTransfer/${this.TransferIdDetails}/${this.transfer.patientId}/ChangeStatus/${statusId}/${reason}`)
            .then(_response => {
                this.saveNote();
                this.$notification(NotificationOptions.notificationOptionsPreset("Change Saved", NotificationOptions.NotificationTypes.success));
                this.$emit("complete");
            })
            .catch(_error => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Save Change", NotificationOptions.NotificationTypes.danger));
            });
    }

    convertDate(date: string | Date) {
        return moment.utc(date).format("L");
    }

    getRxTransferEnum(status: number) {
        return transferStatus[status];
    }

    newStoredPharma() {
        this.pharmacy = new PharmacyRecord();
        this.$bvModal.show("addNewPharmacy");
    }

    newStoredPharmacist() {
        this.pharmacist = new PharmacistRecord();
        this.$bvModal.show("addNewPharmacist");
    }

    onPharmacyCreate(newPharma: PharmacyRecord) {
        this.pharmacy = newPharma;
        this.$bvModal.hide("addNewPharmacy");
    }

    onPharmacistCreate(newPharmacist: PharmacistRecord) {
        this.pharmacist = newPharmacist;
        this.$bvModal.hide("addNewPharmacist");
        this.$nextTick(() => {
            setTimeout(() => {
                this.xferReason?.focus();
            }, 300);
        });
    }
}
