import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { User } from "@/models/User";
import Vue from "vue";

const cognitoLoginUrl = `https://toptier-scriptly.auth.ca-central-1.amazoncognito.com`;
const clientId = "1o746qegtvvvncsh6dboi8p0h7";

const sha256 = async (str: string): Promise<ArrayBuffer> => {
    return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

const generateNonce = async (): Promise<string> => {
    const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const hashArray = Array.from(new Uint8Array(hash));
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
};

const base64URLEncode = (str: any): string => {
    return btoa(String.fromCharCode.apply(null, ((new Uint8Array(str)) as unknown as number[])))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
};

const redirectToPrevious = (): void => {
    const url = sessionStorage.getItem('redirect-url');
    if (url !== null) window.location.assign(url);
    sessionStorage.removeItem('redirect-url');
}

const redirectToLogin = async (): Promise<void> => {
    const state = await generateNonce();
    const codeVerifier = await generateNonce();
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const codeChallenge = base64URLEncode(await sha256(codeVerifier));
    sessionStorage.setItem('redirect-url', window.location.href);
    window.location.assign(`${cognitoLoginUrl}/login?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`);
};

export const fetchUser = (): void => {
    if (!(Vue.prototype.$user.userName?.length > 0)) {
        console.log("fetching user");
        axios.get<User>("/User/info")
            .then(res => {
                if (res.data.userName) {
                    Vue.prototype.$user = Object.assign(new User(), res.data);
                    console.log("Populating $user");
                } else {
                    console.error("No user information available.");
                }
            })
            .catch(err => {
                console.log("Error while getting user info", {
                    err, response: err?.response
                });
            });
    }
}

const fetchToken = (searchParams: URLSearchParams): Promise<any> => {
    // window.history.replaceState({}, document.title, "/");
    const state = searchParams.get("state");
    const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);
    const tokenString = localStorage.getItem("tokens");
    let refreshToken = "";
    if (tokenString !== null)
    {
        const tokens = JSON.parse(tokenString);
        refreshToken = tokens.refresh_token;
    }
    return fetch(`${cognitoLoginUrl}/oauth2/token`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/x-www-form-urlencoded" }),
        body: Object.entries({
            "grant_type": "authorization_code",
            "client_id": clientId,
            "code": searchParams.get("code"),
            "code_verifier": codeVerifier,
            "redirect_uri": window.location.origin,
            "refresh_token": refreshToken,
        }).map(([k, v]) => `${k}=${v}`).join("&"),
    }).then(async res => {
        const tokens = await res.json();
        console.log("res: ", res);
        if (!res?.ok) {
            throw new Error(tokens);
        }
        localStorage.setItem("tokens", JSON.stringify(tokens));

        axios.defaults.headers.Authorization = `Bearer ${tokens.id_token}`;
        sessionStorage.removeItem(`codeVerifier-${state}`);
        redirectToPrevious();
        return tokens;
    });
};

const fullFetch = (config: AxiosRequestConfig): Promise<any> => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("code") !== null) {
        return new Promise((resolve, reject) => {
            return fetchToken(searchParams).then((tokens: any) => {
                config.headers.Authorization = `Bearer ${tokens.id_token}`;
                return resolve(config);
            }).catch(err => reject(err));
        });
    } else {
        const storedToken = localStorage.getItem("tokens");
        if (storedToken !== null) {
            const tokens = JSON.parse(storedToken);
            axios.defaults.headers.Authorization = `Bearer ${tokens.id_token}`;
            if (config && config.headers) {
                config.headers.Authorization = `Bearer ${tokens.id_token}`;
            }
            return Promise.resolve();
        } else {
            return redirectToLogin();
        }
    }
};

export const getToken = (): Promise<any> => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("code") !== null) {
        return new Promise((resolve, reject) => {
            return fetchToken(searchParams).then((tokens: any) => {
                return resolve(tokens.id_token);
            }).catch(err => reject(err));
        });
    } else {
        const storedToken = localStorage.getItem("tokens");
        if (storedToken !== null) {
            const tokens = JSON.parse(storedToken);
            return Promise.resolve(tokens.id_token);
        } else {
            return redirectToLogin();
        }
    }
};

const errorHandler = (err: AxiosError): any => {
    if (err?.response?.status == 401) {
        localStorage.removeItem("tokens");
        const config = err.response.config;
        return fullFetch(config);
    }
    return Promise.reject(err);
};

const requestHandler = (config: AxiosRequestConfig): any => {
    // if the url is using our base url and contains the '/api'
    // again we trim it off since it's included in the base url
    if (config.url?.startsWith('/api')) {
        config.url = config.url?.substr(4);
    }
    if (config.url?.startsWith('api')) {
        config.url = config.url?.substr(3);
    }
    if (
        !config?.headers?.Authorization ||
        config.headers.Authorization == "Bearer " ||
        config.headers.Authorization == "Bearer Unknown"
    ) {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("code");
        if (code !== null) {
            return new Promise((resolve, reject) => {
                return fetchToken(searchParams).then((tokens: any) => {
                    config.headers.Authorization = `Bearer ${tokens.id_token}`;
                    return resolve(config);
                }).catch(err => reject(err));
            });
        } else {
            const storedToken = localStorage.getItem("tokens");
            if (storedToken !== null) {
                const tokens = JSON.parse(storedToken);
                axios.defaults.headers.Authorization = `Bearer ${tokens.id_token}`;
                if (config && config.headers) {
                    config.headers.Authorization = `Bearer ${tokens.id_token}`;
                }
            } else {
                return redirectToLogin();
            }
        }
    } else {
        if (config.url != "/User/info") {
            fetchUser();
        }
    }
    return config;
};

export function setupCognito(): void {
    axios.interceptors.request.use(requestHandler);
    axios.interceptors.response.use(
        response => response,
        (err: AxiosError) => errorHandler(err)
    );
}

export function logoutCognito(): void {
    localStorage.removeItem("tokens");
    window.location.assign(`${cognitoLoginUrl}/logout?response_type=code&client_id=${clientId}&logout_uri=${window.location.origin}/login`);
}
