import { render, staticRenderFns } from "./OtherMedicationsComponent.vue?vue&type=template&id=5f801726&scoped=true"
import script from "./OtherMedicationsComponent.vue?vue&type=script&lang=ts"
export * from "./OtherMedicationsComponent.vue?vue&type=script&lang=ts"
import style0 from "./OtherMedicationsComponent.vue?vue&type=style&index=0&id=5f801726&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f801726",
  null
  
)

export default component.exports