
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
    import { Table, TableColumn } from 'element-ui'
    import SearchComponent from '@/components/SearchComponent.vue';
    import { CompoundWarningLabels } from '@/models/Drug/CompoundDrug';

    @Component({
        name: "WarningLabelsComponent",
        components: {
            SearchComponent,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        }
    })
    export default class WarningLabelsComponent extends Vue {

        @Prop() private compoundId!: number;
        @Prop() private warningLabels!: CompoundWarningLabels [];

        private showForm: boolean = false;
        private upsertId: number = 0;
        private upsertOrder: number = 0;
        private upsertWarningLabel: string = "";
        private upsertWarningLabelRow: CompoundWarningLabels = new CompoundWarningLabels;

        private warningLabelFields = [
            "id",
            "order",
            { key: "warning", label: "Warning Label", },
            { key: "actions", label: "" },
        ]

        submitUpsert() {
            this.upsertWarningLabelRow = new CompoundWarningLabels;
            this.upsertWarningLabelRow.id = this.upsertId || 0;
            this.upsertWarningLabelRow.compoundID = this.compoundId;
            this.upsertWarningLabelRow.order = this.upsertOrder || 0;
            this.upsertWarningLabelRow.warning = this.upsertWarningLabel!;
            if (this.warningLabels.map(item => item.id).indexOf(this.upsertWarningLabelRow.id) > -1) {
                this.warningLabels.splice(this.warningLabels.map(item => item.id).indexOf(this.upsertWarningLabelRow.id), 1);
            }
            this.warningLabels.push(this.upsertWarningLabelRow);
            this.warningLabels.sort((a, b) => (a.order > b.order) ? 1 : -1)
            this.closeForm();
            this.$emit('continue');
        }

        updateWarningLabel(item: CompoundWarningLabels) {
            this.showForm = true;
            this.upsertId = item.id;
            this.upsertOrder = item.order;
            this.upsertWarningLabel = item.warning;
        }

        deleteConfirmDialog(item: CompoundWarningLabels) {
            this.$bvModal.msgBoxConfirm(`This WarningLabel record associated to this drug will get deleted. Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (!value) return;
                    this.deleteWarningLabel(item);
                })
                .catch(err => {
                    console.error("Error caught on Delete button.", err);
                });
        }

        deleteWarningLabel(item: CompoundWarningLabels) {
            if (!item) return;
            const itemIdType = typeof (item.id);
            if (itemIdType != "number") return;
            if (this.warningLabels.map(item => item.id).indexOf(item.id) < 0) return;
            this.warningLabels.splice(this.warningLabels.map(wl => wl.id).indexOf(item.id), 1);
            this.$emit('delete');
        }

        openForm() {
            this.clearForm();
            this.showForm = true;
        }

        closeForm() {
            this.clearForm();
            this.showForm = false;
        }

        clearForm() {
            this.upsertId = Math.min(...this.warningLabels.map(item => item.id)) - 1;
            if (this.upsertId > 0) this.upsertId = 0;
            this.upsertOrder = 0;
            this.upsertWarningLabel = "";
        }

        @Emit('cancel')
        onCancelClick() {
            this.closeForm();
        }

    }
