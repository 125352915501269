

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Consultation } from '../../models/Consultation';
import Radio from "src/components/Inputs/Radio.vue";
import { Prescription } from '@/models/Prescription';
import SearchComponent from '../SearchComponent.vue';

@Component({
    name: "CompleteConsultationComponent",
    components: {
        [Radio.name]: Radio,
        SearchComponent
    }
})
export default class CompleteConsultationComponent extends Vue {
    @Prop() private consultation!: Consultation;
    @Prop() private enableEdit!: boolean;

    private completed = 'false';
    private isClinical = 'false';
    private backUpStatus = 0;
    private prescription: Prescription | null = null;

    created() {
        console.log("%c Created CompleteConsultationComponent", "color: green");
        this.backUpStatus = this.consultation.status
        this.completed = this.consultation.status == 4 ? 'true' : 'false';
        this.isClinical = this.consultation.clinical ? 'true' : 'false';
        this.prescription = Object.assign(new Prescription(), { rxNumber: this.consultation.rxNumber, drugName: this.consultation.drugName });
    }
    beforeDestroy() {
        console.log('%c Destroying CompleteConsultationComponent', 'color: red');
    }

    @Watch('completed')
    updateStatus() {
        this.consultation.status = this.completed == 'true' ? 4 : 2;
    }

    @Watch('isClinical')
    updateClinical() {
        this.consultation.clinical = this.isClinical == 'true';
    }

    @Watch('prescription')
    updatePrescription() {
        this.consultation.rxNumber = this.prescription?.rxNumber;
    }
}
