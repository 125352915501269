import TrackedObject from "./TrackedObject";

export interface ReturnToSender  extends TrackedObject {
    orderId: number;
    patientId: number;
    patientName?: string;
    programName?: string;
    drugName?:string;
    shipDate: Date;
    returnDate: Date;
    returnReason: string;
}

export enum ReturnReason {
    WrongAddress,
    PatientRefusedDelivery,
    LostDuringShipping
}

export class ReturnToSender implements ReturnToSender{
    constructor(obj?:ReturnToSender){
        if(obj) Object.assign(this, obj);
    }
}
