
    import Vue from 'vue';
    import axios from 'axios';
    import Axios, { AxiosRequestConfig } from 'axios';
    import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
    import Vue2Dropzone from 'vue2-dropzone';

    import SearchComponent from '@/components/SearchComponent.vue';
    import EnumSelector from '@/components/EnumSelector.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
    import NameSearchComponent from '@/components/NameSearchComponent.vue'

    import { Plan, BillingFlags } from '@/models/Plan';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { PracticeResult, ProviderWebPerson, AnnouncementResult, Announcement } from "@/models/ProviderWeb";
    import { Prescriber } from "@/models/Prescriber";

    @Component({
        name: 'ProviderWebPage',
        components: {
            SearchComponent,
            Checkbox,
            NewClearSaveButtons,
            EnumSelector,
            NameSearchComponent,
            "VueDropzone": Vue2Dropzone
        },
    })
    export default class ProviderWebPage extends Mixins(DisableAutocompleteMixin) {

        private practices: PracticeResult[] = [];
        private selectedPractice: PracticeResult = {} as PracticeResult;
        private practiceDoctors: ProviderWebPerson[] = [];
        private practiceUsers: ProviderWebPerson[] = [];
        private editMode: string | null = null;
        private newPerson: ProviderWebPerson = {} as ProviderWebPerson;
        private doctor: Prescriber = new Prescriber();
        private displayName = "";
        private nameIntroduced = "";
        private newPracticeName = "";
        private announcement: AnnouncementResult = {} as AnnouncementResult;
        private announcements: Announcement[] = [];

        private userFields = [
            { key: 'username', sortable: true },
            { key: 'email', sortable: true, },
            { key: 'npiNumber', sortable: true, },
            { key: "remove", label: "", },
        ];

        private doctorFields = [
            { key: 'name', sortable: true },
            { key: 'npiNumber', sortable: true, },
            { key: "remove", label: "", },
        ];

        created() {
            this.getPractices();
            this.getAnnouncements();
        }

        // dropzone
        // properties
        get dropzoneOptions(): any {
            return {
                url: `${Axios.defaults.baseURL}/image/ProviderWeb`,
                thumbnailWidth: 150,
                withCredentials: true,
                paramName: 'images',
            };
        }
        // events
        dropzoneSending(file: any, xhr: XMLHttpRequest, formData: FormData) {
            xhr.setRequestHeader("Authorization", Axios.defaults.headers["Authorization"]);
        }

        dropzoneUploadSuccess(file: any, response: any) {
            const dropzone: any = this.$refs.myVueDropzone;
             dropzone.removeFile(file);

            this.announcement.imageurl = response;
            console.log("response: ", response)
        }

        selectPractice(practice: any) {
            this.$bvModal.show('practiceDetailModal');
            this.selectedPractice = practice;

            this.getPracticeUsers();
            this.getPracticeDoctors();

        }

        getAnnouncements() {
            axios.get<Announcement[]>(`/ProviderWeb/Announcement`)
                .then(response => {
                    this.announcements = response.data;
                });
        }

        getPractices() {
            axios.get(`/ProviderWeb/Practices/All`).then(response => {
                this.practices = response.data;
            })
        }

        getPracticeUsers() {
            axios.get(`/ProviderWeb/Practices/Users/${this.selectedPractice.id}`).then(response => {
                this.practiceUsers = response.data;
            })
        }

        getPracticeDoctors() {
            axios.get(`/ProviderWeb/Practices/Doctors/${this.selectedPractice.id}`).then(response => {
                this.practiceDoctors = response.data;
            })
        }

        editModeChange(chosenMode: string) {
            if (chosenMode != this.editMode) {
                this.newPerson = {} as ProviderWebPerson;
            }
            this.editMode = chosenMode;
            if (chosenMode == 'doctor') {
                this.$bvModal.show('doctorSearchModal');
            }

        }

        submitEdit() {
            if (this.editMode == 'user') {
                axios.post(`/ProviderWeb/Practices/${this.selectedPractice.id}/CreateUser`, this.newPerson).then(response => {
                    this.newPerson = {} as ProviderWebPerson;
                })
                    .catch((error) => {
                        this.$notification(NotificationOptions.error(error));
                    })
            }

        }

        selectDoc() {
            this.newPerson.name = this.doctor.lastName + ', ' + this.doctor.firstName;
            this.newPerson.npiNumber = this.doctor.npiNumber;
            this.newPerson.practiceId = this.selectedPractice.id;
            axios.post(`/ProviderWeb/Practices/AddDoctor`, this.newPerson).then(response => {
                this.getPracticeDoctors();
            })
                .catch((error) => {
                    this.$notification(NotificationOptions.error(error));
                })

        }

        personUpdate(value: any) {
            this.doctor = value;
        }
        nameChanged(val: string) {
            this.nameIntroduced = val;
        }

        removeUser(user: any) {
            console.log(user);
            axios.post(`/ProviderWeb/Practices/${this.selectedPractice.id}/DeleteUser`, user).then(response => {
                this.getPracticeUsers();
            })
        }

        removeDoctor(doctor: any) {
            doctor.practiceId = this.selectedPractice.id
            axios.post(`/ProviderWeb/Practices/DeleteDoctor`, doctor).then(response => {
                this.getPracticeDoctors();
            })
        }

        SubmitNewPractice() {
            axios.post(`/ProviderWeb/Practices/New/${this.newPracticeName}`).then(response => {
                this.getPractices();
                this.newPracticeName = "";
            }).catch((error) => {
                this.$notification(NotificationOptions.error(error));
            })
        }

        SubmitNewAnnouncement() {
            axios.post(`/ProviderWeb/Announcement`, this.announcement)
                .then(response => {
                    this.announcement = {} as AnnouncementResult;
                }).finally(() => {
                    this.getAnnouncements();
                })
        }
    } // close export of class
