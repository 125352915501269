
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios';
import { User } from '@/models/User';

@Component({
    name: "UserComponent",
    components: {
    }
})
export default class UserComponent extends Vue {
    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        this.fetchAllUsers();
    }

    beforeDestroy() {
        console.log(`%c Destroying ${this.$options.name}`, "color: red");
    }

    private selectedUser: User | null = null;
    private users: User[] = [];

    async fetchAllUsers() {
        try {
            const response = await axios.get<User[]>(`/User`);
            this.users = response.data;
        } catch {
            //ignore
        }
    }

    rowClicked(user: User) {
        this.selectedUser = user;
        this.$bvModal.show("user-modal");
    }

    async handleOk(bvModalEvent: any) {
        bvModalEvent.preventDefault();

        try {
            const response = await axios.post(`/User/SaveCallback`, this.selectedUser);
            const responseUser = response.data;
            this.users = this.users.map(u => u.userName == responseUser.userName ? responseUser : u);
            this.$nextTick(() => {
                this.selectedUser = {} as unknown as User;
                this.$bvModal.hide("user-modal");
            });
        } catch {
            //ignore
        }
    }

    get tableFields() {
        return [
            "userName",
            "initials",
            "isAdmin",
            "isSuperAdmin",
            "isManager",
            "isPharmacist",
            "isPatientsManager",
            "isInventoryManager",
            "isReadOnly",
            "isCompoundManager",
            { key: "isPapApproval", label: "Is PAP Approval" }
        ]
    }
}
