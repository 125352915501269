var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.shouldShowAll)?_c('b-row',[_c('b-col',[_c('checkbox',{attrs:{"inline":""},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}},[_vm._v("Show All")])],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"items":_vm.filteredConsultations,"fields":_vm.consultationFields,"show-empty":"","empty-text":"No records found","tbody-tr-class":_vm.colorCodeTable,"per-page":_vm.pageSize,"current-page":_vm.pageNumber},scopedSlots:_vm._u([{key:"cell(patientName)",fn:function(data){return [_c('div',[(_vm.patientNameAsLink)?_c('router-link',{attrs:{"to":{ name: 'PatientDashboard', params: { id: data.item.patientId.toString() } }}},[_vm._v(" "+_vm._s(data.item.patientName)+" ")]):_c('span',[_vm._v(" "+_vm._s(data.item.patientName)+" ")])],1),_c('div',[_c('small',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.dateOfBirth,'L'))+" ")])])]}},{key:"cell(buttons)",fn:function(data){return [_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1",staticStyle:{"cursor":"pointer"},attrs:{"icon":"x-circle-fill","title":"Cancel","variant":"danger"},on:{"click":function($event){return _vm.detailsConsultation(data.item, true)}}}),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"b-modal",rawName:"v-b-modal",value:('complete-consultation'),expression:"'complete-consultation'"}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"pencil-square","title":"Details","variant":"info"},on:{"click":function($event){return _vm.detailsConsultation(data.item)}}})]}},{key:"cell(rxNumber)",fn:function(data){return [(data.value)?[_c('router-link',{attrs:{"to":{
                            name: 'PrescriptionDetails'
                            , params: {
                                storeID: data.item.storeId,
                                rxNumber: data.value,
                                rfNumber: 0
                            }
                        }}},[_vm._v(" "+_vm._s(`${data.item.storeId}-${data.value}`)+" ")]),_c('div',[_c('small',{staticClass:"text-muted font-weight-normal"},[_vm._v(" "+_vm._s(data.item.drugName)+" ")])])]:_vm._e()]}}])}),_c('BottomPagerBar',{attrs:{"total-rows":_vm.filteredConsultations.length,"per-page":_vm.pageSize,"show-total":"","item-name":"consultation","show-page-size-selector":""},on:{"change-page-size":function($event){_vm.pageSize = $event}},scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.hidingCount > 0)?_c('b-badge',{staticClass:"mb-0",staticStyle:{"cursor":"pointer"},attrs:{"variant":"info"},on:{"click":function($event){_vm.showAll = true}}},[_vm._v(" Hiding "+_vm._s(_vm.hidingCount)+" "+_vm._s(_vm.hidingCount === 1 ? 'Consultation' : 'Consultations')+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1),_c('b-modal',{attrs:{"id":"complete-consultation","title":"Complete Consultation","hide-footer":!_vm.enableEdit},on:{"ok":_vm.updateConsultation},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.updateConsultation}},[_vm._v(" Save ")])]},proxy:true}])},[_c('CompleteConsultationComponent',{attrs:{"consultation":_vm.consultation,"enable-edit":_vm.enableEdit}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }