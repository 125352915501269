
import { Flags, PharmacyBenefitManager } from '@/models/PharmacyBenefitManager';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import axios from 'axios';
import EnumSelector from '@/components/EnumSelector.vue';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import SearchComponent from "@/components/SearchComponent.vue";
import Multiselect from "vue-multiselect";
import { ValidValues } from '@/data/ValidValues';
import NotificationTypes = NotificationOptions.NotificationTypes;
import { Store } from "@/models/Store";

@Component({
    name: "PBMComponent",
    components: {
        Multiselect,
        SearchComponent,
        NewClearSaveButtons,
        EnumSelector,
    },
})
export default class PBMComponent extends Vue {
    private allowedStore: Store = new Store();
    private PBMRecord: PharmacyBenefitManager = new PharmacyBenefitManager();

    protected PBMflags = Flags;
    private PBMFlag: number = 0;
    @Prop({ type: Boolean, default: false }) private createNewPbm!: boolean;
    @Prop({ type: Boolean, default: false }) private noCancelButton!: boolean;
    private stateOptions = [{ label: "All States", states: ValidValues.addressStates }];

    get allowedStatesForStore(): string[] {
        const idx = this.PBMRecord.allowedStates.findIndex(value => value.storeId == this.allowedStore.id);
        if (idx === -1) return [];
        return this.PBMRecord.allowedStates[idx].states;
    }

    // noinspection JSUnusedGlobalSymbols
    set allowedStatesForStore(value: string[]) {
        const idx = this.PBMRecord.allowedStates.findIndex(value => value.storeId == this.allowedStore.id);
        if (idx === -1) {
            this.PBMRecord.allowedStates.push({ storeId: +this.allowedStore.id, states: value });
        } else {
            this.PBMRecord.allowedStates[idx].states = value;
        }
    }

    created() {
        if (this.createNewPbm) {
            this.createPBM();
        }
    }

    clearForm() {
        this.PBMRecord = new PharmacyBenefitManager();
        this.allowedStore = new Store();
        this.PBMFlag = 0;
    }

    createPBM() {
        this.PBMRecord = new PharmacyBenefitManager();
    }

    backToPatientDashboard() {
        if (this.createNewPbm) {
            this.$emit("close");
            return;
        }
    }

    showConfirmationModal() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
            title: 'Confirm',
            okVariant: 'danger',
            centered: true,
        })
            .then(val => {
                if (val) this.clearForm();
            })
            .catch(err => {
                console.log("Error caught in showConfirmationModal()");
                console.error(err);
            });
    }

    submitPBM() {
        if (this.PBMRecord?.name != undefined || this.PBMRecord?.name != "" || this.PBMRecord?.name != null) {
            this.PBMRecord.flags = this.PBMFlag;
            axios.post<PharmacyBenefitManager>(`/PharmacyBenefitManager`, this.PBMRecord)
                .then(res => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Pharmacy Benefit Manager Created Successfully", NotificationOptions.NotificationTypes.success));
                    this.PBMRecord = res.data;
                    this.$emit('created', this.PBMRecord);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Create Pharmacy Benefit Manager", NotificationOptions.NotificationTypes.danger));
                    console.error("Error while saving PBM.", { err, response: err.response });
                });
        }

    }

    ndcEntered(val: any) {
        let tarVal = val.target.value;
        let idx = this.PBMRecord.exemptedNdcs.indexOf(tarVal);
        if (idx != -1) {
            this.$notification(NotificationOptions.notificationOptionsPreset("NDC already present", NotificationTypes.danger));
            return;
        }
        if (tarVal.length == 13) {
            tarVal = tarVal.replace('-', '');
        }
        if (tarVal.length == 11) {
            this.PBMRecord.exemptedNdcs.push(tarVal);
        } else {
            this.$notification(NotificationOptions.notificationOptionsPreset("Invalid NDC11 length", NotificationTypes.danger));
        }
    }

    binEntered(val: any) {
        const tarVal = val.target.value;
        let idx = this.PBMRecord.bins.indexOf(tarVal);

        if (idx != -1) {
            this.$notification(NotificationOptions.notificationOptionsPreset("BIN already present", NotificationTypes.danger));
            return;
        }

        if (tarVal.length == 6) {
            this.PBMRecord.bins.push(tarVal);
        } else {
            this.$notification(NotificationOptions.notificationOptionsPreset("Invalid BIN length", NotificationOptions.NotificationTypes.warning));
        }
    }

    removeNdc(val: any) {
        let idx = this.PBMRecord.exemptedNdcs.indexOf(val);
        if (idx != -1) {
            this.PBMRecord.exemptedNdcs.splice(idx, 1);
        }
    }

    removeBin(val: any) {
        let idx = this.PBMRecord.bins.indexOf(val);
        if (idx != -1) {
            this.PBMRecord.bins.splice(idx, 1);
        }
    }

    async onPBMChange(value: PharmacyBenefitManager) {
        this.PBMRecord = value;
        if (value.id) {
            let pbmResp = await axios.get<PharmacyBenefitManager>(`/api/PharmacyBenefitManager/${value.id}`);
            this.PBMRecord = new PharmacyBenefitManager(pbmResp.data);
        }
    }

    get sortedBins(): string[] {
        return this.PBMRecord?.bins?.sort();
    }

    get disableFields(): boolean {
        return isNaN(this.PBMRecord.id);
    }

    get isFormValid() {
        return true;
    }

    private get _buttonTitles() {
        let res: NewClearSaveTitles;
        res = {
            save: "Save Changes",
            clear: "Clear",
            new: "",
            cancel: this.noCancelButton ? "" : "Back to Audit Record",
        };
        return res;
    }
}
