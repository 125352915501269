import HasID from "./HasID";
import { Prescription } from "@/models/Prescription";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { Store } from "@/models/Store";
import { Drug } from "@/models/Drug/Drug";
import { DispenseError } from "@/models/DispenseError";
import { Order } from "@/models/Order";
import { Escript } from "@/models/Escript";
import { FunctionalityFlag } from "./FunctionalityFlag";

export interface Verification extends HasID {
    store: boolean;
    patient: boolean;
    prescriber: boolean;
    quantity: boolean;
    drug: boolean;
    direction: boolean;
    dur: boolean;
    created: Date;
    createdBy: string;
}

export interface VerificationResponse {
    prescription: Prescription;
    patient: Patient;
    prescriber: Prescriber;
    store: Store;
    drug: Drug;
    verifications: Verification[];
    dispenseErrors: DispenseError[];
    order: Order;
    escript: Escript;
    programFlags: FunctionalityFlag[];
    requireExplicitPV1: boolean;
    requireExplicitPV2: boolean;
    patientPendingAllergy: boolean;
    patientAddressErrors: string | null;
}

export namespace Verification {
    export class DrugUtilizationReviews {
        public allergies?: Array<Allergy>;
        public drugInteractions?: Array<DrugInteraction>;
        public duplicateTherapies?: Array<DuplicateTherapy>;
        public pregnancyAndLactation?: { ratings: PregnancyAndLactationRatingType[] };
        public wasAbleToCalculate: boolean = false;

        get isEmpty(): boolean {
            return this.wasAbleToCalculate
                && !this.allergies?.length
                && !this.drugInteractions?.length
                && !this.duplicateTherapies?.length
                && !this.pregnancyAndLactation?.ratings?.length
        }
    }

    export interface DuplicateTherapy {
        duplicatePackageId: number;
        drugName: string;
        lastFilled: Date;
    }

    export interface DrugInteraction {
        interactingPackageId: number;
        drugName: string;
        lastFilled: Date;
        interactions: InteractionType[];
    }

    export interface InteractionType {
        observation: string;
        professionalNotes: string;
        severity: string;
        documentationLevel: string;
    }

    export interface Allergy {
        name: string;
        /// 0 = class, 1 = ingredient
        type: number;
        identifier: number;
    }

    export interface PregnancyAndLactationRatingType {
        code: number;
        description: string;
        ratingType: string;
        trimester: string;
    }
}
