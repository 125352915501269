
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import Axios, { AxiosRequestConfig } from 'axios';
import Vue2Dropzone from 'vue2-dropzone';
import moment from "moment";

import { BTable, BvTableCtxObject } from "bootstrap-vue";
import SearchComponent from "@/components/SearchComponent.vue";
import PatientDocumentsModal from '@/components/Patient/PatientDocumentsModal.vue';

import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { LockHandler } from '@/mixins/LockHandler';
import { Prescriber } from '@/models/Prescriber';
import { QueueItem } from '@/models/QueueItem';
import { Program } from '@/models/Program';
import { Patient } from '@/models/Patient';
import { Store } from "@/models/Store";
import IngestionImage from "@/components/IngestionImage.vue";
import Dropzone from "dropzone";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { DatePicker } from 'element-ui';
import BottomPagerBar from './BottomPagerBar.vue';

@Component({
    name: "IngestionComponent",
    components: {
        "VueDropzone": Vue2Dropzone,
        SearchComponent,
        PatientDocumentsModal,
        IngestionImage,
        Checkbox,
        [DatePicker.name]: DatePicker,
        BottomPagerBar
    },
})
export default class IngestionComponent extends Mixins(LockHandler) {
    private itemsSelected: Array<QueueItem> = [];
    private programs: Array<Program> = [];
    private destinationBucket: any = null;
    private selectedBucket: any = null;
    private store: Store = new Store();
    private scanners: Array<string> = [];
    private selectedScanner: string = "";
    private isScanning: boolean = false;
    @Ref("myVueDropzone") private dropzone!: Dropzone;
    @Ref("escriptTable") private escriptTable!: BTable;
    private date = new Date();
    private pageNumber = 1;
    private pageSize = 5;
    private loading = true;

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        this.selectedType = this.persistentType;
        this.fetchPrograms();
        this.interval = setInterval(this.fetchQueue, 600_000);
        this.getAvailableScanners();
        this.allowLocked = !!this.$route.query["allowLocked"];
    }

    getAvailableScanners() {
        Axios.get('http://localhost:7274/scanner').then((result) => {
            this.scanners = result.data.map((s: any) => s.name);
            const previouslySelectedScanner = this.persistentScanner;
            if (this.scanners.indexOf(previouslySelectedScanner) >= 0) {
                this.selectedScanner = previouslySelectedScanner;
            }
        }).catch((err: any) => {
            console.warn(err);
        });
    }

    beforeDestroy() {
        console.log(`%c Destroying ${this.$options.name}`, "color: red");
        clearInterval(this.interval);
    }

    get persistentType(): any {
        return localStorage.getItem('ingestion_selectedType');
    }

    get persistentBucket(): any {
        const objStr = localStorage.getItem('ingestion_selectedBucket');
        if (objStr)
            return JSON.parse(objStr);
        else return null;
    }

    get persistentScanner(): any {
        return localStorage.getItem('ingestion_selected_scanner');
    }

    @Watch('selectedType')
    selectedTypeChanged(val1: any, _val2: any) {
        localStorage.setItem('ingestion_selectedType', val1);
    }

    @Watch('selectedBucket')
    selectedBucketChanged(val1: any, _val2: any) {
        localStorage.setItem('ingestion_selectedBucket', JSON.stringify(val1));
    }

    @Watch('selectedScanner')
    selectedScannerChanged(val1: any, _val2: any) {
        localStorage.setItem('ingestion_selected_scanner', val1);
    }

    @Watch('store')
    @Watch('selectedType')
    @Watch('pageSize')
    @Watch('date')
    @Watch('selectedBucket')
    @Watch('allowLocked')
    resetPageNumber() {
        this.pageNumber = 1;
    }

    private interval!: NodeJS.Timeout;
    // dropzone
    // properties
    get dropzoneOptions(): any {
        return {
            url: `${Axios.defaults.baseURL}/image`,
            thumbnailWidth: 150,
            withCredentials: true,
            paramName: 'images',
        };
    }

    // events
    dropzoneSending(_file: any, xhr: XMLHttpRequest, _formData: FormData) {
        xhr.setRequestHeader("Authorization", Axios.defaults.headers["Authorization"]);
    }

    dropzoneUploadSuccess(file: any, _response: any) {
        this.dropzone.removeFile(file);
        // const dropzone: any = this.$refs.myVueDropzone;
        // dropzone.removeFile(file);

        this.fetchQueue();
    }

    dateTimeFormatter(d: any) {
        return moment.utc(d).local().format("L LT");
    }

    // table
    // properties
    get escriptFields(): any[] {
        return [
            { key: "selected", label: "", class: "selected-col", },
            { key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },
            { key: "patient", label: "Patient", formatter: this.patientFormatter },
            { key: "prescriber", label: "Prescriber", formatter: this.prescriberFormatter },
            { key: "drugs.drugDescription", label: "Drug", },
            { key: "prescription.memo", label: "Memo", },
            //{key: "source", class: "source-col"},
            {
                key: "otherType",
                label: "Type",
                sortable: true,
                formatter: this.typeFormatter,
                sortByFormatted: true, /*class: "type-col",*/
            },
            { key: "completed", label: "Completed By", class: "completed", hidden: !this.isCompleteView },
            { key: "locked", label: "Locked By", class: "locked", hidden: !this.allowLocked },
            { key: "buttons", label: "", class: "button-col", },
        ];
    }

    get otherFields() {
        return [
            { key: "selected", label: "", class: "selected-col", },
            { key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },

            { key: "imgSrc", label: "Image", class: "image-col", },
            {
                key: "otherType",
                label: "Type",
                sortable: true,
                formatter: this.typeFormatter,
                sortByFormatted: true,
                class: "type-col",
            },
            { key: "source", class: "source-col" },
            { key: "completed", label: "Completed By", class: "completed", hidden: !this.isCompleteView },
            { key: "locked", label: "Locked By", class: "locked", hidden: !this.allowLocked },
            { key: "buttons", label: "", class: "button-col", },
        ];
    }

    get programTransferFields() {
        return [
            { key: "selected", label: "", class: "selected-col", },
            { key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },
            { key: "patient", label: "Patient", formatter: this.patientFormatter },
            { key: "prescriber", label: "Prescriber", formatter: this.prescriberFormatter },
            { key: "prescription", label: "Drug", formatter: this.transferDrugFormatter },
            { key: "sendingPharmacy.name", label: "Source", class: "source-col", sortable: true, },
            { key: "completed", label: "Completed By", class: "completed", hidden: !this.isCompleteView },
            { key: "locked", label: "Locked By", class: "locked", hidden: !this.allowLocked },
            { key: "buttons", label: "", class: "button-col", },
        ];
    }

    protected sortBy = "ingested";
    protected sortDesc = false;
    protected tableItems: QueueItem[] = [];
    protected transProps = { name: "flip-list" };
    private urlsCache: { id: string | number, url: string; contentType: string; }[] = [];
    private selectedType = "other";
    private searchValue = "";
    private allowLocked = false;
    private selectedView = "Incomplete";
    private itemCount: number | null = null;

    protected selectedQueueItem: QueueItem | null = null;

    get isCompleteView() {
        return this.selectedView === "Complete";
    }

    get tableFilter() {
        const filter = {
            url: "",
            searchText: this.isCompleteView ? null : this.searchValue,
            type: this.selectedType,
            storeId: this.store?.id,
            allowLocked: this.allowLocked,
            complete: this.isCompleteView,
            date: this.isCompleteView ? this.date : null,
            pageSize: this.isCompleteView ? this.pageSize : null,
            pageNumber: this.isCompleteView ? this.pageNumber : null
        };
        if (this.selectedBucket) {
            filter.url = `/${this.selectedBucket.bucketType}/${this.selectedBucket.bucket}`;
        }
        return filter;
    }

    get fields() {
        let fields: Array<any>;

        switch (this.selectedType) {
            case "escript":
                fields = this.escriptFields;
                break;
            case "programTransfer":
                fields = this.programTransferFields;
                break;
            default:
                fields = this.otherFields;
                break;
        }

        return fields.filter(f => f.hidden !== true);
    }

    tableProvider(ctx: BvTableCtxObject, callback: Function) {
        const filter = ctx.filter as unknown as {
            url: string,
            searchText: string | null,
            type: string,
            storeId: any,
            allowLocked: boolean,
            complete: boolean,
            date: Date | null,
            pageNumber: number | null,
            pageSize: number | null
        };

        this.loading = true;

        if (!filter.type) {
            callback([]);
            return null;
        }

        const uri = ctx.apiUrl + filter.url;
        const config: AxiosRequestConfig = {
            params: {
                allowLocked: filter.allowLocked,
                searchText: filter.searchText,
                queueType: filter.type,
                storeId: filter.storeId,
                complete: filter.complete,
                date: filter.date,
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize
            },
        };
        Axios.get<{ queueItems: QueueItem[], count: number }>(uri, config)
            .then(resp => {
                const dat = resp.data;
                Promise.all(dat.queueItems.map(this.createImageSource))
                    .finally(() => {
                        this.itemsSelected = [];
                        this.itemCount = resp.data.count;
                        callback(resp.data.queueItems);
                    });
            })
            .catch(err => {
                this.itemsSelected = [];
                console.error("Error while loading items for ingestion.", { err, response: err.response });
                callback([]);
            }).finally(() => this.loading = false);

        return null;
    }

    // formatters
    patientFormatter(pt: Patient) {
        return Object.assign(new Patient(), pt).displayNamesForPerson();
    }

    prescriberFormatter(dr: Prescriber) {
        return Object.assign(new Prescriber(), dr).displayNamesForPerson();
    }

    transferDrugFormatter(rxVM: any) {
        // noinspection JSUnresolvedReference
        return `${rxVM?.prescribedNdc} - ${rxVM?.medicationName}`;
    }

    typeFormatter(_value: null, _key: string, item: QueueItem) {
        if (item?.source === "escript") return item?.escriptMessageType;
        const type = item?.escriptMessageType;
        if (type) return type;
        if (item?.source === "programTransfer") return item.escriptMessageKind;
        if (!isNaN(Number(item?.source))) return "Fax";
        if (item?.source.startsWith("+")) return "Fax";

        return "Unknown";
    }

    imageUrlForQueueItem(item: QueueItem): string {
        if (item.imageID != null) {
            return `/image/${item.imageID}`;
        }
        return `/escript/${item.escriptID}/image`;
    }

    async fetchPrograms() {
        const resp = await Axios.get<Program[]>('/Program?active=true');
        this.programs = resp.data;
        this.loadBucketOptions();
    }

    fetchQueue() {
        this.itemsSelected = [];
        if (this.escriptTable?.refresh) this.escriptTable.refresh();
    }

    routeItem(item: QueueItem) {
        item.bucketType = this.destinationBucket.bucketType;
        item.bucket = this.destinationBucket.bucket;
        return Axios.post("IngestionQueue/Route", item);
    }

    routeItems() {
        Promise.all(this.itemsSelected.map(this.routeItem))
            .finally(this.fetchQueue);
    }

    scan() {
        if (this.selectedScanner == "") {
            this.$notification(NotificationOptions.error("Please select a scanner."));
            return;
        }
        this.isScanning = true;
        const scanConfig: AxiosRequestConfig = { params: { scannerId: this.selectedScanner }, responseType: 'blob' };
        Axios.get('http://localhost:7274/scanner/scan', scanConfig)
            .then(result => {
                const formData = new FormData();
                formData.append("images", result.data);
                const imageConfig = { headers: { "Content-Type": "multipart/form-data" } };
                Axios.post('/image', formData, imageConfig)
                    .then(_response => {
                        this.fetchQueue();
                    });
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.isScanning = false;
            });
    }

    get typeOptions() {
        return [
            { value: null, text: "Select Queue Type" },
            { value: "escript", text: "Escript" },
            { value: "programTransfer", text: "ProgramTransfer" },
            { value: "other", text: "Images" },
        ];
    }

    protected bucketOptions: Array<any> = [];

    loadBucketOptions() {
        const programOptions = this.programs.map(p => {
            return { value: { bucketType: 1, bucket: p.id }, text: p.name };
        });

        const staticOptions = [
            { value: { bucketType: 0, bucket: 0 }, text: 'Prior Auth' },
            { value: { bucketType: 0, bucket: 1 }, text: 'Audit' },
            { value: { bucketType: 0, bucket: 2 }, text: 'MD Responses' },
            { value: { bucketType: 0, bucket: 3 }, text: 'Misc' }
        ];

        this.bucketOptions = [
            { value: null, text: 'Select Bucket' },
            { label: 'Programs', options: programOptions },
            { label: 'Other', options: staticOptions }
        ];

        this.selectedBucket = this.persistentBucket;
    }

    createImageSource(item: QueueItem) {
        if (this.selectedType == "escript") return Promise.resolve();
        if (this.selectedType == "programTransfer") return Promise.resolve();
        return new Promise<any>((resolve, reject) => {
            if (item.imgSrc) return resolve({ url: item.imgSrc, item: item });

            const cachedUrl = this.urlsCache.find(o => o.id == item.id)

            if (cachedUrl) {
                item.imgSrc = cachedUrl.url;
                item.imageType = cachedUrl.contentType;
                return resolve({ url: cachedUrl.url, item: item });
            }
            const url = this.imageUrlForQueueItem(item);
            Axios.get(url, { responseType: "blob" })
                .then(r => {
                    const objectUrl = URL.createObjectURL(r.data);
                    item.imgSrc = objectUrl;
                    item.imageType = r.headers['content-type'];
                    this.urlsCache.push({ id: item.id, url: objectUrl, contentType: item.imageType });
                    return resolve({ url: objectUrl, item: item });
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    notScript(queueItem: any) {
        Axios.post(`/IngestionQueue/`, queueItem)
            .then(response => {
                this.urlsCache = this.urlsCache.filter(o => o.id != response.data.id);
            })
            .catch(error => {
                this.$notification(NotificationOptions.error(error));
                console.warn('IngestionQueue marked complete error: ', error);
            })
            .finally(this.fetchQueue);
    }

    setPatientDocument(queueItem: any) {
        this.selectedQueueItem = queueItem;
        this.$bvModal.show('patient_documents_modal');
    }

    processQueueItem(queueItem: QueueItem) {
        if (this.itemsSelected.length > 0) {
            this.$store.queueItems = this.itemsSelected;
            const idx = this.$store.queueItems.findIndex(qi => qi.id == queueItem.id);
            this.$store.queueItems.splice(idx, 1);

            const lockTime = this.$store.queueItems.length * 1000 * 60 * 2; // 1000 ms/sec * 60 sec/min * 2 min

            this.$store.queueItems.forEach(qi => {
                this.addLockWithURL(`/IngestionQueue/${qi.id}/lock`, lockTime);
            });
        }
        this.addLockWithURL(`/IngestionQueue/${queueItem.id}/lock`, 60000)
            .then(_result => {
                this.$router.push({
                    name: 'Prescription',
                    query: {
                        imageID: `${queueItem.imageID}`,
                        escriptID: `${queueItem.escriptID}`,
                        queueItemID: `${queueItem.id}`,
                        programTransfer: `${queueItem.programTransferId}`
                    }
                });
            })
            .catch(error => {
                console.warn(`Added lock -- error == ${error}`);
                if (error.response && error.response.status == 418) {
                    console.log(`QueueItem is locked`);
                    console.log(error.response.data);
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Queue Item is locked by ${lockedBy} until ${expires}.`);
                }
            });
    }

    formatLockExpires(value: Date) {
        return moment.utc(value).fromNow();
    }

    handleQueueRowStyles(item: any, type: any) {
        if (!item || type !== 'row') return;
        if (item.lockedBy) return "bg-warning locked ingestion-item";
        return "ingestion-item";
    }

    get selectViewOptions() {
        return [
            { text: "Incomplete", value: 'Incomplete' },
            { text: "Complete", value: 'Complete' }
        ];
    }
}
