
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { Patient } from '@/models/Patient';
import { BillingFlags, Plan } from '@/models/Plan';
import { PatientPlan, RelationshipCode } from '@/models/PatientPlan';
import Axios, { AxiosError } from 'axios';
import PlanDetailsPage from '@/pages/Plan/PlanDetailsPage.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { CombinedPlan } from "@/models/CombinedPlan";
import Draggable from 'vuedraggable';

@Component({
    components: {
        PlanDetailsPage,
        SearchComponent,
        EnumSelector,
        Draggable
    }
})
export default class PatientPlansComponent extends Vue {
    @VModel({ type: Patient, default: new Patient() }) private patient!: Patient;
    @Prop({ default: false }) private showButtonOnly!: boolean;
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: null }) private addTemplate!: CombinedPlan;
    @Prop({ default: "", type: String }) private id!: string;
    private showNewPlan = false;
    private prePopulatePlanString: any = "";

    private insuranceInfos: CombinedPlan[] = [];
    private addedPlan: Plan = new Plan();
    private addedPatientPlan: PatientPlan = new PatientPlan();
    private relationshipCodes = RelationshipCode;
    // private planFields: any[] = [
    //     { key: "plan.name", label: "Name", },
    //     { key: "plan.bin", label: "BIN", },
    //     { key: "plan.pcn", label: "PCN", },
    //     { key: "patientPlan.memberId", label: "Member Id", },
    //     { key: "patientPlan.groupId", label: "Group Id", },
    //     { key: "patientPlan.cardholderId", label: "Cardholder Id", },
    //     { key: "patientPlan.relationshipCode", label: "Relationship Code", },
    //     { key: "patientPlan.active", label: "Active", },
    // ];
    private planButtonTitles = {
        new: "",
        clear: "",
        save: "Save Changes",
        cancel: ""
    };

    @Watch("addTemplate")
    addTemplateUpdated(val: CombinedPlan, oldVal: any) {
        if (!val) return;

        console.log("addTemplate: ", val, oldVal);

        this.addedPatientPlan = new PatientPlan(val.patientPlan);
        this.addedPatientPlan.relationshipCode = RelationshipCode.Cardholder;
        this.addedPlan = new Plan(val.plan);
        this.prePopulatePlanString = `${val.plan.bin} ${val.plan.pcn}`;
        this.$bvModal.show(`Insurance-Add-Modal-${this.id}`);
    }

    @Watch("addedPlan")
    addedPlanUpdate(val: Plan) {
        //Hide new Plan form if a existing one was selected.
        if (!val.id) return;

        this.showNewPlan = false;

        if (!this.isInventoryAvailable) {
            this.addedPatientPlan.groupId = this.addedPatientPlan.groupId || val.defaultGroupId;
            this.addedPatientPlan.cardholderId = this.addedPatientPlan.cardholderId || val.defaultCardholderId;
            this.addedPatientPlan.personCode = this.addedPatientPlan.personCode || val.defaultPersonCode;
            this.addedPatientPlan.relationshipCode = val.defaultRelationshipCode;
        } else {
            this.addedPatientPlan.groupId = '';
            this.addedPatientPlan.cardholderId = '';
            this.addedPatientPlan.personCode = '';
            this.addedPatientPlan.relationshipCode = 0;
        }
    }

    mounted() {
        this.fetchPlans();
    }

    clear() {
        this.addedPatientPlan = new PatientPlan();
        this.addedPlan = new Plan();
        this.$emit('hide');
    }

    @Watch('patient')
    async fetchPlans() {
        if (!this.patient.id) return;

        try {
            const response = await Axios.get(`insurance/${this.patient.id}`);
            this.processPlanData(response.data);
        } catch (error) {
            console.error("Error while loading the insurance plans", { error, response: (error as AxiosError)?.response });
        }
    }

    processPlanData(data: { plans: Array<Plan>, patientPlans: Array<PatientPlan> }) {
        this.insuranceInfos = data.plans.map((item: any, i: number) => {
            return { plan: item, patientPlan: data.patientPlans[i] } as CombinedPlan;
        });
    }

    async updatePlanActivity(p: CombinedPlan) {
        try {
            const resp = await Axios.post<CombinedPlan[]>("/Plan/UpdatePatientPlan", p.patientPlan);

            this.insuranceInfos = resp.data;
            this.$emit("plan-saved");
        } catch (err) {
            console.error("Error while updating patient plan activity.", { err, response: (err as AxiosError)?.response });
        }
    }

    async applyPlansToPatient(isPrimary : boolean) {
        this.addedPatientPlan.patientId = this.patient.id;
        this.addedPatientPlan.planId = this.addedPlan.id;
        this.addedPatientPlan.relationshipCode = Number(this.addedPatientPlan.relationshipCode);

        this.addedPatientPlan.rank = isPrimary ? 0 : null;

        try {
            await Axios.post(`/Plan/AssignToPatient`, this.addedPatientPlan);

            this.$notification(NotificationOptions.notificationOptionsPreset("Plan was assigned to patient successfully.", NotificationOptions.NotificationTypes.success));
            this.$emit('plan-saved');
            this.fetchPlans();
            this.addedPatientPlan = new PatientPlan();
            this.addedPlan = new Plan();

            this.$bvModal.hide(`Insurance-Add-Modal-${this.id}`);
        } catch (error) {
            console.error("Error while assigning plan to patient.", { error, response: (error as AxiosError)?.response });
            this.$notification(NotificationOptions.error(error));
        }
    }

    showPlanForm() {
        this.showNewPlan = true;
        this.addedPlan = new Plan();
    }

    onPlanCreated(newPlan: Plan) {
        this.addedPlan = newPlan;
        this.showNewPlan = false;
    }

    get isValid(): boolean {
        return !!(this.patient?.id);
    }

    get isInventoryAvailable(): boolean {
        const hasInventory = this.addedPlan.inventoryQuantityAvailable > 0;
        const isConnectiveRx = (this.addedPlan.flags & BillingFlags.ConnectiveRx) != 0;
        const isArcutisCopay = (this.addedPlan.flags & BillingFlags.ArcutisCopay) != 0;
        return hasInventory || isConnectiveRx || isArcutisCopay;
    }

    async reorderPlans(data: { moved: { element: CombinedPlan, newIndex: number, oldIndex: number } }) {
        const moved = data.moved;

        try {
            const updatedPlans = await Axios.post('/Plan/move-patient-plan-priority', {
                ...moved.element.patientPlan,
                rank: moved.newIndex
            });
            this.processPlanData(updatedPlans.data);
        } catch (error) {
            console.error("Error while reordering the insurance plan", { error, response: (error as AxiosError)?.response });
            this.fetchPlans();
        }
    }
}
