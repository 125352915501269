<template>
    <div class="content">
        <top-navbar/>
        <router-view name="header"/>

        <div class="mx-2 mt-n2">
            <FadeTransition :duration="200" mode="out-in">
                <!-- your content here -->
                <router-view/>
            </FadeTransition>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"/>
    </div>
</template>
<script>
    import {FadeTransition} from "vue2-transitions";
    import TopNavbar from "@/pages/Dashboard/Layout/TopNavbar.vue";
    import ContentFooter from "@/pages/Dashboard/Layout/ContentFooter.vue";

    export default {
        components: {
            ContentFooter,
            TopNavbar,
            FadeTransition
        }
    };
</script>
<style></style>
