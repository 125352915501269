
import { Component, Vue } from "vue-property-decorator";

import InventoryParLevelsComponent from "@/components/Inventory/InventoryParLevelsComponent.vue";

@Component({
    name: "PeriodicInventoryDetailsPage",
    components: {
        InventoryParLevelsComponent
    }
})
export default class InventoryParLevelsPage extends Vue {

}

