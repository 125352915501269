
    import { Vue, Prop, Watch, VModel } from 'vue-property-decorator';
    import axios from 'axios'
    import Component from 'vue-class-component';
    import { PatientInsuranceUpload } from '@/models/PatientInsuranceUpload';

    @Component({
        name: "PatientInsuranceModalComponent"
    })

    export default class PatientInsuranceModalComponent extends Vue {

        @VModel() private patientId!: number;

        mounted() {
            this.getlatestUpload();
        }

        private latestUpload: PatientInsuranceUpload = {} as PatientInsuranceUpload;

        get hasImages(): boolean {
            return this.latestUpload?.imageURLs?.length > 0;
        }

        getlatestUpload() {
            axios.get<PatientInsuranceUpload>(`/PatientInsuranceUpload/GetLatest/${this.patientId}`)
                .then(response => {
                    this.latestUpload = response.data;
                    this.latestUpload.imageURLs
                    console.log("latestUpload", this.latestUpload)    
                });
        }
    }

