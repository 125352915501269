
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator';
    import Axios from 'axios';
    import { DatePicker } from "element-ui";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets"

    import { Prescription } from '@/models/Prescription';
    import { Store } from '@/models/Store';

    import SearchComponent from '@/components/SearchComponent.vue';
    import EnumSelector from '@/components/EnumSelector.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import moment from "moment";

    @Component({
        name: "ReversalQueuePage",
        components: {
            SearchComponent,
            EnumSelector,
            [DatePicker.name]: DatePicker,
            PrescriptionsListComponent
        },
    })
    export default class ReversalQueuePage extends Vue {
        private prescriptions:Array<Prescription> = [];
        private storeFilter: Store = new Store();
        private fillDate:Date = moment().toDate();
        private loading:boolean = false;

        created(){
            this.search();
        }

        search(){
            this.loading = true;
            Axios.get(`/Prescription/get-reversal-queue/${this.storeFilter?.id || ''}`)
            .then(response => {
                this.prescriptions = response.data;
            })
            .catch(error => {
                console.warn("Error while fetching prescriptions by date",{error, response: error?.response});
            })
            .finally(() => { this.loading = false; });
        }
    }

