import { Prescription } from "@/models/Prescription";
import { DeliveryCode } from "@/models/DeliveryCode";

export interface OrderLabelData extends Prescription {
    fillDate: Date,
    packageID: number,
    productNameLong: string,
    quantity: number,
    rxNumber: number,
    rfNumber: number,

    totalPrice: number,
    patientPay: number,
    rfLeft: number,

    doctorLastName: string,
    doctorFirstName: string,

    patientLastName: string,
    patientFirstName: string,
    patientMiddleName: string,
    dateOfBirth: Date,
    orderDeliveryMethod: DeliveryCode,

    printed: Date,
    printedBy: string,
    hasAddons: boolean,
    promisedDate: Date
}

export class OrderLabelData extends Prescription {
    constructor(obj?: OrderLabelData) {
        super(obj);
        if (obj) {
            Object.assign(this, obj);
            this.deliveryCode = obj.orderDeliveryMethod;
        }
    }
}
