

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Axios from 'axios';
import 'viewerjs/dist/viewer.css';
import { component as Viewer } from 'v-viewer';

@Component({
    name: "FilledImagesComponent",
    components: {
        Viewer
    }
})
export default class FilledImagesComponent extends Vue {

    @Prop() private rxId!: string;
    @Prop({ default: false }) private modalPresentation!: boolean;
    @Prop({ default: false }) private hideTitle!: boolean;

    private imageIds: Array<number> = [];
    private imageUrls: Array<string> = [];

    viewerOptions() {
        return {
            inline: true,
            debug: true,
            button: false,
            title: false,
            transition: false,
            navbar: true,
            toolbar: {
                zoomIn: 1,
                zoomOut: 1,
                reset: 1,
                prev: 1,
                next: 1,
                rotateLeft: 1,
                rotateRight: 1,
                flipHorizontal: 1,
                flipVertical: 1,
                oneToOne: 0,
                play: 0
            }
        };
    }

    @Watch('rxId')
    handleRxIdChange() {
        this.getFilledImageIds();
    }

    created() {
        this.getFilledImageIds();
    }

    async getFilledImageIds() {
        this.imageIds = (await Axios.get<Array<number>>(`/Prescription/FilledImages/${this.rxId}`)).data;
        if (!this.modalPresentation) this.getFilledImageUrls();
    }

    async getFilledImageUrls() {
        const imageBlobPromises = this.imageIds.map(async i => {
            const blob = await Axios.get(`/api/Image/${i}`, { responseType: "blob" });
            return URL.createObjectURL(blob.data);
        })

        this.imageUrls = await Promise.all(imageBlobPromises);
    }

    get modalId(): string {
        return `filled-images-${this.rxId}-modal`;
    }

    get hasImages(): boolean {
        return !!this.imageIds.length;
    }

    showModal() {
        this.getFilledImageUrls();
        this.$bvModal.show(this.modalId);
    }
}

