
    import {Component, Vue, Watch} from "vue-property-decorator";
    import SearchComponent from "@/components/SearchComponent.vue";
    import {Store} from "@/models/Store";
    import Axios, {AxiosRequestConfig, AxiosError} from "axios";
    import {Prescription, OnHoldSubstatusCode} from "@/models/Prescription";
    import EnumSelector from '@/components/EnumSelector.vue';
    import {BvTableCtxObject} from "bootstrap-vue";
    import {Patient} from "@/models/Patient";
    import moment from "moment";
    import {DatePicker} from "element-ui";
    import {RawLocation} from "vue-router";

    @Component({
        components: {
            SearchComponent,
            EnumSelector,
            [DatePicker.name]: DatePicker,
        }
    })
    export default class PrescriptionOnHoldQueue extends Vue {
        private store: Store = new Store();
        private subStatus: string | null = null;
        readonly enums: any = OnHoldSubstatusCode;

        private fromDate: Date = moment().subtract(14, "days").toDate();
        private toDate: Date = new Date();
        private moreItems: boolean = false;
        readonly fields = [
            {key: "productNameShort", label: "Drug", sortable: true},
            {key: "patient", label: "Patient", formatter: this.patientFormatter},
            {key: "storeName", label: "Store", sortable: true},
            {key: "rxId", label: "Rx Id"},
            {key: "quantity", label: "Quantity"},
            {key: "subStatus", label: "Hold Status", formatter: (s: any) => OnHoldSubstatusCode[s]},
            {key: "fillDate", label: "Fill Date", formatter: (d: Date) => moment.utc(d).local().format("L"), sortable: true},
            {key: "programName", label: "Program"},
        ];

        get tableFilter() {
            const filter = {
                store: this.store,
                subStatus: this.subStatus,
                fromDate: this.fromDate,
                toDate: this.toDate,
            };
            return filter;
        }

        patientFormatter(pt: Patient) {
            const pat = Object.assign(new Patient(), pt);
            return pat.displayNamesForPerson();
        }

        onHoldTableProvider(ctx: BvTableCtxObject, callback: Function) {
            if (ctx.apiUrl == null) return [];


            const filter = ctx.filter as any;
            const config: AxiosRequestConfig = {
                params: {
                    storeId: filter.store?.id,
                    subStatus: filter.subStatus,
                    fromDate: filter.fromDate,
                    toDate: filter.toDate,
                },
            };
            Axios.get<Prescription[]>(ctx.apiUrl, config)
                .then(resp => {
                    if (resp.headers["more-items"] === "true") {
                        this.moreItems = true;
                    } else {
                        this.moreItems = false;
                    }
                    console.log(resp);
                    callback(resp.data);
                })
                .catch((err: AxiosError) => {
                    callback([]);
                    console.warn(err.toJSON());
                });
            return null;
        }

        selectRx(item: Prescription, index: number, event: any) {
            const route:any = {
                name: "Prescription",
                params: {
                    storeID: item.storeID,
                    rxNumber: item.rxNumber,
                    rfNumber: item.rfNumber
                }
            };
            this.$router.push(route);
        }
    }
