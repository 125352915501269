
import { Component, Vue, Ref, Prop } from "vue-property-decorator";

@Component({
    name: "HelpPopup",
})
export default class HelpPopup extends Vue {

    @Prop({ default: "click blur" }) protected triggers?: string;
    @Prop() protected text?: string;
    @Prop({ default: "bottomright" }) protected placement?: string;
    @Prop() protected label?: string;

    @Ref("helpicon") protected helpicon?: any;

}

