
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { DatePicker } from "element-ui";
import axios from 'axios';
import { Patient } from '@/models/Patient';
import NameSearchComponent from '@/components/NameSearchComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import AddressFormFields from '@/components/AddressFormFields.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import EligibilityComponent from "@/components/Insurance/EligibilityComponent.vue";
import { GSDDPatientDiagnosis, ICD10Term } from "@/models/GSDD";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    name: "GSDDDiagnosisComponent",
    components: {
        NameSearchComponent,
        SearchComponent,
        NewClearSaveButtons,
        AddressFormFields,
        Checkbox,
        [DatePicker.name]: DatePicker,
        EligibilityComponent
    }
})

export default class GSDDDiagnosisComponent extends Vue {
    @Prop({ default: null }) private patient!: Patient;
    @Prop({ default: false }) private disable!: boolean;
    @Prop({ default: () => [] }) private value!: GSDDPatientDiagnosis[];

    private selectedDiagnosis: ICD10Term = new ICD10Term();

    private fields = [
        {key: "icD10Code", label: "ICD10",},
        {key: "description",},
        {key: "remove", label: "",},
    ]

    get patientDiagnosis(): GSDDPatientDiagnosis[] {
        return this.value;
    }

    set patientDiagnosis(val: GSDDPatientDiagnosis[]) {
        this.$emit('input', val);
    }

    created() {
        this.getPatientGSDDDiagnosis();
    }

    getPatientGSDDDiagnosis() {
        if (!this.patient?.id) return;

        axios.get(`/Diagnosis/Patient/${this.patient.id}`)
            .then(response => {
                this.patientDiagnosis = response.data;
            }).catch(error => {
                console.error("Error while loading diagnosis", { error, response: error?.response });
            });
    }

    @Watch('patient.id')
    onPatientChanged(_value: Patient | null, _oldValue: Patient | null) {
        this.clearComponent();
        this.getPatientGSDDDiagnosis();
    }

    async addDiagnosis() {
        if (!this.patient?.id) {
            this.$notification(NotificationOptions.error("Must save new patient before adding a diagnosis."));
            return;
        }

        const inputIsEmpty = !Object.keys(this.selectedDiagnosis).length;

        if (inputIsEmpty) {
            this.$notification(NotificationOptions.error("Please select a Diagnosis."));
            return;
        }

        try {
            await axios.post(`/Diagnosis/AddToPatient/${this.patient.id}`, this.selectedDiagnosis);
            this.getPatientGSDDDiagnosis();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async removeDiagnosis(diagnosis: any) {
        try {
            await axios.post(`/Diagnosis/RemoveFromPatient/${this.patient.id}`, diagnosis);
            this.getPatientGSDDDiagnosis();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    clearComponent() {
        this.patientDiagnosis = new Array<GSDDPatientDiagnosis>();
        this.selectedDiagnosis = new ICD10Term();
    }

}
