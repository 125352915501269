import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { Contact } from "./Contact";
import { FunctionalityFlag } from "./FunctionalityFlag";
import { PriceSchedule } from "./PriceSchedule";
import { Prescriber } from "./Prescriber";
import { DeliveryCode } from "./DeliveryCode";
import { DrugProgram } from "@/models/Drug/Drug";
import HasLabel from "./HasLabel";
import { OrderAuditConfiguration } from "@/models/OrderAudit";
import { TextMessageTemplates } from "./TextMessageTemplates";
import { InventoryLocation } from "./InventoryLocation";

export interface Program extends HasID, TrackedObject {
    id: number;
    name: string;
    abbreviation: string;
    memo: string;
    active: boolean;
    forceMemo: boolean;
    autoprocess: boolean;
    customTemplateFileId: number;
    programContact: Contact;
    businessContact: Contact;
    deliveryMethod?: DeliveryCode;
    priceSchedule: PriceSchedule | null;
    priceScheduleId: number | null;
    flags: FunctionalityFlag[];
    customTemplate?: File;
    customTemplateUpdated: Date;
    drugs?: Array<DrugProgram>;
    prescribers?: Array<Prescriber>;
    auditChance: number;
    apiKey: string;
    priceAdjustment: number;
    orderAuditConfigurations?: Array<OrderAuditConfiguration>;
    carriers: string[];
    transferToPharmacyId: number;
    textMessageTemplates: TextMessageTemplates;
    defaultFillingLocation: InventoryLocation;
}

export class Program extends HasLabel {
    constructor(obj?: Program) {
        super();
        this.id = 0;
        this.priceAdjustment = 1;
        this.flags = new Array<FunctionalityFlag>();

        if (obj) {
            Object.assign(this, obj);
        }

        if (!this.textMessageTemplates) {
            this.textMessageTemplates = new TextMessageTemplates();
        }

        if (!this.defaultFillingLocation) {
            this.defaultFillingLocation = new InventoryLocation();
        }
    }

    toString(): string {
        if (this.name)
            return `Program - Abbr: ${this.name} - ${this.abbreviation || ''}`;
        else return '<div class="font-weight-light">Name</div>';
    }

}
