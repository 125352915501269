
import {Component, Watch, Vue } from 'vue-property-decorator';
@Component({})
export default class ContentFooter extends Vue {
    get year(){ return  new Date().getFullYear(); }
    private environment:string|null = null;
    private version:string|null=null;

    @Watch("$root.$data.dataHubFileVersion")
    onDataHubChange(){

      this.environment = this.$root.$data.dataHubFileVersion.environment;
      this.version = this.$root.$data.dataHubFileVersion.version;

    }
};
