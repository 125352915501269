
    import { Component, Vue } from 'vue-property-decorator';
    import axios, {AxiosError, AxiosRequestConfig} from 'axios';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
   // import {AuditTracking, AuditStatus} from "@/models/AuditTracking";
    import {Audit, Auditor, AuditStatus, BillReference} from '@/models/Audit';
    import EnumSelector from "@/components/EnumSelector.vue";
    import { Watch } from "vue-property-decorator";
    import AuditPageComponent from '@/pages/AuditTracking/AuditPage.vue'
    import moment from 'moment';
    import numeral from "numeral";
    @Component({
        name: "AuditTrackingPage",
        components: {
            EnumSelector,
            AuditPageComponent
        }
    })

    export default class AuditTrackingPage extends Vue {
        private isLoading = false;
        private items:Array<Audit> = new Array<Audit>();
        private auditStatus = AuditStatus;
        private selectedStatus: string | null = null;
        private getEditAudit : Audit = new Audit();
        private fields: any[] = [
            // { key: "id", label: 'ShortageId'},
            { key: "pbm.name", label: "PBM"},
            { key: "auditNumber", label: "Audit ID"},
            { key: "initialNotificationDate", label: "Audit Notification Date", formatter: this.formattedDate},
            { key: "totalExposure", label: "Total Exposure", formatter: this.moneyFormatter},
            { key: "dueDate", label: "Audit Due Date", formatter: this.formattedDate},
            { key: "actions", label: 'Actions'},
        ];
        moneyFormatter(value: number) {
            return numeral(value).format('$0,0.00');
        }
        
        created() {
            console.log("%c Created AuditTrackingPage", "color: green");
            this.loadItems();
        }

        beforeDestroy() {
            console.log('%c Destroying AuditTrackingPage', 'color: red');
        }

        loadItems(){
            this.isLoading = true;
            const config: AxiosRequestConfig = {params:{searchTerm: this.selectedStatus}};
            axios.get<Array<Audit>>('AuditTracking', config)
            .then(res => {
                this.items = res.data.map(i => new Audit(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(()=>{
                this.isLoading = false;
            });
        }

         @Watch("selectedStatus")
            selectedStatusUpdate(newVal: string, oldVal: string) {
                console.log("fetch the list for the audit status we've now selected.");
                return this.loadItems();
        }

        newAudit(){
            this.$bvModal.show("newAuditModal");
        }
         showComponent(close?: string){
        switch(close){
                 case 'hideAuditForm':
                    this.$bvModal.hide("newAuditModal");
                    this.loadItems();
                     break;
                 case 'hideEditAuditForm':
                     this.$bvModal.hide("editAuditModal");
                     this.loadItems();
                    break;
            }
        }
        formattedDate(value: Date): string {
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY");
        }
        
          openAuditEditForm(au:Audit){
            this.getEditAudit = au;
            this.$bvModal.show('editAuditModal');
        }
        
        

    }
