
import { Component, Ref, Vue } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import { BasisEnum, PriceSchedule } from "@/models/PriceSchedule";
import axios from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import EnumSelector from "@/components/EnumSelector.vue";
import QuantityPricingComponent from "@/components/Drug/QuantityPricingComponent.vue";
import NewClearSaveButtons from "@/components/NewClearSaveButtons.vue";
import PriceTestModal from '@/components/Drug/PriceTestModalComponent.vue';
import HelpPopup from "@/components/HelpPopup.vue";

@Component({
    components: {
        NewClearSaveButtons,
        QuantityPricingComponent,
        EnumSelector,
        SearchComponent,
        PriceTestModal,
        HelpPopup
    }
})
export default class PriceSchedulePage extends Vue {
    @Ref('qty-pricing') quantityPricingComponent!: QuantityPricingComponent;
    priceSchedule: PriceSchedule = new PriceSchedule();
    newMode: boolean = false;

    showPriceTestModal = false;

    get BasisEnum() {
        return BasisEnum;
    }

    get routeId(): number {
        let res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get isFormValid(): boolean {
        return this.priceSchedule?.name?.length > 0 && this.priceSchedule?.basis !== undefined;
    }

    get disableFields(): boolean {
        const lockedForUser = false; // not sure if we want to implement locks on the price schedule or not...
        const noEntityLoaded = !(this.priceSchedule && this.priceSchedule.id);

        return (noEntityLoaded || lockedForUser) && !this.newMode;
    }

    created() {
        this.fetchPriceSchedule({ id: this.routeId } as PriceSchedule);
    }

    clear() {
        this.newMode = false;
        if (this.routeId) this.fetchPriceSchedule();
        if (this.quantityPricingComponent?.clearDrug) this.quantityPricingComponent.clearDrug();
        this.priceSchedule = new PriceSchedule();
    }

    newClicked() {
        this.clear();
        this.newMode = true;
    }

    async saveClicked() {
        console.log("save clicked");
        let saved = await this.savePriceSchedule();
        await this.fetchPriceSchedule(saved);
    }

    async savePriceSchedule() {
        try {
            if (this.priceSchedule.marginOverrideAmt == undefined
                || this.priceSchedule.marginOverrideAmt as unknown == "") {
                this.priceSchedule.marginOverrideAmt = null;
            }

            if (this.priceSchedule.minimum == undefined || this.priceSchedule.minimum as unknown == "") {
                this.priceSchedule.minimum = null;
            }
            const resp = await axios.post<PriceSchedule>("/api/PriceSchedule/", this.priceSchedule);
            this.priceSchedule = new PriceSchedule(resp.data);
            this.$notification(NotificationOptions.successSaveNotificationPreset("Price Schedule"));
            return this.priceSchedule;
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async fetchPriceSchedule(entity?: PriceSchedule) {
        if (!entity || !entity.id) {
            if (this.routeId) {
                await this.$router.push({ name: "PriceSchedule" });
            }
            this.clear();
            return;
        }

        if (entity.id && entity.id != this.routeId) {
            await this.$router.push({ name: "PriceScheduleDetail", params: { id: entity.id.toString() } });
            return;
        }

        try {
            const resp = await axios.get<PriceSchedule>(`/api/PriceSchedule/${entity.id}`);
            this.priceSchedule = new PriceSchedule(resp.data);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

    }

}
