
    import {Component, Watch, Mixins, Prop, Vue, Ref} from 'vue-property-decorator';
    import axios from 'axios';

    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import {Prescription} from '@/models/Prescription';
    import {Patient} from '@/models/Patient';

    @Component({
        name: "PatientRxHistoryModalComponent",
        components: {
            PrescriptionsListComponent
        }
    })
    export default class PatientRxHistoryModalComponent extends Vue {
        @Prop({default: 'rx-history-modal'}) private id!: string;
        @Prop() private patient!: Patient;

        @Prop() private productName?: string;

        protected patient_prescriptions: Array<Prescription> = [];

        openRxHistory() {
            if (!this.patient?.id) return;

            //Get patient's prescriptions and format the data to be shown in the grid.
            axios.get<Prescription[]>(`/Patient/${this.patient.id}/prescriptions`)
                .then(response => {
                    let prescriptionsToShow = response.data;
                    if (this.productName?.length ?? 0 > 0) {
                        prescriptionsToShow = response.data.filter((rx: Prescription) => rx.productNameShort == this.productName);
                    }
                    this.patient_prescriptions = prescriptionsToShow.map(rx => new Prescription(rx));
                    this.$bvModal.show(`${this.id}-modal`);
                })
                .catch(error => {
                    console.log("Problem loading patient prescriptions", {error, response: error?.response});
                });
        }

        hideModal() {
            this.$bvModal.hide(`${this.id}-modal`);
        }
    }

