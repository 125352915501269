
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Axios, { AxiosError } from 'axios';
import { DatePicker } from "element-ui";
import SearchComponent from '@/components/SearchComponent.vue';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import { Prescription } from '@/models/Prescription';
import { Program } from '@/models/Program';
import { Store } from '@/models/Store';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import moment from "moment";
import { DeliveryCode } from "@/models/DeliveryCode";
import { OrderLabelData } from "@/models/OrderLabelData";
import PrintQueueTable from "@/components/Dashboard/PrintQueueTable.vue";
import { DashboardStatus } from "@/models/DashboardStatus";
import DispenseStatusComponent from "@/pages/Dashboard/Dashboard/DispenseStatusComponent.vue";

const OldQueueDaysKey = 'stale_print_queue_old_queue_days';

@Component({
    name: "PrintQueuePage",
    components: {
        DispenseStatusComponent,
        PrintQueueTable,
        SearchComponent,
        [DatePicker.name]: DatePicker,
        PrescriptionsListComponent,
        Checkbox,
    },
})
export default class PrintQueuePage extends Vue {
    private prescriptions: Array<OrderLabelData> = [];
    private selectedRxsIn: Array<Prescription> = [];
    private selectedRxsOut: Array<Prescription> = [];
    private printerAddress: string = "";
    private rxToPrint: Prescription = new Prescription();
    private N_ORDERS_SELECTION = 10;
    private showOutOfStocks = false;
    private showConsignment = false;
    private showPrinted = false;
    private oldQueueDays: number = 0;
    private outOfStockValue = '';
    private programFilter: Program = new Program();
    private storeFilter: Store = new Store();
    private shouldOverride: boolean = false;
    protected needsOverride: boolean = false;
    private ipAddress: string = "";

    protected isLoading: boolean = false;
    protected isPrinting: boolean = false;
    protected isPrintingRxRf: boolean = false;
    protected isPrintingBadge: boolean = false;

    private localPrinters: any[] = [];
    private activeLocalPrinter: any | null = null;
    private isLoadingPrinters: boolean = false;
    private isMarkingInStock: boolean = false;

    protected fieldsToRemove: Array<string> = ['qtyLeft', 'rxId'];
    private priorityCodes = [DeliveryCode.Overnight, DeliveryCode.StandardOvernight];
    private currentSort: string = "";
    private currentDesc: boolean = true;

    get pageMode() {
        return this.$route.name;
    }

    get isStalePrintQueue(): boolean {
        return this.pageMode == "StalePrintQueue";
    }

    get hasPromised(): Boolean {
        return !!this.prescriptions?.find(i => i.promisedDate);
    }

    get additionalFields() {
        const fields = [
            {
                index: null,
                field: { key: "orderDate", label: "Order Date", sortable: true, formatter: this.dateFormatter },
            },
            {
                index: null,
                field: { key: "drugsFilledDate", label: "Filled", sortable: true, formatter: this.filledDateFormatter },
            },
            {
                index: 1,
                field: { key: "ndc", label: "NDC", sortable: true, formatter: this.ndcFormatter },
            },
            {
                index: 5,
                field: { key: "rxIdWithExpirationWarning", label: "Rx Id" },
            },
        ];

        if (this.hasPromised) {
            fields.push({
                index: 0,
                field: {
                    key: "promisedDate",
                    label: "Promised",
                    sortable: true,
                    formatter: (value: any) => !value ? "" : value.toString(),
                },
            });
        }

        return fields;
    }

    get persistentOldQueueDays(): number {
        const objStr = localStorage.getItem(OldQueueDaysKey);
        if (objStr) return JSON.parse(objStr);
        return 0;
    }

    beforeMount() {
        console.log(`%cBefore Mounting ${this.$options.name}`, 'color: greenyellow');
        this.oldQueueDays = this.persistentOldQueueDays;
    }

    @Watch("oldQueueDays")
    changedOldQueueDays(val: number, _oldVal: any) {
        localStorage.setItem(OldQueueDaysKey, JSON.stringify(val));
    }

    @Watch("currentSort")
    @Watch("currentDesc")
    currentSortUpdated(_newValue: string, _oldValue: string) {
        let scripts = this.prescriptions.sort((a, b) => this.customSort(a, b, this.currentSort, this.currentDesc));
        if (this.currentDesc) scripts = scripts.reverse();
        this.prescriptions = scripts;
    }

    dateFormatter(value: Date, _key: string, _item: any) {
        if (!value) {
            return "";
        }
        return moment.utc(value).format("L");
    }

    filledDateFormatter(value: Date, _key: string, item: any) {
        if (!value || item.fillDate > item.drugsFilledDate) {
            return "";
        }
        return moment.utc(value).format("L");
    }

    ndcFormatter(value: string) {
        if (value == null) {
            return value;
        }
        return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
    };

    get filteredPrescriptions() {
        if (this.oldQueueDays > 0) {
            return this.prescriptions.filter(s => moment(s.printed) < moment().add(-1 * this.oldQueueDays, "days"));
        }

        return this.prescriptions;
    }

    async loadOrders() {
        if (!this.storeFilter.id) return;
        try {
            this.isLoading = true;
            let url = `/Print/prescriptions-pending/${this.storeFilter.id}/${(this.programFilter.id || '')}`;
            if (this.pageMode == "StalePrintQueue") {
                url = `/Print/GetStalePrintQueue/${this.storeFilter.id}/${(this.programFilter.id || '')}`;
            }
            if (this.showPrinted) {
                url = `/Print/recently-printed/${this.storeFilter.id}/${(this.programFilter.id || '')}`;
            }

            //Get patient's prescriptions and format the data to be shown in the grid.
            const response = await Axios.get<OrderLabelData[]>(url, {
                params: {
                    outOfStock: this.showOutOfStocks,
                    isConsignment: this.showConsignment,
                },
            });
            let scripts: OrderLabelData[] = response.data.map((rx: any) => new OrderLabelData(rx));
            scripts = scripts.sort(this.sortDeliveryCode);
            this.prescriptions = scripts;
        } catch (err) {
            const error = err as AxiosError;
            console.warn("Error while loading print queue.", { error, response: error?.response });
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.isLoading = false;
        }
    }

    private toString(value: any): string {
        if (value === null || typeof value === "undefined") {
            return "";
        }
        if (value instanceof Object) {
            return Object.keys(value)
                .sort()
                .map(key => this.toString(value[key]))
                .join(" ");
        }
        return String(value);
    }

    get customSort(): (a: Prescription, b: Prescription, sortBy?: string, sortDesc?: boolean, formatter?: Function, compareOptions?: any, compareLocale?: any) => number {
        return (a: Prescription, b: Prescription, sortBy?: string, _sortDesc?: boolean, _formatter?: Function, compareOptions?: any, compareLocale?: any) => {
            if (sortBy == undefined || sortBy === "deliveryCode" || sortBy === "") {
                return this.sortDeliveryCode(b, a);
            }
            const aSort = (a as any)[sortBy];
            const bSort = (b as any)[sortBy];
            if ((typeof aSort === "number" && typeof bSort === "number") ||
                (aSort instanceof Date && bSort instanceof Date)) {
                return a < b ? -1 : a > b ? 1 : 0;
            } else {
                return this.toString(aSort).localeCompare(this.toString(bSort), compareLocale, compareOptions);
            }
        };
    }

    get sortDeliveryCode(): (a: Prescription, b: Prescription) => number {
        return (a: Prescription, b: Prescription) => {
            if (this.priorityCodes.includes(b.deliveryCode)) return 1;
            if (this.priorityCodes.includes(a.deliveryCode)) return -1;
            if (b.deliveryCode == DeliveryCode.WillCall) return 1;
            if (a.deliveryCode == DeliveryCode.WillCall) return -1;
            return 0;
        };
    }

    get lastStore(): any {
        const objStr = localStorage.getItem('printQueue_lastStore');
        if (objStr)
            return JSON.parse(objStr);
        else return null;
    }

    get status(): DashboardStatus {
        if (this.$root.$data.dashboardData) {
            return this.$root.$data.dashboardData as DashboardStatus;
        }
        return new DashboardStatus();
    }

    @Watch('storeFilter')
    lastStoreChanged(val1: any, _val2: any) {
        if (val1 == new Store()) return;
        localStorage.setItem('printQueue_lastStore', JSON.stringify(val1));
    }

    mounted() {
        this.storeFilter = new Store(this.lastStore) ?? new Store();
        this.setupFromQuery();
        this.getLocalPrinters();
    }

    setupFromQuery() {
        const query = this.$route.query;
        if (Object.keys(query).findIndex(v => v == "storeId") != -1 && this.storeFilter?.id != Number(query.storeId)) {
            if (query.storeId) {
                this.storeFilter = new Store({ id: Number(query.storeId) } as Store);
            }
        }

        if (Object.keys(query).findIndex(v => v == "programId") != -1 && this.programFilter?.id != Number(query.programId)) {
            if (query.programId) {
                this.programFilter = new Program({ id: Number(query.programId) } as Program);
            } else {
                this.programFilter = new Program();
            }
        }

        if (Object.keys(query).findIndex(v => v == "oos") != -1 && this.showOutOfStocks != (query.oos == "true")) {
            this.showOutOfStocks = query.oos == "true";
        }

        if (Object.keys(query).findIndex(v => v == "recent") != -1 && this.showPrinted != (query.recent == "true")) {
            this.showPrinted = query.recent == "true";
        }
    }

    @Watch('$route.query')
    queryChanged(_newValue: any, _oldValue: any) {
        this.setupFromQuery();
    }

    @Watch('showOutOfStocks')
    oosChanged(newValue: boolean, oldValue: boolean) {
        const oos = this.$route.query.oos == "true";
        if (newValue != oldValue && newValue != oos) {
            this.$router.replace({
                name: this.$route.name ?? "",
                query: { ...this.$route.query, oos: newValue.toString() },
            });
        }
    }

    @Watch('showPrinted')
    printedChanged(newValue: boolean, oldValue: boolean) {
        const recent = this.$route.query.recent == "true";
        if (newValue != oldValue && newValue != recent) {
            this.$router.replace({
                name: this.$route.name ?? "",
                query: { ...this.$route.query, recent: newValue.toString() },
            });
        }
    }

    @Watch('storeFilter')
    storeChange(newValue: Store, oldValue: Store) {
        if (newValue.id != oldValue.id && newValue.id != Number(this.$route.query.storeId)) {
            this.$router.replace({
                name: this.$route.name ?? "",
                query: { ...this.$route.query, storeId: this.storeFilter.id.toString() },
            });
        }
    }

    @Watch('programFilter')
    programChange(newValue: Program, oldValue: Program) {
        if (newValue?.id != oldValue?.id && newValue?.id != Number(this.$route.query?.programId)) {
            this.$router.replace({
                name: this.$route.name ?? "",
                query: { ...this.$route.query, programId: newValue?.id?.toString() },
            });
        }
    }

    @Watch('showConsignment')
    @Watch('showOutOfStocks')
    @Watch('storeFilter')
    @Watch('programFilter')
    @Watch('showPrinted')
    reloadOrders(_newValue: any, _oldValue: any) {
        this.loadOrders();
    }

    @Watch('pageMode') pageModeChanged() {
        this.clearFilters();
        this.prescriptions = [];
        this.selectedRxsIn = [];
        this.selectedRxsOut = [];
        this.outOfStockValue = '';
        this.rxToPrint = new Prescription();
    }

    @Watch('activeLocalPrinter')
    activeLocalPrinterChanged() {
        localStorage.setItem("active-printer", this.activeLocalPrinter?.name);
    }

    get allowPrinting() {
        return this.selectedRxsOut?.length;
    }

    selectOrders() {
        this.selectedRxsIn = this.prescriptions.slice(0, this.N_ORDERS_SELECTION);
        this.selectedRxsOut = this.selectedRxsIn;
    }

    rxSelectionChanged(rows: Array<Prescription>) {
        this.selectedRxsOut = rows;
    }

    async printWithoutPatEd() {
        this.isPrinting = true;
        for await (const script of this.selectedRxsOut) {
            await Axios.post(`/Print/SkipPatEd/${script.storeID}/${script.rxNumber}/${script.rfNumber}/${script.orderId}?ipAddress=${this.printerAddress}`);
        }

        await this.loadOrders();
        this.isPrinting = false;
    }

    async print() {
        this.isPrinting = true;
        //Get patient's prescriptions and format the data to be shown in the grid.
        const ids = {
            PrintMode: this.showPrinted ? "RecentlyPrinted" : this.pageMode,
            Labels: this.selectedRxsOut.map(rx => {
                return {
                    OrderId: rx.orderId,
                    StoreId: rx.storeID,
                    RxNumber: rx.rxNumber,
                    RfNumber: rx.rfNumber,
                    ProgramId: rx.programID,
                };
            }),
        };

        const localPrint = !!this.activeLocalPrinter;

        //Get patient's prescriptions and format the data to be shown in the grid.
        try {
            const response = await Axios.post(`/Print/prescriptions-pending`, ids, { params: { localPrint } });

            if (localPrint) {
                await this.sendToLocalPrint(response.data.toPrint, true);
                return;
            }

            const printedLabels = response.data.printedLabels || 0;
            const sentLabels = ids.Labels.length || 0;
            // Notify the number of labels printed
            if (printedLabels) {
                this.$notification(NotificationOptions.notificationOptionsPreset(`Printed ${printedLabels} labels.`, NotificationOptions.NotificationTypes.success));
            } else if (sentLabels > printedLabels) {
                this.$notification(NotificationOptions.notificationOptionsPreset(`${sentLabels - printedLabels} labels were not printed.`, NotificationOptions.NotificationTypes.danger));
                console.error("Errors while printing", response.data);
            }
            // Reload Print Queue.
            await this.loadOrders();
        } catch (error) {
            console.warn(error);
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.isPrinting = false;
        }
    }

    async sendToLocalPrint(jobsToPrint: any, markAsPrinted: boolean) {
        const printerName = this.activeLocalPrinter.name;
        const apiBaseUrl = this.$http.defaults.baseURL?.replace("/api", "");
        const localRequest = {
            printerName,
            jobs: jobsToPrint,
            apiBaseUrl,
            markAsPrinted,
        };
        const localResponse = await Axios.post("http://localhost:7274/print", localRequest);

        const data = localResponse.data;
        const errorCount = data.errors?.length ?? 0;
        const printedCount = jobsToPrint.length - errorCount;

        if (localResponse.data.errors?.length) {
            this.$notification(NotificationOptions.notificationOptionsPreset(`${errorCount} has issues printing`, NotificationOptions.NotificationTypes.danger));
        }

        if (printedCount > 0) {
            this.$notification(NotificationOptions.notificationOptionsPreset(`Printed ${printedCount} successfully`, NotificationOptions.NotificationTypes.success));
        }

        await this.loadOrders();
    }

    async removeWithoutPrinting() {
        try {
            const ids = {
                Labels: this.selectedRxsOut.map(rx => {
                    return {
                        OrderId: rx.orderId,
                        StoreId: rx.storeID,
                        RxNumber: rx.rxNumber,
                        RfNumber: rx.rfNumber,
                    };
                }),
            };
            await Axios.post(`/Print/RemoveFromPrintQueue`, ids);
            this.$notification(NotificationOptions.notificationOptionsPreset(`Labels Removed`, NotificationOptions.NotificationTypes.success));
            await this.loadOrders();
        } catch {
            this.$notification(NotificationOptions.notificationOptionsPreset(`Unable to Remove Labels`, NotificationOptions.NotificationTypes.danger));
        }
    }

    async printByRxRf() {
        this.rxToPrint.storeID = Number(this.storeFilter.id);
        this.rxToPrint.rfNumber = this.rxToPrint.rfNumber || 0;
        console.log('this.rxToPrint', this.rxToPrint.rxID);
        if (!this.rxToPrint.hasFullId) return;

        this.isPrintingRxRf = true;

        const localPrint = !!this.activeLocalPrinter;

        try {
            const res = await Axios.get(`/Print/${this.rxToPrint.rxID}/rx-pated`, { params: { localPrint } });

            if (localPrint) {
                await this.sendToLocalPrint(res.data.toPrint, false);
                return;
            }

            if (res.data.printedLabels > 0 && res.data.badLabels?.length == 0) {
                const msg = `PatEd for Rx ${this.rxToPrint.rxID} was successfully printed`;
                this.$notification(NotificationOptions.notificationOptionsPreset(msg, NotificationOptions.NotificationTypes.success));
            }
        } catch (error) {
            const err = error as any;
            if ((err.response.data as string).includes("No Prescription found"))
                this.$notification(NotificationOptions.notificationOptionsPreset("Prescription not found", NotificationOptions.NotificationTypes.danger));
            else
                console.error("Error while pulling printing Rx", { err, response: err?.response });
        } finally {
            this.isPrintingRxRf = false;
        }
    }

    async printBadge() {
        try {
            console.log('ipAddress', this.ipAddress);
            this.isPrintingBadge = true;
            await Axios.post(`/Print/PrintMyBadge/?storeId=${this.storeFilter.id}&ipAddress=${this.ipAddress}`);
        } catch {
            this.$notification(NotificationOptions.notificationOptionsPreset("Error printing badge.", NotificationOptions.NotificationTypes.danger));
        } finally {
            this.isPrintingBadge = false;
        }
    }

    async sendOutOfStock(e: any) {
        e.preventDefault();

        try {
            const res = await Axios.post<number>(`/Print/${this.outOfStockValue}/OutOfStock`, null, { params: { shouldOverride: this.shouldOverride } });
            const newStockQuantity = res.data;
            const message = `Marked out of stock. Net available: ${newStockQuantity}`;

            this.$notification(NotificationOptions.notificationOptionsPreset(message, NotificationOptions.NotificationTypes.success));
            this.needsOverride = false;
            this.shouldOverride = false;
            await this.loadOrders();
        } catch (err) {
            const error = err as AxiosError;
            this.needsOverride = true;
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Out Of Stock", error));
        } finally {
            this.outOfStockValue = '';
        }
    };

    rxID(prescription: Prescription | null): string {
        if (!prescription) return "";
        return `${prescription.storeID}-${prescription.rxNumber}-${prescription.rfNumber}`;
    }

    // FILTERS
    search() {
        this.loadOrders();
    }

    clearFilters() {
        this.programFilter = new Program();
        this.storeFilter = new Store(this.lastStore) ?? new Store();
        this.showOutOfStocks = false;
        this.showConsignment = false;
        this.showPrinted = false;
    }

    colorCodeTable(rx: Prescription, type: any) {
        if (!rx || type !== "row") return;

        if (this.showPrinted) return 'table-info';
        if (rx.deliveryCode == DeliveryCode.WillCall) return 'table-warning';
        if (this.priorityCodes.includes(rx.deliveryCode)) return 'table-danger';
        const dayDiff = moment(rx.expirationDate).diff(moment(), "days");
        if (dayDiff <= 1) return 'table-danger';
        if (dayDiff <= 3) return 'table-warning';
        if (dayDiff <= 7) return 'table-info';
    }

    async getLocalPrinters() {
        try {
            this.isLoadingPrinters = true;
            const response = await this.$http.get("http://localhost:7274/print/devices");
            this.localPrinters = response.data;

            this.handleDefaultPrinter();
        } catch {
            //ignore
        } finally {
            this.isLoadingPrinters = false;
        }
    }

    handleDefaultPrinter() {
        const lastPrinter = localStorage.getItem("active-printer");

        if (lastPrinter) {
            this.activeLocalPrinter = this.localPrinters?.find(p => p.name === lastPrinter);
        }
    }

    get hasLocalPrinters() {
        return !!this.localPrinters.length;
    }

    prettyPrinterName(printer: any) {
        if (!printer && (!this.storeFilter || this.storeFilter.defaultPrinter)) return "Store Default Printer";
        if (!printer) return "No Printer Defined";
        if (!printer.serverName) return printer.name;
        return printer.name.replace(printer.serverName, "").replace(/\\/g, "");
    }

    get showPrintingModal() {
        return this.isPrinting || this.isPrintingRxRf || this.isPrintingBadge;
    }

    get selectedCount() {
        return this.selectedRxsOut?.length;
    }

    async markInStock() {
        try {
            this.isMarkingInStock = true;

            const selectedScripts = this.selectedRxsOut.map(rx => {
                return {
                    OrderId: rx.orderId,
                    StoreId: rx.storeID,
                    RxNumber: rx.rxNumber,
                    RfNumber: rx.rfNumber,
                    ProgramId: rx.programID,
                };
            });

            await Axios.post("/Print/MarkAsInStock", selectedScripts);

            await this.loadOrders();
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.isMarkingInStock = false;
        }
    }
}

