
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Prescription } from '@/models/Prescription';
import { Store } from '@/models/Store';
import SearchComponent from '@/components/SearchComponent.vue';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import moment from 'moment';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Order } from '@/models/Order';

@Component({
    name: "AutorefillQueuePage",
    components: {
        SearchComponent,
        PrescriptionsListComponent,
    },
})
export default class AutorefillQueuePage extends Vue {
    private prescriptions: Array<Prescription> = [];
    private storeFilter: Store = new Store();
    private loading: boolean = false;
    private processing: boolean = false;

    private fieldsToRename = [
        { key: 'dispensed', label: "Last Dispensed" },
        { key: 'patientPay', label: "Last Copay" },
        { key: 'productNameShort', label: "Drug" },
        { key: 'fillDate', label: "Last Fill Date" },
    ];

    private fieldsKeysToRemove = ['qtyLeft'];

    private additionalFields = [
        {
            index: 9,
            field: {
                key: "nextProcessDate",
                label: "Next Proc.",
                sortable: true,
                sortDirection: "desc",
                formatter: this.dateFormatter,
            },
        },
        {
            index: 8,
            field: {
                key: "writtenDate",
                sortable: true,
                formatter: this.dateFormatter,
            },
        },
        {
            index: 6,
            field: { key: "quantityRemaining", label: "Quantity Remaining", class: "text-center", sortable: true },
        },
        { index: 5, field: { key: "daySupply", label: "DS", sortable: true } },

    ];

    dateFormatter(value: Date, _key: string, _item: any) {
        return moment.utc(value).format("L");
    }

    created() {
        this.search();
    }

    search() {
        this.loading = true;
        this.$http.get(`/autorefill/queue/${this.storeFilter?.id || ''}`)
            .then(res => {
                if (res.data?.length) {
                    res.data = res.data.map((rx: any) => {
                        //Formatting PrescriptionInAutorefill object to look like regular a prescription
                        rx.dispensed = rx.lastDispensedAmount;
                        rx.patientPay = rx.lastCopay;
                        rx.productNameShort = rx.drugName;
                        rx.fillDate = rx.lastFillDate;
                        return rx;
                    });
                    this.prescriptions = res.data.map((rx: any) => {
                        let script = new Prescription(rx) as any;
                        script.patientName = rx.patientName;
                        return script;
                    });
                    console.log(this.prescriptions);
                } else {
                    this.prescriptions = new Array<Prescription>();
                }
            })
            .catch(error => {
                console.warn("Error while fetching prescriptions by date", { error, response: error?.response });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    private scriptsInProcessing: Prescription[] = [];

    createOrder(rx?: Prescription) {
        if (!rx) return;
        if (this.scriptsInProcessing.indexOf(rx) > -1) return;

        this.scriptsInProcessing.push(rx);
        this.processing = true;
        this.$http.post<Order>('/order/auto-refill', rx)
            .then(res => {
                if (res.data.id) {
                    this.$notification(NotificationOptions.successSaveNotificationPreset('Auto-refilled order #' + res.data.id));
                }
            })
            .catch(err => {
                const errorMsg = 'Error creating order';
                const warningMsg = 'Issue during the order creation process';
                const orderNotCreated = err.response?.data?.toLowerCase().includes('order not created');
                this.$notification({
                    title: orderNotCreated ? errorMsg : warningMsg,
                    message: err.response.data,
                    type: orderNotCreated ? "danger" : "warning",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                });
                console.error(errorMsg, { err, response: err.response });
            })
            .finally(() => {
                this.search();
                this.scriptsInProcessing.splice(this.scriptsInProcessing.indexOf(rx), 1);
                this.processing = false;
            });
    }
}

