import TrackedObject from "./TrackedObject";

export interface DrugShortageReport extends TrackedObject {
    id?: number;
    storeId: number;
    storeName: string;
    programId: number | string | undefined;
    programName: string;
    packageId: number;
    qtyOnHand: number; 
    qtyNeeded: number;
    ndc?: string;    
    drugName?: string;
    dateOrdered?: Date;
    estimatedArrivalDate?: Date;
    dateDelivered?: Date;
    backordered?: boolean;
    unitOfMeasure?: number;
    manufacturer: string;
    }

export class DrugShortageReport implements DrugShortageReport{
    constructor(obj?:DrugShortageReport){
        if(obj) Object.assign(this, obj);
    }
}