import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import HasLabel from "./HasLabel";

export interface PharmacistRecord extends HasID, TrackedObject {
    id: number;
    pharmacyId: number;
    firstName: string;
    lastName: string;
    isActive: boolean;
}

export class PharmacistRecord extends HasLabel {

    constructor(obj?:PharmacistRecord) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {

        if (this.id)
            return `Id: ${this.id || ''}, PharmacyId: ${this.lastName || ''}, FirstName: ${this.firstName || ''}, LastName: ${this.lastName || ''}, IsActive: ${this.isActive || ''}`;
        else return '';
    }
}

