import {ProductivityStatsBase} from '@/models/ProductivityStatsBase'

export interface UserProductivity extends ProductivityStatsBase {
    nonEscripts: number;
    nonEscriptsRefills: number;
    escripts: number;
    escriptsRefills: number;
    total: number;
    errors: number;
    netTotal: number;
    error: number;
    totalCalls: number;
    inbound: number;
    outbound: number;
}

export class UserProductivity extends ProductivityStatsBase{
    constructor(){
        super();
        this.nonEscripts = 0;
        this.nonEscriptsRefills = 0;
        this.escripts = 0;
        this.escriptsRefills = 0;
        this.total = 0;
        this.errors = 0;
        this.netTotal = 0;
        this.error = 0;
        this.totalCalls = 0;
        this.inbound = 0;
        this.outbound = 0;
    }
}
