import * as signalR from "@microsoft/signalr";
import { DashboardStatus } from "@/models/DashboardStatus";
import { PhoneStats } from "@/models/PhoneStats";
import Vue from "vue";
import axios from 'axios';

import DashboardSharedWorker from "worker-loader?worker=SharedWorker!@/workers/dashboardWorker.js";
import { UserNotificationViewModel, Notification as NotificationObject } from "@/models/Notification";

let isPrimaryTab = false;
let useSystemNotifications: boolean | null = null;
let electLeaderTab: any | null = null;

const handlePrimaryTab = (): void => {

    const tabCoordinatorChannel = new BroadcastChannel('tab-coordinator');

    const tabId = Math.random().toString(36).substring(2, 9);

    const becomePrimary = (): void => {
        isPrimaryTab = true;
        tabCoordinatorChannel.postMessage({ type: 'PRIMARY', tabId: tabId });
    }

    electLeaderTab = async (): Promise<void> => {
        tabCoordinatorChannel.postMessage({ type: 'ELECTION', tabId: tabId });

        await new Promise<void>(resolve => {
            setTimeout(() => {
                if (!isPrimaryTab) {
                    becomePrimary();
                }
                resolve();
            }, 750);
        });
    }

    tabCoordinatorChannel.onmessage = (event) => {
        const { type, tabId: senderTabId } = event.data;

        if (type === 'ELECTION') {
            if (tabId > senderTabId) {
                tabCoordinatorChannel.postMessage({ type: 'ELECTION_RESPONSE', tabId: tabId });
            }
        } else if (type === 'ELECTION_RESPONSE') {
            isPrimaryTab = false;
        } else if (type === 'PRIMARY') {
            if (senderTabId !== tabId) {
                isPrimaryTab = false;
            }
        }

        if (type === 'PRIMARY_CLOSED' || type === 'PRIMARY_INACTIVE') {
            electLeaderTab();
        }
    };

    window.addEventListener('load', electLeaderTab);

    window.addEventListener('beforeunload', () => {
        if (isPrimaryTab) {
            tabCoordinatorChannel.postMessage({ type: 'PRIMARY_CLOSED' });
        }
    });

    document.addEventListener('visibilitychange', () => {
        if (document.hidden && isPrimaryTab) {
            tabCoordinatorChannel.postMessage({ type: 'PRIMARY_INACTIVE' });
            isPrimaryTab = false;
        } else if (!document.hidden && !isPrimaryTab) {
            electLeaderTab();
        }
    });

    electLeaderTab();
};

const markNotificationRead = (app: Vue, userNotificationId: number): void => {
    try {
        if (userNotificationId <= 0) return;
        app.$http.post(`/notification/mark-read/${userNotificationId}`);
    } catch {
        // ignore
    }
}

const showNotificationToast = (app: Vue, userNotificationId: number, notification: NotificationObject): void => {
    const toastLink = app.$createElement("router-link", {
        props: {
            to: notification.target
        },
        style: {
            color: "black",
            fontWeight: "bold"
        },
        nativeOn: {
            click() {
                markNotificationRead(app, userNotificationId);
            }
        }
    }, [notification.body]);

    app.$bvToast.toast(toastLink, {
        title: notification.title,
        solid: true,
        toaster: "b-toaster-bottom-right",
        href: notification.target
    });
};

const showWebNotification = (app: Vue, userNotificationId: number, notification: NotificationObject): void => {
    const systemNotification = new Notification(notification.title, {
        body: notification.phi ? undefined : notification.body,
        tag: notification.id.toString()
    });

    systemNotification.onclick = (): any => {
        app.$router.push(notification.target);
        markNotificationRead(app, userNotificationId);
    };
}

const handleNotification = async (app: Vue, userNotification: UserNotificationViewModel): Promise<void> => {
    const notification = userNotification.notification;

    console.debug("beginning notification handling...");

    const supportsSystemNotifications = "Notification" in window;
    if (!supportsSystemNotifications) {
        showNotificationToast(app, userNotification.id, notification);
        return;
    }

    if (useSystemNotifications === null) {
        useSystemNotifications = !(await app.$http.get<boolean>("/configuration/DisableWebNotifications")).data;
    }

    if (!useSystemNotifications) {
        showNotificationToast(app, userNotification.id, notification);
        return;
    }

    const notificationPermission = await Notification.requestPermission();

    if (notificationPermission !== "granted") {
        showNotificationToast(app, userNotification.id, notification);
        return;
    }

    await electLeaderTab();

    if (!isPrimaryTab) {
        console.debug("not primary tab, not showing web notification");
        return;
    }

    showWebNotification(app, userNotification.id, notification);
};

const handleSharedWorker = (app: Vue, connectionsOptions: signalR.IHttpConnectionOptions): void => {
    const dashboardSharedWorker = new DashboardSharedWorker();

    dashboardSharedWorker.port.start();

    const token = connectionsOptions.accessTokenFactory ? connectionsOptions.accessTokenFactory() : null;

    //Send in the current token to get the hub party started
    dashboardSharedWorker.port.postMessage([{ token, serverURL: axios.defaults.baseURL?.replace("/api", "") }]);

    //Get messages back from the shared worker
    dashboardSharedWorker.port.onmessage = function (e: any) {     
        switch (e.data[0]) {
            case "data":
                app.$root.$data.dataHubState = e.data[1].status;
                app.$root.$data.dataHubFileVersion = e.data[1].fileVersion;
                app.$root.$data.dataHubPhoneStats = e.data[1].phoneStats;
                app.$root.$data.notificationCount = e.data[1].notificationCount;
                app.$root.$data.dispenseErrorCount = e.data[1].dispenseErrorCount;
                app.$root.$data.dashboardData = new DashboardStatus(e.data[1].dashboardData);
                app.$root.$data.dashboardDataUpdated = e.data[1].dashboardDataDate;
                break;
            case "notification":
                console.debug("got message from shared worker", e);
                handleNotification(app, e.data[1]);
                break;
        }
    }
}

const handleDirectHubs = (app: Vue, serverURL: string, connectionsOptions: signalR.IHttpConnectionOptions): void => {
    const dataHub = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(`${serverURL}/data-hub`, connectionsOptions)
        .build();

    dataHub
        .start()
        .then(() => {
            dataHub.on("PhoneStats", (res: PhoneStats) => {
                app.$root.$data.dataHubPhoneStats = res;
            });
            dataHub.on("FileVersion", (res) => {
                app.$root.$data.dataHubFileVersion = res;
            });
            dataHub.on("Notification", (res) => {
                if (res.ids) {
                    dataHub.invoke("AcknowledgeNotification", res.ids);
                } else {
                    dataHub.invoke("AcknowledgeNotification", res.id);
                }
                handleNotification(app, res);
            });
            dataHub.on("UpdateDispenseErrorCounter", (res) => {
                app.$root.$data.dispenseErrorCount = res;
            });
            dataHub.on("UpdateDashboard", (res: DashboardStatus) => {
                app.$root.$data.dashboardData = new DashboardStatus(res);
                app.$root.$data.dashboardDataUpdated = new Date();
            });

            dataHub.invoke("UpdateDashboard");
            dataHub.invoke("GetFileInfo");
            dataHub.invoke("GetUnreadNotificationCount");

            dataHub.on("NotificationUnreadCount", (res) => {
                app.$root.$data.notificationCount = res;
            });

            dataHub.onclose(() => { app.$root.$data.dataHubState = "Closed"; });
            dataHub.onreconnected(() => { app.$root.$data.dataHubState = "Connected"; });
            dataHub.onreconnecting(() => { app.$root.$data.dataHubState = "Reconnecting"; });
        })
        .catch((err: any) => {
            console.warn("Error while connecting to data-hub", {
                err,
                response: err?.response
            });
        });
}

export function dashboardSetup(app: Vue, serverURL: string, connectionsOptions: signalR.IHttpConnectionOptions): void {
    handlePrimaryTab();

    if (window.SharedWorker) {
        handleSharedWorker(app, connectionsOptions);
    } else {
        handleDirectHubs(app, serverURL, connectionsOptions);
    }
}
