
    import { Component, Vue } from 'vue-property-decorator'
    import { Drug } from '@/models/Drug/Drug.ts';
    import { DrugManufacturer } from '@/models/DrugManufacturer';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "PriorAuthorizationAutosendConfigPage",
        components: {
            SearchComponent
        }
    })
    export default class PriorAuthorizationAutosendConfigPage extends Vue {
        private drugs : Array<Drug> = new Array<Drug>();
        private drugsSelected : Array<Drug> = new Array<Drug>();
        private manufacturer : DrugManufacturer = new DrugManufacturer();
        private isLoading = false;

        private fields: any[] = [
            { key: "select", label: "Autosend", sortable: false, thStyle: 'width: 3em' },
            { key: "ndc", label: "NDC", sortable: true },
            { key: "productNameShort", label: "Drug", sortable: true },
        ];

        getDrugsByManufacturer(man: DrugManufacturer){
            if(!this.manufacturer?.id) return;

            this.isLoading = true;
            this.$http.get<Array<Drug>>(`drug/manufacturers/${man.id}/drugs`)
                .then(res => {
                    if(res.data?.length){
                        this.drugs = res.data.map(drug => Object.assign(new Drug(), drug));
                        this.drugsSelected = this.drugs.filter(drug => drug.autosend);
                    }
                })
                .catch(err => {
                    console.log("Error while getting manufacturer drugs", {err, response: err?.response});
                })
                .finally(() => this.isLoading = false)
        }

        save(){
            if(!this.manufacturer?.id) return;

            console.log('drugsSelected', this.drugsSelected);
            const packageIDs = this.drugsSelected.map(drug => drug.packageID);
            this.$http.post<Array<Drug>>(`drug/manufacturers/${this.manufacturer.id}/set-drugs-autosend`, packageIDs)
                .then(res => {
                    this.$notification(NotificationOptions.successSaveNotificationPreset("PA Autosend setup"));
                })
                .catch(err => {
                    const errorMsg = "Error while setting drugs PA autosend";
                    console.log(errorMsg, {err, response: err?.response});
                    this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg));
                })
        }

        selectAll(){
            this.drugsSelected = this.drugs;
        }

        removeAll(){
            this.drugsSelected = new Array<Drug>();
        }

        get areDrugsSelected() : boolean {
            return this.drugsSelected?.length > 0;
        }

    }
