
import Card from '@/components/Cards/Card.vue';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import SearchComponent from '@/components/SearchComponent.vue';
import { DatePicker } from "element-ui";
import { User } from '@/models/User';
import Axios, { AxiosRequestConfig, AxiosError } from "axios";
import { Call } from '@/models/Call';
import TranscriptionViewer from '@/components/TranscriptionViewer.vue'

@Component({
    name: "CallLog",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        TranscriptionViewer,
    }
})
export default class CallLogPage extends Vue {
    protected startDate?: string = moment().add(-30, 'day').toISOString();
    protected endDate?: string = moment().toISOString();
    protected externalNumber: string = "";
    protected isLoading?: boolean = false;
    protected userFilter: User = new User();
    protected calls: Array<Call> = [];
    protected selectedCall: Call = new Call();
    protected fields: any[] = [
        { key: "externalNumber", label: "External Number", sortable: true },
        { key: "agent", label: "Agent", sortable: true },
        { key: "initiated", label: "Initiated", sortable: true, formatter: (d: Date) => moment(d).local().format("L LT") },
        { key: "duration", label: "Duration", sortable: true },
        { key: "outbound", label: "Direction", sortable: true, formatter: (d: boolean) => d ? "Outbound" : "Inbound" },
        { key: "recordingSid", label: "" },
    ];


    searchCalls() {
        Axios.get('/api/Call', { params: { startDate: this.startDate, endDate: this.endDate, externalNumber: this.externalNumber, agent: this.userFilter?.userName } }).then((response) => {
            this.calls = response.data;
        });
    }

    selectRecording(r:any) {
        r.toggleDetails();

    }
    async downloadCall(itemId: string) {
        try {
            const config: AxiosRequestConfig = {
                responseType: 'blob'
            }
            var response = await Axios.get(`/Call/DownloadAudio/${itemId}`, config);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: "audio/wav" }));
            let sourceItem: any = document.getElementById(`source-${itemId}`);
            sourceItem.src = url;
            let audioItem: any = document.getElementById(`audio-${itemId}`);
            audioItem.hidden = false;
            audioItem.pause();
            audioItem.load();
            audioItem.play();
            console.log(response);
        } catch (err) {
            const error = err as any;
            let errorMessage = "Unable To Download Audio for Call";
            if (error && error?.response?.data) {
                errorMessage += ` - ${error.response.data}`;
            }
        }

    }
}
