import { render, staticRenderFns } from "./InventoryBatchFormComponent.vue?vue&type=template&id=1297d53c&scoped=true"
import script from "./InventoryBatchFormComponent.vue?vue&type=script&lang=ts"
export * from "./InventoryBatchFormComponent.vue?vue&type=script&lang=ts"
import style0 from "./InventoryBatchFormComponent.vue?vue&type=style&index=0&id=1297d53c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1297d53c",
  null
  
)

export default component.exports