
import { Component, Vue } from "vue-property-decorator";
import { AxiosRequestConfig } from "axios";
import PatientAssistanceProgramTable from "@/components/PatientAssistanceProgram/PatientAssistanceProgramTable.vue";

@Component({
    name: "PatientAssistanceProgramQueue",
    components: {
        PatientAssistanceProgramTable

    }
})
export default class PatientAssistanceProgramQueue extends Vue {
    protected showAll: boolean = false;

    get tableFilter(): AxiosRequestConfig {
        let filter: AxiosRequestConfig;
        filter = {
            params: {
                all: this.showAll
            }
        };
        return filter;
    }
}
