<template>
    <div>
        <label>{{ label }}</label>
        <div class="d-flex" style="gap: 15px;align-items: center;margin-top: -7px;">
            <DatePicker size="sm" :value="startDate" today-button reset-button :disabled="disabled"
                @input="updateStartDate" />
            <DatePicker size="sm" :value="endDate" today-button reset-button :disabled="disabled"
                @input="updateEndDate" />

            <b-dropdown size="sm" right :disabled="disabled">
                <b-dropdown-item @click="today">Today</b-dropdown-item>
                <b-dropdown-item @click="yesterday">Yesterday</b-dropdown-item>
                <b-dropdown-item @click="thisWeek">This Week</b-dropdown-item>
                <b-dropdown-item @click="lastWeek">Last Week</b-dropdown-item>
                <b-dropdown-item @click="last30Days">Last 30 Days</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="clearDates">Clear</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { DatePicker } from "element-ui";
import moment from 'moment';

export default {
    name: "DateRangeFilter",
    components: {
        DatePicker
    },
    props: {
        label: {
            type: String,
            default: "Date:"
        },
        disabled: Boolean,
        value: Object
    },
    computed: {
        startDate() {
            return this.value.startDate;
        },
        endDate() {
            return this.value.endDate;
        }
    },
    watch: {
        startDate() {
            console.log(this.startDate);
        }
    },
    methods: {
        updateStartDate(e) {
            if (!e) {
                this.$emit("input", { startDate: "", endDate: "" });
                return
            }
            this.$emit("input", { startDate: e, endDate: this.endDate ? this.endDate : e });
        },
        updateEndDate(e) {
            if (!e) {
                this.$emit("input", { startDate: "", endDate: "" });
                return
            }
            this.$emit("input", { startDate: this.startDate ? this.startDate : e, endDate: e });
        },
        today() {
            const today = new Date().toDateString();
            this.$emit("input", { startDate: today, endDate: today });
        },
        yesterday() {
            const yesterday = moment().subtract(1, 'day').toString();
            this.$emit("input", { startDate: yesterday, endDate: yesterday });
        },
        thisWeek() {
            this.$emit("input", {
                startDate: moment().startOf('week').toString(),
                endDate: moment().startOf('week').add(1, 'week').toString()
            });
        },
        lastWeek() {
            this.$emit("input", {
                startDate: moment().startOf('week').subtract(1, 'week').add(1, 'day').toString(),
                endDate: moment().startOf('week').subtract(1, 'week').add(1, 'week').toString()
            });
        },
        last30Days() {
            const today = new Date().toDateString();
            this.$emit("input", {
                startDate: moment().subtract(30, 'days').toString(),
                endDate: today
            });
        },
        clearDates() {
            this.$emit("input", { startDate: "", endDate: "" });
        }
    },
}

</script>