
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator';
    import Axios, { AxiosResponse } from 'axios';
    import {NotificationOptions} from "@/util/NotificationOptionsPresets"

    import Card from '@/components/Cards/Card.vue';
    import SearchComponent from '@/components/SearchComponent.vue';
    import PrescriptionsListComponent, { PrescriptionListColumn } from '@/components/Prescription/PrescriptionsListComponent.vue';

    import { Prescription } from '@/models/Prescription';
    import { DrugCategory } from '@/models/DrugCategory';

    @Component({
        name: "IncompleteAnswersQueuePage",
        components: {
            SearchComponent,
            PrescriptionsListComponent
        },
    })
    export default class IncompleteAnswersQueuePage extends Vue {
        private prescriptions:Array<Prescription> = [];
        private categoryFilter:DrugCategory = new DrugCategory();

        created(){
            this.load();
        }
        
        load(){
            //Get patient's prescriptions and format the data to be shown in the grid.
            Axios.get(`/Prescription/list-rx-incomplete-answers/${( this.categoryFilter?.id || '')}`)
                .then(response => {
                    console.log("DATA: ", response.data);
                    this.prescriptions = response.data;
            })
            .catch(error => {
                console.error("Error while loading Rxs with incomplete answers", {error, response: error?.response })
            });
        }
        
        search(){
            this.load();
        }
    }
        
