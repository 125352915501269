
import { Component, Vue } from 'vue-property-decorator';
import DispenseErrorListComponent from '@/components/DispensingError/DispenseErrorListComponent.vue';

@Component({
    name: "PendingToAcceptErrorsPage",
    components: {
        DispenseErrorListComponent,
    },
})
export default class PendingToAcceptErrorsPage extends Vue {

}
