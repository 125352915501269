
    import {Component, VModel, Vue} from "vue-property-decorator";

    @Component({
        name: "IngestionImage"
    })
    export default class IngestionImage extends Vue {
        @VModel() private data!: any;
        private visible = false;

        embedVisible(vis: boolean) {
            console.log("imgSrc: ", this.data.imgSrc);
            if (vis) this.visible = vis;
        }

    }
