
    import { Component, Mixins } from 'vue-property-decorator';
    import axios from 'axios';
    import { Lock, LockHandler } from '@/mixins/LockHandler';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets"

    import NewClearSaveButtons from './NewClearSaveButtons.vue';
    import NameSearchComponent from './NameSearchComponent.vue';
    import SearchComponent from '@/components/SearchComponent.vue';

    import { DrugCategory } from '@/models/DrugCategory';
    import { Questionary } from '@/models/Questionary';
    import { Drug } from '@/models/Drug/Drug.ts';
    import { Identifier } from '@/models/GSDD';
    import { NewClearSaveTitles } from "@/components/NewClearSaveButtons.vue";

    @Component({
        name: "CategoryComponent",
        components: { SearchComponent, NewClearSaveButtons, NameSearchComponent },
    })
    export default class CategoryComponent extends Mixins(LockHandler, DisableAutocompleteMixin) {
        private drug: Drug = new Drug();
        private questionary: Questionary = new Questionary();
        private questions: Array<Questionary.Question> = [];

        private category: DrugCategory = new DrugCategory();
        private categoryLock: Lock | null = null;

        private drugsInCategory: any[] = [];
        protected drugFields = [
            { key: 'productNameLong', label: 'Name', sortable: true },
            { key: 'productIdentifier', label: 'NDC', sortable: true, formatter: 'ndcFormat' },
        ];

        private createMode = false;
        private questionsWereLoaded = false;

        protected buttonTitles = {
            save: "Save Changes",
            new: "New Category",
            clear: "Clear",
        } as NewClearSaveTitles;

        get showForm():boolean {
            return !!(this.category?.id || this.createMode);
        }
        ndcFormat(value: Identifier[]) {
            const ndcIdentifier = value.find(p => p.identifierType == 0);
            return ndcIdentifier ? ndcIdentifier.identifier : 'Unknown';

        }
        private selected: any[] = [];
        onRowSelected(items: any[]) {
            this.selected = items;
        }

        removeSelectedDrugs() {
            for (const item of this.selected) {
                console.log("item: ", item);
                const identifiers = item.productIdentifier as Identifier[];
                const productIdent = identifiers.find(p => p.identifierType == 1);
                if (productIdent) this.removeDrug(productIdent.identifier, 0);
            }
        }

        removeDrug(productId: string, drugSource: number) {
            if (!this.category) return;
            axios.post(`/DrugCategory/${this.category.id}/Remove/${productId}/${drugSource}`)
            .then(_response => {
                this.fetchDrugs();
            })
            .catch(error => {
                console.error('Error while removing drug', {error, response:error?.response})
            })
        }

        fetchDrugs() {
            if (!this.category?.id) return;

            axios.get(`/DrugCategory/${this.category.id}/Drugs`)
            .then(response => {
                this.drugsInCategory = response.data;
                this.drugsInCategory.forEach(dr => {
                    dr.productIdentifier = dr.productIdentifier[1]?.identifier;
                })
            })
            .catch(error => {
                console.error('Error while getting associted drugs', {error, response:error?.response})
            });
        }

        fetchCustomQuestions(){
            if (!this.category?.id) return;

            axios.get<Questionary>(`/DrugCategory/${this.category.id}/custom-questions`)
            .then(response => {
                this.questionary = Object.assign(new Questionary(), response.data);
                this.questions = this.questionary.orderedQuestionsAsc;
                this.questionsWereLoaded = this.questions.length > 0;
            })
            .catch(error => {
                console.error('Error while getting custom questionary', {error, response:error?.response})
            });
        }

        fetchCategory(category: DrugCategory) {
            this.addLockWithURL(`/DrugCategory/${category.id}/lock`, 60000)
            .then(result => {
                this.categoryLock = result;
            })
            .catch(error => {
                if (error.response && error.response.status == 418) {
                    // already locked
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Category is locked by ${lockedBy} until ${expires}.`);
                }
            })
            .finally(() => {
                axios.get(`/DrugCategory/${category.id}`)
                .then(response => {
                    this.category = response.data;
                    this.fetchDrugs();
                    this.fetchCustomQuestions();
                })
                .catch(error => {
                    console.error('Error while getting the drug category', {error, response:error?.response})
                })
            });
        }

        createCategory() {
            this.createMode = true;
            this.category = new DrugCategory();
        }

        clearForm() {
            if (this.categoryLock && this.categoryLock.refreshURL) {
                this.releaseLockAtURL(this.categoryLock.refreshURL);
                this.categoryLock = null;
            }
            this.createMode = false;
            this.category = new DrugCategory();
            this.drugsInCategory = [];
            this.questionary = new Questionary();
            this.questions = [];
        }

        undoChanges() {
            this.clearForm();
        }

        saveQuestionary(){
            if(!this.questionsWereLoaded && this.questionary.amountOfQuestions == 0 || !this.category?.id) return;

            this.questionary.drugCategoryID = Number(this.category.id);
            this.questionary.prepareQuestions();
            axios.post(`/DrugCategory/custom-questions`, this.questionary)
            .then(response => {
                this.questionary = Object.assign(new Questionary(), response.data);
                this.questions = this.questionary.orderedQuestionsAsc;
                this.questionsWereLoaded = this.questions.length > 0;
                this.$notification(NotificationOptions.successSaveNotificationPreset("Custom Questions"));
            })
            .catch(error => {
                const errorMsg = 'Error while saving questions.';
                this.$notification(NotificationOptions.error(error));
                console.error(errorMsg, {error, response:error?.response})
            });
        }

        saveCategory() {
            axios.post(`/DrugCategory/`, this.category)
                .then(response => {
                    this.category = response.data;
                    for (let i = 0; i < this.drugsInCategory.length; i++) {
                        this.drug = this.drugsInCategory[i];
                        this.addDrug();
                    }
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Category"));
                    this.saveQuestionary();
                })
                .catch(error => {
                    this.$notification({
                        message: error.response?.statusText ?? "Unknown Error",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger"
                    });
                })
                .finally(() => {
                    this.createMode = false;
                    //this.clearForm();
                });
        }

        addDrug() {
            if (this.category?.id) {
                if(this.drug.productID) {
                    axios.post(`/DrugCategory/${this.category.id}/Add/${this.drug.productID}/${this.drug.source}`)
                    .then(_response => {
                        this.drug = new Drug();
                        this.fetchDrugs();
                    })
                    .catch(error => {
                        console.error('Error while adding drug', {error, response:error?.response})
                    })
                }
            }
            else {
                const newDrug:any = this.drug;
                newDrug.productNameLong = this.drug.productNameShort;
                newDrug.productIdentifier = this.drug.ndc;
                this.drugsInCategory.push(newDrug);
                this.drug = new Drug();
            }

        }

        removeQuestion(q: Questionary.Question){
            console.log('Remove Question', q);
            this.questions = this.questionary.removeQuestion(q);
        }
        addNewQuestion(){
            this.questions = this.questionary.pushNewQuestion(new Questionary.Question());
        }
    }

