
export interface ProductivityStatsBase {
    employee: string | null;
    isPharmacist: boolean;
    productivityReport: number
}

export class ProductivityStatsBase{
    constructor(){
        this.employee = null;
        this.isPharmacist = false;
        this.productivityReport = 0;
    }
}
