
import axios from 'axios';
import {Component, Watch, Mixins} from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import {LockHandler, Lock} from '@/mixins/LockHandler';
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import {Vendor} from '@/models/Vendor';

import NameSearchComponent from '@/components/NameSearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import AddressFormFields from '@/components/AddressFormFields.vue';

@Component({
    name: 'VendorDetailsPage',
    components: {NameSearchComponent, NewClearSaveButtons, AddressFormFields}
})
export default class VendorDetailsPage extends Mixins(LockHandler, DisableAutocompleteMixin) {
    private vendor: Vendor = new Vendor();
    private vendorLock: Lock | null = null;

    private buttonTitles = {
        save: "Save Changes",
        new: "New Vendor",
        clear: "Clear",
    };

    private newMode = false;
    private nameIntroduced = "";
    private displayName = "";

    get disableFields(): boolean {
        const lockedForUser = !this.vendorLock;
        const noEntityLoaded = !(this.vendor && this.vendor.id);

        // Disable fields if
        // ** its locked for the user
        // ** or if there is no doctor loaded and is not in New object Mode.
        return (lockedForUser || noEntityLoaded) && !this.newMode;
    }

    @Watch('vendor') onVendorChanged(value: Vendor, oldValue: Vendor) {
        if (!value) {
            this.displayName = "";
            return;
        }

        if (value && value.id && (!oldValue || oldValue.id != value.id)) {
            this.addLockWithURL(`/Vendor/${value.id}/lock`, 60000)
                .then(result => {
                    this.vendorLock = result;
                })
                .catch(error => {
                    if (error.response && error.response.status == 418) {
                        // vendor locked
                        console.log('Doctor is locked');
                        console.log(error.response);
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(`The Vendor is locked by ${lockedBy} until ${expires}.`);
                    }
                })
                .finally(() => {
                    axios.get(`/Vendor/${value.id}`)
                        .then(response => {
                            this.vendor = Object.assign(new Vendor(), response.data);
                        })
                        .catch(error => {
                            console.warn(error);
                        });
                });

        }

        if (value && value.name) {
            this.displayName = value.name;
        }
    }

    nameChanged(val: string) {
        this.nameIntroduced = val;
    }

    get isFormValid() {
        return !!(this.nameIntroduced);
    }

    personUpdate(value: any) {
        this.vendor = value;
        this.nameIntroduced = this.vendor.displayNamesForPerson();
    }

    undoChanges() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
            title: 'Confirm',
            okVariant: 'danger',
            centered: true,
        })
            .then(value => {
                if (!value) return;
                if (this.vendorLock && this.vendorLock.refreshURL) {
                    this.releaseLockAtURL(this.vendorLock.refreshURL);
                    this.vendorLock = null;
                }
                this.vendor = new Vendor();
                this.newMode = false;
                this.displayName = "";
                this.nameIntroduced = "";
            })
            .catch(err => {
                console.log("Error caught in undoChanges()");
                console.error(err);
            });
    }

    createVendor() {
        this.vendor = new Vendor();
        this.newMode = true;
    }

    saveVendor() {
        const vendor: Vendor = this.vendor;
        vendor.name = this.displayName;
        axios.post<Vendor>('/Vendor', vendor)
            .then(response => {
                this.vendor = new Vendor(response.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset('Vendor'));
            })
            .catch(error => {
                console.warn(error);
                this.$notification(NotificationOptions.errorSaveNotificationPreset('Vendor'));
                // probably do some smart error handling here at some point.
            })
    }

} // close export of class

