
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import { StripeReader } from "@/models/Payment";
import axios from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    components: { SearchComponent }
})
export default class CardReader extends Vue {
    @Prop({ default: 0, type: Number }) private orderId!: number;
    @Prop({ default: 0, type: Number }) private storeId!: number;
    private selectedReader: StripeReader = new StripeReader();
    private creatingPayment: boolean = false;
    private readerActive = false;
    mounted() {
        this.selectedReader = new StripeReader(this.lastReader) ?? new StripeReader();
    }

    get buttonText(){
        return this.readerActive ? "Refresh Payments" : "Swipe Card";
    }

    @Watch("selectedReader")
    lastReaderChanged(newVal: any, _oldVal: any) {
        if (newVal == new StripeReader()) return;
        localStorage.setItem("lastCardReader", JSON.stringify(newVal));
    }

    get canCreatePayment() {
        if (this.creatingPayment) return false;
        if (!this.selectedReader?.id) return false;
        console.log("this.selectedReader.id: ", this.selectedReader?.id);
        return true;
    }

    get lastReader(): any {
        const objReader = localStorage.getItem("lastCardReader");
        if (objReader) {
            return JSON.parse(objReader);
        }
        return null;
    }

    createCardPayment() {
        const url = `/api/stripe/Readers/${this.storeId}/${this.selectedReader?.id}/Payment/${this.orderId}`;
        this.creatingPayment = true;
        axios.post<StripeReader>(url)
            .then(resp => {
                console.log("resp: ", resp);
                this.readerActive = true;
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                this.$emit("swipe-error");
                console.log("err?.message: ", err?.message);
                console.log("err?.response?.data?.message: ", err?.response?.data?.message);
                if (err?.response?.data?.message == "The selected reader is not available to this location.") {
                    console.info("Resetting selectedReader");
                    this.selectedReader = new StripeReader();
                }
            })
            .finally(() => {
                this.creatingPayment = false;
            })

    }

    clearReader() {
        const url = `/api/stripe/Readers/${this.storeId}/${this.selectedReader?.id}/Clear`;
        axios.post<StripeReader>(url)
            .then(resp => {
                console.log("resp: ", resp);
                this.readerActive = false;
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                console.log("err?.message: ", err?.message);
                console.log("err?.response?.data?.message: ", err?.response?.data?.message);
                if (err?.response?.data?.message == "The selected reader is not available to this location.") {
                    console.info("Resetting selectedReader");
                    this.selectedReader = new StripeReader();
                }
            });
    }
}
