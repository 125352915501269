
import { Component, Mixins, Watch } from 'vue-property-decorator';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import SearchComponent from '@/components/SearchComponent.vue';
import InventoryHistory from "@/components/Inventory/InventoryHistory.vue";
import axios from 'axios';
import { ReportExporter } from '@/mixins/ReportExporter';
import { Store } from "@/models/Store";
import { Program } from "@/models/Program";
import BottomPagerBar from '@/components/BottomPagerBar.vue';

@Component({
    name: "InventoryReportPage",
    components: { Checkbox, SearchComponent, InventoryHistory, BottomPagerBar },
})
export default class InventoryReportPage extends Mixins(ReportExporter) {

    private store: Store | null = new Store();
    private program: Program | null = new Program();
    private deaSelection: boolean[] = [true, true, true, true, true, true];
    private sortBy: any = null;
    private reportItems: any[] = [];
    private pageNumber = 1;
    private pageSize = 100;
    private isLoading = false;

    created() {
        console.log("%c Created InventoryReportPage", "color: green;");
        this.loadHasInventoryLocations();
    }

    beforeDestroy() {
        console.log('%c Destroying InventoryReportPage', 'color: red');
    }

    get reportFields() {
        return [
            { label: "NDC", key: "ndc" },
            { label: "Drug Name", key: "drugName" },
            { label: "Quantity", key: "quantity" },
            { label: "Allocated", key: "allocated" },
            { label: "Unit of Measure", key: "billingUnit" },
            { label: "DEA Schedule", key: "deaClassId" },
            { label: "Store", key: "storeName" },
            { label: "Program", key: "programName" },
            { label: "Location", key: "location.name", hidden: !this.hasInventoryLocations }
        ].filter(f => f.hidden !== true);
    }

    get hasFilters() {
        return this.store?.id || this.program?.id;
    }

    @Watch('store')
    @Watch('program')
    @Watch('deaSelection')
    async getReport() {
        if (!this.hasFilters) {
            this.reportItems = [];
            return;
        }

        const config = {
            params: {
                storeId: this.store ? this.store.id : 0,
                programId: this.program ? this.program.id : 0,
                deaClasses: this.deaSelection.map(selection => selection ? 1 : 0).join()
            }
        };
        const reportPath = `/inventory/report`;

        try {
            this.isLoading = true;
            const response = await axios.get(reportPath, config);

            this.reportItems = response.data;
            this.sortBy = this.reportFields[0].key;

            const deaSelectionEmpty = !this.deaSelection.includes(true);
            if (deaSelectionEmpty) {
                for (let i = 0; i < 6; i++)
                    this.deaSelection[i] = true;
            }
        } catch (error) {
            console.warn(error);
        }

        this.isLoading = false;
    }

    async savePDF() {
        const currentPageSize = this.pageSize;
        this.pageSize = this.reportItems.length;
        await this.ExportPDF("Inventory Report", "#report-table");
        this.pageSize = currentPageSize;
    }

    saveCSV() {
        this.ExportCSV("Inventory Report", this.reportItems, this.reportFields);
    }

    clearClicked() {
        this.store = new Store();
        this.program = new Program();
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
            this.hasInventoryLocations = false;
        } catch {
            //ignore
        }
    }
}
