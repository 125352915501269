
import { Component, Vue } from 'vue-property-decorator';
import PBMComponent from "@/components/Audit/PBMComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";

@Component({
    name: "PharmacyBenefitManagerPage",
    components: { SearchComponent, PBMComponent },
})
export default class PharmacyBenefitManagerPage extends Vue {

    created() {

    }
}
