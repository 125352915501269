
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DispenseError } from '@/models/DispenseError';
    import { Note } from '@/models/Note';
    import Axios from 'axios';

    import NotesList from '@/components/Note/NotesList.vue'
    import NotesForm from '@/components/Note/NotesForm.vue'
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { EntityType } from "@/models/EntityType";

    @Component({
        name: "DispensingErrorAlertComponent",
        components: {
            NotesList, NotesForm
        }
    })
    export default class DispensingErrorAlertComponent extends Vue {
        @Prop() value!: DispenseError;
        @Prop({default: () => {return {accept: null, reject: null, resolve:null, awaitingResponse:null}}}) private buttonTitles!: any;
        @Prop({default: 'dispenseAlertConfirmation'}) private confirmationModalId!: string;

        private action: string = '';
        private typeOfErrors = DispenseError.DispenseErrorTypeEnum;
        private statusEnum = DispenseError.DispenseErrorStatusEnum;
        private notes:Array<Note> = [];
        private entityType = EntityType.DispenseError;

        get headerAlert():string {
            if(this.value.statusEnum == DispenseError.DispenseErrorStatusEnum.Accepted)
                return "The dispense error was already accepted."
            else if(this.value.statusEnum == DispenseError.DispenseErrorStatusEnum.Resolved)
                return "The dispense error was already resolved. Pending to be accepted."
            else
                return "A dispense error was reported for this prescription."
        }

        get saveButtonLabel():string{
            return `Save note and ${this.action}`;
        }

        get prefixNote():string{
            let res = '';
            if(this.action=='accept')
                res = "** ACCEPTED **";
            if(this.action=='reject')
                res = "** REJECTED **";
            return res;
        }

        noteSaved(){
            this.$emit("onNoteSaved");
            this.loadNotes();
        }
        postNote(action:string){
            this.action = action;
            const note = {} as Note;
            note.body = this.prefixNote;
            Axios.post(`/DispenseError/${this.value.id}/annotation`, note)
            .then(response => {
                this.actionOnDispenseError();
            })
            .catch(err => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset(`Dispense error note.`));
                console.error('Error while creating notation for the dispense error', {err, response: err.response})
            })
        }

        actionOnDispenseError(){
            Axios.post<DispenseError>(`DispenseError/${this.action}/${this.value.id}`)
            .then(response => {
                if(response.data?.id){
                    const actionVerb = this.action == 'resolve' ? 'resolved' :
                        this.action == 'awaitingResponse' ? 'Marked Awaiting Response' :
                            (this.action + 'ed');
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(`Dispense error was ${actionVerb}.`
                            , NotificationOptions.NotificationTypes.success
                    ))
                    this.$emit("onStatusChange", this.action, this.value.id);
                }
            })
            .catch(error => {
                console.error(`Error ${this.action}ing dispense error`, {error, response: error?.response});
            })
        }

        loadNotes(){
            if(this.$refs.notesList instanceof NotesList)
                this.$refs.notesList.loadNotes();
        }
    }

