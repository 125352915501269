
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
    import { CompoundDrug } from '@/models/Drug/CompoundDrug';
    import EnumSelector from '@/components/EnumSelector.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import { DrugStatus } from "@/models/Drug/Drug";

    @Component({
        name: "CompoundFormComponent",
        components: {
            EnumSelector,
            Checkbox
        }
    })
    export default class CompoundFormComponent extends Mixins(DisableAutocompleteMixin) {
        @Prop() private drug!: CompoundDrug;
        @Prop() private formSection!: string;
        private statuses = DrugStatus.DrugStatusEnum;


        get disableFields(): boolean {
            return false
        }

        created() {
            //console.log("%c Created NonGSDDFormComponent", "color: green");
        }

        continueClicked() {
            if (this.drug.instructions == null) this.drug.instructions = "";
            if (this.drug.ndc == null || this.drug.name == null || this.drug.unitOfMeasure == null ||
                this.drug.minimumDispenseQuantity == null ||
                this.drug.ndc.trim() == "" || this.drug.name.trim() == "" || this.drug.unitOfMeasure.trim() == "" ||
                this.drug.minimumDispenseQuantity < 0.0001) {
                this.$bvModal.msgBoxOk(`Required Fields: NDC, Name, Unit of Measure, Minimum Dispense Quantity - can not be left empty`);
                return
            }
            this.$emit('continue');
        }

        backToCompoundDashboard() {
            this.$emit("close");
            return;
        }
    }
