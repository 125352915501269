import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import AccessLogComponent from "src/components/AccessLogComponent.vue";
import AutorefillQueuePage from "src/pages/Prescription/AutorefillQueuePage";
import CategoryComponent from "src/components/CategoryComponent.vue";
import ConsultationsPage from "src/pages/Consultations/ConsultationsPage.vue";
import ConsignmentQueuePage from "src/pages/Consignments/ConsignmentQueuePage";
import DashboardConfigurationPage from "@/pages/Dashboard/Dashboard/DashboardConfigurationPage.vue";
import AdhocDashboard from "@/pages/Dashboard/Dashboard/AdHocDashboard.vue";
import DispensedPage from "src/pages/Reports/DispensedPage.vue";
import DoctorDashboard from "@/pages/Doctor/DoctorDashboard.vue";
import DoctorDetailsPage from "src/pages/Doctor/DoctorDetailsPage.vue";
import DoctorMergePage from "src/pages/Doctor/DoctorMergePage.vue";
import DoctorFollowUpQueue from "src/pages/Doctor/DoctorFollowUpQueue.vue";
import DrugComponent from "src/pages/Drug/DrugDetailsPage.vue";
import NonGSDDDrugDetailsPage from "src/pages/Drug/NonGSDDDrugDetailsPage.vue";
import CompoundDrugDetailsPage from "src/pages/Drug/CompoundDrugDetailsPage.vue";
import DrugDashboard from "@/pages/Drug/DrugDashboard.vue";
import ProgramDashboard from "@/pages/Program/ProgramDashboard.vue";
import HistoryComponent from "src/components/HistoryComponent.vue";
import InventoryReportPage from "src/pages/Reports/InventoryReportPage.vue";
import IngestionComponent from "src/components/IngestionComponent.vue";
import IncompleteAnswersQueuePage from "src/pages/Questionary/IncompleteAnswersQueuePage.vue";
import InventoryBatchQueuePage from "@/pages/Inventory/InventoryBatchQueuePage.vue";
import MergePatientsPage from "src/pages/Patient/MergePatientsPage.vue";
import MergePatientsQueuePage from "@/pages/Patient/PatientMergeQueuePage.vue";
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
import AcceptanceConfigurationMaintenancePage from "src/pages/Acceptance/AcceptanceConfigurationMaintenancePage.vue";
import PriceSchedulePage from "src/pages/PriceSchedule/PriceSchedulePage.vue";
import PatientDetailsPage from "src/pages/Patient/PatientDetailsPage.vue";
import PatientDashboardPage from "src/pages/Patient/PatientDashboardPage.vue";
import OnlineOrderQueue from "@/pages/PatientWeb/OnlineOrderQueue.vue";
import PartialPaymentQueue from "@/pages/PatientWeb/PartialPaymentQueue.vue";
import OrderDetailsPage from "src/pages/Order/OrderDetailsPage.vue";
import OTCItemDetailsPage from "src/pages/OTCItem/OTCItemDetailsPage.vue";
import OpenedCasesPage from "src/pages/Cases/OpenedCasesPage.vue";
import PatientInsuranceQueue from "src/pages/PatientInsuranceUploads/PatientInsuranceQueue";
import PatientWebAllergyUpdateQueue from "src/pages/PatientWeb/PatientWebAllergyUpdateQueue";
import PrintQueuePage from "src/pages/Order/PrintQueuePage.vue";
import OrderAuditConfigPage from "@/pages/Order/OrderAuditConfigPage.vue";
import OrderAuditQueuePage from "@/pages/Order/OrderAuditQueuePage.vue";
import OrderAuditDetails from "@/pages/Order/OrderAuditDetails.vue";
import VerifiedNotShippedQueue from "@/pages/Order/VerifiedNotShippedQueue.vue";
import PendingForBillingQueuePage from "src/pages/Prescription/PendingForBillingQueuePage.vue";
import PlanDetailsPage from "src/pages/Plan/PlanDetailsPage.vue";
import PharmacyBenefitManagerPage from "src/pages/PharmacyBenefitManager/PharmacyBenefitManagerPage.vue";
import ProviderWebPage from "src/pages/ProviderWeb/ProviderWebPage";
import FailedFaxComponent from "src/pages/Fax/FailedFaxComponent.vue";
import PrescriptionDetailsPage from "src/pages/Prescription/PrescriptionDetailsPage.vue";
import PrescriptionVerificationDetailsPage
    from "src/pages/PrescriptionVerification/PrescriptionVerificationDetailsPage.vue";
import RxAuditVerificationPage from "src/pages/PrescriptionVerification/RxAuditVerificationPage.vue";
import PriorAuthorizationAutosendConfigPage from "@/pages/PriorAuthorization/PriorAuthorizationAutosendConfigPage.vue";
import PV1QueuePage from "src/pages/PrescriptionVerification/PV1QueuePage.vue";
import ProgramDetailsPage from "src/pages/Program/ProgramDetailsPage.vue";
import PendingToAcceptErrorsPage from "src/pages/DispenseError/PendingToAcceptErrorsPage.vue";
import PriorAuthorizationListPage from "src/pages/PriorAuthorization/PriorAuthorizationListPage.vue";
import ElectronicPriorAuthorizationListPage
    from "src/pages/PriorAuthorization/ElectronicPriorAuthorizationListPage.vue";
import PrescriptionOnHoldQueue from "@/pages/Prescription/PrescriptionOnHoldQueue.vue";
import RefillRequestBatchPage from "src/pages/RefillRequestBatch/RefillRequestBatchPage.vue";
import PharmacyDetailPage from "@/pages/Pharmacy/PharmacyDetailPage.vue";
import PharmacistDetailPage from "@/pages/Pharmacist/PharmacistDetailPage.vue";
import ReportsComponent from "src/components/ReportsComponent.vue";
import ReportedErrorsPage from "src/pages/DispenseError/ReportedErrorsPage.vue";
import ResolvedCasesPage from "src/pages/Cases/ResolvedCasesPage.vue";
import ReversalQueuePage from "src/pages/Prescription/ReversalQueuePage.vue";
import RxTransferQueuePage from "src/pages/RxTransferQueue/RxTransferQueuePage";
import SigMaintenancePage from "src/pages/SigTranslation/SigMaintenancePage.vue";
import StoreDetailsPage from "src/pages/Store/StoreDetailsPage.vue";
import UserComponent from "src/components/User/UserComponent.vue";
import VendorDetailsPage from "src/pages/Vendor/VendorDetailsPage.vue";
import VerificationQueuePage from "src/pages/PrescriptionVerification/VerificationQueuePage.vue";
import PickupQueuePage from "src/pages/Order/PickupQueuePage.vue";
import Voicemails from "src/pages/Voicemail/VoicemailsPage.vue";
import PMPStatusPage from "src/pages/Prescription/PMPStatusPage.vue";
import DrugShortageReportPage from "src/pages/Reports/DrugShortageReportPage.vue";
import SurplusReportsPage from "src/pages/Surplus/SurplusReportsPage.vue";
import ConfigurationPage from "@/pages/Configuration/ConfigurationPage.vue";
import ReturnToSenderPage from "src/pages/Order/ReturnToSenderPage.vue";
import AuditTrackingPage from "src/pages/AuditTracking/AuditTrackingPage.vue";
import PatientAssistanceProgramQueue from "src/pages/PatientAssistanceProgram/PatientAssistanceProgramQueue.vue";
import TransferRxReportPage from "src/components/TransferReport.vue";
import PlaidPage from "src/pages/Plaid/PlaidPage.vue";
import Ordering from "@/pages/EDI/Ordering.vue";
import EDIOrderDetails from "@/pages/EDI/OrderDetails.vue";
import FlexEmbed from "@/pages/FlexEmbed.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import ActiveQueue from "@/pages/Prescription/ActiveQueuePage.vue";
import CompletedCasesPage from "src/pages/Cases/CompletedCasesPage.vue";
import PeriodicInventoriesPage from "@/pages/Inventory/PeriodicInventoriesPage.vue";
import PeriodicInventoryDetailsPage from "@/pages/Inventory/PeriodicInventoryDetailsPage.vue";
import CallLogPage from "@/pages/CallLog/CallLog.vue";
import NotificationListPage from "@/pages/Notifications/NotificationListPage.vue";
import InventoryPARLevelsPage from "@/pages/Inventory/InventoryParLevelsPage.vue";
import ExpiredOnOrderPage from "@/pages/Prescription/ExpiredOnOrderPage.vue";

const InventoryLocationsPage = () => import("@/pages/Inventory/InventoryLocationsPage.vue");
const LabelPrinters = () => import("@/pages/Printers/LabelPrintersPage.vue");

// Page Headers
import DefaultHeader from "src/pages/Dashboard/DefaultHeader";
import Content from "@/pages/Dashboard/Layout/Content.vue";

// Dashboard pages
const Dashboard = () =>
    import(
        /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
        );

const maintenanceMenu = {
    path: "/maintenance",
    component: DashboardLayout,
    redirect: "/maintenance/patient",
    name: "Maintenance",
    children: [
        {
            path: "price-schedule/:id",
            name: "PriceScheduleDetail",
            components: { default: PriceSchedulePage, header: DefaultHeader, },
            meta: { title: "Price Schedule", },
        },
        {
            path: "price-schedule",
            name: "PriceSchedule",
            components: { default: PriceSchedulePage, header: DefaultHeader, },
            meta: { title: "Price Schedule", },
        },
        {
            path: "acceptance-configuration/:id",
            name: "AcceptanceConfigurationDetails",
            components: { default: AcceptanceConfigurationMaintenancePage, header: DefaultHeader },
            meta: { title: "Acceptance Configuration" }
        },
        {
            path: "acceptance-configuration",
            name: "AcceptanceConfiguration",
            components: { default: AcceptanceConfigurationMaintenancePage, header: DefaultHeader },
            meta: { title: "Acceptance Configuration" }
        },
        {
            path: "patient/:id",
            name: "PatientDetails",
            components: { default: PatientDetailsPage, header: DefaultHeader },
            meta: { title: "Patient" }
        },
        {
            path: "patient",
            name: "Patient",
            components: { default: PatientDetailsPage, header: DefaultHeader },
            meta: { title: "Patient" }
        },
        {
            path: "doctor",
            name: "Doctor",
            components: { default: DoctorDetailsPage, header: DefaultHeader },
            meta: { title: "Doctor" }
        },
        {
            path: "merge-doctor",
            name: "Merge-Doctor",
            components: { default: DoctorMergePage, header: DefaultHeader },
            meta: { title: "Merge Doctor" }
        },
        {
            path: "vendor",
            name: "Vendor",
            components: { default: VendorDetailsPage, header: DefaultHeader },
            meta: { title: "Vendor" }
        },
        {
            path: "store",
            name: "Store-New",
            components: {default: StoreDetailsPage, header: DefaultHeader},
            meta: {title: "Store"}
        },
        {
            path: "store/:id",
            name: "Store",
            components: { default: StoreDetailsPage, header: DefaultHeader },
            meta: { title: "Store" }
        },
        {
            path: "drug/:id",
            name: "Drug",
            components: { default: DrugComponent, header: DefaultHeader },
            meta: { title: "Drug" }
        },
        {
            path: "drug",
            name: "Drug-New",
            components: { default: DrugComponent, header: DefaultHeader },
            meta: { title: "Drug" }
        },
        {
            path: "non-gsdd-drug",
            name: "Non-GSDD-Drug-New",
            components: {
                default: NonGSDDDrugDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Non GSDD Drug" }
        },
        {
            path: "non-gsdd-drug/:id",
            name: "Non-GSDD-Drug",
            components: {
                default: NonGSDDDrugDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Non GSDD Drug" }
        },
        {
            path: "compound-drug/:id",
            name: "Compound-Drug",
            components: {
                default: CompoundDrugDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Compound Drug" }
        },
        {
            path: "compound-drug",
            name: "Compound-Drug-New",
            components: {
                default: CompoundDrugDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Compound Drug" }
        },
        {
            path: "category",
            name: "Category",
            components: { default: CategoryComponent, header: DefaultHeader },
            meta: { title: "Drug Category" }
        },
        {
            path: "program/:id",
            name: "ProgramDetails",
            components: { default: ProgramDetailsPage, header: DefaultHeader },
            meta: { title: "Program" }
        },
        {
            path: "program",
            name: "Program",
            components: { default: ProgramDetailsPage, header: DefaultHeader },
            meta: { title: "Program" }
        },
        {
            path: "otc-Item",
            name: "OTCItem",
            components: { default: OTCItemDetailsPage, header: DefaultHeader },
            meta: { title: "OTC Item" }
        },
        {
            path: "consultation",
            name: "Consultation",
            components: { default: ConsultationsPage, header: DefaultHeader },
            meta: { title: "Consultations" }
        },
        {
            path: "plan",
            name: "Plan",
            components: { default: PlanDetailsPage, header: DefaultHeader },
            meta: { title: "Plan" }
        },
        {
            path: "pharmacyBenefitManager",
            name: "PharmacyBenefitManager",
            components: { default: PharmacyBenefitManagerPage, header: DefaultHeader },
            meta: { title: "Pharmacy Benefit Manager" }
        },
        {
            path: "users",
            name: "Users",
            components: { default: UserComponent, header: DefaultHeader },
            meta: { title: "Users" }
        },
        {
            path: "dashboard-configuration",
            name: "DashboardConfiguration",
            components: {
                default: DashboardConfigurationPage,
                header: DefaultHeader
            },
            meta: { title: "Dashboard Configuration" }
        },
        {
            path: "provider-web",
            name: "ProviderWeb",
            components: { default: ProviderWebPage, header: DefaultHeader },
            meta: { title: "Provider Web Management" }
        },
        {
            path: "pa-autosend-config",
            name: "PAAutosendConfiguration",
            components: {
                default: PriorAuthorizationAutosendConfigPage,
                header: DefaultHeader
            },
            meta: { title: "PA Autosend Configuration" }
        },
        {
          path: "faxes/failed",
          name: "FaxesFailed",
          components: { default: FailedFaxComponent, header: DefaultHeader },
          meta: { title: "Faxes - Failed" },
        },
        {
            path: "case/opened",
            name: "CasesOpened",
            components: { default: OpenedCasesPage, header: DefaultHeader },
            meta: { title: "Cases - Opened" }
        },
        {
            path: "case/resolved",
            name: "CasesResolved",
            components: { default: ResolvedCasesPage, header: DefaultHeader },
            meta: { title: "Cases - Resolved" }
        },
        {
            path: "case/completed",
            name: "CasesCompleted",
            components: { default: CompletedCasesPage, header: DefaultHeader },
            meta: { title: "Cases - Completed" }
        },
        {
            name: "SigMaintenance",
            path: "Sig-Maintenance",
            components: { default: SigMaintenancePage, header: DefaultHeader },
            meta: { title: "Sig" }
        },
        {
            name: "MergePatientsPage",
            path: "merge-patients/:sourcePatientId?/:targetPatientId?",
            components: { default: MergePatientsPage, header: DefaultHeader },
            meta: { title: "Merge Patients" },
            props: true
        },
        {
            name: "PatientMergeQueue",
            path: "merge-patients-queue",
            components: { default: MergePatientsQueuePage, header: DefaultHeader },
            meta: { title: "Merge Patients Queue"}
        },
        {
            name: "RefillRequestBatchPage",
            path: "refill-request-batch",
            components: {
                default: RefillRequestBatchPage,
                header: DefaultHeader
            },
            meta: { title: "Refill Request Batch" }
        },
        {
            name: "PharmacyDetail",
            path: "stored-pharmacy/:pharmacyId?",
            components: {
                default: PharmacyDetailPage,
                header: DefaultHeader
            },
            meta: { title: "Stored Pharmacies" },
            props: {
                default: true
            }
        },
        {
            name: "PharmacistDetail",
            path: "stored-pharmacist",
            components: {
                default: PharmacistDetailPage,
                header: DefaultHeader
            },
            meta: { title: "Stored Pharmacists" }
        },
        {
            name: "ConfigurationPage",
            path: "configuration",
            components: {
                default: ConfigurationPage,
                header: DefaultHeader
            },
            meta: { title: "Configuration" }
        },
        {
            name: "LabelPrinters",
            path: "label-printers",
            components: {
                default: LabelPrinters,
                header: DefaultHeader
            },
            meta: { title: "Label Printers" }
        },
        {
            name: "PeriodicInventories",
            path: "periodic-inventories",
            components: {
                default: PeriodicInventoriesPage,
                header: DefaultHeader
            },
            meta: { title: "Periodic Inventories" }
        },
        {
            name: "PeriodicInventory",
            path: "periodic-inventories/:id",
            components: {
                default: PeriodicInventoryDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Periodic Inventory"},
            props: {
                default: true
            }
        },
        {
            name: "InventoryPARLevels",
            path: "inventory-par",
            components: {
                default: InventoryPARLevelsPage,
                header: DefaultHeader
            },
            meta: { title: "Inventory PAR Levels"}
        },
        {
            name: "InventoryLocations",
            path: "inventory-locations",
            components: {
                default: InventoryLocationsPage,
                header: DefaultHeader
            },
            meta: { title: "Inventory Locations" }
        }
    ]
};

const reportsMenu = {
    path: "/reports",
    component: DashboardLayout,
    redirect: "/reports/reports",
    name: "ReportsCategory",
    children: [
        {
            path: "reports",
            name: "Reports",
            components: { default: ReportsComponent, header: DefaultHeader },
            meta: { title: "Reports" }
        },
        {
            path: "history",
            name: "History",
            components: { default: HistoryComponent, header: DefaultHeader },
            meta: { title: "History Report" }
        },
        {
            path: "access",
            name: "Access",
            components: { default: AccessLogComponent, header: DefaultHeader },
            meta: { title: "Access Report" }
        },
        {
            path: "inventory",
            name: "Inventory",
            components: { default: InventoryReportPage, header: DefaultHeader },
            meta: { title: "Inventory Report" }
        },
        {
            path: "dispensed",
            name: "Dispensed",
            components: { default: DispensedPage, header: DefaultHeader },
            meta: { title: "Dispensed Report" }
        },
        {
            path: "pmpstatus",
            name: "PMP Status",
            components: { default: PMPStatusPage, header: DefaultHeader },
            meta: { title: "PMP Status" }
        },
        {
            name: "DrugShortageReport",
            path: "drug-shortage-report",
            components: {
                default: DrugShortageReportPage,
                header: DefaultHeader
            },
            meta: { title: "Drug Shortage Report" },
        },
        {
            path: "surplus",
            name: "Surplus",
            components: { default: SurplusReportsPage, header: DefaultHeader },
            meta: { title: "Surplus Reports" }
        },
        {
            path: "transferredRx",
            name: "tramsferredrx-report",
            components: { default: TransferRxReportPage, header: DefaultHeader },
            meta: { title: "Transferred RX Reports" }
        },
        {
            path: "notifications",
            components: { default: NotificationListPage, header: DefaultHeader },
            name: "NotificationsList",
            meta: { title: "My Notifications"}
        },
    ]
};

const cphubMenu = {
    path: "/processing",
    component: DashboardLayout,
    redirect: "/processing/patient-dashboard",
    name: "CPHub",
    children: [
        {
            name: "PrescriptionDetails",
            path: "prescription/:storeID/:rxNumber/:rfNumber",
            components: {
                default: PrescriptionDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Details" }
        },
        {
            name: "PrescriptionDetailsId",
            path: "prescription/:rxid",
            components: {
                default: PrescriptionDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Details" }
        },
        {
            name: "Prescription",
            path: "prescription",
            components: {
                default: PrescriptionDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Details" }
        },
        {
            name: "OrderDetails",
            path: "order/:orderId",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Order Details" }
        },
        {
            name: "OrderPickup",
            path: "order-pickup/:orderId",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            params: { mode: "pickup" },
            meta: { title: "Order Pickup" }
        },
        {
            name: "OrderVerification",
            path: "order-verification/:orderId/",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            params: {
                mode: "verify"
            },
            meta: { title: "Order Verification" }
        },
        {
            name: "Order",
            path: "order-patient/:patientId/",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Order Details" }
        },
        {
            name: "Ingestion",
            path: "ingestion",
            components: { default: IngestionComponent, header: DefaultHeader },
            meta: { title: "Rx Intake" }
        },
        {
            name: "Audit",
            path: "audit/:id",
            components: {
                default: PrescriptionVerificationDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Audit Details" }
        },
        {
            name: "PV2",
            path: "pv2/prescription/:storeID/:rxNumber/:rfNumber",
            components: {
                default: PrescriptionVerificationDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Verification" }
        },
        {
            name: "PV1",
            path: "pv1/prescription/:storeID/:rxNumber/:rfNumber",
            components: {
                default: PrescriptionVerificationDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Verification" }
        },
        {
            name: "PrescriptionVerification",
            path: "prescription-verification/:pvNbr",
            components: {
                default: PrescriptionVerificationDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Verification" }
        },
        {
            name: "VerificationQueue",
            path: "verification-queue",
            components: {
                default: VerificationQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Verification Queue" }
        },
        {
            name: "PV1QueuePage",
            path: "pv1-queue",
            components: {
                default: PV1QueuePage,
                header: DefaultHeader
            },
            meta: { title: "PV1 Queue" }
        },
        {
            name: "OnHoldQueue",
            path: "on-hold-queue",
            components: {
                default: PrescriptionOnHoldQueue,
                header: DefaultHeader
            },
            meta: { title: "On Hold Queue" }
        },
        {
            name: "ActiveQueue",
            path: "active-script-queue",
            components: {
                default: ActiveQueue,
                header: DefaultHeader
            },
            meta: { title: "Active Prescription Queue" }
        },
        {
            name: "PrintQueue",
            path: "print-queue",
            components: {
                default: PrintQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Print Queue" }
        },
        {
            name: "StalePrintQueue",
            path: "stale-print-queue",
            components: {
                default: PrintQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Stale Print Queue" }
        },
        {
            name: "OrderAuditConfigs",
            path: "order-audit-configs",
            components: {
                default: OrderAuditConfigPage,
                header: DefaultHeader
            },
            meta: { title: "Order Audit Configs" }
        },
        {
            name: "OrderAuditQueue",
            path: "order-audit-queue",
            components: {
                default: OrderAuditQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Order Audit Queue" }
        },
        {
            name: "OrderAuditDetails",
            path: "order-audit/:id",
            components: {
                default: OrderAuditDetails,
                header: DefaultHeader
            },
            meta: { title: "Order Audit" }
        },
        {
            name: "PatientInsuranceQueue",
            path: "patient-insurance-queue",
            components: {
                default: PatientInsuranceQueue,
                header: DefaultHeader
            },
            meta: { title: "Insurance Queue" }
        },
        {
            name: "PatientWebAllergyUpdateQueue",
            path: "patient-web-allergy-update-queue",
            components: {
                default: PatientWebAllergyUpdateQueue,
                header: DefaultHeader
            },
            meta: { title: "Allergy Queue" }
        },
        {
            name: "OnlineOrderQueue",
            path: "online-order-queue",
            components: {
                default: OnlineOrderQueue,
                header: DefaultHeader
            },
            meta: { title: "Online Order Queue" }
        },
        {
            name: "PartialPaymentQueue",
            path: "partial-payment-queue",
            components: {
                default: PartialPaymentQueue,
                header: DefaultHeader
            },
            meta: { title: "Partial Payment Queue" }
        },
        {
            name: "ConsignmentQueue",
            path: "consignment-queue",
            components: {
                default: ConsignmentQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Consignment Queue" }
        },
        {
            name: "IncompleteAnswersQueue",
            path: "incomplete-answers-queue",
            components: {
                default: IncompleteAnswersQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Incomplete Answers Queue" }
        },
        {
            name: "VoicemailsNew",
            path: "voicemails-queue",
            components: {
                default: Voicemails,
                header: DefaultHeader
            },
            meta: { title: "Voicemails" }
        },
        {
            name: "Voicemails",
            path: "voicemails-queue/:id",
            components: {
                default: Voicemails,
                header: DefaultHeader
            },
            meta: { title: "Voicemails" }
        },
        {
            name: "InventoryBatchQueuePage",
            path: "inventory-batch-queue",
            components: {
                default: InventoryBatchQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Inventory Batch Queue" }
        },
        {
            name: "RxTransferQueue",
            path: "RxTransferQueue",
            components: {
                default: RxTransferQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Transfer Queue" }
        },
        {
            name: "PendingForBillingQueuePage",
            path: "pending-billing-prescriptions-queue",
            components: {
                default: PendingForBillingQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Billing Queue" }
        },
        {
            name: "AutorefillQueuePage",
            path: "autorefill-queue",
            components: {
                default: AutorefillQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Autorefill Queue" }
        },
        {
            name: "ReversalQueuePage",
            path: "reversal-queue",
            components: {
                default: ReversalQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Reversal Queue" }
        },
        {
            name: "ExpiredOnOrderPage",
            path: "expired-on-order",
            components: {
                default: ExpiredOnOrderPage,
                header: DefaultHeader
            },
            meta: { title: "Expired on Order" }
        },
        {
            name: "DoctorFollowUpQueue",
            path: "doctor-followup-queue",
            redirect: "doctor-followup"
        },
        {
            name: "DoctorFollowUp",
            path: "doctor-followup",
            components: {
                default: DoctorFollowUpQueue,
                header: DefaultHeader
            },
            meta: { title: "Doctor Follow-Ups" }
        },
        {
            name: "PatientDashboard",
            path: "patient-dashboard/:id",
            components: {
                default: PatientDashboardPage,
                header: DefaultHeader
            },
            meta: { title: "Patient Dashboard" }
        },
        {
            name: "PatientDashboardNew",
            path: "patient-dashboard",
            components: {
                default: PatientDashboardPage,
                header: DefaultHeader
            },
            meta: { title: "Patient Dashboard" }
        },
        {
            name: "PriorAuthorizationList",
            path: "prior-authorizations",
            components: {
                default: PriorAuthorizationListPage,
                header: DefaultHeader
            },
            meta: { title: "Prior Authorizations" }
        },
        {
            name: "ElectronicPriorAuthorization",
            path: "electronic-prior-authorizations",
            components: {
                default: ElectronicPriorAuthorizationListPage,
                header: DefaultHeader
            }
        },
        {
            name: "DispenseErrors",
            path: "dispense-errors",
            components: {
                default: ReportedErrorsPage,
                header: DefaultHeader
            },
            meta: { title: "Dispense Errors" }
        },
        {
            name: "PendingDispenseErrors",
            path: "pending-errors",
            components: {
                default: PendingToAcceptErrorsPage,
                header: DefaultHeader
            },
            meta: { title: "Dispense Errors - Pending" }
        },
        {
            name: "DoctorDashboard",
            path: "doctor-dashboard",
            components: {
                default: DoctorDashboard,
                header: DefaultHeader
            },
            meta: { title: "Doctor Dashboard" }
        },
        {
            name: "DrugDashboard",
            path: "drug-dashboard",
            components: {
                default: DrugDashboard,
                header: DefaultHeader
            },
            meta: { title: "Drug Dashboard" }
        },
        {
            name: "ProgramDashboard",
            path: "program-dashboard",
            components: {
                default: ProgramDashboard,
                header: DefaultHeader
            },
            meta: { title: "Program Dashboard" }
        },
        {
            name: "RxAudit",
            path: "rx-audit",
            components: {
                default: RxAuditVerificationPage,
                header: DefaultHeader
            },
            meta: { title: "Rx Audit Verification" }
        },
        {
            name: "PickupQueue",
            path: "pickup-queue",
            components: {
                default: PickupQueuePage,
                header: DefaultHeader
            },
            meta: { title: "Pickup Queue" }
        },
        {
            name: "VerifiedNotShippedQueue",
            path: "verified-not-shipped-queue",
            components: {
                default: VerifiedNotShippedQueue,
                header: DefaultHeader
            },
            meta: { title: "Verified Not Shipped" },
        },
        {
            name: "ReturnToSender",
            path: "return-to-sender",
            components: {
                default: ReturnToSenderPage,
                header: DefaultHeader
            },
            meta: { title: "Return To Sender" }
        },
        {
            name: "AuditTracking",
            path: "audit-tracking",
            components: {
                default: AuditTrackingPage,
                header: DefaultHeader
            },
            meta: { title: "Audit Tracking " }
        },
        {
            name: "PatientAssistanceProgramQueue",
            path: "pap-queue",
            components: {
                default: PatientAssistanceProgramQueue,
                header: DefaultHeader
            },
            meta: { title: "Patient Assistance Program Queue" }
        },
        {
            path: "plaid",
            name: "Plaid Page",
            components: { default: PlaidPage, header: DefaultHeader },
            meta: { title: "Plaid Testing" },
        },
        {
            name: "EDIOrdering",
            path: "edi-ordering",
            components: {
                default: Ordering,
                header: DefaultHeader
            },
            meta: { title: "EDI Ordering" }
        },
        {
            name: "EDIOrderDetails",
            path: "edi-ordering/:id",
            components: {
                default: EDIOrderDetails,
                header: DefaultHeader
            },
            meta: { title: "EDI Order Details" }
        },
        {
            path: "calllog",
            name: "CallLog",
            components: { default: CallLogPage, header: DefaultHeader }
        }
    ]
};

const flexMenu = {
    path: "/flex",
    component: Content,
    redirect: "/flex/embed",
    name: "Flex",
    children: [
        {
            name: "FlexEmbed",
            path: "embed/:phoneNumber",
            components: {
                default: FlexEmbed,
                header: DefaultHeader,
            },
            meta: { title: "Flex Embed" }
        },
        {
            name: "FlexEmbedEmpty",
            path: "embed",
            components: {
                default: FlexEmbed,
                header: DefaultHeader,
            },
            meta: { title: "Flex Embed" }
        },
        {
            name: "PatientDashboardFlex",
            path: "patient-dashboard/:id",
            components: {
                default: PatientDashboardPage,
                header: DefaultHeader
            },
            meta: { title: "Patient Dashboard" }
        },
    ]
};

const DashboardHeader = () =>
    import(
        /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
        );
const routes = [
    {
        path: "/",
        name: "Home",
        component: Login
    },
    cphubMenu,
    maintenanceMenu,
    reportsMenu,
    flexMenu,
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/dashboard",
        name: "DashboardCategory",
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                //components: { default: Dashboard, header: DefaultHeader }
                components: { default: Dashboard, header: DashboardHeader }
            }
        ]
    },
    {
        path: "/adhoc-reporting",
        component: DashboardLayout,
        name: "DashboardCategory",
        children: [
            {
                path: "",
                name: "AdhocDashboard",
                //components: { default: Dashboard, header: DefaultHeader }
                components: { default: AdhocDashboard, header: DefaultHeader }
            }
        ]
    },
    {
        path: "/login",
        redirect: "/"
    },

    { path: "*", component: NotFound }
];

export default routes;
