<template>
  <div class="w-100 vh-100 bg-dark d-flex align-items-center">
    <div class="col-md-4 ml-auto mr-auto">
      <img src="img/ScriptlyLogo.png" alt="">
      <n-button type="primary" size="lg" round block @click="login">
        Get Started
      </n-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {};
  },
  async mounted() {
    // if (this.$msal.isAuthenticated()) {
    //   await this.$router.push("/processing");
    // }
      await this.$router.push("/processing");

  },
  methods: {
    async login() {
      if (window !== window.parent) {
        this.$msal.loginPopup();
      } else {
        this.$msal.loginRedirect();
      }
    },
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
