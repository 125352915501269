
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
     import SearchComponent from '@/components/SearchComponent.vue';
     import axios, {AxiosError, AxiosRequestConfig} from 'axios';
     import {DocumentModel} from '@/models/Audit'
     import Vue2Dropzone from 'vue2-dropzone';
    import moment from "moment";
    import {NotificationOptions} from '@/util/NotificationOptionsPresets';
import { BvModalEvent } from 'bootstrap-vue';
     @Component({
        name: "AuditFaxComponent",
        components: {SearchComponent,
        "VueDropzone": Vue2Dropzone,}
    })
     export default class AuditFaxComponent extends Vue {
         @Prop({type: Number}) private audStatus!: number;
         @Prop({type: Boolean, default: false}) private isFromAppeal!: boolean;
         @Prop({type: String}) private rxString!: string;
         @Prop({type: String}) private auditNumber!: string;
         private fields = [
            {label:"", key:"isChecked"},
            {label: "File Name", key: "fileName", sortable: true},
            {label: "Uploaded Date", key: "createdDate", sortable: true, formatter: this.formattedDate},
            {label: "Action", key: "view"}
        ]
        private docuList:any [] = [] ;
        created(){
            this.loadDocu();
        }

         openDocument(pd: any) {
                    window.open(pd.url, '_blank');
         }

        loadDocu(){
            this.docuList = [];
            const config: AxiosRequestConfig = {params:{rxString: this.rxString, auditId: this.auditNumber}};
            console.log('AuditFaxComponent')
             axios.get<Array<any>>('AuditTracking/get-documents-per-rx', config)
              .then(res => {
                  res.data.forEach(epa => {
                      this.docuList.push({fileName: epa.fileName.split("/")[1], createdDate: epa.uploaded, url: epa.fileName})
                      });

                 })
                .catch(err => {
                    console.error("Error while saving Prescription.", {err, response: err.response});
                    this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
                })
        }

       get dropzoneOptions(): any {
           var rxString: string = this.rxString;
           var auditNumber : string  = this.auditNumber;
            return {
                url: `${axios.defaults.baseURL}/AuditTracking/upload-documents/${auditNumber}/${rxString}`,
                thumbnailWidth: 150,
                withCredentials: true,
                paramName: 'documents',
                uploadMultiple: true,
                acceptedFiles: 'application/pdf'
            };
        }
        dropzoneUploadSuccess(file: any, response: any){
            const dropzone: any = this.$refs.myVueDropzone;
            dropzone.removeFile(file);

            // close the modal.

            // reload prescription's image
            console.log("response: ", response);
            this.loadDocu();
        }

          dropzoneSending(file: any, xhr: XMLHttpRequest, _formData: FormData) {
            xhr.setRequestHeader("Authorization", axios.defaults.headers["Authorization"]);
        }
        closeUpload(){

        }

         formattedDate(value: string): string {
            const dateSplt = value.split(" ");
            return `${dateSplt[0]} ${dateSplt[1]} ${dateSplt[2]}`
        }
     }
