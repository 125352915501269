import HasLabel from "./HasLabel";

export interface InventoryLocation {
    id: number;
    name: string;
}

export class InventoryLocation extends HasLabel {

    constructor(obj?: InventoryLocation) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (this.name) return `${this.name}`;
        return '<div class="font-weight-light">Inventory Location</div>';
    }

}