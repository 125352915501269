import { Person } from "./Person";
import HasSearchHelp from "./HasSearchHelp";

export interface Prescriber extends Person {
    emailAddress: string;
    phoneNumber: string;
    altNumber: string;
    faxNumber: string;

    deaNumber: string;
    npiNumber: string;

    stateLicenseNumber: string;
    sureScriptsId: string;
    programId: number | null;

    allowsPa: boolean;
    reminderOptOut: boolean;
    alert: string;
    contacts: PrescriberContact[];
}

export class Prescriber extends Person implements HasSearchHelp {
    constructor(prescriber?: Prescriber) {
        super();
        this.allowsPa = true;
        if (prescriber) Object.assign(this, prescriber);
    }

    toString(): string {
        if(this.id && this.lastName) {
            return "ID: " + this.id + " " + this.lastName
                + (this.firstName ? ", " + this.firstName : "")
                + (this.fullAddress ? ", " + this.fullAddress : "");
        }
        if (this.lastName && this.firstName) {
            return this.lastName
                + (this.firstName ? ", " + this.firstName : "")
                + (this.fullAddress ? ", " + this.fullAddress : "");
        }
        return '<div class="font-weight-light">NPI/Name/PhoneNumber/FaxNumber</div>';
    }

    getSearchHelpHtml() : string {
        return "<p>Search for prescribers using any of the following formats:</p>" +
        "<ul>" +
        "<li>Phone Number: \"+1XXX...\"</li>" +
        "<li>Prescriber Name: \"LastName, FirstName\"</li>" +
        "<li>NPI: \"123456789\"</li>" +
        "</ul>";
    } 

}

export interface PrescriberContact {
    id: number;
    lastName: string;
    firstName: string;
    title: string;
    phoneNumber: string;
    note: string;
} 

export class PrescriberContact {
    
}