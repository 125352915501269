import { Drug } from "@/models/Drug/Drug";
import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { DrugSource } from "./Drug/DrugProduct";
import { InventoryLocation } from "./InventoryLocation";

export interface InventoryBatch extends HasID, TrackedObject{
    storeID : number
    vendorID : number
    programID : number
    purchaseOrderNumber : string | null

    vendorName : string | null
    storeName : string | null
    programName : string | null
    linesQty : number
    isNDCProblem : boolean

    items : Array<InventoryBatch.InventoryBatchItem>

    submitted : Date | null
    submittedBy : string | null
    canceled : Date | null
    canceledBy : string | null

    isReturn: boolean;
    location: InventoryLocation;
    senderName: string
}

export class InventoryBatch {
    constructor(obj? : InventoryBatch)
    {
        this.storeID = 0;
        this.vendorID = 0;
        this.vendorName = null;
        this.storeName = null;
        this.purchaseOrderNumber = null;
        this.isNDCProblem = false;
        this.items = new Array<InventoryBatch.InventoryBatchItem>();
        this.isReturn = false;

        if(obj){
            Object.assign(this, obj);
            this.items = obj.items?.map(i => Object.assign(new InventoryBatch.InventoryBatchItem(), i))
                || new Array<InventoryBatch.InventoryBatchItem>();
        }

        if (!this.location) {
            this.location = new InventoryLocation();
        }
    }
}

export namespace InventoryBatch{
    export interface InventoryBatchItem extends HasID, TrackedObject{
        drugSource : DrugSource
        packageID : number | null
        shippedQuantity : number
        receivedQuantity : number
        inventoryBatchId : number
        ediDrugDesc : string

        packageSize : number
        ndc : string | null
        productNameShort : string | null
        manufacturerName : string | null
        location: InventoryLocation
        lots: Array<InventoryBatchItemLot>
    }

    export class InventoryBatchItem {
        constructor(drug?:Drug)
        {
            this.packageID = 0;
            this.receivedQuantity = 0;
            this.shippedQuantity = 0;
            this.ndc = null;
            this.productNameShort = null;
            this.lots = [];

            if (drug)
            {
                this.drugSource = drug.source;
                this.packageID = drug.drugId ? Number(drug.drugId) : null
                this.productNameShort = drug.productNameShort;
                this.packageSize = drug.packageSize;
                this.ndc = drug.ndc?.replace(/-/g,"");
            }

            if (!this.location) {
                this.location = new InventoryLocation();
            }
        }

        get totalShippedUnits():number | null {
            return this.packageID ? this.shippedQuantity * this.packageSize : null;
        }

        get totalReceivedUnits():number | null {
            return this.packageID ? this.receivedQuantity * this.packageSize : null;
        }

        get drugName():string|null {
            return this.packageID ? this.productNameShort : this.ediDrugDesc;
        }
    }

    export interface InventoryBatchItemLot {
        lot: string;
        expiry: string;
        quantity: number;
        serialNumbers: string[];
    }
}
