

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Note } from '@/models/Note';
import moment from "moment";

@Component({
    name: "NotesListItem",
    components: {}
})
export default class NotesListItem extends Vue {

    @Prop() private note!: Note;
    @Prop() private verboseByline!: boolean;

    get isChild() {
        return !!this.note.parentNoteId;
    }

    formatName(name: string) {
        if (name.includes("/")) return name.split("/")[1];
        if (name.includes("\\")) return name.split("\\")[1];
        return name.split("@")[0];
    }

    formatDate(date: Date) {
        return moment.utc(date).local().format("LLL");
    }

    relativeDate(date: Date) {
        return moment.utc(date).fromNow();
    }

}

