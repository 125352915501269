
    import Vue from 'vue'
    import {Component, Watch} from 'vue-property-decorator';
    import Axios, {AxiosResponse} from 'axios';
    import moment from 'moment';
    import SearchComponent from '@/components/SearchComponent.vue';
    import PatientAllergyComponent from '@/components/GSDDAllergies/GSDDAllergyComponent.vue'
    import {GSDDPatientAllergy} from "@/models/GSDD";
    import {PatientAllergyUpdate} from '../../models/PatientAllergyUpdate';
    import {Patient} from '../../models/Patient';

    @Component({
        name: "PatientWebAllergyUpdateQueue",
        components: {
            SearchComponent,
            PatientAllergyComponent,
        },
    })

    export default class PatientWebAllergyUpdateQueue extends Vue {

        private unprocessedPatientAllergyUpdates: PatientAllergyUpdate[] = []
        private patientsAllergies: GSDDPatientAllergy[][] = [];
        private patients: Patient[] = [];
        private currentPatientId: number | string = "";
        private updateAllergy = false;

        created() {
            this.getUnprocessedPatientAllergyUpdates()
        }

        @Watch("unprocessedPatientAllergyUpdates")
        resetAllergies(){
            this.patientsAllergies = [];
        }

        getUnprocessedPatientAllergyUpdates() {

            Axios.get(`/PatientAllergyUpdate/GetUnprocessed`)
                .then((response:any) => {
                    this.unprocessedPatientAllergyUpdates = response.data;
                })
                .catch((error:any) => {
                    console.warn(error);
                });
        }

        toggleInfo(allergy: PatientAllergyUpdate) {
            this.$root.$emit('bv::toggle::collapse', 'panel-' + allergy.id)
        }


        processAllergy(allergyList: GSDDPatientAllergy[], patWebAllergyId: number) {
            if (allergyList.length) {

                Axios.post(`/Allergy/SaveGSDDAllergies/${allergyList[0].patientId}`, allergyList)
                    .then((response:any) => {
                        Axios.post(`PatientAllergyUpdate/ProcessPatientAllergy/` + patWebAllergyId).then(() =>
                            this.getUnprocessedPatientAllergyUpdates())
                    })
            } else {
                Axios.post(`PatientAllergyUpdate/ProcessPatientAllergy/${patWebAllergyId}`)
                    .then(() => this.getUnprocessedPatientAllergyUpdates())
            }
        }

        getAge(date: Date) {
            return moment.utc(date).local().fromNow()
        }
    }
