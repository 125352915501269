
    import { Component, Vue } from 'vue-property-decorator';
    import { Drug, DrugStatus } from '@/models/Drug/Drug';
    import { NonGSDDDrug } from '@/models/Drug/NonGSDDDrug';
    import { PackagePrice } from '@/models/GSDD';
    import SearchComponent from '@/components/SearchComponent.vue';
    import { DrugSource } from '@/models/Drug/DrugProduct';
    import Axios from "axios";

    @Component({
        name: "IngredientSelectModalComponent",
        components: {
            SearchComponent
        }
    })

    export default class IngredientSelectModalComponent extends Vue {
        private drugSelected: boolean = false;
        private isLoading = false;
        private drugSearch: Drug | null = new Drug();
        private gsddDrug: Drug | null = new Drug();
        private gsddDrugPackageId: number = 0;
        private nonGSDDDrug: NonGSDDDrug | null = new NonGSDDDrug;
        // private gsddMatchDisplay: Array<any> = []; //this.gsddDrugMatch.forEach(function(element) {return  element.toString()});
        private drugSourceSelected: string | null = null
        private drugIdSelected: string | null = null;
        private gsddStatus: number | null = null;
        private nonGSDDStatus: string | null = null; //DrugStatus.DrugStatusEnum[this.gsddDrug.status.status];
        private nonGSDDTitle: string = "NonGSDD Drug";
        private gsddTitle: string = "GSDD Drug";
        private ingredientQuantity: number = 0;
        private ingredientUom: string = "";

        get awp(): any {
            if (!this.nonGSDDDrug || !this.nonGSDDDrug.awp) return ``;
            return `${this.nonGSDDDrug.awp}`;
        }

        get packageSize(): any {
            if (!this.nonGSDDDrug || !this.nonGSDDDrug.packageSize) return ``;
            return `${this.nonGSDDDrug.packageSize}`;
        }

        get unitOfMeasure(): any {
            if (!this.nonGSDDDrug) return ``;
            return this.nonGSDDDrug.unitOfMeasure;
        }

        get minimumDispenseQuantity(): any {
            if (!this.nonGSDDDrug || !this.nonGSDDDrug.minimumDispenseQuantity) return ``;
            return `${this.nonGSDDDrug.minimumDispenseQuantity}`;
        }

        get deaClass(): any {
            if (!this.nonGSDDDrug) return ``;
            return this.nonGSDDDrug.deaClass;
        }

        get disableFields(): boolean {
            return false
        }

        get marketer(): any {
            if (!this.gsddDrug) return ``;
            return this.gsddDrug.marketer;
        }

        get packageDescription(): any {
            if (!this.gsddDrug) return ``;
            return this.gsddDrug.packageDescription;
        }

        get doseForm(): any {
            if (!this.gsddDrug) return ``;
            return this.gsddDrug.doseForm;
        }

        get gsddDeaClass(): any {
            if (!this.gsddDrug) return ``;
            return this.gsddDrug.deaClass;
        }

        get gsddPackageSize(): any {
            if (!this.gsddDrug || !this.gsddDrug.packageSize || !this.gsddDrug.billingUnit) return ``;
            return `${this.gsddDrug.packageSize} ${this.gsddDrug.billingUnit}`;
        }

        // from Price
        get drugPrice(): any {
            if (!this.gsddDrug || !this.gsddDrug.prices) return ``;
            const filteredPrices = this.gsddDrug.prices as PackagePrice[];

            // 1  -- Average Wholesale Price
            // 13 -- Calculated Average Wholesale Price
            // 2  -- Wholesale Acquisition Cost
            const awp = filteredPrices.find(p => p.priceTypeID == 1 || p.priceTypeID == 13);
            const wac = filteredPrices.find(p => p.priceTypeID == 2);

            const awpString = awp && awp.unitPrice ? `AWP: $${awp.unitPrice}/${awp.ncpdpBillingUnit}` : ``;
            const wacString = wac && wac.unitPrice ? `WAC: $${wac.unitPrice}/${wac.ncpdpBillingUnit}` : ``;

            return `${awpString} ${wacString}`;
        }

        created() {
        }

        clearClicked() {
            this.drugSelected = false;
            this.drugSearch = new Drug();
            this.gsddDrug = new Drug();
            this.nonGSDDDrug = new NonGSDDDrug();
            this.gsddDrugPackageId = -1;
            this.drugSourceSelected = null;
            this.drugIdSelected = null;
            this.ingredientQuantity = 0;
        }

        onDrugSearchSelected() {
            this.drugSourceSelected = this.drugSearch!.source.toString();
            this.drugIdSelected = this.drugSearch!.drugId.toString();
            if (this.drugSourceSelected == "2") {
                this.$bvModal.msgBoxOk(`Compound drug selection is invalid`);
                return;
            }
            if (this.drugSearch?.source == DrugSource.GsddFederal) {
                this.onGSDDDrugSelected();
            }
            if (this.drugSearch?.source == DrugSource.NonGsddProduct) {
                this.onNonGSDDDrugSelected();
            }
        }

        onCancelClicked() {
            this.$emit('cancel');
        }

        onGSDDDrugSelected() {
            this.drugSelected = true;
            const PackageID = this.drugSearch?.drugId;
            const packageId = Number(PackageID || 0);
            this.gsddDrug = new Drug();
            this.gsddDrugPackageId = packageId;
            this.nonGSDDDrug = new NonGSDDDrug();
            this.fetchGSDDDrug(packageId);
        }

        onNonGSDDDrugSelected() {
            this.drugSelected = true;
            const ProductID = this.drugSearch?.drugId;
            const productId = Number(ProductID || 0);
            this.gsddDrug = new Drug();
            this.gsddDrugPackageId = 0;
            this.nonGSDDDrug = new NonGSDDDrug();
            this.fetchNonGSDDDrug(productId);
        }

        fetchGSDDDrug(packageID: number) {
            if (!packageID) return;

            Axios.get<Drug>(`/Drug/${packageID}`, { params: { getCategories: true, getVendors: true } })
                .then(response => {
                    const drug = response.data;
                    this.gsddDrug = new Drug(null, drug);
                    this.gsddStatus = drug.activeStatus;
                })
                .catch(error => {
                    console.error('Problem while getting drug details.', { error, response: error?.response });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        fetchNonGSDDDrug(productID: number) {
            if (!productID) return;

            Axios.get<NonGSDDDrug>(`/NonGSDD/${productID}`)
                .then(response => {
                    const drug = response.data;
                    this.nonGSDDDrug = new NonGSDDDrug(null, drug);
                    this.nonGSDDStatus = DrugStatus.DrugStatusEnum[drug.activeStatus];
                })
                .catch(error => {
                    console.error('Problem while getting NonGSDD drug details.', { error, response: error?.response });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        onSaveClicked() {
            if (this.ingredientQuantity < 0.0001) {
                this.$bvModal.msgBoxOk('Quantity must be specified.');
                return;
            } else if (this.ingredientUom == null || this.ingredientUom == "") {
                this.$bvModal.msgBoxOk('Unit of Measure must be specified.');
                return;
            } else if (this.ingredientUom.length > 5 || this.ingredientUom.indexOf("_") > -1) {
                this.$bvModal.msgBoxOk('Unit of Measure can not exceed maximum of 5 characters or have any special characters.');
                return;
            }
            this.$emit('select', this.drugSourceSelected + "_" + this.drugIdSelected + "_" + this.ingredientQuantity.toString() + "_" + this.ingredientUom);
        }

    }
