
import { Component, Watch, Mixins, Prop, Vue } from 'vue-property-decorator'
import { DatePicker } from "element-ui";
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { NotificationOptions } from "@/util/NotificationOptionsPresets"
import { LockHandler, Lock } from '@/mixins/LockHandler';
import { DeliveryCode } from '@/models/DeliveryCode';
import { Program } from '@/models/Program';
import { Patient, PatientProgram, Language } from '@/models/Patient';
import NameSearchComponent from '@/components/NameSearchComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import AddressFormFields from '@/components/AddressFormFields.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import EligibilityComponent from "@/components/Insurance/EligibilityComponent.vue";
import { GSDDPatientAllergy } from "@/models/GSDD";

@Component({
    name: "GSDDAllergyComponent",
    components: {
        NameSearchComponent,
        SearchComponent,
        NewClearSaveButtons,
        AddressFormFields,
        Checkbox,
        [DatePicker.name]: DatePicker,
        EligibilityComponent
    }
})
export default class GSDDAllergyComponent extends Vue {
    @Prop({ default: null }) private patient!: Patient;
    @Prop({ default: false }) private disable!: boolean
    @Prop({ default: () => [] }) private value!: GSDDPatientAllergy[];

    //private patientAllergies: GSDDPatientAllergy[] = []
    private selectedAllergy: GSDDPatientAllergy = new GSDDPatientAllergy();

    get patientAllergies(): GSDDPatientAllergy[] {
        return this.value;
    }

    set patientAllergies(val: GSDDPatientAllergy[]) {
        this.$emit('input', val);
    }

    get disableNkda(): boolean {
        return this.patientAllergies.length > 0;
    }

    created() {
        this.getPatientGSDDAllergies();
    }

    getPatientGSDDAllergies() {
        if (!this.patient?.id) return;

        axios.get(`/Allergy/GetGSDDAllergiesForPatient/${this.patient.id}`)
            .then(response => {
                this.patientAllergies = response.data;
            }).catch(error => {
                console.error("Error while loading allergies", { error, response: error?.response });
            });
    }

    @Watch('patient.id') onPatientChanged(value: Patient | null, oldValue: Patient | null) {
        this.clearComponent();
        this.getPatientGSDDAllergies();
    }

    nkdaChanged(val: boolean) {
        if (val) {
            this.patientAllergies = [];
        }
    }

    addAllergy() {
        if (!this.selectedAllergy?.allergyName) {
            this.$notification(NotificationOptions.error("Please select an Allergy."));
            return;
        }

        if (this.patientAllergies.includes(this.selectedAllergy)) {
            this.$notification(NotificationOptions.error("This Allergy has already been added."));
            return;
        }

        this.selectedAllergy.patientId = this.patient.id as number;
        let allergies = this.patientAllergies;
        allergies.push(this.selectedAllergy);
        this.patientAllergies = allergies;
        this.patient.nkda = false;
    }

    RemoveAllergy(allergy: any) {
        this.patientAllergies = this.patientAllergies.filter(item => item.allergyId != allergy.allergyId);
    }

    clearComponent() {
        this.patientAllergies = new Array<GSDDPatientAllergy>();
        this.selectedAllergy = new GSDDPatientAllergy();
    }

}
