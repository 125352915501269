
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DispenseError } from '@/models/DispenseError';
import EnumSelector from '@/components/EnumSelector.vue';
import NotesList from '@/components/Note/NotesList.vue'
import NotesForm from '@/components/Note/NotesForm.vue'
import { Note } from '@/models/Note';
import { EntityType } from "@/models/EntityType";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    name: "DispensingErrorModalComponent",
    components: {
        EnumSelector, NotesList, NotesForm
    }
})
export default class DispensingErrorModalComponent extends Vue {
    @Prop() private value!: DispenseError;

    @Prop({ default: "dispense-error-modal" }) private modalId!: string
    @Prop({ default: true }) private showButtons!: boolean
    @Prop({ default: false }) private reviewMode!: boolean
    @Prop({ default: "Report Error" }) private saveButtonLabel!: string

    private typeOfErrors = DispenseError.DispenseErrorTypeEnum;
    private newNote = { body: '' } as Note;
    private typeOfErrorsCustomDisplayNames = DispenseError.ERROR_TYPE_DISPLAY_NAMES;
    private entityType = EntityType.DispenseError;

    save() {
        this.$emit("onClickSave", this.value);
    }
    accept() {
        this.$emit("onClickAccept", this.value);
    }
    reject() {
        this.$emit("onClickReject", this.value);
    }

    show() {
        this.$emit("errorModalOpened");
    }

    hide() {
        this.$emit("errorModalClosed");
    }

    closeModal() {
        this.newNote.body = '';
    }

    onSavingNote() {
        this.$emit('savedNote', this.value.id)
        this.$bvModal.hide(this.modalId);
    }

    get PV(): string | undefined {
        return this.value.pv?.toUpperCase();
    }

    get validForm() {
        return !!((this.value?.errorType || this.value?.errorType == 0));
    }

    get disableTypesField() {
        if (!this.reviewMode) return false;
        if (this.$user.isPharmacist || this.$user.isAdmin) return false;
        return true;
    }

    private isHandling = false;

    @Watch("value")
    updateValue() {
        this.isHandling = true;
        this.$nextTick(() => {
            this.isHandling = false;
        })
    }

    @Watch("value.errorType")
    async updateErrorType() {
        if (this.isHandling || !this.value?.id) return;

        try {
            const response = await this.$http.post<DispenseError>(`DispenseError/error-type/${this.value.id}/${this.value.errorType}`);

            this.value.errorType = response.data.errorType;
            this.value.notes = response.data.notes;

            this.$notification(NotificationOptions.successSaveNotificationPreset("Dispense Error"));

            this.$emit("changed-type");
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }  
    }
}

