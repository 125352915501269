
    import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
    import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
    import Axios, { AxiosRequestConfig } from "axios";
    import { ExternalPatientOrder, ExternalPatientOrderSource } from "@/models/PatientWebOrder";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";

    @Component({
        name: "OnlineOrderDetailsModal"

    })
    export default class OnlineOrderDetailsModal extends Vue {
        @VModel() private onlineOrder?: ExternalPatientOrder;
        @Prop({ default: 'online-order-details-modal' }) private modalId!: string;

        private fields: BvTableFieldArray = [
            { key: "rxNbr", label: "Rx #", },
            { key: "rfNbr", label: "Rf #", },
            { key: "drugName", },
            { key: "doctorName", label: "Prescriber" },
            { key: "amount", label: "Copay", formatter: this.currencyFormatter },
        ];
        private addOnsFields: BvTableFieldArray = [
            { key: "productName", label: "Product", },
            { key: "productAmount", label: "Price", formatter: this.currencyFormatter },
            { key: "taxAmount", label: "Tax", formatter: this.currencyFormatter },
        ];

        private card: { brand: string, last4: string, total: string } = { brand: '', last4: '', total: '' };


        get orderSource(): string {
            const source = this.onlineOrder?.source ?? ExternalPatientOrderSource.NotSpecified;
            return ExternalPatientOrderSource[source];
        }

        get attestationLanguage(): string {
            return this.onlineOrder?.attestationLanguage ??
                `By signing electronically below, I, ${this.onlineOrder?.patientName ?? ''}, authorize Carepoint Pharmacy to process this prescription order and coordinate with my medical provider and any applicable insurance and I attest that I request this medication.`;
        }

        get ctxFilter() {
            return {
                id: this.onlineOrder?.id,
                source: ExternalPatientOrderSource[this.onlineOrder?.source ?? 0],
            };
        }

        get patientName() {
            return this.onlineOrder?.patientName;
        }

        get signatureHtml() {
            let sigData = this.onlineOrder?.signatureData;
            if (sigData != null && sigData.startsWith("<")) {
                return sigData;
            }
            return `<img src="${sigData}" alt="Signature"/>`;
        }

        @Watch('onlineOrder.transactionId')
        transactionIdChange(newValue: string) {
            if (!newValue) return;

            let config: AxiosRequestConfig = {
                params: {
                    storeId: this.onlineOrder?.storeId
                }
            };

            Axios.get(`/Stripe/Charge/${newValue}`, config)
                .then(response => {
                    this.card = {
                        brand: response.data.paymentMethodDetails.card.brand,
                        last4: response.data.paymentMethodDetails.card.last4,
                        total: `$${(response.data.amount / 100).toFixed(2)}`
                    };
                })
                .catch(_ => {
                    this.card = { brand: '', last4: '', total: '' };
                });
        }

        async printModal() {
            await this.$htmlToPaper('printArea');
        }

        currencyFormatter(a: Number) {
            return `$${a?.toFixed(2)}`;
        }

        ctxProvider(ctx: BvTableCtxObject, callback: Function) {
            if (ctx.apiUrl == null) return null;

            const uri = ctx.apiUrl;
            const config: AxiosRequestConfig = {
                params: ctx.filter
            };
            Axios.get(uri, config)
                .then(resp => {
                    callback(resp.data);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    callback([]);
                });
            return null;
        }

    }
