import HasID from "@/models/HasID";

export interface OrderAuthorization extends HasID {
    id: number;
    orderId: number;
    attestationLanguage: string;
    signatureData: string;
}

export class OrderAuthorization {
    constructor(obj?: OrderAuthorization) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
