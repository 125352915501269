
import { Component, Vue } from "vue-property-decorator";
import { PeriodicInventory } from "@/models/PeriodicInventory";
import { Store } from "@/models/Store";
import moment from "moment";
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import axios from 'axios';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { PharmacistRecord } from "@/models/Pharmacist";

@Component({
    name: "PeriodicInventoriesPage",
    components: {
        NewClearSaveButtons
    }
})
export default class PeriodicInventoriesPage extends Vue {
    private showClosed = false;

    private items: Array<PeriodicInventory> = [];

    mounted() {
        this.loadHasInventoryLocations();
        this.loadItems();
    }

    get fields() {
        return [
            { key: "id" },
            { key: "store", formatter: (value: Store) => value.name },
            { key: "isComplete", label: "Complete Inventory" },
            { key: "startDate", formatter: (value: Date) => this.dateTimeFormatter(value) },
            { key: "supervisingPharmacist" },
            { key: "location.name", label: "Inventory Location", hidden: !this.hasInventoryLocations }
        ].filter(i => i.hidden !== true)
    }

    get selectViewOptions() {
        return [
            { text: `Open (${this.openCount})`, value: false },
            { text: `Closed (${this.closedCount})`, value: true }
        ]
    }

    get filteredItems() {
        return this.items.filter(i => i.active == !this.showClosed)
            .sort((a: PeriodicInventory, b: PeriodicInventory) => parseInt(b.id.toString()) - parseInt(a.id.toString()));
    }

    get openCount() {
        return this.items.filter(i => i.active).length;
    }

    get closedCount() {
        return this.items.filter(i => !i.active).length;
    }

    dateTimeFormatter(d: any) {
        return moment.utc(d).local().format("LL");
    }

    createNew() {
        this.$router.push("/maintenance/periodic-inventories/0");
    }

    async loadItems() {
        try {
            const response = await axios.get(`/PeriodicInventories`);
            this.items = response.data;
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }
    }

    onRowSelected(data: any) {
        this.$router.push(`/maintenance/periodic-inventories/${data[0].id}`);
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }
}
