import { HasDropDownOption, DropDownOption } from "./HasDropDownOption";
import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import HasLabel from "@/models/HasLabel";

// noinspection JSUnusedGlobalSymbols
export enum BasisEnum {
    AWP,
    Cost,
}

export interface PriceSchedule extends HasID, TrackedObject {
    id: number
    name: string
    basis: BasisEnum
    factor: number
    flatMarkup: boolean
    markup: number
    flatServiceFee: boolean
    serviceFee: number
    factorOutSalesTax: boolean
    marginOverrideAmt?: number|null
    minimum?: number|null
    isConsignment: boolean
    isOffline: boolean
    useLinearInterpolation: boolean
}

export class PriceSchedule extends HasLabel implements HasDropDownOption  {
    constructor(obj?: PriceSchedule) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString():string{
        return `${this.name ?? ''}`;

    }
    toDropDownOption(): DropDownOption {
        return new DropDownOption(this.id, this.name);
    }

}
