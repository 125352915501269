
    import axios from 'axios';
    import SearchComponent from '@/components/SearchComponent.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';

    import { Component, Mixins, Watch } from 'vue-property-decorator';
    import { LockHandler } from '@/mixins/LockHandler';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';

    import { Program } from '@/models/Program';
    import { Vendor } from '@/models/Vendor';
    import { OTCItem, OTCItemProgram, OTCItemVendor } from '@/models/OTCItem';

    @Component({
        name: 'OTCItemDetailsPage',
        components: { SearchComponent, Checkbox, NewClearSaveButtons }
    })
    export default class OTCItemDetailsPage extends Mixins(LockHandler, DisableAutocompleteMixin) {
        private item: OTCItem = new OTCItem();
        private itemPrograms: OTCItemProgram[] = [];
        private itemVendors: OTCItemVendor[] = [];
        private selectedProgram: Program = new Program();
        private selectedVendor: Vendor = new Vendor();

        private buttonTitles = {
            save: "Save Changes",
            new: "New Item",
            clear: "Clear",
        };

        private searchValue = "";
        private displayName = "";
        private newMode = false;

        get disableFields(): boolean {
            return !(this.item && this.item.id) && !this.newMode;
        }

        create() {
            this.item = new OTCItem();
            this.newMode = true;
        }

        undoChanges() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            })
                .then(value => {
                    this.item = new OTCItem();
                    this.newMode = false;
                    this.itemPrograms = [];
                    this.itemVendors = [];
                    this.selectedProgram = new Program();
                    this.selectedVendor = new Vendor();
                })
                .catch(err => {
                    console.log("Error caught in undoChanges()");
                    console.error(err);
                });
        }

        get isFormValid() {
            return !!(this.item?.name && this.item.defaultPrice);
        }

        save() {
            let errorMessage: string;
            let typeMessage: string;
            if (this.item) {
                const data = this.item;
                data.itemPrograms = this.itemPrograms;
                data.itemVendors = this.itemVendors;

                axios.post('/otcitem', data)
                    .then(response => {
                        this.item = response.data;
                        errorMessage = "OTC Item Successfully Saved";
                        typeMessage = "success";

                        //Check if contacts were saved correctly, report to user if not
                    })
                    .catch(error => {
                        // probably do some smart error handling here at some point.
                        errorMessage = "OTC Item was not able to be saved";
                        typeMessage = "danger";
                        console.warn("Error on saving OTC Item", error);
                    })
                    .finally(() => {
                        this.$notification({
                            message: errorMessage,
                            timeout: 10000,
                            icon: "now-ui-icons ui-1_bell-53",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: typeMessage
                        });
                    });
            } else {
                console.warn("We have no OTC Item to save, get a new one?");
            }
        }

        @Watch('item')
        onItemChanged(value: OTCItem, oldValue: OTCItem) {
            if (this.item) {
                if (value.id) {
                    //Get programs associated to the OTC Item
                    axios.get(`/otcitem/${value.id}/programs`)
                        .then(response => {
                            this.itemPrograms = response.data;
                        })
                        .catch(error => {
                            console.warn(error);
                        });

                    //Get vendros associated to the OTC Item
                    axios.get(`/otcitem/${value.id}/vendors`)
                        .then(response => {
                            this.itemVendors = response.data;
                        })
                        .catch(error => {
                            console.warn(error);
                        });
                }
            }
        }

        @Watch('selectedProgram') onSelectedProgramChanged(value: Program | null, oldValue: Program | null) {
            if (value?.id) {
                this.addProgram(value);
            }
        }

        addProgram(newProgram: Program | null = null) {
            const selectedProgram: Program | null = newProgram || this.selectedProgram;
            if (selectedProgram) {
                const wasAdded = this.itemPrograms.filter(ip => ip.programId == selectedProgram.id)[0];
                if (!wasAdded) {
                    const newItemProgram = {
                        programName: selectedProgram?.name,
                        itemId: this.item?.id || 0,
                        programId: selectedProgram?.id,
                        price: 0,
                    } as OTCItemProgram;

                    this.itemPrograms.push(newItemProgram);
                }
            }
        }

        removeProgram(programId: number) {
            this.itemPrograms = this.itemPrograms.filter(p => p.programId != programId);
        }

        @Watch('selectedVendor') onSelectedVendorChanged(value: Vendor | null, oldValue: Vendor | null) {
            if (value?.id) {
                this.addVendor(value);
            }
        }

        addVendor(newVendor: Vendor | null = null) {
            const selectedVendor: Vendor | null = newVendor || this.selectedVendor;
            if (selectedVendor) {
                const wasAdded = this.itemVendors.filter(ip => ip.vendorId == selectedVendor.id)[0];
                if (!wasAdded) {
                    const newItemVendor = {
                        vendorName: selectedVendor?.name,
                        itemId: this.item?.id || 0,
                        vendorId: selectedVendor?.id,
                        vendorItemNbr: "",
                        cost: 0,
                    } as OTCItemVendor;

                    this.itemVendors.push(newItemVendor);
                }
            }
        }

        removeVendor(vendorId: number) {
            this.itemVendors = this.itemVendors.filter(p => p.vendorId != vendorId);
        }

    } // close export of class

