
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { PrescriberFollowUpCase, FaxType, PrescriberFollowUpStatus } from '@/models/PrescriberFollowUpCase';
    import DoctorFollowUpCaseForm from '@/components/Doctor/DoctorFollowUpCaseForm.vue';

    @Component({
        name: "DoctorFollowUpCasesAlertComponent",
        components: { DoctorFollowUpCaseForm }
    })
    export default class DoctorFollowUpCasesAlertComponent extends Vue {
        @Prop() public patientId!: number;
        @Prop({ default: 'followup-case-form' }) private caseModalId!: string;

        protected followUpCases: Array<PrescriberFollowUpCase> = new Array<PrescriberFollowUpCase>();
        protected faxTypeEnum = FaxType;

        private selectedCase: PrescriberFollowUpCase = new PrescriberFollowUpCase();

        created() {
            this.fetchFollowUpCases();
        }

        @Watch('patientId') changedPatient() {
            this.followUpCases = [];
            this.fetchFollowUpCases();
        }

        fetchFollowUpCases() {
            if (!this.patientId) return;
            //Check if there are pending follow up cases
            this.$http.get<Array<PrescriberFollowUpCase>>(`/prescriber/follow-up-by-patient/${this.patientId}`)
                .then(res => {
                    if (res.data?.length)
                        this.followUpCases = res.data.filter(c => c.status == PrescriberFollowUpStatus.Created || c.status == PrescriberFollowUpStatus.InProgress)
                            .map(c => new PrescriberFollowUpCase(c));
                })
                .catch(err => console.error('Error while getting doctor follow-up cases by patient.', {
                    err,
                    response: err?.response
                }));
        }

        openDetails(selectedItem: PrescriberFollowUpCase) {
            this.selectedCase = selectedItem;
            this.$bvModal.show(this.caseModalId);
        }

        closeCaseModalAndReload() {
            this.$bvModal.hide(this.caseModalId);
            this.fetchFollowUpCases();
        }
    }

