import HasID from "./HasID";
import HasLabel from "@/models/HasLabel";
import { PharmacyBenefitManager } from "./PharmacyBenefitManager";
import TrackedObject from "./TrackedObject";
import { Store } from "./Store";

export interface Audit extends TrackedObject{
    id: number;
    auditNumber?:string;
    initialNotificationDate:Date;
    dueDate:Date;
    followUpDate:Date;
    status: AuditStatus;
    pbm: PharmacyBenefitManager;
    auditor:Auditor;
    billReference:BillReference[];
    store: Store;
}

export enum AuditStatus{
Received,
Compiled,
Submitted,
ResponseReceived,
AppealPending,
AppealSubmitted,
FinalDeterminationReceived
}
export interface Auditor extends HasID, TrackedObject{
    id: number;
    firstName:string;
    lastName:string;
}

export interface BillReference extends HasID,TrackedObject{
    id: number;
    auditId: number;
    storeId: number;
    rxNumber: number;
    rfNumber: number;
    brnString:string;
    clawAmount: number;
    note: string;
    status: number;
    fileName: AuditPrescriptionDocument[];
    filePreview: string;
}

export interface AuditPrescriptionDocument extends HasID {
    id: number;
    auditPrescriptionId: number;
    fileName: string;
    documentOrder: number;
    uploaded: Date;
    uploadedBy: string;
    removed: Date;
    removedBy: string;
}

export class Audit{
    constructor(obj?:Audit) {
        if (obj) Object.assign(this, obj);
    }
}

export class Auditor extends HasLabel{
    constructor(obj?:Auditor) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        let label = "";
        if(this.id){
            label = "Id: " + this.id + " Name: " + this.lastName + ", " + this.firstName
        }
        return label;
    }
}

export class AuditPrescriptionDocument extends HasLabel {
    constructor(obj?: AuditPrescriptionDocument) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (this.fileName.indexOf('/') > 0)
            return this.fileName.split('/')[1];
        else
            return this.fileName;
    }
}

export class BillReference extends HasLabel{
    constructor(obj?:BillReference) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        let label = "";
        if(this.id){
            label = this.storeId + "-" + this.rxNumber + "-" + this.rfNumber
        }
        return label;
    }
}

export interface DocumentModel{
   supportingDocument:File[],
   rxNumber:number
}

export class DocumentModel{
    constructor(obj?:DocumentModel) {
        if (obj) Object.assign(this, obj);
    }
}