import HasID from './HasID';
import { Note } from './Note';
import HasLabel from "@/models/HasLabel";

export interface RxTransfer extends HasID{
    requestedBy: string,
    timeStamp: Date,
    inbound: boolean|undefined,
    patientId: number,
    externalPharmacyName: string,
    externalPharmacyPhone: string,
    externalPharmacyFax: string;
    externalPharmacistName: string,
    transferringPharmacist: string,
    transferStatus: transferStatus,
    transferStatusReason: string,
    transferStatusDate: Date,
    transferStatusSetBy: string,
    notes: Note[]
    contents: RxTransferContents[]
    rxTransferReasonId: number;
    rxTransferReasonName: string;

    //patientInfo only displayed on frontEnd in RxTransferQueue
    patientName: string,
    patientDOB: Date,
    patientState: string
}

export interface RxTransferContents extends HasID {
    rxTransferId: number,
    medicationName: string,
    rxNumber: number | null
}

export enum transferStatus {
    New,
    Open,
    Cancelled,
    Completed
}

export interface RxTransferReason extends HasID {
    id: number;
    name: string;
}

export class RxTransferReason extends HasLabel {
    constructor(obj?: RxTransferReason) {
        super();
        if (obj){
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (!this.id) return "";
        return `${this.id} - ${this.name}`;
    }
}
