
    import Axios from "axios";
import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({
        name: "TranscriptionViewer",
    })
    export default class TranscriptionViewer extends Vue {
        @Prop() private entityType!: number;
        @Prop() private entityId!: number;
        private transcript?: Array<any> = [];
            private pendingTranscript: boolean = false;
            private noTranscript: boolean = false;

        mounted(){
            this.fetchTranscript();
        }

        fetchTranscript(){
            this.pendingTranscript = false;
            this.noTranscript = false;
            Axios.get(`Transcript/${this.entityType}/${this.entityId}`).then((response) =>{
                if(response.status == 204){
                    this.pendingTranscript = true;
                }
                this.transcript = response.data;
            }).catch((error) => {
                if(error.response.status == 404){
                    this.noTranscript = true;
                }
            });
        }

        segmentClicked(segment:any){
            this.$emit("transcriptclick", this.entityId, segment.s);
        }

        requestTranscript(){
            Axios.post(`Transcript/${this.entityType}/${this.entityId}`).then((response) =>{
                this.fetchTranscript();
            })
        }
    }
