import HasLabel from "@/models/HasLabel";
import TrackedObject from "./TrackedObject";

export interface PharmacyBenefitManager extends TrackedObject {
    id: number;
    name: string;
    mailOrderDaySupply: number;
    vendorId: string;
    allowedStates: AllowedStates[];
    exemptedNdcs: string[];
    flags: Flags;
    bins: string[];
}

export interface AllowedStates {
    storeId: number;
    states: string[];
}

export enum Flags {
    None,
    CovidPcn
}

export class PharmacyBenefitManager extends HasLabel {
    constructor(pbm?: PharmacyBenefitManager) {
        super();
        this.allowedStates = [];
        this.exemptedNdcs = [];
        this.bins = [];
        if (pbm) {
            Object.assign(this, pbm);
        }
    }

    toString(): string {
        let label = "";
        if (this.name) {
            label = "Id: " + this.id + " Name: " + this.name;
        }
        return label;
    }
}
