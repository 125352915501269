export interface Call {
    recordingSid: string;
    callSid: string;
    externalNumber: string;
    outbound: boolean;
    initiated: Date;
    agent: string;
    fileName: string;
    duration: number;
    patientId: number;
    disposition: string;
}

export class Call {
    constructor(obj?: Call) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
