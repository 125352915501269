
    import { Component, Emit, Prop, Ref, Watch, Mixins } from 'vue-property-decorator';
    import Axios from 'axios';
    import moment from 'moment';
    import SearchComponent from '@/components/SearchComponent.vue';
    import PatientAllergyComponent from '@/components/GSDDAllergies/GSDDAllergyComponent.vue'
    import { OnlineRefillOrder, OnlineRefillPrescription } from "@/models/OnlineRefillViewModel";
    import { Store } from "@/models/Store"
    import { Prescription } from "@/models/Prescription";
    import { Order } from "@/models/Order";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { BModal } from "bootstrap-vue";
    import { Program } from "@/models/Program";
    import { LockHandler, Lock } from '@/mixins/LockHandler';



    @Component({
        name: "PatientWebOnlineOrderQueue",
        components: {
            SearchComponent,
            PatientAllergyComponent,
            RxPage: () => import('@/pages/Prescription/PrescriptionDetailsPage.vue'),
        },
    })

    export default class PatientWebOnlineOrderQueue extends Mixins(LockHandler) {
        @Ref() readonly rxModal!: BModal;
        @Prop({ default: true, type: Boolean }) private refillBatchMode!: boolean;
        @Prop({ default: "New Refills In Batch" }) private batchModalTitle!: string;
        private unprocessedOnlineRefills: OnlineRefillOrder[] = [];
        private selectedOnlineRefill: any = {} as any;
        private storeFilter: Store = {} as Store;
        private programFilter: Program | null = null;
        private prescriptionsSelected: Prescription[] = [];
        private prescriptions: Array<Prescription> = [];
        private fields = [
            { key: 'patientName', label: 'Patient Name', sortable: true },
            { key: 'created', formatter: this.convertDate, label: 'Date', sortable: true },
            { key: 'prescriptions', label: 'Drug Name', formatter: this.drugNameFormatter },
            { key: 'programName', label: 'Program Name' },
            { key: 'amount', label: 'Copay', formatter: this.convertAmount },
            { key: 'process' }
        ];

        private rxForBatch: Prescription[] = [];

        drugNameFormatter(items: OnlineRefillPrescription[]) {
            return items.map(i => i.drugName).join(', ');
        }

        get prescriptionsToShow(): Array<Prescription> {
            return this.prescriptions.map(rx => Object.assign(new Prescription(), rx));
        }

        viewProcessingModal(selectedOrder: any) {
            this.addLockWithURL(`PatientWeb/${selectedOrder.item.id}/lock`, 60000)
                .then(result => {
                    this.patientWebOrderLock = result;

                    this.selectedOnlineRefill = selectedOrder;

                    let scripts = this.selectedOnlineRefill.item.prescriptions.map((rx: any) => {
                        let script = new Prescription();
                        script.storeID = rx.storeId;
                        script.rxNumber = rx.rxNumber;
                        script.rfNumber = rx.rfNumber;
                        return script;
                    });
                    this.openBatch(scripts);
                })
                .catch(error => {
                    console.log(`added lock -- error == ${error}`);
                    if (error.response && error.response.status == 418) {
                        const lockdata = error.response.data;
                        const lockedby = lockdata.lockedBy;
                        const expires = lockdata.expires;
                        this.$bvModal.msgBoxOk(`The process is locked by ${lockedby} until ${expires}.`);
                    }
                })
        }

        openBatch(rxForBatch: Array<Prescription>) {
            console.log("rxForBatch: ", rxForBatch);
            if (this.refillBatchMode) {
                this.prescriptionsSelected = rxForBatch;
                this.prescriptions = rxForBatch;
                if (!this.prescriptionsSelected?.length) return;

                const sameStoreRx = (rx: any, rx2: any) => {
                    return rx.storeID == rx2.storeID && rx.rxNumber == rx2.rxNumber;
                };

                const findMaxRfInStoreRxGroup = (rx1: Prescription, rx2: Prescription): Prescription => {
                    return (rx1.rfNumber || 0) > (rx2.rfNumber ?? 0) ? rx1 : rx2;
                };

                //Find the distinct StoreId - RxNumber combinations in the selection
                const distinctStoreRxList = this.prescriptionsSelected.filter((rx: any, i: any, rxs: any) => {
                    const i2 = rxs.findIndex((rx2: any) => sameStoreRx(rx, rx2));
                    return i2 == i;
                }).map(rx => {
                    return { storeID: rx.storeID, rxNumber: rx.rxNumber };
                });

                //Find the prescription with the max RfNumber for each distinct StoreId - RxNumber combination
                this.rxForBatch = distinctStoreRxList.map(ids => {
                    const group = this.prescriptionsToShow.filter((rx: any) => sameStoreRx(rx, ids));
                    return Object.assign(new Prescription(), group.reduce(findMaxRfInStoreRxGroup));
                });
            } else {
                this.rxForBatch = rxForBatch;
            }

            this.rxModal.show();
        }

        @Emit()
        batchCanceled() {
            this.rxModal.hide();
        }

        @Emit("batchFinished")
        batchFinished(...args: any[]) {
            console.log("batch Finished args:", args);

            let url = `order/scripts/${this.selectedOnlineRefill.item.id}`;
            if(!this.selectedOnlineRefill.item.legacyPortal){
                url = `order/scripts/app/${this.selectedOnlineRefill.item.id}`;
            }
            Axios.post(url, this.rxForBatch)
                .then(orderResponse => {
                    console.log("orderResponse: ", orderResponse);
                    this.getUnprocessedOnlineRefills();
                }).catch(orderError => {
                    console.log(orderError.response)
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Order", orderError));
                })

            this.rxModal.hide();
        }

        created() {
            Axios.get(`/Store/`).then(response => {
                this.storeFilter = response.data[0];
            }).then(() => {
                this.getUnprocessedOnlineRefills();
            })
        }

        getProgramName(item: OnlineRefillOrder) {
            return item.programName;
        }

        private patientWebOrderLock: Lock | null = null;

        @Watch('storeFilter')
        @Watch('programFilter')
        getUnprocessedOnlineRefills() {
            Axios.get(`/PatientWeb/GetUnprocessed`).then(response => {
                this.unprocessedOnlineRefills = response.data;
                if (this.storeFilter) {
                    this.unprocessedOnlineRefills = this.unprocessedOnlineRefills.filter((order => order.storeId == this.storeFilter.id))
                }
                if (this.programFilter) {
                    console.log("programFilter =", this.programFilter)
                    console.log("unprocessedOnlineRefills = ", this.unprocessedOnlineRefills);
                    this.unprocessedOnlineRefills = this.unprocessedOnlineRefills.filter((order => this.getProgramName(order) == this.programFilter!.name))
                }
            })

        }

        convertDate(date: string) {
            return moment.utc(date).format("L");
        }

        convertAmount(amt: number) {
            return '$' + amt.toFixed(2);
        }

        searchLabel(object: Store) {
            if (object?.id)
                return object.name;
            else return '';
        }

        finishProcessingOrder() {
            Axios.post(`/PatientWeb/CreateOrder`, this.selectedOnlineRefill.item)
                .then(_response => {
                    this.$notification({
                        message: "Order Processed Successfully",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success"
                    });
                    this.getUnprocessedOnlineRefills();
                })
                .catch(error => {
                    this.$notification({
                        message: "Error Occured: " + error.response?.data.substring(0, 100),
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger"
                    });
                })
        }
    }
