
import HasAddress from '@/models/HasAddress';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: "AddressFormFields",
})
export default class AddressFormFields extends Vue {
    @Prop({ type: Boolean, default: false }) private disableFields!: boolean;
    @Prop() private value!: HasAddress;

    get shouldDisable(): boolean {
        return this.disableFields;
    }

    get address(): HasAddress {
        return this.value;
    }

    set address(val: HasAddress) {
        this.$emit('input', val);
    }
}

