

import { Component, Vue } from "vue-property-decorator";
import SearchComponent from "@/components/SearchComponent.vue";
import { Store } from "@/models/Store";
import { Program } from "@/models/Program";
import { BvTableCtxObject } from "bootstrap-vue";
import Axios, { AxiosRequestConfig } from "axios";
import { OrderAuditRow } from "@/models/OrderAudit";
import moment from "moment";
import PromisedTimeSummary from "@/components/Order/PromisedTimeSummaryComponent.vue";

@Component({
    name: "OrderAuditQueuePage",
    components: {
        SearchComponent,
        PromisedTimeSummary
    },
})
export default class OrderAuditQueuePage extends Vue {

    private storeFilter: Store = new Store();
    private programFilter: Program = new Program();

    get tableFilter(): AxiosRequestConfig {
        const filter: AxiosRequestConfig = {
            params: {
                includeCompleted: true,
                storeId: this.storeFilter?.id ?? 0,
                programId: this.programFilter?.id ?? 0
            }
        };
        return filter;
    }

    private currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    get tableFields() {
        return [
            { key: "orderId", sortable: true },
            { key: "orderDate", sortable: true, formatter: (d: any) => moment(d).format("L LT"), },
            { key: "promisedDate", sortable: true, hidden: !this.hasPromised },
            { key: "patientName", sortable: true, },
            { key: "drugName", sortable: true, },
            { key: "copay", sortable: true, formatter: this.currencyFormatter.format, },
            { key: "programName", },
            { key: "auditReason", sortable: true, },
            { key: "edit", },
        ].filter(f => f.hidden !== true);
    }

    private hasPromised = false;

    async tableProvider(ctx: BvTableCtxObject) {
        const uri = ctx.apiUrl + "";
        const config = ctx.filter as AxiosRequestConfig;
        try {
            const resp = await Axios.get<OrderAuditRow[]>(uri, config);
            this.hasPromised = !!resp.data.find(i => i.promisedDate);
            return resp.data.map(a => new OrderAuditRow(a));
        } catch (err) {
            return [];
        }
    }

    editClick(item: OrderAuditRow) {
        console.log("attempt to edit: ", item);

    }

    filterProgramChanged(newFilter: Program) {
        this.programFilter = newFilter;
    }
}
