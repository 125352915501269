
import {Component, Vue, Watch} from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import {AcceptanceConfiguration, AcceptanceConfigurationStatus, LinkedEntity} from "@/models/AcceptanceConfiguration";
import EnumSelector from "@/components/EnumSelector.vue";
import axios, {AxiosRequestConfig} from "axios";
import NewClearSaveButtons, {NewClearSaveTitles} from "@/components/NewClearSaveButtons.vue";
import {Program} from "@/models/Program";
import {Drug} from "@/models/Drug/Drug";
import {NonGSDDDrug} from "@/models/Drug/NonGSDDDrug";
import {CompoundDrug} from "@/models/Drug/CompoundDrug";
import HasLabel from "@/models/HasLabel";
import HasID from "@/models/HasID";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import {BvTableFieldArray} from "bootstrap-vue";
import { EntityType } from "@/models/EntityType";

@Component({
    name: "AcceptanceConfigurationMaintenancePage",
    components: {NewClearSaveButtons, EnumSelector, SearchComponent},
})
export default class AcceptanceConfigurationMaintenancePage extends Vue {
    private acceptanceConfiguration?: AcceptanceConfiguration = new AcceptanceConfiguration();
    protected acceptanceConfigurationStatuses = AcceptanceConfigurationStatus;
    protected entityTypes = EntityType;
    private entitySearch: HasLabel & HasID = {} as any;

    private selectedEntityType: EntityType | null = null;

    private loading = false;
    private newMode = false;

    private newDocument: File = new File([], "");

    get discardTypes(): EntityType[] {
        return [
            EntityType.Unknown,
            EntityType.PrescriptionHardCopyAnnotation,
            EntityType.Patient,
            EntityType.Case,
            EntityType.PriorAuthorization,
            EntityType.DispenseError,
            EntityType.RxTransferQueue,
            EntityType.Prescriber,
            EntityType.PrescriberFollowUpCase,
            EntityType.ElectronicPriorAuthorization,
            EntityType.DrugShortageReport,
            EntityType.SurplusReports
        ];
    }

    get routeId(): number {
        let res: number;
        res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get isFormValid(): boolean {
        return true;
    }

    get editing(): boolean {
        return !!this.routeId || this.newMode;
    }

    get buttonTitles(): NewClearSaveTitles {
        let res: NewClearSaveTitles;
        if (this.editing) {
            res = {
                save: "Save",
                clear: "Clear",
                new: "",
                cancel: "",
            } as NewClearSaveTitles;
        } else {
            res = {
                new: "New Configuration",
                clear: "Clear",
                save: "Save Changes",
                cancel: "",
            } as NewClearSaveTitles;
        }
        return res;
    }

    get disableFields(): boolean {
        return !this.editing;
    }

    get disableEntitySearch(): boolean {
        let haveEntityType = this.selectedEntityType != null;
        console.log("haveEntityType: ", haveEntityType);
        return !haveEntityType;
    }

    get entityLabel(): string {
        if (this.selectedEntityType == null) return "Search";
        return `${EntityType[this.selectedEntityType]}`;
    }

    get entityFields(): BvTableFieldArray {
        return [
            {key: "entityId",},
            {key: "entityType", formatter: value => EntityType[value]},
            {key: "actions", label: "",},
        ];
    }

    get entitySearchUrl(): string {
        switch (this.selectedEntityType) {
            case EntityType.Program:
                return "/api/Program";
            case EntityType.GsddDrug:
                return "/api/Drug";
            case EntityType.CompoundDrug:
                return "/api/Compound";
            case EntityType.NonGsddDrug:
                return "/api/NonGsdd";
        }
        return "";
    }

    created() {
        if (this.routeId) this.fetchConfiguration({id: this.routeId} as AcceptanceConfiguration);
    }

    fetchConfiguration(configuration: AcceptanceConfiguration) {
        this.loading = true;
        axios.get<AcceptanceConfiguration>(`/api/AcceptanceConfiguration/${configuration.id}`)
            .then(res => {
                this.acceptanceConfiguration = new AcceptanceConfiguration(res.data);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    submitConfiguration() {
        axios.post(`/api/AcceptanceConfiguration`, this.acceptanceConfiguration)
            .then(resp => {
                this.acceptanceConfiguration = new AcceptanceConfiguration(resp.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Acceptance Configuration"));
            });
    }

    updateForm() {
        if (!this.acceptanceConfiguration?.id) {
            this.$notification(NotificationOptions.error("You Must Save Before You Can Update The Form"));
            return;
        }

        const formData = new FormData();
        formData.append('document', this.newDocument);
        formData.append('fileName', this.acceptanceConfiguration.name.trim());

        const url = `/api/AcceptanceConfiguration/${this.acceptanceConfiguration.id}/UpdateForm`;
        let config: AxiosRequestConfig = {headers: {'Content-Type': 'multipart/form-data'}};
        axios.post<AcceptanceConfiguration>(url, formData, config)
            .then(resp => {
                this.acceptanceConfiguration = new AcceptanceConfiguration(resp.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Form Update"))
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
    }

    newClicked() {
        this.newMode = true;
    }

    clearConfiguration() {
        this.newMode = false;
        this.acceptanceConfiguration = undefined;
    }

    removeEntity(item: LinkedEntity) {
        console.log("remove the entity");
        axios.post<AcceptanceConfiguration>(`/api/AcceptanceConfiguration/${this.acceptanceConfiguration?.id}/Unlink`, item)
            .then(resp => {
                this.acceptanceConfiguration = new AcceptanceConfiguration(resp.data);
                this.selectedEntityType = null;
                this.entitySearch = {} as any;
                this.$notification(NotificationOptions.successSaveNotificationPreset("Removed Entity"));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            });
    }

    addEntity() {
        if (!this.acceptanceConfiguration?.id) {
            this.$notification(NotificationOptions.error("You Must Save Before You Can Link Entities"));
            return;
        }
        console.log("link the entity", this.selectedEntityType, this.entitySearch);
        const obj = this.objectifiedSearch(this.entitySearch);
        if (this.selectedEntityType == null) {
            this.$notification(NotificationOptions.error("You Must Select an Entity Type First"));
            return;
        }
        if (obj?.id == null) {
            this.$notification(NotificationOptions.error("You Must Select an Entity First"));
            return;
        }

        const data: LinkedEntity = {
            entityId: +obj.id,
            entityType: this.selectedEntityType,
        };

        axios.post<AcceptanceConfiguration>(`/api/AcceptanceConfiguration/${this.acceptanceConfiguration?.id}/Link`, data)
            .then(resp => {
                this.acceptanceConfiguration = new AcceptanceConfiguration(resp.data);
                this.selectedEntityType = null;
                this.entitySearch = {} as any;
                this.$notification(NotificationOptions.successSaveNotificationPreset("Linked Entity"));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
    }

    objectifiedSearch(o: any): (HasLabel & HasID) | null {
        let obj: (HasLabel & HasID) | null = null;
        switch (this.selectedEntityType) {
            case EntityType.Program:
                obj = new Program(o);
                break;
            case EntityType.GsddDrug:
                obj = new Drug(null, o);
                break;
            case EntityType.NonGsddDrug:
                obj = new NonGSDDDrug(null, o);
                break;
            case EntityType.CompoundDrug:
                obj = new CompoundDrug(null, o);
                break;
        }
        return obj;
    }

    optionLabel(o: any): string {
        let obj = this.objectifiedSearch(o);
        if (obj == null) return "";
        return obj.getLabel();
    }

    @Watch('acceptanceConfiguration')
    onConfigurationChange(value: AcceptanceConfiguration) {
        if (!value) {
            this.acceptanceConfiguration = new AcceptanceConfiguration();
            this.selectedEntityType = null;
            this.entitySearch = {} as any;
            return;
        }
        if (!value?.id) {
            if (this.routeId) this.$router.push({name: 'AcceptanceConfiguration'});
            return;
        }
        if (value.id && value.id != this.routeId) {
            this.$router.push({name: 'AcceptanceConfigurationDetails', params: {id: value.id.toString()}})
        }
    }

    @Watch('entitySearch')
    onEntitySearchChange(value: any) {
        console.log("entity: ", value);
    }

    @Watch('selectedEntityType')
    onEntityTypeChange(value: EntityType) {
        switch (value) {
            case EntityType.Program:
                this.entitySearch = new Program();
                break;
            case EntityType.GsddDrug:
                this.entitySearch = new Drug();
                break;
            case EntityType.NonGsddDrug:
                this.entitySearch = new NonGSDDDrug();
                break;
            case EntityType.CompoundDrug:
                this.entitySearch = new CompoundDrug();
                break;
        }
    }

}
