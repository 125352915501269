import { Plan } from "@/models/Plan";
import { PatientPlan } from "@/models/PatientPlan";
import HasLabel from "@/models/HasLabel";

export interface CombinedPlan {
    plan: Plan;
    patientPlan: PatientPlan;
}

export class CombinedPlan extends HasLabel {
    constructor (combinedPlan: CombinedPlan|null) {
        super();
        if (combinedPlan) {
            this.plan = new Plan(combinedPlan.plan);
            this.patientPlan = new PatientPlan(combinedPlan.patientPlan);
            //Object.assign(this, combinedPlan);
        }
    }

    toString(): string {
        return `${this.plan?.toString()} ${this.patientPlan?.toString()}`;

    }
}

export interface IncludeCombinedPlan extends CombinedPlan {
    include?: boolean;
}

export class IncludeCombinedPlan extends CombinedPlan {
    constructor(includeCombinedPlan: IncludeCombinedPlan|null) {
        super(includeCombinedPlan);
        if (includeCombinedPlan) {
            this.include = includeCombinedPlan.include ?? false;
        }
    }
}
