
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import Axios from 'axios';
    import moment from 'moment';
    import { Prescription } from '@/models/Prescription';
    import { CoverMyMedsResponse, PriorAuthorization } from '@/models/PriorAuthorization';
    import CoverMyMedsResponseComponent from "@/components/PriorAuthorization/CoverMyMedsResponseComponent.vue";

    @Component({
        name: "PriorAuthorzationCreate",
        components: {
            CoverMyMedsResponseComponent,
        },
    })
    export default class PriorAuthorzationCreate extends Vue {
        @Prop() private patientId!: number;
        @Prop() private value!: Prescription;
        private cmmResponse: CoverMyMedsResponse = {} as CoverMyMedsResponse;
        private prescriptions: Prescription[] = [];
        private isLoading = false;

        private fields: any[] = [
            { key: "storeID" },
            { key: "rxNumber" },
            { key: "rfNumber" },
            { key: "quantity" },
            { key: "refills" },
            { key: "productNameShort" },
            { key: "writtenDate", formatter: this.formatDate },
            { key: "start", label: "" },
        ];

        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
            this.fetchPrescriptionsForPriorAuthorization();
        }
        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }

        formatDate(d: Date): string {
            if (!d) return "";
            return moment.utc(d).local().format("L");
        }

        get displayValue(): string {
            return `${this.value.rxID} ${this.value.productNameShort} - ${this.formatDate(this.value.writtenDate)}`
        }

        createPa(item: Prescription) {
            this.$emit('input', Object.assign(new Prescription(), item));
            const data = item;
            console.log("data: ", JSON.stringify( data));
            this.isLoading = true;
            Axios.post<PriorAuthorization>(`/PriorAuthorization/Create?forcePA=true`, data)
                .then(response => {
                    console.log("createPa response.data: ", response.data);
                    if (response.data.responses.length > 0) {
                        const resp = response.data.responses[0];
                        this.cmmResponse = resp;

                        this.prescriptions.slice(this.prescriptions.indexOf(item), 1);
                        if (resp.requiredAction) {
                            console.log("Required action!");
                        }
                    }
                })
                .catch(err => {
                    console.error('Error while creating PA', {err, response: err?.response });
                })
                .finally(() => this.isLoading=false );
        }

        @Watch('patientId')
        fetchPrescriptionsForPriorAuthorization() {
            console.log("fetch that rx");
            Axios.get(`/PriorAuthorization/PossiblePrescriptions/${this.patientId}`)
                .then(response => {
                    console.log("rx's response.data: ", response.data);
                    this.prescriptions = response.data;
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        rowClicked(prescription: Prescription) {
            console.log("Row clicked: ", prescription);
            this.$emit('input', prescription);
        }

    }
