
import { Store } from '@/models/Store';
import axios from 'axios';
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { BvModalEvent } from "bootstrap-vue";

@Component({})
export default class BrandingModal extends Vue {
    @Prop({ default: "branding-modal", type: String }) private id!: string;
    @VModel() private store!: Store;
    private uploadDocument: File = new File([], "");
    private imageSrc: string = "";

    async loadImage(): Promise<string> {
        if (!this.store.brandingImageId) return Promise.reject("no image id");

        try {
            let blob = await axios.get(`/api/Image/${this.store.brandingImageId}`, { responseType: "blob" });
            const objectUrl = URL.createObjectURL(blob.data);
            return objectUrl;
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

        return `${axios.defaults.baseURL}/image/${this.store.brandingImageId}`;
    }

    @Watch('store')
    async storeUpdate(_newVal: Store, _oldVal: Store) {
        this.resetForm();
        try {
            this.imageSrc = await this.loadImage();
        } catch (_err) {
            // swallow
        }
    }

    handleOk(event: BvModalEvent) {
        event.preventDefault();
        this.uploadBranding();
    }

    uploadBranding() {
        const formData = new FormData();
        formData.append('document', this.uploadDocument, this.uploadDocument.name.trim());

        console.log("formData: ", JSON.stringify(formData));

        axios.post(`/api/Store/${this.store.id}/branding`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(resp => {
                console.log("branding resp: ", resp);
                this.resetForm();
                this.$nextTick(() => {
                    this.$bvModal.hide(this.id);
                });
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            });
    }

    resetForm() {
        this.uploadDocument = new File([], "");
        this.imageSrc = "";
    }
}
