import HasLabel from './HasLabel';

export interface User {
    userName: string;
    initials: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isManager: boolean;
    isPharmacist: boolean;
    isPatientsManager: boolean;
    isInventoryManager: boolean;
    isReadOnly: boolean;
    isCompoundManager: boolean;
    isPapApproval: boolean;
    infinitrakApiKey: string;
}

export class User extends HasLabel {
    constructor(obj?: User) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }
    toString():string{
        return this.userName;
    }
}
