
import { Component, Vue } from 'vue-property-decorator';

import { Patient } from '@/models/Patient';
import { Store } from '@/models/Store';
import { Consultation } from '@/models/Consultation';
import ConsultationListComponent from '@/components/Consultation/ConsultationListComponent.vue';
import NewConsultationFormComponent from '@/components/Consultation/NewConsultationFormComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';

@Component({
    name: "ConsultationsPage",
    components: {
        ConsultationListComponent,
        SearchComponent,
        NewConsultationFormComponent
    }
})
export default class ConsultationsPage extends Vue {
    private searchValue = "";
    private patient: Patient = new Patient();
    private patients: Patient[] = [];
    private store: Store = new Store();
    private stores: any[] = [];
    private consultation: Consultation = new Consultation();

    created() {
        this.patient.id = 0;
    }

    patientLabel(option: any) {
        return `${option.lastName}, ${option.firstName}`;
    }

    openNewConsultationModal() {
        this.$bvModal.show("newConsultationModal");
    }

    updateList() {
        this.$bvModal.hide("newConsultationModal");

        const component = this.$refs.consultationList as ConsultationListComponent;
        if (component)
            component.getConsultation();
        else console.error("No reference for consultationList component.");
        // this.patient.id = 0;
        // this.store = new Store();
    }

    clearInputs() {
        this.store = new Store();
        this.patient = Object.assign(new Patient(), { id: 0 });
    }
}
