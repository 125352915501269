
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Patient } from "@/models/Patient";

const recentStorageKey = "recent-patients";

@Component({
    name: "PatientRecentlyViewed",
    components: {}
})
export default class PatientRecentlyViewed extends Vue {

    @Prop({ required: false, default: false }) private tracking!: boolean;
    @Prop({ required: false }) private patient?: Patient | null;
    @Prop({ required: false }) private selected?: Patient | null;
    @Prop({ required: false, default: false }) private hideList!: boolean;
    @Prop({ required: false }) private disablePatient?: Patient | null;
    @Prop({ required: false, default: false }) private keyboardNav!: boolean;
    @Prop({ required: false, default: false }) private noItemsMessage!: boolean;

    private items: Array<Patient> = [];
    private itemIndex = 0;

    mounted() {
        if (this.tracking) return;

        this.getItems();
        if (this.keyboardNav) {
            document.addEventListener("keyup", this.hotKeyListener);
        }
    }

    beforeDestroy() {
        document.removeEventListener("keyup", this.hotKeyListener);
    }

    hotKeyListener(e: any) {
        if (e.code === "ArrowDown") {
            if (this.itemIndex < this.items.length - 1) this.itemIndex += 1;
        } else if (e.code === "ArrowUp") {
            if (this.itemIndex > 0) this.itemIndex -= 1;
        } else if (e.code === "Enter" && this.items.length) {
            this.$emit('change', this.items[this.itemIndex]);
        }
    }

    async getItems() {
        const recents = this.getRecentsFromHistory();

        if (!recents.length) return;

        const response = await this.$http.get(`/patient?searchTerm=[[${recents.join(",")}]]`);

        this.items = response.data.map((i: any) => Object.assign(new Patient(), i));

        if (this.$route.name === "PatientDashboard") {
            this.itemIndex = this.items.findIndex(i => i.id.toString() === this.$route.params.id);
            if (this.itemIndex === -1) this.itemIndex = 0;
        }
    }

    getRecentsFromHistory(): Array<number> {
        return JSON.parse(localStorage.getItem(recentStorageKey) ?? "[]");
    }

    @Watch("patient")
    trackPatientView() {
        if (!this.tracking || !this.patient?.id) return;

        const recents = this.getRecentsFromHistory();

        const id = parseInt(this.patient.id.toString());

        if (isNaN(id)) return;

        const index = recents.indexOf(id);

        if (index !== -1) {
            recents.splice(index, 1);
        }

        recents.unshift(id);

        if (recents.length > 10) {
            recents.pop();
        }

        localStorage.setItem(recentStorageKey, JSON.stringify(recents));
    }

}

