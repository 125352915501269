
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DashboardStatus } from '@/models/DashboardStatus'
import { BvTableFieldArray } from "bootstrap-vue";
import { Store } from '@/models/Store'
import { Route } from "vue-router";

@Component({
    name: "PrintQueueTable"
})
export default class PrintQueueTable extends Vue {
    @Prop({ default: () => [], }) private status!: Array<DashboardStatus.PrintQueueStatus>;
    @Prop({ default: () => null, }) private sList!: Array<Store> | null;

    get fields(): BvTableFieldArray {
        const fields = [];
        fields.push({ key: "programName", label: "", });

        let stores = this.status.flatMap(s => s.printQueueRowRecord.map(r => r.storeName));
        if (this.sList != null) {
            this.sList.forEach(sL => {
                if (stores.find(s => sL.name == s)) {
                    fields.push({
                        key: (sL.name.toLowerCase()).replace(/\s/g, "") + sL.id,
                        label: sL.name
                    });
                }
            })
        }
        return fields;
    }

    routerLink(row: any, kind: string, oos: boolean = false): Route {
        return {
            name: kind == "stale" ? "StalePrintQueue" : "PrintQueue",
            query: {
                storeId: row.field.key.slice(-1),
                programId: row.item.programId,
                oos: oos,
            }
        } as unknown as Route;
    }

    filteredItems(filter: string): any[] {
        const items: any[] = [];
        this.status.forEach(st => {
            const obj: any = {};
            const obj2: any = {};
            st.printQueueRowRecord.forEach((pqr: any) => {
                const uniqueId = pqr.storeName.toLowerCase().replace(/\s/g, "") + pqr.storeId;
                if (pqr[filter] > 0) {
                    obj[uniqueId] = pqr[filter];
                    if (pqr.dateDays != null) {
                        if (pqr.dateDays > 4) {
                            obj2[uniqueId] = 'danger';
                        } else if (pqr.dateDays > 2 && pqr.dateDays < 4) {
                            obj2[uniqueId] = 'warning';
                        }
                    }
                }
            });
            obj.programName = st.programName;
            obj.programId = st.programId;
            obj._cellVariants = obj2;
            if (Object.keys(obj).length > 3) {
                items.push(obj);
            }
        });
        return items;
    }
}
