
import Axios from 'axios';
import { Table, TableColumn } from "element-ui";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { DispenseError } from '@/models/DispenseError';
import { Store } from '@/models/Store';

import DispensingErrorModalComponent from '@/components/DispensingError/DispensingErrorModalComponent.vue';
import DispensingErrorCardComponent from '@/components/DispensingError/DispensingErrorCardComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import DispenseErrorStatusEnum = DispenseError.DispenseErrorStatusEnum;

@Component({
    name: "DispenseErrorListComponent",
    components: {
        DispensingErrorModalComponent, DispensingErrorCardComponent,
        SearchComponent, Checkbox,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    }
})
export default class DispenseErrorListComponent extends Vue {
    @Prop({ default: false, type: Boolean }) private cards!: boolean;
    @Prop({ default: false, type: Boolean }) private hideStatusFilter!: boolean;
    @Prop({ default: "default", type: String }) private mode!: string;

    private statusEnums = DispenseError.DispenseErrorStatusEnum;
    private errors: Array<DispenseError> = [];
    private selectedDispenseError: DispenseError = new DispenseError();
    private storeFilter: Store = new Store();
    private pharmacistCreatedErrors = false;
    private pharmacistAssignedErrors = false;
    private filterCreated = true;
    private filterAccepted = false;
    private filterResolved = false;
    private filterAwaitingResponse = true;

    created() {
        this.storeFilter.id = this.persistentStoreFilterId || 0;

        this.handleMode();
    }

    @Watch("mode")
    handleMode() {
        this.filterCreated = true;
        this.filterAccepted = false;
        this.filterResolved = false;
        this.filterAwaitingResponse = true;

        if (this.mode === "pending") {
            this.filterResolved = true;
        } else if (this.mode === "assigned") {
            this.pharmacistAssignedErrors = true;
        }

        this.loadErrors();
    }

    @Watch("strStatusFilter")
    async loadErrors() {

        try {
            const response = await Axios.get("DispenseError", {
                params: {
                    statusFilter: this.strStatusFilter,
                    storeID: this.storeFilter?.id
                }
            });

            this.errors = response.data.map((d: DispenseError) => Object.assign(new DispenseError(), d));

            if (this.errors?.length)
                this.errors = this.errors.sort(this.compareFunc);

            if (this.pharmacistCreatedErrors) {
                this.errors = this.errors.filter(e => e.createdBy?.toLowerCase() === e.viewedBy?.toLowerCase())
            }

            if (this.pharmacistAssignedErrors) {
                this.errors = this.errors.filter(e => e.userNameAssignedTo?.toLowerCase() == e.viewedBy?.toLowerCase())
            }
        } catch (error) {
            console.error("Error while listing dispense errors.", { error, response: (error as any)?.response })
        }
    }

    private compareFunc = (a: DispenseError, b: DispenseError): number => {
        const compStatus = this.statusPriority(a.statusEnum) - this.statusPriority(b.statusEnum);
        if (!compStatus) return compStatus;

        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateA.getTime() - dateB.getTime();
    }

    statusPriority(statusNbr: DispenseError.DispenseErrorStatusEnum): number {
        if (statusNbr == DispenseError.DispenseErrorStatusEnum.Resolved) return 0;
        if (statusNbr == DispenseError.DispenseErrorStatusEnum.Created) return 1;
        if (statusNbr == DispenseError.DispenseErrorStatusEnum.Accepted) return 2;
        if (statusNbr == DispenseError.DispenseErrorStatusEnum.AwaitingResponse) return 3;
        return 100;
    }

    get persistentStoreFilterId(): number | null {
        const val = Number(localStorage.getItem('dispenseError_storeFilterId'));
        return val;
    }

    set persistentStoreFilterId(storeId: number | null) {
        localStorage.setItem('dispenseError_storeFilterId', (storeId || 0).toString());
    }

    get strStatusFilter(): string {
        let res = '';
        if (this.selectedStatusFilter?.length)
            res = this.selectedStatusFilter.map(f => Number(f).toString()).reduce((f1, f2) => f1 + ',' + f2)
        return res;
    }

    get selectedStatusFilter(): Array<DispenseError.DispenseErrorStatusEnum | null> {
        const res: Array<DispenseError.DispenseErrorStatusEnum | null> = [
            this.filterCreated ? DispenseError.DispenseErrorStatusEnum.Created : null
            , this.filterResolved ? DispenseError.DispenseErrorStatusEnum.Resolved : null
            , this.filterAccepted ? DispenseError.DispenseErrorStatusEnum.Accepted : null
            , this.filterAwaitingResponse ? DispenseError.DispenseErrorStatusEnum.AwaitingResponse : null
        ].filter(st => st != null);

        return res;
    }

    openErrorDispenseModal(dispenseErrorId: number) {
        // Load Error
        Axios.get(`DispenseError/${dispenseErrorId}`)
            .then(response => {
                this.selectedDispenseError = response.data;
                //Open modal with error details
                this.$bvModal.show("dispense-error-modal");
            })
            .catch(err => {
                console.error("Error while getting dispense error details", err);
            })

    }

    statusStyle(status: DispenseError.DispenseErrorStatusEnum) {
        switch (status) {
            case DispenseErrorStatusEnum.Accepted: return "info";
            case DispenseErrorStatusEnum.Resolved: return "success";
            case DispenseErrorStatusEnum.AwaitingResponse: return "danger";
            default: return "warning";
        }
    }

    storeFilterChanged(store: Store) {
        this.persistentStoreFilterId = Number(store?.id);
        this.loadErrors();
    }
}

