import { UserOptions } from 'jspdf-autotable';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export class ReportExporter extends Vue {

    public async ExportPDF(reportName: string, HtmlTable: any, options?: UserOptions): Promise<void> {
        const jspdf = await import(/* webpackChunkName: "jspdf" */ "jspdf");
        const autotable = await import(/* webpackChunkName: "jspdf" */ "jspdf-autotable");

        const doc = new jspdf.default();
        autotable.default(doc, {
            html: HtmlTable,
            includeHiddenHtml: false,
            showFoot: 'never',
            ...options
        });
        doc.save(`${reportName}.pdf`);
    }

    public ExportCSV(reportName: string, reportItems: any, reportFields: any): void {

        const separator = ',';
        const csvContent =
            reportFields.map((v: { label: any; }) => v.label).join(separator) +
            '\n' +
            reportItems.map((row: any) => {
                return reportFields.map((field: { key: any; formatter: any; }) => {
                    const key = field.key
                    const keys: string[] = key.split(".");
                    let cell = row;

                    keys.forEach(k => {
                        cell = cell[k] === null || cell[k] === undefined ? '' : cell[k];
                    });
                    const formatter = field.formatter;
                    if (formatter) {
                        cell = formatter(cell)
                    } else {
                        cell = cell.toString();
                    }
                    if (cell?.toString().search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, reportName + ".csv");
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', reportName + ".csv");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}
