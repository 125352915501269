
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { BvTableCtxObject } from "bootstrap-vue";
import Axios, { AxiosRequestConfig } from "axios";

@Component({
    name:"OrderAuditConfigPage",
    components: {
    },
})
export default class OrderAuditConfigPage extends Vue {

    ctxProvider(ctx: BvTableCtxObject, callback: Function) {
        if (ctx.apiUrl == null) return null;

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter
        };
        Axios.get(uri, config)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                callback([]);
            });
        return null;
    }
}

