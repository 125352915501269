import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { User } from "@/models/User";
import Vue from "vue";
import { ScopeSet } from "@azure/msal-common";
import { MsalPlugin } from "msal-vue";

export interface MicrosoftAuthOptions {
    serverUrl: string,
    msalConfig: any
}

export default {
    async install(app: typeof Vue, options: MicrosoftAuthOptions) {
        app.use(MsalPlugin, options.msalConfig);
        await this.setupAuthentication();
    },
    async errorHandler(this: any, err: AxiosError): Promise<any> {
        if (err?.response?.status == 401) {
            console.log("got 401!");
            if (!Vue.prototype.$msal.isAuthenticated()) {
                await Vue.prototype.$msal.loginPopup();
                axios
                    .get<User>("user/info")
                    .then(res => {
                        if (res.data.userName) {
                            Vue.prototype.$user = res.data;
                        } else console.error("No user information available.");
                    })
                    .catch(err => {
                        console.log("Error while getting user info", {
                            err,
                            response: err?.response
                        });
                    });
            }
            const config = err.response.config;
            await this.fetchToken()
            config.headers.Authorization = axios.defaults.headers.Authorization;

            return new Promise((resolve, reject) => {
                axios(config).then(
                    res => resolve(res),
                    secondErr => reject(secondErr)
                );
            });
        }
        return Promise.reject(err);
    },
    async requestHandler(this: any, config: AxiosRequestConfig): Promise<any> {
        if (config.url?.startsWith('/api')) {
            config.url = config.url?.substr(4);
        }
        if (config.url?.startsWith('api')) {
            config.url = config.url?.substr(3);
        }
        if (!Vue.prototype.$msal.isAuthenticated()) {
            await Vue.prototype.$msal.loginPopup();
        }

        if (Vue.prototype.$user?.userName == undefined && config.url != "/user/info") {
            axios
                .get<User>("api/user/info")
                .then(res => {
                    if (res.data.userName) {
                        Vue.prototype.$user = res.data;
                    } else console.error("No user information available.");
                })
                .catch(err => {
                    console.log("Error while getting user info", {
                        err,
                        response: err?.response
                    });
                });
        }
        if (
            !config.headers.Authorization ||
            config.headers.Authorization == "Bearer " ||
            config.headers.Authorization == "Bearer Unknown"
        ) {
            await this.fetchToken();
            config.headers.Authorization = axios.defaults.headers.Authorization;
        }
        return config;
    },
    async setupAuthentication(this: any): Promise<void> {
        axios.interceptors.response.use(
            response => response,
            (err: AxiosError) => this.errorHandler(err));
        axios.interceptors.request.use(
            (config) => this.requestHandler(config),
            (err) => this.errorHandler(err));
    },
    async fetchToken(): Promise<any> {
        const token = await Vue.prototype.$msal.acquireToken(ScopeSet.createSearchScopes([`api://${process.env.VUE_APP_ADAL_GRAPHID}/access_as_user`]));
        // console.log(token);
        axios.defaults.headers.Authorization = `BEARER ${token.accessToken}`;
        return token;

    }
}

