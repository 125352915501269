
import Vue from 'vue';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {Component, Watch} from 'vue-property-decorator';

import SearchComponent from '@/components/SearchComponent.vue';
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import moment from "moment";

import {Order, OrderStatus} from "@/models/Order";
import {ReturnReason, ReturnToSender} from "@/models/ReturnToSender";
import EnumSelector from "@/components/EnumSelector.vue";
import {Prescription, RefillStatusCode} from "@/models/Prescription";

@Component({
    name: "ReturnToSenderPage",
    components: {
        EnumSelector,
        SearchComponent
    },
})
export default class ReturnToSenderPage extends Vue {
    private isLoading = false;
    protected items: Array<ReturnToSender> = new Array<ReturnToSender>();
    private orderFilter: Order = new Order();
    private order: Order = new Order();
    private orderStatus = OrderStatus;

    protected returnReasons = ReturnReason;
    private returnReason: ReturnReason = ReturnReason.WrongAddress;


    private fields: any[] = [
        {key: "orderId", label: 'Order Id', sortable: true, sortDirection: "desc"},
        {key: "patientName", label: 'Patient Name', sortable: true, sortDirection: "desc"},
        {key: "programName", label: "Program Name", sortable: true, sortDirection: "desc"},
        {key: "shipDate", label: "Ship Date", sortable: true, sortDirection: "desc", formatter: this.etaDateFormatter},
        {
            key: "returnDate",
            label: "Return Date",
            sortable: true,
            sortDirection: "desc",
            formatter: this.etaDateFormatter
        },
        {key: "drugName", label: "Drug", sortable: true, sortDirection: "desc"},
        {key: "actions", label: ''},
        {key: "actions", label: ''},
    ];

    created() {
        this.loadItems();
    }

    etaDateFormatter(value: Date, key: string, item: any) {
        if (!value || item.estimatedArrivalDate > item.estimatedArrivalDate) {
            return "";
        }
        return moment.utc(value).format("L");
    }

    loadItems() {
        this.isLoading = true;
        axios.get<Array<ReturnToSender>>('Order/get-all-return-to-sender')
            .then(res => {
                this.items = res.data.map(i => new ReturnToSender(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onOrderFilterSelected() {
        //console.log(this.orderFilter.id);
        if (this.orderFilter) {
            console.log("order id is selected" + this.orderFilter.id)
        } else {
            console.log('no record selected')
        }
    }

    markAsReturnToSender() {
        if (!this.orderFilter || !this.orderFilter.id) {
            this.$bvModal.msgBoxOk(`No Order selected`);
            return;
        } else {
            const data: ReturnToSender = {
                orderId: this.orderFilter.id as number,
                returnReason: ReturnReason[this.returnReason]
            } as ReturnToSender;
            axios.post(`/Order/return-to-sender`, data)
                .then(response => {
                    this.order = response.data;
                    if (this.order.status == OrderStatus.Canceled) {

                        this.$notification(NotificationOptions.notificationOptionsPreset('Order was mask as return to sender.', NotificationOptions.NotificationTypes.success));
                    }
                })
                .catch(error => {
                    let errorMsg = "Error while loading order";
                    console.error(errorMsg, {error, response: error?.response})
                    this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg));
                })
                .finally(() => {
                    this.items = []
                    this.loadItems();
                });

        }

        this.loadItems();
    }

    setReshipOrder(order: ReturnToSender) {
        console.log('reshipped order' + order.drugName);
        this.$router.push({name: 'OrderVerification', params: {orderId: order.orderId.toString()}})
        //updating the code for redirection
    }

    cancelConfirmDialog(orderId: number) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to cancel this return to sender Order?` + orderId, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.cancelOrder(orderId);
            })
            .catch(err => {
                console.error("Error caught on Cancel button.", err);
            })
            .finally(() => {
                this.items = []
                this.loadItems();
            });


    }

    cancelOrder(orderId: Number) {
        if (!orderId) return;

        axios.post(`/Order/cancel-return-to-order`, orderId)
            .then(response => {
                this.order = response.data;
                if (this.order.status == OrderStatus.Canceled) {
                    this.$notification(NotificationOptions.notificationOptionsPreset('Order was cancelled.', NotificationOptions.NotificationTypes.success));
                }

            })
            .catch(error => {
                let errorMsg = "Error while loading order";
                console.error(errorMsg, {error, response: error?.response})
                this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg));
            })
    }

    colorCodeTable(item: ReturnToSender, type: any) {
        if (!item.returnDate) return;
        const cutoff = moment.utc().add(-14, "day");
        const returnDate = moment.utc(item.returnDate);
        if (returnDate.isBefore(cutoff, "day")) return "table-danger";
    }

}

