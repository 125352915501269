
import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import {Escript} from "@/models/Escript";
import moment from "moment/moment";
import Axios from "axios";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import {BvTableCtxObject} from "bootstrap-vue";

@Component({
    name: "EscriptListModal",
    components: {}
})
export default class EscriptListModal extends Vue {
    @VModel({type: String, default: ""}) public rxId!: String;
    @Prop({type: String, default: "all-escripts-modal"}) private id!: String;

    private allEscriptFields = [
        "id",
        {key: "sentTime", formatter: (v: any) => moment(v).format("L LT")},
        "messageType",
    ];

    myProvider(ctx: BvTableCtxObject, callback: Function) {
        const uri = ctx.apiUrl + "/" + this.rxId;
        Axios.get<Escript[]>(uri)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                callback([]);
            })
        return null;
    }

    escriptSelected(items: any[]) {
        if (items.length == 0) return;
        const item = items[0];
        console.log("item: ", item);
        Axios.get(`/api/Escript/${item.id}/image`, {responseType: "blob"})
            .then(resp => {
                const fileURL = URL.createObjectURL(resp.data);
                window.open(fileURL, "_blank");
            });
    }


}
