
import RefillRequestBatchDetails from '@/components/RefillRequestBatch/RefillRequestBatchDetails.vue';
import { RefillRequestBatch, RefillRequestBatchStatus } from '@/models/RefillRequestBatch';
import {Component, Mixins, Watch, Vue} from 'vue-property-decorator';
import moment from 'moment';
import { DatePicker } from 'element-ui'

@Component({
    name: 'RefillRequestBatchHistory',
    components: {
        [DatePicker.name]: DatePicker,
        RefillRequestBatchDetails
    }
})
export default class RefillRequestBatchHistory extends Vue{
    private items : Array<RefillRequestBatch> = new Array<RefillRequestBatch>();
    private selectedItem : RefillRequestBatch = new RefillRequestBatch();
    private startDate : Date | null = null;
    private endDate : Date | null= null;
    private fields = [
        { key: "created", label: "Created"},
        { key: "statusName", label: "Status"},
        { key: "rxCount", label: "RXs Included"},
        { key: "details", label: ""},
    ]

    created() {
        this.endDate = moment().toDate();
        this.startDate = moment().subtract(1, 'month').toDate();
        this.loadData();
    }
    
    private isLoading = false;
    @Watch('startDate')
    @Watch('endDate') 
    loadData(){
        if(this.startDate === null || this.endDate === null || moment(this.startDate).isAfter(this.endDate)) return;

        this.isLoading = true;
        this.$http.get<Array<RefillRequestBatch>>('RefillRequestBatch/history',{params: {
            startDate: this.startDate, endDate: this.endDate,
        }})
        .then(res => {
            this.items = res.data?.map(i => new RefillRequestBatch(i)) || new Array<RefillRequestBatch>();
        })
        .catch(err => {
            console.error('Error loading RefillRequestBatch history', {err, response: err.response});
        })
        .finally(() => {
            this.isLoading = false;
        })
    }

    openDetails(item:RefillRequestBatch){
        this.selectedItem = item;
        this.$bvModal.show('detailsModal');
    }

}
