
    import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
    import PatientDocumentsForm from '@/components/Patient/PatientDocumentsForm.vue';

    import { QueueItem } from '@/models/QueueItem'
    import { PatientDocument } from '@/models/PatientDocument'
    import { Patient } from '@/models/Patient';


    @Component({
        name: "PatientDocumentsModal",
        components: {    
            PatientDocumentsForm       
        }
    })
    export default class PatientDocumentsModal extends Vue {
        @Ref('patient-documents-form') patientDocumentsForm!:PatientDocumentsForm;
        @Prop() private patient!: Patient;
        @Prop({default: 'patient_documents_modal'}) modalId!: string;
        @Prop() private queueItem!: QueueItem | null;

        close(){
            this.$bvModal.hide(this.modalId);
        }

        onSave(obj:PatientDocument){
            this.$emit('on-save', obj)
        }
    }

