
import { Component, Prop, Vue } from "vue-property-decorator";
import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import Axios from "axios";
import moment from "moment/moment";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Store } from "@/models/Store";
import { Program } from "@/models/Program";
import { DrugProduct } from "@/models/Drug/DrugProduct";

@Component({
    name: "InventoryHistory",
    components: {},
})
export default class InventoryHistory extends Vue {
    @Prop() protected store?: Store;
    @Prop() protected program?: Program;
    @Prop() protected drug?: DrugProduct;

    protected hasReasons = false;

    get fields(): BvTableFieldArray {
        return [
            { key: "DrugName" },
            { key: "NDC11" },
            { key: "Updated", formatter: (value: any) => moment(value).format("L LTS"), },
            { key: "UpdatedBy" },
            { key: "RxId" },
            { key: "StoreName", label: "Store", },
            { key: "ProgramName", label: "Program", },
            { key: "Reason", hidden: !this.hasReasons },
            { key: "OldQuantity" },
            { key: "NewQuantity", tdClass: this.cellClass },
        ].filter(i => i.hidden !== true);
    }

    get inventoryFilter() {
        return {
            storeId: this.store?.id,
            programId: this.program?.id,
            packageId: this.drug?.drugId,
            source: this.drug?.source,
        };
    }

    async inventoryHistory(ctx: BvTableCtxObject) {
        if (ctx.apiUrl == null) return null;

        try {
            const resp = await Axios.get<any[]>(ctx.apiUrl, { params: ctx.filter });
            const histories = resp.data.map((value, _index, _array) => {
                if (!value.NewValue) return undefined;

                const newValue = JSON.parse(value.NewValue)[0];
                const ret = {
                    ...value,
                    Reason: newValue.Reason,
                    NewQuantity: newValue.Quantity,
                };

                if (value.OldValue) {
                    const oldValue = JSON.parse(value.OldValue)[0];
                    ret.OldQuantity = oldValue.Quantity;
                }
                return ret;
            });

            this.hasReasons = !!histories.find(h => h?.Reason);

            return histories.filter(h => h);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
            return null;
        }
    }

    cellClass(value: number, _key: string, item: any) {
        return this.cellClassForQuantities(value, item.OldQuantity);
    }

    cellClassForQuantities(newQuantity: number, oldQuantity: number): string {
        let qtyChange = Number(newQuantity - (oldQuantity ?? 0));
        if (qtyChange > 0) return "table-success";
        if (newQuantity < 0) return "table-danger";
        if (qtyChange < 0) return "table-warning";
        return "";
    }
};
