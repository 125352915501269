import HasLabel from "./HasLabel";
import TrackedObject from "./TrackedObject";

export interface AdhocDash extends TrackedObject{
    dashboardId: string;
    definition: string;
}

export class AdhocDash extends HasLabel {
    constructor(obj?:AdhocDash){
        super();
        if(obj) Object.assign(this, obj);
    }

    toString(): string {
        return this.dashboardId;
    }

}