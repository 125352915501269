
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class DebugViewComponent extends Vue {
    @Prop({default:"Debug View", type: String}) protected header!: string;
    private showing: boolean = false;

    private listenerOptions: AddEventListenerOptions = { passive: true };
    private listenerEvent = (ev: KeyboardEvent) => {
        if (ev.key == "F12") this.toggleShowing();
    };

    protected mounted() {
        window.addEventListener("keydown", this.listenerEvent, this.listenerOptions)
    }
    protected beforeDestroy() {
        window.removeEventListener("keydown", this.listenerEvent, this.listenerOptions)
    }

    toggleShowing() {
        console.log("toggle showing");
        this.showing = !this.showing;
    }
}
