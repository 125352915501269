

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Note, NoteVisibility } from '@/models/Note';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Table, TableColumn } from "element-ui";
import axios, { AxiosError } from 'axios';
import { EntityType } from "@/models/EntityType";
import HelpPopup from "@/components/HelpPopup.vue";

@Component({
    name: "NotesForm",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        HelpPopup
    }
})
export default class NotesForm extends Vue {
    @Prop({ default: 0 }) private entityId!: number;
    @Prop({ default: 0 }) private entityType!: EntityType;
    @Prop({ default: true }) private bodyRequired!: boolean;

    /**
     * @prefixNote: String that will be inserted at the beginning of the note and saved with it.
     */
    @Prop({ default: '' }) private prefixNote!: string;
    @Prop({ default: "Add Note" }) private saveButtonLabel!: string;
    @Prop({ default: "Write a note..." }) private placeholder!: string;
    @Prop() protected textBoxLabel!: string;
    @Prop({ default: false }) silent!: boolean;
    @Prop({ default: null }) private parentNote!: Note | null;

    private private = false;

    private note: Note = {} as Note;

    async save() {
        if (this.prefixNote)
            this.note.body = `${this.prefixNote} ${this.note.body || ''}`;

        if (!this.note.body) {
            this.$emit("saved");
            return;
        }

        this.note.entityType = this.entityType;
        this.note.entityId = this.entityId.toString();
        this.note.visibility = this.private ? NoteVisibility.Pharmacist : NoteVisibility.AllUsers;

        if (this.parentNote) {
            this.note.parentNoteId = this.parentNote.id as number;
        }

        try {
            const response = await axios.post(`Note`, this.note);

            this.$emit("saved", response.data)
            if (!this.silent) this.$notification(NotificationOptions.successSaveNotificationPreset("Note"));
            this.note = {} as Note;
        } catch (error) {
            console.error("Error while posting new note.", { error, response: (error as AxiosError)?.response });
        }
    }

    get disableSaveButton(): boolean {
        return (this.bodyRequired && !this.note?.body);
    }

    @Watch("note.body") noteChanged(val: string) {
        this.$emit('input', val)
    }

}
