export interface PhoneStats {
    longestHold: number | null;
    callsHolding: number | null;
    repsReady: number | null;
}

export class PhoneStats {
    constructor(obj?: PhoneStats) {
        return;
    }
}