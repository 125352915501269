
import { Consultation } from '../../models/Consultation';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CompleteConsultationComponent from './CompleteConsultationComponent.vue';
import { Table, TableColumn } from 'element-ui';
import axios from 'axios';
import { Store } from "@/models/Store";
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import moment from "moment";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import BottomPagerBar from '../BottomPagerBar.vue';

@Component({
    name: "ConsultationListComponent",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CompleteConsultationComponent, Checkbox,
        BottomPagerBar
    }
})

export default class ConsultationListComponent extends Vue {
    @Prop() private patientId!: number;
    @Prop() private store!: Store;
    @Prop() private enableCancel!: boolean;
    @Prop() private enableEdit!: boolean;
    @Prop({ default: false }) private patientNameAsLink!: boolean;
    @Prop() private hidePatient!: boolean;

    private consultations: Consultation[] = [];
    private consultation: Consultation = new Consultation();

    private showAll: boolean = false;
    private pageSize = 50;
    private pageNumber = 1;

    get consultationFields() {
        return [
            { key: "storeName" },
            { key: "patientName", hidden: this.hidePatient },
            { key: "requested", formatter: (value: any) => { return moment.utc(value).local().format('LLL'); } },
            { key: "statusName" },
            { key: "note" },
            { key: "rxNumber", label: "Script" },
            { key: "buttons", label: "" },
        ].filter(f => f.hidden !== true);
    }

    created() {
        this.getConsultation();
    }

    detailsConsultation(consultation: Consultation, cancel: boolean = false) {
        this.consultation = consultation;
        if (cancel) {
            this.consultation.status = 3;
            this.updateConsultation();
        }
    }

    updateConsultation() {
        if (this.enableEdit || this.enableCancel) {
            axios.post<Consultation>('/Consultation/', this.consultation)
                .then(response => {
                    if (response.data.id) {
                        this.$notification(NotificationOptions.successSaveNotificationPreset("Consultation"));
                        this.consultations.splice(this.consultations.findIndex(c => c.id == this.consultation.id), 1, response.data);
                        this.$bvModal.hide('complete-consultation');
                    }
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Consultation"));
                    console.log("Error while saving consultation", { error, response: error?.response });
                });
        }
    }

    addConsultation(item: Consultation) {
        this.consultations.push(item);
    }

    colorCodeTable(c: Consultation) {
        if (c?.status === 3)
            return "table-danger";

        if (c?.status === 3 || c?.status === 4)
            return "table-info";

        return;
    }

    @Watch('patientId')
    @Watch('store')
    @Watch('showAll')
    resetPage() {
        this.pageNumber = 1;
    }

    get filteredConsultations() {
        if (this.showAll) return this.consultations;
        return this.consultations?.filter(c => ![3, 4].includes(c.status));
    }

    get hidingCount() {
        return (this.consultations?.length ?? 0) - (this.filteredConsultations?.length ?? 0);
    }

    get shouldShowAll() {
        return !!this.consultations?.find(c => [3, 4].includes(c.status));
    }

    @Watch('patientId')
    @Watch('store')
    getConsultation() {
        let request = '/Consultation/';
        if (this.patientId != 0)
            request += this.patientId;

        if (this.store && this.store.id)
            request += '/' + this.store.id;

        const config = {
            params: {
                includeAllStatuses: this.patientId > 0
            }
        };

        axios.get(request, config)
            .then(response => {
                this.consultations = response.data;
            })
            .catch(error => {
                console.log("Error while loading consultations", { error, response: error?.response });
            });
    }
}
