

    import { Component, Vue } from 'vue-property-decorator';
    import CasesListComponent from '@/components/Cases/CasesListComponent.vue'

    @Component({
        name: "ResolvedCasesPage",
        components: { CasesListComponent },
    })
    export default class ResolvedCasesPage extends Vue {
        created() {
            console.log("%c Created ResolvedCasesPage", "color: green");
        }

        beforeDestroy() {
            console.log('%c Destroying ResolvedCasesPage', 'color: red');
        }
    }
