export interface TextMessageTemplates {
    welcome : TextMessageTemplate;
    npc: TextMessageTemplate;
    npOverLimit: TextMessageTemplate;
    refill: TextMessageTemplate;
    outOfNetwork: TextMessageTemplate;
    passwordReset: TextMessageTemplate;
    autorefill: TextMessageTemplate;
    transfer: TextMessageTemplate;
    shipping: TextMessageTemplate;
    partialPayment: TextMessageTemplate;
    partialPaymentOverLimit: TextMessageTemplate;
    awaitingInsurance: TextMessageTemplate;
    pharmacyInfo: TextMessageTemplate;
    readyForPickup: TextMessageTemplate;
}

export class TextMessageTemplates {
    constructor(obj?: TextMessageTemplates) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export interface TextMessageTemplate {
    active: boolean;
    template: string;
    tokens: string[];
}

export class TextMessageTemplate {
    constructor(obj?: TextMessageTemplate) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}