<template>
  <div :class="{ sidebar: true, force: forceShow }" :data-color="backgroundColor">

    <div class="logo">
      <a href="#" class="simple-text logo-normal">
        <div class="logo-image" style="width:80%">
          <img :src="logo">
        </div>
      </a>

      <!-- <a
        href="#"
        class="simple-text logo-normal"
      >
        {{ title }}
      </a> -->
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-outline-white btn-icon btn-round" @click="minimizeSidebar">
          <i class="now-ui-icons text_align-center visible-on-sidebar-regular" />
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
        </button>
      </div>
    </div>
    <div class="logo" v-if="phoneConnecting == false && phoneDisconnected == false">
      <b-row class="text-center text-white">
        <b-col v-if="!isMinimized">
          <h4 class="mt-0 mb-2">{{ formattedHold }}</h4><i class="far fa-clock fa-2x" title="Longest Hold"></i>
        </b-col>
        <b-col>
          <h4 class="mt-0 mb-2">{{ callsHolding }}</h4><i class="fas fa-user-friends fa-2x" title="Calls Holding"></i>
        </b-col>
        <b-col v-if="!isMinimized">
          <h4 class="mt-0 mb-2">{{ repsReady }}</h4><i class="fas fa-headset fa-2x" title="Reps Ready"></i>
        </b-col>
      </b-row>
    </div>
    <div class="logo" v-if="phoneConnecting == true && phoneDisconnected == false">
      <b-row class="text-center text-white">
        <b-col>
          <h4 class="mt-0 mb-2">Reconnecting...</h4><i class="fas fa-spinner fa-2x fa-spin" title="Reconnecting"></i>
        </b-col>
      </b-row>
    </div>
    <div class="logo" v-if="phoneDisconnected == true">
      <b-row class="text-center text-white">
        <b-col v-if="!isMinimized">
          <h4 class="mt-0 mb-2">Disconnected</h4><i class="fas fa-exclamation-circle fa-2x" title="Disconnected"></i>
        </b-col>
      </b-row>
    </div>
    <div ref="sidebarScrollArea" class="sidebar-wrapper">
      <slot />
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink" />
          </sidebar-item>
        </slot>
      </ul>
    </div>

    <MountingPortal v-if="forceShow" mountTo="body" append>
      <div class="dismiss" @click="$emit('toggle-force')"></div>
    </MountingPortal>

  </div>
</template>
<script>

import { MountingPortal } from "portal-vue";

export default {
  name: "Sidebar",
  components: {
    MountingPortal
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  props: {
    title: {
      type: String,
      default: "CPHub"
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        const acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: "/img/ScriptlyLogo.png"
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    callsHolding: {
      type: Number | null,
      default: null
    },
    longestHold: {
      type: Number | null,
      default: null
    },
    repsReady: {
      type: Number | null,
      default: null
    },
    phoneConnecting: {
      type: Boolean,
      default: false
    },
    phoneDisconnected: {
      type: Boolean,
      default: true
    },
    forceShow: Boolean
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  computed: {
    isMinimized() {
      return this.$sidebar.isMinimized;
    },
    formattedHold() {
      return `${Math.floor(this.longestHold / 60)}:${(this.longestHold % 60).toString().padStart(2, "0")}`;
    }
  },
  watch: {
    forceShow() {
      if (this.forceShow) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.removeProperty("overflow");
      }
    }
  }
};
</script>

<style scoped>
.sidebar {
  z-index: 200;
  transition: .3s;
}

@media screen and (max-width: 991px) {
  .sidebar {
    transform: translate3d(-300px, 0, 0);
  }
}

.sidebar.force {
  transform: translate3d(0px, 0, 0);
}

.dismiss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
  z-index: 150;
}

@media screen and (min-width: 990px) {
  .dismiss {
    display: none;
  }
}
</style>
