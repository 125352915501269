import HasID from "./HasID";
import HasLabel from "./HasLabel";

export interface DrugCategory extends HasID {
    name: string;
    notes: string;
    created: Date;
    createdBy: string;
    updated: Date;
    updatedBy: string;
}

export class DrugCategory extends HasLabel {
    constructor(obj?: DrugCategory) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (this.name)
            return `ID:${this.id} ${this.name}`;
        else return '';
    }
}
