
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from "axios";
import Navbar from "@/components/Navbar/Navbar.vue";
import NavbarToggleButton from "@/components/Navbar/NavbarToggleButton.vue";
import { UserProductivity } from "@/models/UserProductivity";
import { ProductivityStatsBase } from "@/models/ProductivityStatsBase";
import { PharmacistProductivity } from "@/models/PharmacistProductivity";
import { logoutCognito } from "@/util/cognito-auth";

@Component({
    name: 'TopNavbar',
    components: {
        Navbar,
        NavbarToggleButton,
        PriceTestModal: () => import('@/components/Drug/PriceTestModalComponent.vue'),
        NotificationsList: () => import("@/components/Notifications/NotificationsList.vue"),
        PatientRecentlyViewed: () => import("@/components/Patient/PatientRecentlyViewed.vue"),
    },
})
export default class TopNavbar extends Vue {
    private activeNotifications = false;
    private showNavbar = false;
    protected userProductivity = {};
    protected viewProductivity = false;
    protected errorCounter = 0;
    protected notificationCounter = 0;
    protected isLoading = false;
    protected showPriceTestModal = false;
    protected notificationShouldShake = false;
    protected showPatientRecentlyViewedModal = false;

    get routeName(): string {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
    }

    @Watch('routeName') changedRoute() {
        this.getProductivity();
    }

    created() {
        this.isLoading = true;
        this.getProductivity();
        if (localStorage.getItem("ViewProductivityStats") == null || localStorage.getItem("ProductivityStatsDate") != new Date().toLocaleDateString()) {
            localStorage.setItem("ViewProductivityStats", 'true');
            this.viewProductivity = true;
        }
        if (localStorage.getItem("ViewProductivityStats") == 'true') {
            this.viewProductivity = true;
        }

        this.$http.get('DispenseError/error-count')
            .then(res => {
                this.errorCounter = res?.data || 0;
            })
            .catch(err => {
                console.error('Error while pulling number of dispense errors assigned.', {
                    err,
                    response: err?.response,
                });
            });

        //Dispense Error Counter live connection setup
        this.$watch("$root.$data.dispenseErrorCount", () => {
            this.errorCounter = this.$root.$data.dispenseErrorCount;
        });

        this.$watch("$root.$data.notificationCount", () => {
            this.notificationCounter = this.$root.$data.notificationCount;
        });

        document.addEventListener("keyup", this.hotKeyListener);
    }

    hotKeyListener(e: any) {
        if (e.code === 'F4') this.showPatientRecentlyViewedModal = true;
    }

    @Watch('notificationCounter')
    notificationCountChange(newValue: number, oldValue: number) {
        if (newValue > oldValue) {
            this.notificationShouldShake = true;
            setTimeout(() => {
                this.notificationShouldShake = false;
            }, 800);
        }
    }

    capitalizeFirstLetter(str: string | null | undefined) {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
    }

    closeDropDown() {
        this.activeNotifications = false;
    }

    toggleNavbar() {
        this.showNavbar = !this.showNavbar;
    }

    toggleProductivity() {
        if (localStorage.getItem("ViewProductivityStats") == 'true') {
            localStorage.setItem("ViewProductivityStats", 'false');
            this.viewProductivity = false;
        } else {
            localStorage.setItem("ViewProductivityStats", 'true');
            this.viewProductivity = true;
        }
    }

    getProductivity() {
        axios.get<ProductivityStatsBase>('reports/GetProductivityReport')
            .then(response => {
                let stats = response.data;
                if (stats.isPharmacist)
                    this.userProductivity = Object.assign(new PharmacistProductivity(), stats);
                else
                    this.userProductivity = Object.assign(new UserProductivity(), stats);
            })
            .catch(err => {
                console.error('Error while getting productivity stats', { err, response: err?.response });
            })
            .finally(() => this.isLoading = false);
    }

    async logOut() {
        if (process.env.VUE_APP_USE_COGNITO == 'true') {
            logoutCognito();
        } else {
            await this.$msal.logout();
        }
        this.$router.push("/login");
    }
};
