import { render, staticRenderFns } from "./CasesListComponent.vue?vue&type=template&id=050bd57a&scoped=true"
import script from "./CasesListComponent.vue?vue&type=script&lang=ts"
export * from "./CasesListComponent.vue?vue&type=script&lang=ts"
import style0 from "./CasesListComponent.vue?vue&type=style&index=0&id=050bd57a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050bd57a",
  null
  
)

export default component.exports