import { Plan } from "./Plan";
import { PatientPlan, RelationshipCode } from "./PatientPlan";
import { PatientInsuranceUpdateBase } from "./PatientInsuranceUpdateBase";

export interface InsuranceUpdateRequest extends PatientInsuranceUpdateBase {
    programID        : number
    planName         : string
    bin              : string
    pcn              : string
    providerType     : Plan.ProviderType
    groupId          : string
    cardholderId     : string
    personCode       : string
    relationshipCode : RelationshipCode
    comments         : string
    programName      : string
}

export class InsuranceUpdateRequest{
    constructor(obj?:InsuranceUpdateRequest){
        this.providerType = Plan.ProviderType.Unknown;
        this.relationshipCode = RelationshipCode.NotSpecified;

        if(obj) Object.assign(this, obj);
    }

    get providerTypeDesc():string {
        return Plan.ProviderTypeToString(this.providerType);
    }
    get relationshipDesc():string {
        return PatientPlan.RelationshipToString(this.relationshipCode);
    }
}