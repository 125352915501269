
import { Component, Mixins, Prop, Ref, Watch, Vue } from "vue-property-decorator";
import axios, { AxiosError } from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Language, Patient, PatientProgram, ResidenceCode } from "@/models/Patient";
import EnumSelector from "@/components/EnumSelector.vue";
import NameSearchComponent from "@/components/NameSearchComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { EmergencyContact } from "@/models/EmergencyContact";
import Button from "../Button.vue";
import PatientFormComponent from "./PatientFormComponent.vue";
    import NotificationTypes = NotificationOptions.NotificationTypes;

@Component({
    name: "EmergencyContactList",
    components: {
        NameSearchComponent,
        SearchComponent,
        EnumSelector,
        Checkbox,
        PatientFormComponent,
    },
})
export default class EmergencyContactList extends Vue {
    @Prop() private patient: any;
    @Prop({ type: Boolean, default: false }) private getByContactMode!: boolean;
    private hipaaReleased: boolean = false;
    private emergencyContacts: EmergencyContact[] = [];
    private newContact: Patient = new Patient();
    private showPatientForm: boolean = false;

    cardTitle() {
        return this.getByContactMode ? "Linked Patients" : "Emergency Contacts";
    }

    created() {
        console.log("%c Created Emergency Contact Component", "color: green");
        this.fetchPatient();
    }

    contactUpdate() {
        this.showPatientForm = false;
    }

    onPatientCreated(newPatient: Patient) {
        this.newContact = newPatient;
    }

    removeContact(contact: EmergencyContact) {
        contact.active = false;
        axios.post("/Patient/EmergencyContact", contact).finally(this.fetchPatient);
    }

    saveContact() {
        if (this.newContact.id) {
            this.finalSave();
        } else {
            axios
                .post<{ patient: Patient; warning: string }>("/Patient", this.newContact)
                .then((response) => {
                    this.newContact = Object.assign(new Patient(), response.data.patient);
                    this.finalSave();
                })
                .catch((error: AxiosError) => {
                    const errorMsg = `Error while saving new patient. ${error?.response?.data}`;
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            errorMsg,
                            NotificationTypes.danger
                        )
                    );
                    console.error(errorMsg, { error, response: error?.response });
                });
        }
    }

    finalSave(){
        var data = new EmergencyContact();
        data.patientId = this.patient.id;
        data.contactPatientId = +this.newContact.id;
data.active = true;
data.hipaaAuthorized = this.hipaaReleased;
        axios.post("/Patient/EmergencyContact", data).then(response => {
            this.$bvModal.hide("addContact" + this.$attrs.id);
            this.newContact = new Patient();
            this.hipaaReleased = false;
            this.fetchPatient();
        }).catch((error: AxiosError) => {
                    const errorMsg = `Error while saving new patient. ${error?.response?.data}`;
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            errorMsg,
                            NotificationTypes.danger
                        )
                    );
                    console.error(errorMsg, { error, response: error?.response });
                });
    }

    @Watch("patient")
    fetchPatient() {
        if (!this.patient?.id) {
            return;
        }
        var url = `/Patient/EmergencyContact/${this.patient?.id}`;
        if (this.getByContactMode) {
            url += "/Patients";
        }

        axios
            .get(url)
            .then((response) => {
                this.emergencyContacts = response.data.map((d: any) => new EmergencyContact(d));
            })
            .catch((error) => {
                console.error("Error while loading the Emergency Contacts", {
                    error,
                    response: error?.response,
                });
            });
    }
}
