

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Note } from '@/models/Note';
import axios, { AxiosError } from 'axios';
import { EntityType } from "@/models/EntityType";
import NotesListItem from "./NotesListItem.vue";
import NotesForm from "./NotesForm.vue";
import moment from "moment";

@Component({
    name: "NotesList",
    components: {
        NotesListItem,
        NotesForm
    }
})
export default class NotesList extends Vue {
    @Prop({ default: 0 }) private entityId!: number;
    @Prop({ default: 0 }) private entityType!: EntityType;

    @Prop({ default: 1 }) private pageSize!: number;
    @Prop({ default: true }) private paging!: boolean;
    @Prop({ default: "Notes not found" }) private emptyText!: string;

    /// Only send notes as props if you dont want this component to load them by EntityId and EntityName
    @Prop() private notes!: Array<Note>;

    private loadedNotes: Array<Note> = [];
    private pageNbr: number = 0;
    private verboseByline = false;
    private switchingPages = false;
    private replyForNote: Note | null = null;
    private showAllChildren: Array<number> = [];
    private replyLimit = 2;

    created() {
        if (!this.notes)
            this.loadNotes();
    }

    loadMore() {
        this.pageNbr++;
        this.switchingPages = true;
        this.loadNotes();
    }

    loadPrevious() {
        this.pageNbr--;
        this.switchingPages = true;
        this.loadNotes();
    }

    async loadNotes() {
        const url = `/Note/${this.entityId}/${this.entityType}`;
        const getParams = this.pagingEnabled ? { pageSize: this.pageSize, pageNbr: this.pageNbr } : {};

        try {
            const response = await axios.get(url, { params: getParams })
            this.loadedNotes = response.data;
        } catch (error) {
            console.error("Error while loading notes", { error, response: (error as AxiosError)?.response });
        } finally {
            this.$nextTick(() => {
                this.switchingPages = false;
            })
        }
    }

    @Watch('entityId') IdChanged() {
        this.pageNbr = 0;
        this.loadNotes();
    }

    get notesToShow() {
        return this.notes || this.loadedNotes;
    }

    get pagingEnabled() {
        return this.paging && !(this.notes && this.notes.length);
    }

    get viewableNotes() {
        // @ts-ignore
        const sortedNotes = this.notesToShow.filter(n => n.canView).toSorted((a, b) => moment(b.created).diff(moment(a.created)));

        return [
            ...sortedNotes.filter((n: Note) => n.pinned),
            ...sortedNotes.filter((n: Note) => !n.pinned)
        ];
    }

    get parentNotes() {
        return this.viewableNotes.filter(n => !n.parentNoteId);
    }

    get totalNotesCount() {
        return this.notesToShow?.length ?? 0;
    }

    get viewableNotesCount() {
        return this.viewableNotes?.length ?? 0;
    }

    get hidingCount() {
        return this.totalNotesCount - this.viewableNotesCount;
    }

    getChildNotes(note: Note) {
        return this.viewableNotes.filter(n => n.parentNoteId === note.id);
    }

    getChildNotesToDisplay(note: Note) {
        const childNotes = this.getChildNotes(note);
        if (this.showAllChildren.includes(note.id as number)) return childNotes;
        return childNotes.slice(0, this.replyLimit);
    }

    async togglePin(note: Note) {
        try {
            const url = `/Note/toggle-pin/${note.id}`;
            await axios.post(url);

            this.pageNbr = 0;

            this.loadNotes();
        } catch {

        }
    }

    reply(note: Note) {
        if (this.replyForNote === note) {
            this.replyForNote = null;
            return;
        }
        this.showAllReplies(note);
        this.replyForNote = note;
    }

    showAllReplies(note: Note) {
        this.showAllChildren.push(note.id as number);
    }

    shouldShowMoreReplies(note: Note) {
        return this.getChildNotes(note)?.length > this.replyLimit &&
            !this.showAllChildren.includes(note.id as number);
    }

    additionalReplyCount(note: Note) {
        return this.getChildNotes(note)?.length - this.replyLimit;
    }
}
