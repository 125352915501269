
import { Drug } from '@/models/Drug/Drug';
import { DrugProduct } from '@/models/Drug/DrugProduct';
import { PriceSchedule } from '@/models/PriceSchedule';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import QuantityPricingComponent from "@/components/Drug/QuantityPricingComponent.vue";

const lastPriceScheduleIdStorageKey = "price-test-selected-schedule-id";

@Component({
    name: "PriceTestModalComponent",
    components: {
        SearchComponent,
        QuantityPricingComponent
    }
})
export default class PriceTestModalComponent extends Vue {

    @Prop({ type: PriceSchedule }) protected priceSchedule!: PriceSchedule;
    @Prop({ required: false }) onAdd?: Function;
    @Prop({ default: true }) protected modal: boolean = true;

    showSchedulePicker = false;
    selectedPriceSchedule = new PriceSchedule();

    drug: DrugProduct = new Drug();
    quantity: number = 0;

    priceData?: any = {};
    error?: string = "";
    loaded: boolean = false;

    mounted() {
        this.$bvModal.show("price-test-modal");
        if (!this.priceSchedule) {
            const lastPriceScheduleId = localStorage.getItem(lastPriceScheduleIdStorageKey);
            if (lastPriceScheduleId) {
                this.selectedPriceSchedule.id = Number(lastPriceScheduleId);
            }
            this.showSchedulePicker = true;
        }
    }

    get scheduleToUse() {
        return this.priceSchedule ? this.priceSchedule : this.selectedPriceSchedule;
    }

    async getPrice() {
        if (!this.drug.id) {
            this.priceData = undefined;
            this.error = undefined;
            return;
        }

        try {
            const params: any = {
                priceScheduleId: this.scheduleToUse.id,
                drugId: this.drug.id,
                quantity: this.quantity,
                drugSource: this.drug.source,
            }

            const config = {
                params: params
            } as AxiosRequestConfig;

            const priceData = (await axios.get(`/api/PriceSchedule/price-preview`, config)).data;
            this.priceData = priceData;
            this.priceData.unitPrice = priceData.totalPrice / params.quantity;
            this.priceData.quantity = params.quantity;
            this.error = "";
            this.loaded = true;
        } catch (err) {
            this.error = (err as AxiosError)?.response?.data.message ?? "There was an unknown error";
        }
    }

    get formIsValid() {
        return this.quantity > 0 && this.drug?.id;
    }

    @Watch("selectedPriceSchedule")
    setLastSelectedPriceSchedule() {
        localStorage.setItem(lastPriceScheduleIdStorageKey, this.selectedPriceSchedule.id.toString());
    }

}

