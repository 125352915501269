import HasID from "./HasID";

export enum ExternalPatientOrderSource {
    NotSpecified,
    PatientWeb,
    PatientApp,
    ExternalApi,
    Online
}

export function sortOrders(a: ExternalPatientOrder, b: ExternalPatientOrder): number {
    // Debug for a specific local order to always show on top.
    if (a.id == 36007) return -1;
    if (b.id == 36007) return 1;

    // sort by create date first (Descending)
    // if create dates are equal then sort between order source (Ascending)
    // if order source and create dates are equal then sort based on id (Descending)
    return b.created.valueOf() - a.created.valueOf() || a.source - b.source || b.id - a.id;
}

export interface ExternalPatientOrder extends HasID {
    id: number;
    storeId: number;
    patientId: number;
    requiresConsult?: boolean;
    drugs: string;
    created: Date;
    processed?: Date;
    processedBy?: string;
    attestationLanguage?: string;
    patientName?: string;
    source: ExternalPatientOrderSource;
    address1: string,
    address2: string | null,
    addressCity: string,
    addressState: string,
    addressZip: string,
    newAllergies: boolean,
    requestingConsult: boolean,
    transactionId: string,
    charged: Date,
    signatureData: string,
}

export class ExternalPatientOrder {
    constructor(obj?: ExternalPatientOrder) {
        if (obj) {
            Object.assign(this, obj);
            if (obj.created) this.created = new Date(obj.created);
            if (obj.processed) this.processed = new Date(obj.processed);
            if (obj.charged) this.charged = new Date(obj.charged);
        }
    }
}

export interface PatientWebOrder extends ExternalPatientOrder {
    patStoreId: number, //should always be 1
    patKey: number,
    addressChange: boolean,
    allergiesNote: string,
    paymentResultId: number,
    processorResponseCode: number,
    processorResponseText: string,
    amount: number,
    discrepancyReason: number,
    isRefill: boolean,
    emailAddress: string,
    missingAllergies: boolean,
    patientWebPrescriptions: PatientWebPrescription[]
    patientWebAddons: PatientWebAddon[]
}

export interface PatientWebPrescription extends HasID {
    storeId: number,
    rxNbr: number,
    rfNbr: number,
    drugName: string,
    needsRefrigeration: boolean,
    amount: number,
    orderId: number,
    programName: string
}

export interface PatientWebAddon extends HasID {
    productId: string,
    productName: string,
    quantity: number,
    productAmount: number,
    taxAmount: number,
    orderId: number
}
