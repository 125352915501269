import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { Allergy } from "@/models/Allergy";
import { Store } from "@/models/Store";

export interface EscriptData {
    patient: Patient;
    prescriber: Prescriber;
    prescribedMed: MedicationAbstractData;
    allergies: Array<Allergy>;
    store: Store;
}

export class EscriptData implements EscriptData {
    constructor(obj?: EscriptData) {
        if (obj) Object.assign(this, obj);
    }
}

export interface MedicationAbstractData {
    drugDescription: string;
    quantityValue: number | null;
    quantityCodeQualifier: string;
    quantityUnitOfMeasureCode: string;
    daysSupply: number | null;
    substitutions: number;

    numberOfRefills: number | null;

    diagnosisClinicalInformationQualifier: string;
    diagnosisPrimaryCode: string;
    diagnosisPrimaryQualifier: string;
    diagnosisPrimaryDescription: string;
    diagnosisSecondaryCode: string;
    diagnosisSecondaryQualifier: string;
    diagnosisSecondaryDescription: string;

    note: string;
    drugCoverageStatusCode: string;
    sigText: string;
    sigSNOMEDVersion: string;
    sigFMTVersion: string;

    doseDeliveryText: string;
    doseDeliveryQualifier: string;
    doseDeliveryCode: string;

    dosageDoseQuantity: string;
    dosageDoseUnitOfMeasureText: string;
    dosageDoseUnitOfMeasureQualifier: string;
    dosageDoseUnitOfMeasureCode: string;

    routeOfAdministrationText: string;
    routeOfAdministrationQualifier: string;
    routeOfAdministrationCode: string;

    intervalNumericValue: string;
    intervalUnitsText: string;
    intervalUnitsQualifier: string;
    intervalUnitsCode: string;

    writtenDate: Date | null;

    compoundFinalPharmaceuticalDosageForm: string;

    ndc11: string;
    allowedNdcList: string;
}
