
import { CaseNote, CaseDetail, types as CaseTypes } from '@/models/Case';
import { Prescription } from '@/models/Prescription';
import { Note } from '@/models/Note';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Table, TableColumn } from 'element-ui';
// @ts-ignore
import { Radio } from '@/components/index';
import NotesList from '@/components/Note/NotesList.vue';
import axios, { AxiosError } from 'axios';
import { EntityType } from "@/models/EntityType";
import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import { Shipment } from "@/models/Shipment";
import moment from "moment";
import { Lock, LockHandler } from '@/mixins/LockHandler';

@Component({
    name: "CaseDetailComponent",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Radio.name]: Radio,
        NotesList
    }
})
export default class CaseDetailComponent extends Mixins(LockHandler) {

    @Prop() private caseSelected!: CaseDetail;
    @Prop() private show?: string | null;
    @Prop() private editable!: boolean;

    private prescriptions: Prescription[] = [];
    private isResolved = 'false';
    private carePointsError = 'false';
    private option = 'accept';
    private newNote = '';
    private editingType = false;
    private selectedProblem = '';

    private caseType = EntityType.Case;

    created() {
        this.getCaseDetail();
    }

    get mode() {
        if (this.show) return this.show;

        if (this.caseSelected.accepted) return "completed";
        if (this.caseSelected.resolved) return "resolved";
        return "opened";
    }

    private shipmentFields: BvTableFieldArray = [
        { key: "shipped", formatter: (d: any) => moment(d).format("L"), },
        { key: "shipmentType", },
        { key: "trackingNumber", label: "Tracking", },
    ];

    async shipmentTableProvider(ctx: BvTableCtxObject): Promise<Shipment[]> {
        const uri = ctx.apiUrl!;
        try {
            const resp = await axios.get<Shipment[]>(uri);
            return resp.data.map(s => new Shipment(s));
        } catch (err) {
            return [];
        }
    }

    protected problems = CaseTypes;
    protected caseLock: Lock | null = null;

    get disableFields(): boolean {
        const lockedForUser = !this.caseLock;
        return lockedForUser;
    }

    @Watch('caseSelected')
    async getCaseDetail() {
        if (!this.caseSelected.id) {
            this.caseLock = null;
            return;
        }

        try {
            const lockResponse = await this.addLockWithURL(`/Case/${this.caseSelected.id}/lock`, 60000);
            this.caseLock = lockResponse;
        } catch (err) {
            const error = err as AxiosError;
            console.log(`Added lock -- error == ${error}`);
            if (error.response && error.response.status == 418) {
                // case locked
                console.log(error.response.data);
                const lockData = error.response.data;
                const lockedBy = lockData.lockedBy;
                const expires = lockData.expires;
                this.$bvModal.msgBoxOk(`The Case is locked by ${lockedBy} until ${expires}.`);
            }
        }

        this.carePointsError = this.caseSelected.error ? 'true' : 'false';
        this.isResolved = 'false';
        this.newNote = '';
        this.option = 'reject';
        this.editingType = false;
        this.selectedProblem = this.caseSelected.problem;

        try {
            const response = await axios.get(`/Case/${this.caseSelected.id}/Prescriptions`);
            this.prescriptions = response.data;
        } catch (error) {
            console.warn(error);
        }
    }

    updateCase() {
        if (!this.editable) return;

        const newCaseNote: CaseNote | any = {
            id: this.caseSelected.id,
            patientId: this.caseSelected.patientId,
            problem: this.selectedProblem,
            note: this.newNote,
            error: this.carePointsError == 'true'
        }

        if (this.mode == 'opened') {
            newCaseNote.resolvedBy = this.isResolved;
            newCaseNote.acceptedBy = 'false';
        }
        else {
            newCaseNote.resolvedBy = this.option == 'accept' ? 'true' : 'false';
            newCaseNote.acceptedBy = this.option == 'accept' ? 'true' : 'false';
        }

        this.caseSelected.error = newCaseNote.error;
        this.caseSelected.resolved = newCaseNote.resolved;
        axios.post('/Case/', {
            caseProblem: newCaseNote,
            prescriptions: []
        })
            .then(response => {
                this.newNote = '';
                this.isResolved = 'false';
                this.$emit('updateCase');
            })
            .catch(error => {
                console.warn(error);
            });
    }

    get modalTitle() {
        if (this.caseSelected?.id) return `Case Details (ID: ${this.caseSelected.id})`;
        return 'Case Details';
    }

    async hideModal() {
        this.$emit('hidden');
        if (this.caseLock?.refreshURL) {
            this.releaseLockAtURL(this.caseLock.refreshURL);
        } 
    }
}
