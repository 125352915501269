
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator';
    import Axios, { AxiosResponse } from 'axios';
    import moment from 'moment';
    import { Consignment } from '@/models/Consignment'

    @Component({
        name: "ConsignmentQueuePage",
        components: {
        },
    })

    export default class ConsignmentQueuePage extends Vue {

        private consignments: Array<Consignment> = [];

        created() {
            this.loadConsignments()
        }

        loadConsignments() {
            Axios.get(`/Consignment/GetIncomplete`)
                .then(response => {
                    this.consignments = response.data
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        updateConsignmentId(consignment: Consignment) {
            let data = consignment
            console.log(data);
            Axios.post('/Consignment/UpdateConsignmentId', data)
                .then(response => {
                    this.loadConsignments();
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        cancelConsignmentId(consignment: Consignment) {
            let data = consignment
            console.log(data);
            Axios.post('/Consignment/DeleteConsignmentId', data)
                .then(response => {
                    this.loadConsignments();
                })
                .catch(error => {
                    console.warn(error);
                });
        }



        convertDate(date:string) {
            return moment.utc(date).format("L");
        }
        
    }
        
