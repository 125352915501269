
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className: string) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
//import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { Component, Vue, Watch } from "vue-property-decorator";
import { PhoneStats } from "@/models/PhoneStats";

import SideBar from "@/components/SidebarPlugin/SideBar.vue";
import SidebarItem from "@/components/SidebarPlugin/SidebarItem.vue";

@Component({
    components: {
        TopNavbar,
        ContentFooter,
        //UserMenu,
        ZoomCenterTransition,
        SideBar,
        SidebarItem,
    },
})
export default class Dashboard extends Vue {

    protected navQuery?: string = "";
    protected forceShowSidebar: boolean = false;

    mounted() {
        const docClasses = document.body.classList;
        const isWindows = navigator.platform.startsWith("Win");
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar("sidebar");
            initScrollbar("sidebar-wrapper");

            docClasses.add("perfect-scrollbar-on");
        } else {
            docClasses.add("perfect-scrollbar-off");
        }

        this.favoriteItems = JSON.parse(window.localStorage.getItem("nav-favorites") ?? "[]");
    }

    @Watch("$root.data.dataHubState") onConnectionStatusChange() {
        console.log("dataHubStatus = " + this.$root.$data.dataHubState);
    }

    get disconnected(): boolean {
        return this.$root.$data.dataHubState == "Disconnected" || this.$root.$data.dataHubState == "Closed";
    }

    get reconnecting(): boolean {
        return this.$root.$data.dataHubState == "Connecting" || this.$root.$data.dataHubState == "Reconnecting";
    }

    get res(): PhoneStats {
        return this.$root.$data.dataHubPhoneStats;
    }

    //Temporary solution to disable sidebar colors.
    get bgColor(): string {
        if ((this.res.longestHold ?? 0) >= 30) {
            return "black";
            //return "red";
        } else if ((this.res.longestHold ?? 0) >= 15) {
            return "black";
            //return "orange";
        } else {
            return "black";
        }
    }

    toggleSidebar() {
        if ((this.$refs?.sidebar as any)?.showSidebar) {
            (this.$refs!.sidebar as any).displaySidebar(false);
        }
    }

    private sidebarItems = [
        { name: 'Dashboard', icon: 'now-ui-icons design_app', path: '/dashboard' },
        { name: 'Analytics', icon: 'now-ui-icons business_chart-bar-32', path: '/adhoc-reporting' },
        {
            name: 'Customer Service', icon: 'fas fa-headset', children: [
                { name: 'Patient', path: '/processing/patient-dashboard' },
                { name: 'Doctor', path: '/processing/doctor-dashboard', keywords: 'dr prescriber' },
                { name: 'Drugs', path: '/processing/drug-dashboard' },
                { name: 'Programs', path: '/processing/program-dashboard' }
            ]
        },
        {
            name: 'Pharmacists', icon: 'fas fa-notes-medical', children: [
                { name: 'Consultations', path: '/maintenance/consultation' },
                { name: 'Rx Transfers', path: '/processing/RxTransferQueue' },
                { name: 'Corrected Errors', path: '/processing/pending-errors', keywords: 'dispense to approve pending' },
                { name: 'Verification Queue', path: '/processing/verification-queue' },
                { name: 'PV1 Queue', path: '/processing/pv1-queue' }
            ]
        },
        {
            name: 'Dispensing', icon: 'fas fa-print', children: [
                { name: 'Print Queue', path: '/processing/print-queue' },
                { name: 'Stale Print Queue', path: '/processing/stale-print-queue' },
                { name: 'Pickup Queue', path: '/processing/pickup-queue' },
                { name: 'Verified Not Shipped', path: '/processing/verified-not-shipped-queue' }
            ]
        },
        {
            name: 'Processing', icon: 'fas fa-file-prescription', children: [
                { name: 'Rx Intake Queue', path: '/processing/ingestion', keywords: 'scanning ingestion' },
                { name: 'On Hold Report', path: '/processing/on-hold-queue' },
                { name: 'Patient Insurance', path: '/processing/patient-insurance-queue' },
                { name: 'Patient Allergy Updates', path: '/processing/patient-web-allergy-update-queue' },
                { name: 'Online Order Queue', path: '/processing/online-order-queue' },
                { name: 'Partial Payment Queue', path: '/processing/partial-payment-queue' },
                { name: 'Autorefill Queue', path: '/processing/autorefill-queue' },
                { name: 'Pending Billing Queue', path: '/processing/pending-billing-prescriptions-queue' },
                { name: 'Reverse Queue', path: '/processing/reversal-queue' },
                { name: 'Rx Errors', path: '/processing/dispense-errors', keywords: "dispense" },
                { name: 'Doctor Follow-Ups', path: '/processing/doctor-followup', keywords: "dr queue prescriber" },
                { name: 'Return To Sender', path: '/processing/return-to-sender' },
                { name: 'Patient Assistance Program Queue', path: '/processing/pap-queue', keywords: "pap" },
                { name: 'Order Audit Queue', path: '/processing/order-audit-queue' },
                { name: 'Active Queue', path: '/processing/active-script-queue' },
                { name: 'Failed Faxes', path: '/maintenance/faxes/failed', },
                { name: 'Expired on Order', path: '/processing/expired-on-order' }
            ]
        },
        {
            name: 'Compliance', icon: 'fas fa-exclamation-triangle', children: [
                { name: 'Audit Tracking', path: '/processing/audit-tracking' },
                { name: 'Opened Cases', path: '/maintenance/case/opened' },
                { name: 'Resolved Cases', path: '/maintenance/case/resolved' },
                { name: 'Completed Cases', path: '/maintenance/case/completed', keywords: "accepted" },
                { name: 'Rx Audit Verification', path: '/processing/rx-audit' }
            ]
        },
        {
            name: 'Services', icon: 'fas fa-concierge-bell', children: [
                { name: 'Prior Authorization', path: '/processing/prior-authorizations' },
                { name: 'Electronic PA', path: '/processing/electronic-prior-authorizations' },
                { name: 'Consignment Queue', path: '/processing/consignment-queue' },
                { name: 'Incomplete Answers Queue', path: '/processing/incomplete-answers-queue' },
                { name: 'Voicemails', path: '/processing/voicemails-queue' }
            ]
        },
        {
            name: 'Purchasing', icon: 'fas fa-dolly', children: [
                { name: 'EDI Ordering', path: '/processing/edi-ordering' },
                { name: 'Inventory Batch Queue', path: '/processing/inventory-batch-queue' },
                { name: 'Drug Shortage Report', path: '/reports/drug-shortage-report' },
                { name: 'Surplus', path: '/reports/surplus' },
                { name: 'Inventory PAR Levels', path: '/maintenance/inventory-par', keywords: "alert" }
            ]
        },
        {
            name: 'Reports', icon: 'fas fa-chart-bar', children: [
                { name: 'Call Log', path: '/processing/calllog' },
                { name: 'History', path: '/reports/history' },
                { name: 'Access', path: '/reports/access' },
                { name: 'Reports', path: '/reports/reports' },
                { name: 'Inventory', path: '/reports/inventory' },
                { name: 'Dispensed', path: '/reports/dispensed' },
                { name: 'PMP Status', path: '/reports/pmpstatus' },
                { name: 'Drug Shortage Report', path: '/reports/drug-shortage-report' },
                { name: 'Surplus', path: '/reports/surplus' },
                { name: 'Transferred RX Report', path: '/reports/transferredRx' },
                { name: 'Notifications', path: '/reports/notifications' }
            ]
        },
        {
            name: 'Maintenance', icon: 'fas fa-tools', children: [
                { name: 'Patient', path: '/maintenance/patient' },
                { name: 'Doctor', path: '/maintenance/doctor', keywords: 'dr prescriber' },
                { name: 'Vendor', path: '/maintenance/vendor' },
                { name: 'Store', path: '/maintenance/store' },
                { name: 'Drug', path: '/maintenance/drug' },
                { name: 'NonGSDD Drug', path: '/maintenance/non-gsdd-drug' },
                { name: 'Compound Drug', path: '/maintenance/compound-drug' },
                { name: 'Merge Doctor', path: '/maintenance/merge-doctor' },
                { name: 'Merge Patients', path: '/maintenance/merge-patients' },
                { name: 'Merge Patients Queue', path: '/maintenance/merge-patients-queue' },
                { name: 'Refill Request Batch', path: '/maintenance/refill-request-batch' },
                { name: 'Stored Pharmacies', path: '/maintenance/stored-pharmacy' },
                { name: 'Acceptance Configuration', path: '/maintenance/acceptance-configuration' },
                { name: 'Price Schedule', path: '/maintenance/price-schedule' },
                { name: 'Periodic Inventory', path: '/maintenance/periodic-inventories' },
                { name: 'Inventory Locations', path: '/maintenance/inventory-locations' }
            ]
        },
        {
            name: 'Admin', icon: 'fas fa-user-cog', children: [
                { name: 'Prescription', path: '/processing/prescription' },
                { name: 'Category', path: '/maintenance/category' },
                { name: 'Program', path: '/maintenance/program' },
                { name: 'OTC Items', path: '/maintenance/otc-item' },
                { name: 'Pharmacy Benefit Manager', path: '/maintenance/pharmacyBenefitManager' },
                { name: 'Plan', path: '/maintenance/plan' },
                { name: 'Users', path: '/maintenance/users' },
                { name: 'Sig Translations', path: '/maintenance/sig-maintenance' },
                { name: 'Dashboard Config', path: '/maintenance/dashboard-configuration' },
                { name: 'PA Autosend Config', path: '/maintenance/pa-autosend-config' },
                { name: 'Provider Web Management', path: '/maintenance/provider-web' },
                { name: 'Configuration', path: '/maintenance/configuration' },
                { name: 'Label Printers', path: '/maintenance/label-printers' },
            ],
        },
    ];

    get allSidebarItemChildren() {
        return this.sidebarItems.map(i => i.children).flat();
    }

    get allSidebarItems() {

        if (this.favoriteItems === false) return this.sidebarItems;

        const favorites = this.favoriteItems.map((fi: any) => {
            const path = fi.path ? fi.path : fi;

            const cat = this.sidebarItems.find(sc => sc.children?.find(c => c.path === path));
            const navItem = cat?.children?.find(i => i.path === path);

            const hasMultipleSameName = this.allSidebarItemChildren.filter(i => i?.name?.toLowerCase() === navItem?.name?.toLowerCase()).length > 1;

            return {
                ...navItem,
                subtitle: hasMultipleSameName ? cat?.name : undefined,
            };
        });

        return [{ name: "Favorites", children: favorites, icon: "fas fa-heart" }, ...this.sidebarItems];
    }

    @Watch("navQuery")
    resetSidebarScrollPosition() {
        const sidebar = (this.$refs?.sidebar as any)?.$el.querySelector('.sidebar-wrapper');
        if (!sidebar) return;
        sidebar.scrollTop = 0;
    }

    get filteredSidebarItems() {
        if (!this.navQuery) return this.allSidebarItems;

        const filteredItems: any = [];

        this.allSidebarItems.forEach(i => {
            if (this.menuItemHasFilterMatch(i)) {
                filteredItems.push(i);
                return;
            }

            const filteredChildren = i.children?.filter((ci: any) => this.menuItemHasFilterMatch(ci, i));

            if (!filteredChildren?.length) return;

            filteredItems.push({ ...i, children: filteredChildren });
        });

        return filteredItems;
    }

    get navQueryTerms() {
        return this.navQuery?.toLowerCase().split(" ");
    }

    private menuItemHasFilterMatch(item: any, parent?: any) {
        return this.navQueryTerms?.every(t => (item.name + parent?.name + item.keywords + item.name?.match(/\b(\w)/g).join('')).toLowerCase().includes(t));
    }

    protected favoriteItems: any = [];

    updateNavFavorites(favorites: any) {
        this.favoriteItems = favorites;
    }

    favoritesToFalse() {
        this.favoriteItems = false;
        window.localStorage.setItem("nav-favorites", JSON.stringify(false));
    }

    navFilterSubmit() {
        if (!this.navQuery?.trim()) return;
        const first = this.filteredSidebarItems[0];
        if (first.children?.length) {
            this.$router.push(first.children[0].path);
        } else if (first.path) {
            this.$router.push(first.path);
        }
    }
}
