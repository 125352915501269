
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { NonGSDDDrug } from '@/models/Drug/NonGSDDDrug';
    import { Drug, DrugStatus } from '@/models/Drug/Drug';
    import { DrugView } from '@/models/Drug/DrugView';
    import { PackagePrice } from '@/models/GSDD';
    import Axios from "axios";

    @Component({
        name: "GSDDMergeModalComponent",
        components: {}
    })

    export default class GSDDMergeModalComponent extends Vue {
        @Prop() gsddDrugMatch!: Array<DrugView>;
        @Prop() sourceDrug!: NonGSDDDrug;
        readonly labelCols: number | undefined;
        drugSelected: boolean = false;
        protected gsddMatchDisplay: Array<any> = []; //this.gsddDrugMatch.forEach(function(element) {return  element.toString()});
        private gsddDropDownLabel: string = "Comparable GSDD Drugs - Same NDC, PackageSize, UnitOfMeasure";
        private gsddMatchDrugIdSelected: number | null = null;
        private sourceStatus: string | null = DrugStatus.DrugStatusEnum[this.sourceDrug.activeStatus];
        private sourceTitle: string = "Source NonGSDD";
        private targetTitle: string = "Target GSDD";
        private targetDrug: Drug | null = new Drug();
        private targetStatus: number | null = null;
        private isLoading: boolean = false;

        get awp(): any {
            if (!this.sourceDrug || !this.sourceDrug.awp) return ``;
            return `${this.sourceDrug.awp}`;
        }

        get packageSize(): any {
            if (!this.sourceDrug || !this.sourceDrug.packageSize) return ``;
            return `${this.sourceDrug.packageSize}`;
        }

        get unitOfMeasure(): any {
            if (!this.sourceDrug) return ``;
            return this.sourceDrug.unitOfMeasure;
        }

        get minimumDispenseQuantity(): any {
            if (!this.sourceDrug || !this.sourceDrug.minimumDispenseQuantity) return ``;
            return `${this.sourceDrug.minimumDispenseQuantity}`;
        }

        get deaClass(): any {
            if (!this.sourceDrug) return ``;
            return this.sourceDrug.deaClass;
        }

        get disableFields(): boolean {
            return false
        }

        get marketer(): any {
            if (!this.targetDrug) return ``;
            return this.targetDrug.marketer;
        }

        get packageDescription(): any {
            if (!this.targetDrug) return ``;
            return this.targetDrug.packageDescription;
        }

        get doseForm(): any {
            if (!this.targetDrug) return ``;
            return this.targetDrug.doseForm;
        }

        get targetDeaClass(): any {
            if (!this.targetDrug) return ``;
            return this.targetDrug.deaClass;
        }

        get targetPackageSize(): any {
            if (!this.targetDrug || !this.targetDrug.packageSize || !this.targetDrug.billingUnit) return ``;
            return `${this.targetDrug.packageSize} ${this.targetDrug.billingUnit}`;
        }

        // from Price
        get drugPrice(): any {
            if (!this.targetDrug || !this.targetDrug.prices) return ``;
            const filteredPrices = this.targetDrug.prices as PackagePrice[];

            // 1  -- Average Wholesale Price
            // 13 -- Calculated Average Wholesale Price
            // 2  -- Wholesale Acquisition Cost
            const awp = filteredPrices.find(p => p.priceTypeID == 1 || p.priceTypeID == 13);
            const wac = filteredPrices.find(p => p.priceTypeID == 2);

            const awpString = awp && awp.unitPrice ? `AWP: $${awp.unitPrice}/${awp.ncpdpBillingUnit}` : ``;
            const wacString = wac && wac.unitPrice ? `WAC: $${wac.unitPrice}/${wac.ncpdpBillingUnit}` : ``;

            return `${awpString} ${wacString}`;
        }

        created() {
            this.gsddDrugMatch.forEach(e => {
                this.gsddMatchDisplay.push(
                    { id: e.drugId, name: "PackageId: " + e.drugId.toString() + ", DrugName: " + e.drugName })
            });
        }

        clearClicked() {
            this.drugSelected = false;
            this.targetDrug = new Drug();
            return;
        }

        async onSaveClicked() {
            let continueSaveMerge = await this.confirmSaveMerge();
            if (!continueSaveMerge) return;
            let sc: number = 0;
            await this.dbMerge(this.sourceDrug.productID!.toString(), this.gsddMatchDrugIdSelected!.toString())
                .then(value => {
                    sc = Number(value);
                });
            if (sc != 200) {
                await this.$bvModal.msgBoxOk(`Database error occurred while attempting to save GSDD Merge`);
                return;
            }
            this.$emit('merge');
        }

        onCancelClicked() {
            this.$emit('cancel');
        }

        onDrugSelected() {
            this.drugSelected = true;
            const PackageID = this.gsddMatchDrugIdSelected;
            const packageId = Number(PackageID || 0);
            this.targetDrug = new Drug();
            this.fetchDrug(packageId);
        }

        fetchDrug(packageID: number) {
            if (!packageID) return;

            Axios.get<Drug>(`/Drug/${packageID}`, { params: { getCategories: true, getVendors: true } })
                .then(response => {
                    const drug: Drug = response.data;
                    this.targetDrug = new Drug(null, drug);
                    this.targetStatus = drug.activeStatus;
                })
                .catch(error => {
                    console.error('Problem while getting drug details.', { error, response: error?.response });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        dbMerge(fromPackageId: string, toPackageId: string): Promise<number> {
            return Axios.post('/NonGSDD/gsdd-merge', null, {
                params: {
                    fromPackageId: fromPackageId,
                    toPackageId: toPackageId
                }
            })
                .then(response => {
                    const sc: number = Number(response.status);
                    return sc;
                })
                .catch(error => {
                    console.error('Error saving GSDD drug merge.', { error, response: error?.response });
                    return error.response?.status;
                })
        }

        confirmSaveMerge(): Promise<boolean> {
            return this.$bvModal.msgBoxConfirm(`Save Merge will permanently delete Source NonGSDD drug after Merge! - Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    return value == null ? false : value;
                })
                .catch(err => {
                    console.error("Error caught on verify Save Merge.", err);
                    return false;
                })
        }
    }
