
import SearchComponent from "@/components/SearchComponent.vue";
import { DatePicker } from "element-ui";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Audit, Auditor, AuditStatus, BillReference } from "@/models/Audit";
import { PharmacyBenefitManager } from "@/models/PharmacyBenefitManager";
import PBMComponent from "@/components/Audit/PBMComponent.vue";
import AuditorComponent from "@/components/Audit/AuditorComponent.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import NewClearSaveButtons from "@/components/NewClearSaveButtons.vue";
import axios from "axios";
import AuditPrescriptionComponent from "@/components/Audit/AuditPrescription.vue";
import AuditFaxComponent from "@/components/Audit/AuditFaxComponent.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Store } from "@/models/Store";
@Component({
    name: "AuditPageComponent",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        PBMComponent,
        AuditorComponent,
        EnumSelector,
        NewClearSaveButtons,
        AuditPrescriptionComponent,
        AuditFaxComponent,
    },
})
export default class AuditPageComponent extends Vue {
    @Prop({ type: Boolean, default: false }) private createNewAudit!: boolean;
    @Prop({ default: () => new Audit() }) private au!: Audit;
    protected auditStatuses = AuditStatus;
    private audit: Audit = new Audit();
    private PBMRecord: PharmacyBenefitManager = new PharmacyBenefitManager();
    private AuditorRecord: Auditor = new Auditor();
    private showPBMForm: boolean = false;
    private showAuditorForm: boolean = false;
    private auditor: Auditor | null = null;
    private auditStatus: number = 0;
    private showStatus: string = "";
    private toNextStatus: string = "";
    private toPrevStatus: string = "";
    private hasClawRec: boolean = false;
    private hasUncategRx: boolean = false;
    private store: Store = new Store();

    get disableFields(): boolean {
        if (this.auditStatus != 0) {
            return true;
        } else {
            return false;
        }
    }

    get isFormValid(): boolean {
        return true;
    }

    isLoading(isLoad: boolean): boolean {
        return isLoad;
    }

    get hideComponents(): boolean {
        if (this.audit.id == undefined || this.audit.id == 0) {
            return false;
        }
        return true;
    }
    showComponent(close?: string) {
        switch (close) {
            case "hidePBMForm":
                this.showPBMForm = false;
                this.PBMRecord = new PharmacyBenefitManager();
                break;
            case "hideAuditorForm":
                this.showAuditorForm = false;
                this.AuditorRecord = new Auditor();
                break;
        }
    }
    created() {
        this.isLoading(false);
        this.audit = new Audit();
        this.PBMRecord = new PharmacyBenefitManager();
        this.AuditorRecord = new Auditor();
        this.store = new Store();
        if (!this.createNewAudit) {
            this.audit = this.au;
            this.PBMRecord.id = this.au.pbm.id;
            this.PBMRecord.name = this.au.pbm.name;
            this.PBMRecord.flags = this.au.pbm.flags;
            this.AuditorRecord.id = this.au.auditor.id;
            this.AuditorRecord.firstName = this.au.auditor.firstName;
            this.AuditorRecord.lastName = this.au.auditor.lastName;
            this.auditStatus = this.au.status;
            this.showStatus = AuditStatus[this.au.status];
            this.statusButtonLabel(this.au.status);
            if (this.au.billReference.length == 0) {
                this.audit.billReference = new Array<BillReference>();
            } else {
                this.audit.billReference = this.au.billReference;
            }
            if (this.au.store != null) {
                this.store = this.au.store;
            }
        } else {
            this.toPrevStatus = "";
            this.toNextStatus = "Update to " + AuditStatus[this.auditStatus + 1];
        }
    }
    onPBMCreated(newPBM: PharmacyBenefitManager) {
        this.showPBMForm = false;
        this.PBMRecord = newPBM;
    }

    onAuditorCreated(newAuditor: Auditor) {
        this.showAuditorForm = false;
        this.AuditorRecord = newAuditor;
    }

    @Watch("auditStatus")
    onStatus(newVal: number, oldVal: number) {
        this.showStatus = AuditStatus[newVal];
        this.statusButtonLabel(newVal);
    }

  get checkAudit():boolean{
        if(!this.PBMRecord?.id || !this.audit?.auditNumber || !this.audit?.initialNotificationDate || !this.audit?.dueDate || !this.audit?.followUpDate || !this.AuditorRecord?.id){
           this.$notification(NotificationOptions.notificationOptionsPreset("ERROR: Fill out all necessary fields.", NotificationOptions.NotificationTypes.danger));
            return false;
        }else{
            return true;
        }
    }
    saveAudit() {
        if(this.checkAudit){
        this.isLoading(true);
        this.audit.pbm = this.PBMRecord;
        this.audit.auditor = this.AuditorRecord;
        this.audit.status = this.auditStatus;
        this.audit.store = this.store;
        axios
            .post<Audit>(`/AuditTracking/audit`, this.audit)
            .then((res) => {
                if (this.audit.id == undefined) {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            "Audit Created Successfully",
                            NotificationOptions.NotificationTypes.success
                        )
                    );
                } else {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            "Audit Updated Successfully",
                            NotificationOptions.NotificationTypes.success
                        )
                    );
                }
                this.audit = res.data;
                this.createNewAudit = false;
                this.isLoading(false);
            })
            .catch((err) => {
                console.error("Error while saving Audit.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Audit", err.response));
            });
        }
    }

    showConfirmationModal() {
        this.$bvModal
            .msgBoxConfirm("Are you sure you want to clear?", {
                title: "Confirm",
                okVariant: "danger",
                centered: true,
            })
            .then((value) => {
                this.audit = new Audit();
                this.PBMRecord = new PharmacyBenefitManager();
                this.auditor = new Auditor();
            })
            .catch((err) => {
                console.log("Error caught in showConfirmationModal()");
                console.error(err);
            });
    }

    private get _buttonTitles() {
        let res = {};
        if (this.createNewAudit) {
            res = {
                save: "Save Changes",
                clear: "Reset",
                new: "",
                cancel: "Back To Audit Tracking",
            };
        } else {
            res = {
                save: "Save Changes",
                clear: "",
                new: "",
                cancel: "Back To Audit Tracking",
            };
        }
        return res;
    }

    backToAuditTracking() {
        this.$emit("close");
        return;
    }

    toPrevAuditStatus() {
        if (this.auditStatus == 6 && !this.hasClawRec) {
            this.auditStatus = 3;
        } else {
            this.auditStatus = this.auditStatus - 1;
        }
        this.saveAudit();
    }
    toNextAuditStatus() {
        if (this.auditStatus == 3 && !this.hasClawRec) {
            this.auditStatus = 6;
        } else {
            this.auditStatus = this.auditStatus + 1;
        }
        this.saveAudit();
    }
    checkClawRecord(hasClawRec: boolean) {
        this.hasClawRec = hasClawRec;
    }
    statFromPres(status: number) {
        this.statusButtonLabel(status);
    }
    updateStatusToSubmitted() {
        this.toNextAuditStatus();
    }
    checkUncategorizedRx(hasUncategorized: boolean) {
        this.hasUncategRx = hasUncategorized && this.auditStatus == 3;
    }
    statusButtonLabel(stat: number) {
        if (stat != 0) {
            if (stat == 6 && !this.hasClawRec) {
                this.toPrevStatus = "Revert to " + AuditStatus[3];
            } else {
                this.toPrevStatus = "Revert to " + AuditStatus[stat - 1];
            }
        } else {
            this.toPrevStatus = "";
        }

        if (stat <= 5) {
            if (stat == 3 && !this.hasClawRec) {
                this.toNextStatus = "Update to " + AuditStatus[6];
            } else {
                this.toNextStatus = "Update to " + AuditStatus[stat + 1];
            }
        } else {
            this.toNextStatus = "";
        }
    }
      storeOptionLabel(store: Store){
        if(store.id != undefined)
            return store.id + " - " + store.name;
        return;
    }
}
