<template>
    <div class="mt-1 mb-2">
        <div class="d-flex align-items-center mb-3">
            <div v-if="title" class="flex-fill">
                <h1 class="m-0">{{ title }}</h1>
            </div>
            <div class="alert-legend">
                <span v-for="w in warningsToDisplay" :key="w.name">
                    <Transition name="fade">
                        <i v-if="w.icon && dismissed.includes(w.name)" v-b-tooltip.hover
                            :class="`${w.icon} text-${w.variant ?? 'info'} ${w.dismissable ? 'cursor-pointer' : ''}`"
                            :title="w.title" @click="dismissed = dismissed.filter(d => d !== w.name)" />
                    </Transition>
                </span>
            </div>
        </div>

        <div class="alerts">
            <b-alert v-for="w in warningsToDisplay" :key="w.name" :variant="w.variant ?? 'info'"
                :show="!dismissed.includes(w.name)" :dismissible="w.dismissable" fade
                @dismissed="dismissed.push(w.name)">
                <div class="d-flex">
                    <div>
                        <i v-if="w.icon" :class="w.icon" />
                    </div>
                    <div>
                        <strong>
                            {{ w.title + (w.title && w.message ? ":" : "") }}
                        </strong>
                        {{ w.message }}
                    </div>
                </div>
            </b-alert>
        </div>
    </div>
</template>

<script>

export default {
    name: "WarningsHeader",
    props: {
        title: String,
        warnings: Array
    },
    data() {
        return {
            dismissed: []
        }
    },
    computed: {
        warningsToDisplay() {
            return this.warnings.filter(w => w.visible !== false)
        }
    }
}
</script>

<style scoped lang="scss">
.alert-legend {
    font-size: 1.5em;
    position: sticky;
    top: 0;
    text-align: center;

    i {
        width: 1.8em;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}

.alerts i {
    background: white;
    color: black;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin: -10px;
    margin-right: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .25);
    text-align: center;
    font-size: 1.2em !important;
    line-height: 2;
}

.alerts .alert-danger i {
    color: #ff5050;
}

.alerts .alert-info i {
    color: #46b3ff;
}

.alerts .alert-warning i {
    color: #ffbc50;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
