
import { Component, Ref, Vue } from "vue-property-decorator";
import { BTable, BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import moment from "moment/moment";
import axios from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import PDFObject from "pdfobject";

@Component({
    name: "FailedFaxComponent",
})
export default class FailedFaxComponent extends Vue {
    @Ref("failed-fax-table") faxTable!: BTable;
    archived: string[] = [];
    faxFields: BvTableFieldArray = [
        { key: "messageId", },
        { key: "faxNumber", sortable: true, },
        { key: "resultInfo", },
        { key: "pagesCount", },
        { key: "errorCode", sortable: true, },
        { key: "resultCode", },
        { key: "createdAt", formatter: (d: any) => moment(d).format("L LT"), sortable: true, },
        { key: "resolvedDate", formatter: (d: any) => moment(d).format("L LT"), sortable: true, },
        { key: "actions", class: "text-center", },
        { key: "details", class: "text-center", },
    ];

    detailsClass(r: any) {
        return `text-primary fa fa-eye${r.detailsShowing ? '-slash' : ''}`;
    }

    reloadFaxes() {
        this.faxTable.refresh();
    }

    filterFunction(item: any, filt: any[]): boolean {
        const messageId = item.messageId;
        return !filt.find(v => v == messageId);
    }

    async resend(row: any) {
        const messageId = row.item.messageId;
        const idx = this.archived.push(messageId);
        try {
            const faxNumber = row.item.faxNumber;
            await axios.post(`/api/Fax/resend/${row.item.messageId}`, { faxNumber: faxNumber });
            this.$notification(NotificationOptions.notificationOptionsPreset("Successfully re-sent",
                NotificationOptions.NotificationTypes.success));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
            this.archived.pop();
        }
    }

    async archive(row: any) {
        const messageId = row.item.messageId;
        const idx = this.archived.push(messageId);
        try {
            await axios.post(`/api/Fax/archive/${messageId}`);
            this.$notification(NotificationOptions.notificationOptionsPreset("Successfully archived",
                NotificationOptions.NotificationTypes.success));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
            this.archived.pop();
        }
    }

    selectRow(r: any) {
        r.toggleDetails();
    }

    imageSource(row: any) {
        const imageSource = axios.defaults.baseURL + "/fax/image/" + row.item.messageId;
        axios.get(imageSource, { responseType: "blob" })
            .then(r => {
                const objectUrl = URL.createObjectURL(r.data);
                const options = {
                    pdfOpenParams: {
                        view: 'FitW',
                        pagemode: 'thumbs',
                        toolbar: 1,
                        scrollbar: 0,
                    }
                };
                PDFObject.embed(objectUrl, `#pdf-viewer-${row.item.messageId}`, options);
            });

        return imageSource;
    }

    async faxTableProvider(ctx: BvTableCtxObject): Promise<any[]> {
        const uri = ctx.apiUrl + "";
        try {
            const resp = await axios.get<any[]>(uri);
            return resp.data;
        } catch (err) {
            return [];
        }
    }
};
