import HasID from "./HasID";
import TrackedObject from "./TrackedObject";

export interface RefillRequestBatchItem extends HasID, TrackedObject {
    id : number;
    status : RefillRequestBatchItemStatus;
    refillRequestBatchId : number;
    storeID : number;
    rxNumber : number;
    rfNumber : number;

    rxID:string;
}

export enum RefillRequestBatchItemStatus
{
    Created, Sent, Failed
}

export class RefillRequestBatchItem {
    constructor(obj?:RefillRequestBatchItem){
        if(obj) Object.assign(this, obj);
    }

    get statusName():string {
        return RefillRequestBatchItemStatus[this.status];
    }

    get statusStyleVariant():string {
        if(this.status == RefillRequestBatchItemStatus.Sent) return 'success';
        else if(this.status == RefillRequestBatchItemStatus.Created) return 'info';
        else if(this.status == RefillRequestBatchItemStatus.Failed) return 'danger';
        else return 'black';
    }
}
