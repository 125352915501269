

import axios from 'axios';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons, {NewClearSaveTitles} from '@/components/NewClearSaveButtons.vue';
import {PharmacistRecord} from "@/models/Pharmacist"
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import Checkbox from "@/components/Inputs/Checkbox.vue";

@Component({
    name: 'PharmacistDetailPage',
    components: {
        SearchComponent,
        NewClearSaveButtons,
        Checkbox
    }
})

export default class PharmacistDetailPage extends Vue {

    private pharmacistDetail: PharmacistRecord = new PharmacistRecord();

    @Prop({ type: Boolean, default: false }) private newMode!: boolean;
    @Prop({type:Number, default: 0}) private pharmacyId!:number;
    @Prop({ default: false}) private hideTitle!: boolean;
    @Prop({ default: false}) private defaultToActive!: boolean;

    created() {
        this.pharmacistDetail.isActive = this.defaultToActive;
    }

    onPharmacistChange(_value: PharmacistRecord) {
        if (this.pharmacistDetail.id) {
            axios.get<PharmacistRecord>(`/StoredPharmacist/${this.pharmacistDetail.id}`)
                .then(pbmResponse => {
                    this.pharmacistDetail = new PharmacistRecord(pbmResponse.data);
                });
        }
    }

    get disableFields(): boolean | null {
        return (this.pharmacistDetail?.id || 0) == 0 && !this.newMode;
    }

    protected buttonTitles:NewClearSaveTitles = {
        new: "",
        save: "Save",
        clear: "",
        cancel: "Cancel",
    };

    createStoredPharmacist() {
        this.newMode = true;
        this.pharmacistDetail = new PharmacistRecord();
        this.pharmacistDetail.isActive = true;
    }

    clearForm() {
        this.newMode = false;
        this.pharmacistDetail = new PharmacistRecord();
    }

    saveStoredPharmacist() {
        this.pharmacistDetail.pharmacyId = this.pharmacyId;
        if (this.pharmacistDetail?.id as number) this.pharmacistDetail.id = this.pharmacistDetail.id as number;
        else this.pharmacistDetail.id = null as unknown as number;

        axios.post<PharmacistRecord>('/StoredPharmacist', this.pharmacistDetail)
            .then(response => {
                if (response.data.id) {
                    this.pharmacistDetail = Object.assign(new PharmacistRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacist"));
                    this.$emit('created', this.pharmacistDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacist", error));
            });
    }

}

