
    import {Component, Vue, Prop, VModel, Model, PropSync} from 'vue-property-decorator';
    import {Vendor} from '@/models/Vendor';
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "VendorPriceComponent",
        components: {SearchComponent},
    })
    export default class VendorPriceComponent extends Vue {
        @VModel() private vendor!: Vendor;
        @Prop() private mode!:string;
        @PropSync("price", {type: Number, default: 0}) private syncedPrice!: number;
        @PropSync("primary", {type:Boolean, default: false}) private syncedPrimary!: boolean;
        
    }
