
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import Card from "@/components/Cards/Card.vue";
import Axios from 'axios';
import moment from 'moment';
import PriorAuthorizationCreate from "@/components/PriorAuthorization/PriorAuthorizationCreate.vue";
import PriorAuthorizationDetails from "@/components/PriorAuthorization/PriorAuthorizationDetails.vue";
import { Prescription } from '@/models/Prescription';
import { PriorAuthorization, PriorAuthorizationStatusCode, CoverMyMedsResponse } from '@/models/PriorAuthorization';

@Component({
    name: "PriorAuthorizationList",
    components: {
        Card,
        PriorAuthorizationCreate,
        PriorAuthorizationDetails,
    }
})
export default class PriorAuthorizationListComponent extends Vue {
    @Prop() private value: any;

    private priorAuthorizations: PriorAuthorization[] = [];
    private selectedPa: PriorAuthorization = {} as PriorAuthorization;
    private selectedPrescription: Prescription = {} as Prescription;

    created() {
        this.fetchPriorAuthorizations();
    }

    @Watch("value")
    valueUpdated(val: any, newVal: any) {
        this.priorAuthorizations = [];
        this.fetchPriorAuthorizations();
    }

    private fields: any[] = [
        { key: "id" },
        { key: "prescription", formatter: (p: Prescription) => `${p.storeID}-${p.rxNumber}-${p.rfNumber}` },
        { key: "status", formatter: (s: PriorAuthorizationStatusCode) => PriorAuthorizationStatusCode[s], sortable: true },
        { key: "updated", formatter: (d: any) => moment.utc(d).format("L"), sortable: true },
        { key: "updatedBy" },
        { key: "created", formatter: (d: any) => moment.utc(d).format("L"), sortable: true },
        { key: "createdBy" },
    ];

    createModalHide() {
        this.fetchPriorAuthorizations();
    }

    fetchPriorAuthorizations() {
        let url = `/PriorAuthorization/`;
        if (this.value?.id) url = `/PriorAuthorization/ByPatient/${this.value.id}`;
        Axios.get<Array<PriorAuthorization>>(url)
            .then(response => {
                if (response.data?.length) this.priorAuthorizations = response.data.map(pa => new PriorAuthorization(pa));
            })
            .catch(error => {
                console.error('Error loading PAs', { error, response: error?.response });
            });
    }

    newPriorAuthorization() {
        this.$bvModal.show("prior-authorization-create-modal");
    }

    selectPa(item: PriorAuthorization) {
        this.selectedPa = item;
        this.$bvModal.show("pa-details-modal");
    }
}
