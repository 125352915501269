import TrackedObject  from "./TrackedObject";
import HasID from "./HasID";

enum ContactType{
    None,
    Program,
    Business
}

export interface Contact extends HasID, TrackedObject {
    firstName: string;
    lastName: string;
    phone: string;
    emailAddress: string;
    type: ContactType;
}

export {ContactType}
