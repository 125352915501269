import moment from "moment";
import HasLabel from "./HasLabel";
import { Order, OrderStatus } from "./Order";
import TrackedObject from "./TrackedObject";
import TextUtils from "@/util/TextUtils";
import { Patient } from "@/models/Patient";
import { Program } from "@/models/Program";
import { Claim } from "./Claim";
import { Prescriber } from "@/models/Prescriber";
import { DeliveryCode } from '@/models/DeliveryCode'
import { DrugSource } from "@/models/Drug/DrugProduct";
import { ReminderSupression } from "./ReminderSuppression";

export interface Prescription extends TrackedObject {
    storeID: number;
    rxNumber: number;
    rfNumber?: number;
    billingReferenceNumber: string;
    imageID?: number;
    escriptID?: number;
    programTransferId?: number;
    directions: string;
    daySupply: number;
    quantity: number;
    dispensed: number;
    refills: number;
    originCode: OriginCode;
    dawCode: DAWCode;
    status: RefillStatusCode;
    subStatus: OnHoldSubstatusCode | null;
    memo: string;
    programName: string;
    storeName: string;
    lotNumber: string;
    drugExpirationDate: Date | null;
    totalPrice: number;
    cost: number;
    patientPay: number;
    tax: number;
    deliveryCode: DeliveryCode;
    deliveryOption: string;
    packageID: number;
    drugSource: DrugSource;
    patientID: number;
    prescriberID: number;
    programID?: number;
    orderId: number;
    originalRxDate: Date;
    fillDate: Date;
    writtenDate: Date;
    expirationDate: Date | null;
    drugsFilledDate: Date;
    annotations: string;
    productNameShort: string;
    drugName: string;
    pV1ID: number;
    pV2ID: number | null;
    pv1: Date;
    pv1By: string;
    pv2: Date;
    pv2By: string;
    dispenseErrorId?: number;
    canBeFilled: boolean;
    totalDispensed: number;
    totalAllowedToDispense: number;
    priceScheduleID?: number;
    deaClassificationID?: number;
    isPV1Required?: boolean;
    isAvailableForAutorefill: boolean;

    orders: Array<Order>;
    patient: Patient;
    prescriber: Prescriber;
    claims: Array<Claim>;
    program: Program;
    suppression: ReminderSupression;

    serialNumber: string;
    partnerFillNumber: string;
    originPharmacyId?: number;
}

export interface FaxForm {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    fax: string;
    dea: string;
    pharmacist: string;
    verbalTransfer: boolean | null;
    note: string;
    alternateDrug: string;
    rxID: string;
    patientID: number;
    prescriberID: number;
    packageID: number;
    drugSource: number;
    storedPharmacyId: number;
    patientPlanIds: number[];
    storeID: number;
}

export class FaxForm {
    constructor() {
        this.name = '';
        this.address = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.phone = '';
        this.fax = '';
        this.dea = '';
        this.pharmacist = '';
        this.verbalTransfer = null;
        this.note = '';
        this.alternateDrug = '';
        this.storedPharmacyId = 0;
        this.patientPlanIds = [];
        this.storeID = 0;
    }
}

export enum OriginCode {
    NotSpecified,
    Written,
    Phone,
    Escript,
    Fax,
    Transfer,
    Custom
}

export enum DAWCode {
    // noinspection JSUnusedGlobalSymbols
    NotSpecified,
    Prescriber,
    Patient,
    Pharmacy,
    GenericNotStocked,
    DispenseAsGeneric,
    Override,
    SubstitutionNotAllowed,
    SubstitutionOkNoGeneric,
    Other
}

export enum RefillStatusCode {
    // noinspection JSUnusedGlobalSymbols
    Refillable,
    AutoRefillable,
    NonRefillable,
    Suspended,
    TransferOut,
    TransferIn,
    OnHold,
    Canceled,
    Expired,
    Void,
    AwaitingInsurance
}

export enum OnHoldSubstatusCode {
    // noinspection JSUnusedGlobalSymbols
    WaitingOnMd,
    InventoryShort,
    WaitingOnInsurance,
    ReturnToStock,
    GovernmentFunded,
    NoCommercialInsurance,
    NotInNetwork,
    PtRefused,
    NoPtContact,
    InvalidPhoneNumber,
    MdGaveSamples,
    WaitingOnCoupon, // (for horizon? )
    CouponMaxReached,
    DuplicatedPrescription,
    RxDenied, // same as being Void
    AwaitingPAP,
    PAPDenied
}

export class Prescription extends HasLabel {
    constructor(obj?: Prescription) {
        super();
        this.fillDate = moment.utc().toDate();
        this.status = RefillStatusCode.Refillable;
        this.deliveryCode = DeliveryCode.Standard;
        this.patientID = 0;
        this.storeID = 0;
        this.packageID = 0;
        this.prescriberID = 0;
        this.isAvailableForAutorefill = false;
        this.programID = 0;
        this.program = new Program();
        this.orders = new Array<Order>();

        if (obj) {
            Object.assign(this, obj);
            this.program = new Program(obj.program);
            if (obj.orders) {
                this.orders = obj.orders.map(o => new Order(o));
            }
        }
    }

    toString(): string {
        if (this.rxNumber) return `Store-Rx-Rf:${this.rxID}, patientID:${this.patientID}`;
        return '<div class="font-weight-light">Store-Rx-Rf</div>';
    }

    get rxID(): string {
        return Prescription.createRxId(this.storeID, this.rxNumber, this.rfNumber);
    }

    get isActive(): boolean {
        return !(this.status == RefillStatusCode.TransferOut || this.isVoid) && !this.isExpired;
    }

    /**
     * Indicates if prescription is Void or RxDenied.
     */
    get isVoid(): boolean {
        return this.status == RefillStatusCode.Void
            || (this.status == RefillStatusCode.OnHold && this.subStatus == OnHoldSubstatusCode.RxDenied);
    }

    /**
     * Indicates if the program allows the prescription to refill even if previous refill has not been shipped.
     */
    get programAllowsRefillsIfUnshipped(): boolean {
        if (!this.program?.flags.length) return false;

        return this.program.flags.some(f => f.value && f.name == "Allow Refills if Unshipped");
    }

    get isRefillable(): boolean {
        //const hasBeenPV2 = !!this.pV2ID;
        const rx30ScriptUpdatedByCli = (this.createdBy == 'Rx30' && this.updatedBy == 'CPHub/CLI');

        return this.canBeFilled && this.isRefillableStatus
            && (this.hasBeenShipped || this.programAllowsRefillsIfUnshipped || this.updatedBy == 'Rx30' || rx30ScriptUpdatedByCli);
        // && ((this.hasBeenShipped && hasBeenPV2) || this.fillDate < moment('04/01/2021').toDate());
    }

    get isRefillableStatus(): boolean {
        return (this.status == RefillStatusCode.Refillable || this.status == RefillStatusCode.AutoRefillable
            || this.status == RefillStatusCode.TransferIn) && this.isActive;
    }

    get isExpired(): boolean {
        if (!this.expirationDate) return false;

        const expMoment = moment(this.expirationDate);
        return moment().isAfter(expMoment, 'days');
    }

    /**
     * Indicates if the prescription is in a order that has already been shipped.
     */
    get hasBeenShipped(): boolean {
        return this.orders?.some(o => o.status == OrderStatus.Shipped) ?? false;
    }

    get hasFullId(): boolean {
        return !!(this.storeID && this.rxNumber && (this.rfNumber || this.rfNumber == 0));
    }

    get statusName(): string {
        if (this.isExpired) return "Expired";
        return TextUtils.camelCaseToNormal(RefillStatusCode[this.status]);
    }

    get isCashedOut(): boolean {
        return !!(this.priceScheduleID);
    }

    get isRx30BeforeApril(): boolean {
        const myMoment = moment;
        return (this.status != RefillStatusCode.OnHold &&
                myMoment(this.fillDate).isSameOrBefore(myMoment("2021-04-01")) &&
                this.createdBy == 'Rx30')
            || (this.status != RefillStatusCode.OnHold &&
                (this.updatedBy == 'Rx30' || this.updatedBy == 'CPHub/CLI') &&
                this.memo == "Transferred from Rx30 post-launch");
    }

    public static createRxId(storeID: number, rxNumber: number, rfNumber: number | undefined): string {
        return `${storeID || ''}-${rxNumber || ''}-${rfNumber == undefined ? '' : (rfNumber || 0)}`;
    }

    get remainingQuantity(): number {
        if (!this.rxNumber || this.rfNumber == 0) return (this.quantity * ((this.refills || 0) + 1));

        const res = this.totalAllowedToDispense - (this.totalDispensed || 0);
        return Number(res.toFixed(2));
    }

    get isCII(): boolean {
        return this.deaClassificationID == 1 || this.deaClassificationID == 2;
    }

    get trackingNumber(): string {
        let orders = this.orders;
        let tracking = "";

        if (orders) {
            orders = orders.filter(o => o && o.status == OrderStatus.Shipped && o.trackingNumber.length > 0);
            if (orders.length > 0) {
                const order = orders.reduce((prev, current) => (prev && prev.updated > current.updated) ? prev : current);
                tracking = order.trackingNumber;
            }
        }
        return tracking;
    }

    get shipDate(): string {
        let orders = this.orders;
        let shipDate = "";

        if (orders) {
            orders = orders.filter(o => o && o.status == OrderStatus.Shipped && o.shipDate.length > 0);
            if (orders.length > 0) {
                const order = orders.reduce((prev, current) => (prev && prev.updated > current.updated) ? prev : current);
                shipDate = order.shipDate;
            }
        }
        return shipDate;
    }
}
