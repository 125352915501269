
    import Vue from 'vue';
    import { Component, VModel } from 'vue-property-decorator'; //Watch
    import SearchComponent from '@/components/SearchComponent.vue';
    import { Store } from '@/models/Store';
    import { Drug } from '@/models/Drug/Drug';
    import { DatePicker } from "element-ui";
    //import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { Program } from '@/models/Program';
    import { DrugShortageReport } from '@/models/DrugShortageReport';
    import { PackagePrice } from '@/models/GSDD';
    import Axios from 'axios';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    @Component({
        name: "DrugShortageReportFormComponent",
        components: {
            SearchComponent,
            [DatePicker.name]: DatePicker,
        },
    })
    export default class DrugShortageReportFormComponent extends Vue {

        //Batch ID
        @VModel() private item!: DrugShortageReport;
        //protected Drug = Drug;
        private store: Store = new Store();
        private program: Program = new Program();
        private drug: Drug = new Drug();
        protected drugSelected: boolean = false;
        protected drugStatus: number | null = null;

        protected isLoading = false;
        private isSaving = false;
        private isSubmitting = false;

        created(){
            console.log("created");
           if(this.item?.id ?? 0 > 0){
               this.fetchDrug(this.item.packageId);
               this.fetchStore(this.item.storeId);
               this.fetchProgram(this.item.programId);
               this.drugSelected = true;
           }
        }

        clearForm(){
            this.item = new DrugShortageReport();

            this.store = new Store();
            this.program = new Program();
            this.drug = new Drug();
            this.drugSelected = false;
        }

        get disableSubmit():boolean {
            return false;
        }

        get disableSave(){
            return false;
        }

        get isProductFormValid():boolean{
            return true;
        }

        get isBusy():boolean {
            return this.isSaving || this.isSubmitting;
        }

        get disableFields(): boolean {
            return false
        }
        get marketer(): any {
            if (!this.drug) return ``;
            return this.drug.marketer;
        }
        get packageDescription(): any {
            if (!this.drug) return ``;
            return this.drug.packageDescription;
        }
            get doseForm(): any {
            if (!this.drug) return ``;
            return this.drug.doseForm;
        }
        get DeaClass(): any {
            if (!this.drug) return ``;
            return this.drug.deaClass;
        }
        get PackageSize(): any {
            if (!this.drug || !this.drug.packageSize || !this.drug.billingUnit) return ``;
            return `${this.drug.packageSize} ${this.drug.billingUnit}`;
        }
        // from Price
        get drugPrice(): any {
            if (!this.drug || !this.drug.prices) return ``;
            const filteredPrices = this.drug.prices as PackagePrice[];

            // 1  -- Average Wholesale Price
            // 13 -- Calculated Average Wholesale Price
            // 2  -- Wholesale Acquisition Cost
            const awp = filteredPrices.find(p => p.priceTypeID == 1 || p.priceTypeID == 13);
            const wac = filteredPrices.find(p => p.priceTypeID == 2);

            const awpString = awp && awp.unitPrice ? `AWP: $${awp.unitPrice}/${awp.ncpdpBillingUnit}` : ``;
            const wacString = wac && wac.unitPrice ? `WAC: $${wac.unitPrice}/${wac.ncpdpBillingUnit}` : ``;

            return `${awpString} ${wacString}`;
        }
        clearClicked(){
            this.drug = new Drug();
            this.drugSelected = false;
            return;
        }
        onDrugSelected(){
            this.drugSelected = true;
            const PackageID = this.drug.packageID;
            const packageId = Number(PackageID || 0);
            this.drug = new Drug();
            this.fetchDrug(packageId);
        }

        fetchStore(storeId: number){
            this.$http.get(`/Store/${storeId}`)
                .then(response => {
                    this.store = new Store(response.data);
                })
                .catch(error => {
                    console.error('Problem while getting store details.', {error, response: error?.response});
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        fetchProgram(programId: number | string | undefined){
            if(programId){
            this.$http.get(`/Program/${programId}`)
                .then(response => {
                    this.program = new Program(response.data);
                })
                .catch(error => {
                    console.error('Problem while getting program details.', {error, response: error?.response});
                })
                .finally(() => {
                    this.isLoading = false;
                });
            }
        }

        fetchDrug(packageID: number) {
            if (!packageID) return;

            this.$http.get(`/Drug/${packageID}`, {params: {'getCategories': true, 'getVendors': true}})
                .then(response => {
                    const drug: Drug = response.data;
                    this.drug = new Drug(packageID, drug);
                    const statusID = drug.status ? drug.status.status : 0;
                    this.drugStatus = statusID;
                })
                .catch(error => {
                    console.error('Problem while getting drug details.', {error, response: error?.response});
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
        // submit request

        onHandUpdated(value: number){
            this.item.qtyOnHand = value;
        }

        submitDrugRequest(){
            const data = {
                id: this.item?.id,
                storeId: Number(this.store.id),
                storeName: this.store.name,
                programId: this.program.id,
                programName: this.program.name,
                packageId: Number(this.drug.packageID),
                qtyOnHand: this.item.qtyOnHand,
                qtyNeeded: this.item.qtyNeeded,
                estimatedArrivalDate: this.item.estimatedArrivalDate,
                dateOrdered: this.item.dateOrdered
            } as DrugShortageReport;

            Axios.post('DrugShortageReport/addRequestToReport', data)
            .then(addRequestResponse => {
                console.log("addRequestResponse: ", addRequestResponse);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Drug Shortage Report"));
                this.$emit('itemSaved');
                this.clearForm();
            })
            .catch(err=>{
                this.$notification(NotificationOptions.error(err));
            });

        }
    }
