import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import routes from "@/routes/routes";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/styles/custom.scss";
import VueRouter from "vue-router";
import App from "./App.vue";
import DashboardPlugin from "./dashboard-plugin";
import Notifications from "@/components/NotificationPlugin";
import { AxiosStatic, default as axios } from "axios";
import moment from "moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import * as signalR from "@microsoft/signalr";
import { User } from "./models/User";
import * as dotenv from 'dotenv';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueMask from "v-mask";
import Button from "@/components/Button.vue";
import Card from "@/components/Cards/Card.vue";
import { DashboardStatus } from "./models/DashboardStatus";
import { PhoneStats } from "./models/PhoneStats";
import LoadScript from "vue-plugin-load-script";
import MicrosoftAuth from "@/util/microsoftAuth";
import { dashboardSetup } from "./util/dashboardSetup";
import { BrowserAuthError } from "@azure/msal-browser";
import { setupCognito, getToken } from "@/util/cognito-auth";

const useCognito = process.env.VUE_APP_USE_COGNITO == "true";
console.log("****UseCognito?:", process.env.VUE_APP_USE_COGNITO, useCognito);

// Declaring global variables
declare module "vue/types/vue" {
    interface Vue {
        $http: AxiosStatic;
        $user: User;
        $stripeKey: string;
    }
}

Vue.prototype.$environment = process.env.VUE_APP_ENVIRONMENT_NAME ?? "";
Vue.prototype.$version = "";
Vue.prototype.$stripeKey = process.env.STRIPE_PUBLIC_KEY ?? "";
dotenv.config();

const serverURL = process.env.VUE_APP_APIURL ?? '';

axios.defaults.baseURL = serverURL + "/api";
axios.defaults.headers = { "Content-Type": "application/json" };
axios.defaults.withCredentials = true;
const router = new VueRouter({
    routes,
    mode: "history",
    linkActiveClass: "active",
} as Record<string, any>);

router.beforeEach((to, from, next) => {
    if (to.path.startsWith("/cphub")) {
        next(to.path.replace("/cphub", "/processing"));
    } else next();
});

if (useCognito) {
    setupCognito();
} else {
    MicrosoftAuth.install(Vue, {
        serverUrl: serverURL,
        msalConfig: {
            auth: {
                clientId: process.env.VUE_APP_ADAL_GRAPHID,
                authority: `https://login.microsoftonline.com/${process.env.VUE_APP_ADAL_TENANT}`,
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: 'localStorage',
            },
        },
    });
}

Vue.config.productionTip = true;
Vue.prototype.$http = axios;

Vue.use(BootstrapVue, { BTable: { sortIconLeft: true, noSortReset: true } });
Vue.use(BootstrapVueIcons);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Notifications);
Vue.use(vueNumeralFilterInstaller, { locale: "en" });
Vue.use(VueMask);

// @ts-ignore
Vue.use(LoadScript);

const htmlToPaperOptions = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css",
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, htmlToPaperOptions);

Vue.component("VSelect", vSelect);

Vue.component(Button.name, Button);
Vue.component(Card.name, Card);

const store = Vue.observable({ queueItems: [] });
Vue.prototype.$store = store;

// Custom filters, this should be better to be declared in a separate folder if we use more of this custom filters
Vue.filter("formatDate", (value: Date, format: string, setLocal = true) => {
    if (value && format) {
        let momentDate = moment.utc(value);
        momentDate = setLocal ? momentDate.local() : momentDate;
        return momentDate.format(format);
    } else return "";
});

Vue.filter("formatLocalDate", (value: Date, format: string, _setLocal = true) => {
    if (value && format) {
        const momentDate = moment(value);
        return momentDate.format(format);
    } else return "";
});

const updateTime: Date | null = null;

const app = new Vue({
    data: {
        dispenseErrorCount: 0,
        notificationCount: 0,
        dashboardData: new DashboardStatus(),
        dashboardDataUpdated: updateTime,
        phoneStats: new PhoneStats(),
        environment: "",
        version: "",
        dataHubFileVersion: {},
        dataHubPhoneStats: {},
        dataHubState: "",
        user: new User(),
    },
    created() {
        if (useCognito) {

            console.log("startedBeforeCreate");
            getToken().then((resp) => {
                const connectionsOptions = {
                    accessTokenFactory: () => {
                        return resp;
                    },
                } as signalR.IHttpConnectionOptions;
                dashboardSetup(this, serverURL, connectionsOptions);
            });
        }
    },
    async mounted() {
        if (!useCognito) {

            await this.$msal.instance.handleRedirectPromise();

            const isHomeRoute = this.$route?.name === "Home";
            let isAuthenticated = this.$msal.isAuthenticated();

            const shouldShowLoginPopup = !isAuthenticated && !isHomeRoute;

            if (shouldShowLoginPopup) {
                try {
                    await this.$msal.loginPopup();
                    isAuthenticated = this.$msal.isAuthenticated();
                } catch (e) {
                    if (e instanceof BrowserAuthError) {
                        await this.$router.push({ name: "Home" });
                    }
                }
            }

            if (!isAuthenticated) return;

            const token = await MicrosoftAuth.fetchToken();

            const connectionsOptions = {
                accessTokenFactory: () => {
                    return token.idToken;
                },
            } as signalR.IHttpConnectionOptions;
            dashboardSetup(this, serverURL, connectionsOptions);

            if (isHomeRoute) await this.$router.push("/processing");

        }
    },
    render: h => h(App),
    router,
});

Object.defineProperty(Vue.prototype, '$user', {
    get() {
        return app.user;
    },
    set(value) {
        app.user = value ?? new User();
    },
});

app.$mount("#app");
