
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Questionary } from '@/models/Questionary';
import axios from 'axios';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Prescription } from '@/models/Prescription';

@Component({
    name: "QuestionaryComponent"
})
export default class QuestionaryComponent extends Vue {
    @Prop() private categoryID!: number;
    @Prop() private prescription!: Prescription;
    @Prop({ default: false }) private newMode!: boolean;
    @Prop({ default: false }) private disable!: boolean;

    private questionary: Questionary = new Questionary();
    private questions: Array<Questionary.Question> = [];

    created() {
        this.load();
    }

    beforeDestroy() {
        this.clear();
    }

    @Watch('categoryID') categoryIdChanged() {
        this.load();
    }

    clear() {
        this.questionary = new Questionary();
        this.questions = [];
    }

    load() {
        if (!this.categoryID) return;

        this.clear();
        axios.get<Questionary>(`/DrugCategory/${this.categoryID}/custom-questions`)
            .then(response => {
                this.questionary = Object.assign(new Questionary(), response.data);

                if (!this.questionary.id) return;

                if (this.prescription.hasFullId) {
                    axios.get<Array<Questionary.Answer>>(`/Prescription/${this.prescription.rxID}/get-answers/${this.questionary.id}`)
                        .then(response => {
                            this.questionary.answers = response.data.map(a => Object.assign(new Questionary.Answer(), a));
                            this.questions = this.questionary.orderedQuestionsAsc;
                        })
                        .catch(error => {
                            console.error('Error while getting questionary answers', {
                                categoryID: this.categoryID,
                                error,
                                response: error?.response
                            });
                        });
                } else {
                    this.questions = this.questionary.orderedQuestionsAsc;
                }
            })
            .catch(error => {
                console.error('Error while getting custom questionary', {
                    categoryID: this.categoryID,
                    error,
                    response: error?.response
                });
            });
    }

    save(rx?: Prescription, preventRedirection: boolean = false) {
        if (!(this.questionary?.id && this.questionary?.amountOfQuestions > 0)) return;

        let answers = this.questions.map(q => q.answer);
        this.questionary.answers = answers;

        let rxID = this.prescription.rxID;
        if (rx?.hasFullId) rxID = rx.rxID;

        const questionaryTitle = this.questionary?.title || "questionary";
        axios.post<Array<Questionary.Answer>>(`/Prescription/${rxID}/save-answers`, this.questionary)
            .then(response => {
                answers = response.data.map(a => Object.assign(new Questionary.Answer(), a));
                this.questionary.answers = answers;
                this.questions = this.questionary.orderedQuestionsAsc;
                this.$notification(NotificationOptions.successSaveNotificationPreset(`Answers for ${questionaryTitle}`));
                this.$emit("saved-answers", this.categoryID, preventRedirection);
            })
            .catch(error => {
                console.error('Error while saving questionary answers', {
                    categoryID: this.categoryID,
                    error,
                    response: error?.response
                });
            });
    }
}

