
import SearchComponent from '@/components/SearchComponent.vue';
import CompoundFormComponent from '@/components/Compound/CompoundFormComponent.vue';
import GSDDMergeModalComponent from '@/components/NonGSDD/GSDDMergeModalComponent.vue';
import WarningLabels from '@/components/Compound/WarningLabels.vue';
import Ingredients from '@/components/Compound/Ingredients.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
    CompoundDrug,
    CompoundIngredients,
    CompoundPatEd,
    CompoundWarningLabels
} from '@/models/Drug/CompoundDrug';
import Axios, { AxiosError } from "axios";
import { DrugSource } from "@/models/Drug/DrugProduct";
import { DrugStatus } from "@/models/Drug/Drug";
import DrugStatusEnum = DrugStatus.DrugStatusEnum;
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

import DrugDocumentsComponent from '@/components/Drug/DrugDocumentsComponent.vue'

@Component({
    name: "CompoundDrugDetailsPage",
    components: {
        SearchComponent,
        Ingredients,
        WarningLabels,
        CompoundFormComponent,
        GSDDMergeModalComponent,
        DrugDocumentsComponent
    }
})
export default class CompoundDrugDetailsPage extends Vue {
    private isLoading = false;
    private drugSearch: CompoundDrug = new CompoundDrug();
    private drugOriginal: CompoundDrug = new CompoundDrug();
    private drug: CompoundDrug = new CompoundDrug();
    private drugEdit: CompoundDrug = new CompoundDrug();
    private status: string | null = null;
    private patEd: CompoundPatEd = new CompoundPatEd();
    private warningLabels: CompoundWarningLabels[] = [];
    private ingredients: CompoundIngredients[] = [];
    private brandTitle: string = "Brand";
    private patEdTitle: string = "Patient Education";
    private editBrandForm: boolean = false;
    private editPatEdForm: boolean = false;
    private formDataModified: boolean = false;
    private alert = "";

    get routeDrugId(): number {
        let res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get drugSource(): DrugSource {
        return DrugSource.CompoundProduct;
    }

    // from Product
    get drugName(): any {
        if (!this.drug) return ``;
        return this.drug.name;
    }

    get awp(): any {
        if (!this.drug || !this.drug.awp) return ``;
        return `${this.drug.awp}`;
    }

    get packageSize(): any {
        if (!this.drug || !this.drug.packageSize) return ``;
        return `${this.drug.packageSize}`;
    }

    get unitOfMeasure(): any {
        if (!this.drug) return ``;
        return this.drug.unitOfMeasure;
    }

    get minimumDispenseQuantity(): any {
        if (!this.drug || !this.drug.minimumDispenseQuantity) return ``;
        return `${this.drug.minimumDispenseQuantity}`;
    }

    get deaClass(): any {
        if (!this.drug) return ``;
        return this.drug.deaClass;
    }

    get instructions(): any {
        if (!this.drug) return ``;
        return this.drug.instructions;
    }

    get patEdDescription(): any {
        if (!this.drug.patEd || !this.drug.patEd.description) return ``;
        return this.drug.patEd.description;
    }

    get patEdContraindications(): any {
        if (!this.drug.patEd || !this.drug.patEd.contraindications) return ``;
        return this.drug.patEd.contraindications;
    }

    get patEdAdministration(): any {
        if (!this.drug.patEd || !this.drug.patEd.administration) return ``;
        return this.drug.patEd.administration;
    }

    get patEdMissedDoseInstructions(): any {
        if (!this.drug.patEd || !this.drug.patEd.missedDoseInstructions) return ``;
        return this.drug.patEd.missedDoseInstructions;
    }

    get patEdMonitoring(): any {
        if (!this.drug.patEd || !this.drug.patEd.monitoring) return ``;
        return this.drug.patEd.monitoring;
    }

    get patEdSideEffects(): any {
        if (!this.drug.patEd || !this.drug.patEd.sideEffects) return ``;
        return this.drug.patEd.sideEffects;
    }

    get patEdStorage(): any {
        if (!this.drug.patEd || !this.drug.patEd.storage) return ``;
        return this.drug.patEd.storage;
    }

    get ncpdBillingUnit(): string {
        return this.drug ? this.drug.unitOfMeasure : ``;
    }

    created() {
        if (this.routeDrugId) {
            this.fetchDrug(this.routeDrugId);
        }
    }

    @Watch('$route.params.id')
    onParamChanged(value: string, oldValue: string) {
        if (value != oldValue) {
            this.fetchDrug(Number(value))
        }
    }

    @Watch('drug')
    onDrugChanged(value: CompoundDrug | null, oldValue: CompoundDrug | null) {
        if (!value || value.compoundID == oldValue?.compoundID) return;

        if (value.compoundID && value.compoundID != -1 && value.compoundID != this.routeDrugId) {
            this.$router.push({ name: 'Compound-Drug', params: { id: value.compoundID.toString() } });
        }
    }

    async clearClicked() {
        let ignoreFormDataModified = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.drugSearch = new CompoundDrug();
        this.drug = new CompoundDrug();
        await this.$router.push({ name: 'Compound-Drug-New' });
    }

    async onDrugSelected(drug: CompoundDrug) {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.editBrandForm = false;
        this.editPatEdForm = false;
        //this.clearClicked();
        this.status = null;
        this.ingredients = [];
        this.warningLabels = [];
        const compoundId = Number(drug.compoundID || 0);
        this.fetchDrug(compoundId);
    }

    async fetchDrug(compoundID: number) {
        if (!compoundID || compoundID == -1) {
            this.drug = new CompoundDrug();
            return;
        }

        try {
            const response = await Axios.get<CompoundDrug>(`/Compound/${compoundID}`);

            const drug = response.data;
            this.drug = new CompoundDrug(drug.compoundID, drug);
            if (drug.patEd) this.drug.patEd = new CompoundPatEd(drug.patEd);
            this.status = DrugStatusEnum[drug.activeStatus];
            if (drug.ingredients) this.ingredients = Object.assign(new Array<CompoundIngredients>(), drug.ingredients);
            if (drug.warningLabels) this.warningLabels = Object.assign(new Array<CompoundWarningLabels>(), drug.warningLabels);

            try {
                const alertResponse = await Axios.get(`/Drug/${drug.drugId}/Alert`, { params: { drugSource: drug.source } });
                this.alert = alertResponse.data;
            } catch (error) {
                console.error('There was an error while getting Alerts', {
                    error,
                    response: (error as AxiosError)?.response
                });
            }
        } catch (error) {
            console.error('Problem while getting drug details.', { error, response: (error as AxiosError)?.response });
        }

        this.isLoading = false;
        this.drugOriginal = new CompoundDrug(null, this.drug);
        this.drugOriginal.patEd = new CompoundPatEd();
        this.drugOriginal.ingredients = new Array<CompoundIngredients>();
        this.drugOriginal.warningLabels = new Array<CompoundWarningLabels>();
        if (this.drug.patEd) this.drugOriginal.patEd = new CompoundPatEd(this.drug.patEd);
        if (this.drug.ingredients) this.drugOriginal.ingredients = this.drug.ingredients;
        if (this.drug.warningLabels) this.drugOriginal.warningLabels = this.drug.warningLabels;
    }

    onEditBrand() {
        this.drugEdit = new CompoundDrug(null, this.drug);
        this.editBrandForm = true;
    }

    onEditPatEd() {
        this.drugEdit = new CompoundDrug(null, this.drug);
        this.drugEdit.patEd = Object.assign(new CompoundPatEd(), this.drug.patEd);
        this.editPatEdForm = true;
    }

    onBrandFormContinue() {
        this.drug = new CompoundDrug(null, this.drugEdit);
        this.status = DrugStatusEnum[this.drugEdit.activeStatus];
        this.drug.patEd = new CompoundPatEd(this.drugEdit.patEd);
        this.editBrandForm = false;
        this.checkFormDataModified();
    }

    onPatEdFormContinue() {
        this.drug = new CompoundDrug(null, this.drugEdit);
        this.status = DrugStatusEnum[this.drugEdit.activeStatus];
        this.drug.patEd = new CompoundPatEd(this.drugEdit.patEd);
        this.editPatEdForm = false;
        this.checkFormDataModified();
    }

    async onNewBrand() {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
        }
        this.formDataModified = false;
        this.drug = new CompoundDrug(-1);
        this.drug.patEd = new CompoundPatEd();
        this.drug.ingredients = new Array<CompoundIngredients>();
        this.drug.warningLabels = new Array<CompoundWarningLabels>();
        this.drugEdit = new CompoundDrug(null, this.drug);
        this.drugOriginal = new CompoundDrug(null, this.drug);
        this.drugOriginal.patEd = new CompoundPatEd();
        this.drugOriginal.ingredients = new Array<CompoundIngredients>();
        this.drugOriginal.warningLabels = new Array<CompoundWarningLabels>();
        this.ingredients = new Array<CompoundIngredients>();
        this.warningLabels = new Array<CompoundWarningLabels>();
        this.onEditBrand();
        this.onEditPatEd();
    }

    async onSaveClicked() {
        if (this.ingredients.length == 0) {
            await this.$bvModal.msgBoxOk(`Compound can not be saved without any ingredients`);
            return;
        }
        let fetchByNdcResults: CompoundDrug[] = [];
        let sc: number = 0;
        let scGood: number[] = [200, 204];
        if ((this.drugOriginal.ndc && this.drug.ndc != this.drugOriginal.ndc) || !this.drugOriginal.ndc) {
            const value = await this.fetchByNdc(this.drug.ndc);

            if (value.length == null) {
                sc = value;
            }
            else {
                sc = value[0];
                fetchByNdcResults = value[1];
            }

            if (!scGood.includes(sc)) {
                await this.$bvModal.msgBoxOk(`Database error occurred while attempting to verify new NDC`);
                return;
            }
        }
        let ignoreDuplicateNdc: boolean = false;
        if (sc == 200 && fetchByNdcResults.map(item => item.ndc).includes(this.drug.ndc)) {
            ignoreDuplicateNdc = await this.confirmIgnoreDuplicateNdc(this.drug.ndc);
            if (!ignoreDuplicateNdc) return;
        }
        this.drug.ingredients = this.ingredients;
        this.drug.warningLabels = this.warningLabels;
        let newCompoundID: number = -1;
        const value = await this.dbSave(this.drug);

        if (!Array.isArray(value)) {
            sc = Number(value);
        }
        else {
            sc = Number(value[0]);
            newCompoundID = value[1];
        }

        if (sc === 403) {
            await this.$bvModal.msgBoxOk(`You do not have permissions for this action. Compound Manager must be assigned to user.`);
            return;
        }
        if (sc != 200) {
            await this.$bvModal.msgBoxOk(`Database error occurred while attempting to save data`);
            return;
        }
        this.formDataModified = false;
        this.ingredients = [];
        this.warningLabels = [];
        if (newCompoundID > 0) this.drug.compoundID = newCompoundID;
        await this.onDrugSelected(this.drug);
        await this.$bvModal.msgBoxOk(`Data saved successfully`);
        return;
    }

    async onCancelClicked() {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.drug = new CompoundDrug(null, this.drugOriginal);
        this.status = DrugStatusEnum[this.drugOriginal.activeStatus];
        this.drug.patEd = new CompoundPatEd(this.drugOriginal.patEd);
        this.drug.ingredients = Object.assign(new Array<CompoundIngredients>(), this.drugOriginal.ingredients);
        this.drug.warningLabels = Object.assign(new Array<CompoundWarningLabels>(), this.drugOriginal.warningLabels);
        this.ingredients = Object.assign(new Array<CompoundIngredients>(), this.drugOriginal.ingredients);
        this.warningLabels = Object.assign(new Array<CompoundWarningLabels>(), this.drugOriginal.warningLabels);
        this.editBrandForm = false;
        this.editPatEdForm = false;
        this.formDataModified = false;
        return;
    }

    checkFormDataModified() {
        const drugsMatch = JSON.stringify(this.drugOriginal) == JSON.stringify(this.drug);
        const ingredientsMatch = JSON.stringify(this.drugOriginal.ingredients) == JSON.stringify(this.ingredients);
        const warningLabelsMatch = JSON.stringify(this.drugOriginal.warningLabels) == JSON.stringify(this.warningLabels);
        const allMatch = (drugsMatch && ingredientsMatch && warningLabelsMatch);
        this.formDataModified = !allMatch;
    }

    async confirmIgnoreFormDataModified(): Promise<boolean> {
        try {
            const value = await this.$bvModal.msgBoxConfirm(`Data has been modified. New search selection, or Clicking 'New Drug', Clear buttons will not save data - Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            });
            return value == null ? false : value;
        } catch (err) {
            console.error("Error caught on New Drug or Clear button.", err);
            return false;
        }
    }

    async confirmIgnoreDuplicateNdc(ndc: string): Promise<boolean> {
        try {
            const value = await this.$bvModal.msgBoxConfirm(`NDC: ${ndc} - already exist! - Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            });
            return value == null ? false : value;
        } catch (err) {
            console.error("Error caught on verify duplicate NDC.", err);
            return false;
        }
    }

    async fetchByNdc(ndc: string): Promise<[number, Array<CompoundDrug>] | any> {
        try {
            const response = await Axios.get(`/Compound/ndc/${ndc}`);
            const drugNdc: CompoundDrug[] = response.data;
            const sc: number = Number(response.status);
            return [sc, drugNdc];
        } catch (error) {
            const err = error as AxiosError;
            console.error('Error while getting CompoundDrug ByNdc.', { error, response: err?.response });
            return err.response?.status;
        }
    }

    async dbSave(drug: CompoundDrug): Promise<[number, number] | number | undefined> {
        this.isNullPatEd(drug.patEd);
        console.log("drug = ", drug);
        try {
            const response = await Axios.post('/Compound', drug, { params: { bypassDuplicateCheck: true } });
            const sc: number = Number(response.status);
            let drugResponse = response.data;
            const newCompoundID: number = drugResponse.compoundID!;
            return [sc, newCompoundID];
        } catch (error) {
            const errorMsg = "Error while saving CompoundDrug";
            const err = error as AxiosError;
            console.error(errorMsg, { error, response: err?.response });
            return err.response?.status;
        }
    }

    isNullPatEd(patEd: CompoundPatEd) {
        if (patEd.description == null) patEd.description = "";
        if (patEd.contraindications == null) patEd.contraindications = "";
        if (patEd.administration == null) patEd.administration = "";
        if (patEd.missedDoseInstructions == null) patEd.missedDoseInstructions = "";
        if (patEd.monitoring == null) patEd.monitoring = "";
        if (patEd.sideEffects == null) patEd.sideEffects = "";
        if (patEd.storage == null) patEd.storage = "";
    }

    async saveAlert() {
        if (!this.alert.length) return;
        if (!this.drug || !this.drug.drugId) return;

        try {
            const response = await Axios.post(`/Drug/${this.drug.drugId}/Alert`, { body: this.alert }, { params: { drugSource: this.drug.source } });

            this.alert = response.data;
            this.$notification(NotificationOptions.successSaveNotificationPreset("Alert"));
        } catch (error) {
            console.error('There was an error while updating alert', { error, response: (error as AxiosError)?.response });
            this.$notification(NotificationOptions.error(error));
        }
    }

}
