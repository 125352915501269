
    import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
    import { DrugCategory } from '../models/DrugCategory';
    import axios from 'axios';
    import { debounce } from 'throttle-debounce';

    @Component({
        name: "CategoryPickerComponent",
        components: {},
    })
    export default class CategoryPickerComponent extends Vue {
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
        }
        private object: any = null;
        private objects: any[] = [];
        @Prop() private value!: DrugCategory;
        
        get category(): DrugCategory {
            return this.value;
        }
        set category(category: DrugCategory) {
            this.$emit('input', category);
        }

        optionLabel(option: DrugCategory) {
            return `ID:${option.id} ${option.name}`;
        }

        @Watch('object') objectUpdated(value: DrugCategory | null, oldValue: DrugCategory | null) {
            if (!value) return;
            if (value.id && (!oldValue || oldValue.id != value.id)) {
                this.fetchCategory(value);
            }
        }

        fetchCategory(category: DrugCategory) {
            axios.get(`/DrugCategory/${category.id}`)
                .then(response => {
                    this.category = response.data;
                })
                .catch(error => {
                    console.warn(error);
                });
        }
        private throttledSearch: Function = debounce(500, (loading, searchValue) => { this.onSearch(loading, searchValue) });
        searchValueUpdated(value: string, loading: any) {
            if (!value) return;
            loading(true);
            this.throttledSearch(loading, value);
        }
        onSearch(loading: any, searchValue: string) {
            if (!searchValue.length) return;
            axios.get(`/DrugCategory/`, {params: { searchTerm: searchValue } })
                .then(response => {
                    this.objects = response.data;
                })
                .catch(error => {
                    console.warn(error);
                })
                .finally(() => {
                    loading(false);
                })
        }

    }
