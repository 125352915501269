
import { Watch, Component, Vue } from "vue-property-decorator";

@Component({
    name: "App",
})
export default class App extends Vue {

    @Watch("$route", { immediate: true })
    routeUpdate(to: any, _from: any) {
        document.title = to.meta.title || "Scriptly";
    }

}
