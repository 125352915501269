import HasID from "./HasID";
import HasLabel from "./HasLabel";

export interface PMPStatus extends HasID {
    stateId: string,
    dateSubmitted: string,
    data: string,
    frequency: string
}

export class PMPStatus extends HasLabel {
    toString(): string {
        return "Status";
    }
}