
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { Prescription, RefillStatusCode } from '@/models/Prescription';
import { OrderOTCItem } from '@/models/Order';
import { CaseNote, types as CaseTypes } from '@/models/Case';
import { PrepopulateProblemQuestions } from '@/util/PrepopulatedProblemQuestions';
import { Table, TableColumn } from "element-ui";
import SearchComponent from '@/components/SearchComponent.vue';

import RXSubscriptionForm from '@/components/Prescription/RXSubscriptionForm.vue';

import axios from 'axios';
import swal from 'sweetalert2';
import moment from "moment";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import RxTransferModalComponent from "@/components/RxTransfer/RxTransferModalComponent.vue";
import { RxTransferContents } from '@/models/RxTransfer';
import { Patient } from '@/models/Patient';
import PromisedTimeSummary from "@/components/Order/PromisedTimeSummaryComponent.vue";

@Component({
    name: "PrescriptionsListComponent",
    components: {
        RxTransferModalComponent,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        //Importing this way prevents component circular reference
        RxPage: () => import('@/pages/Prescription/PrescriptionDetailsPage.vue'),
        SearchComponent, Checkbox,
        RXSubscriptionForm,
        PromisedTimeSummary
    }
})
export default class PrescriptionsListComponent extends Vue {
    @Prop({ default: null }) private prescriptions!: Array<Prescription>;
    @Prop({ default: null }) private selected!: Array<Prescription>;
    @Prop({ default: false, type: Boolean }) protected hideActionsColumn!: boolean;
    @Prop({ default: false, type: Boolean }) private colorCodePrescriptions!: boolean;
    @Prop({ default: false, type: Boolean }) protected disabled!: boolean;
    @Prop({ default: false, type: Boolean }) protected showReportProblemButton!: false;
    @Prop({ default: false, type: Boolean }) protected showProccessQueueButton!: false;
    @Prop({ default: false, type: Boolean }) private showPatientName!: boolean;
    @Prop({ default: false, type: Boolean }) private showAddOns!: boolean;
    @Prop({ default: false, type: Boolean }) protected isLoading!: boolean;
    @Prop({ default: false, type: Boolean }) protected showTransferButton!: boolean;
    @Prop({ default: false, type: Boolean }) private alwaysShowVoid!: boolean;
    @Prop({ default: true, type: Boolean }) protected showSelectCheckox!: boolean;
    @Prop({ default: true, type: Boolean }) protected showVoidBox!: boolean;
    @Prop({ default: null, type: (Function || null) }) protected customSort!: Function | null;

    @Prop({ default: "New Refills In Batch", type: String }) protected batchModalTitle!: string;
    @Prop({ default: false, type: Boolean }) private refillBatchMode!: boolean;
    @Prop() protected processingButtonCallback!: Function;
    @Prop() private colorCodes?: Function;

    @Prop() private orderID!: number;
    @Prop() private actionButtons!: Array<string>;
    @Prop() private mode!: string;
    @Prop() private tableEmptyText!: string;
    @Prop() private additionalFields!: Array<any>;
    @Prop() private fieldsKeysToRemove!: Array<any>;
    @Prop() private fieldsToRename!: Array<any>;
    @Prop() private defaultSort!: string;
    @Prop() private defaultDesc!: boolean;
    @Prop() protected patient!: Patient;

    @Prop() private manualSort?: Array<string>;

    @Ref() private rxTransferComponent!: RxTransferModalComponent;

    @PropSync('currentSort', { type: String, default: "fillDate" }) protected sortBy!: string;
    @PropSync('currentDesc', { type: Boolean, default: true }) protected sortDesc!: boolean;

    protected rxToSubscribe: Prescription = new Prescription();
    private rxToUnsuppress: Prescription = new Prescription();

    moment = moment;

    mounted() {
        if (this.defaultSort) {
            this.sortBy = this.defaultSort;
        }

        // noinspection PointlessBooleanExpressionJS
        if (this.defaultDesc != null && this.defaultDesc != undefined) {
            this.sortDesc = this.defaultDesc;
        }
    }

    protected isSortable: boolean = true;
    private showVoidRxs: boolean = false;
    protected rxForBatch: Array<Prescription> = [];
    private prescriptionsSelected: Prescription[] = [];
    private selectedProblem = null;
    private problemNote = "";
    private suppressReason = "";
    protected suppressReasons: string[] = ['No Longer Taking', 'Too Much On Hand', 'Too Expensive', 'Other'];

    get isManualSort() {
        return !!this.manualSort?.length;
    }

    get disableBatchRefill() {
        if (this.prescriptionsSelected.length == 0) return true;
        if (!this.prescriptionsSelected.every(rx=>!rx.isExpired && !rx.isVoid)) return true;
        return false;
    }

    get disableTransfer() {
        if (this.prescriptionsSelected.length == 0) return true;
        if (!this.prescriptionsSelected.every(rx=>!rx.isExpired && !rx.isVoid)) return true;
        return false;
    }

    protected problems = [
        { value: null, text: 'Select A Problem' },
        ...CaseTypes
    ];

    private rxFields: any[] = [
        { key: "select", label: "", sortable: false },
        { key: "hasAddons", label: "Adds", sortable: true, class: this.showAddOns ? "" : "d-none" },
        { key: "productNameShort", label: "Drug", sortable: this.isManualSort ? this.manualSort?.includes('productNameShort') : true },
        { key: "patientName", label: "Patient", sortable: this.isManualSort ? this.manualSort?.includes('patientName') : false, class: this.showPatientName ? "" : "d-none" },
        { key: "storeName", label: "Store", sortable: this.isManualSort ? this.manualSort?.includes('storeName') : false },
        { key: "rxId", label: "Rx Id", sortable: this.isManualSort ? this.manualSort?.includes('rxId') : false },
        { key: "daySupply", label: "DS", sortable: this.isManualSort ? this.manualSort?.includes('daySupply') : false, class: "text-center", },
        { key: "dispensed", label: "Dispensed", sortable: this.isManualSort ? this.manualSort?.includes('dispensed') : false, class: "text-center", },
        { key: "qtyLeft", label: "Quantity Left", sortable: false, class: "text-center", },
        { key: "fillDate", label: "Date", sortable: this.isManualSort ? this.manualSort?.includes('fillDate') : true, sortDirection: "desc", formatter: this.dateFormatter },
        { key: "patientPay", label: "Patient Pay", sortable: this.isManualSort ? this.manualSort?.includes('patientPay') : false, },
        { key: "programName", label: "Program", sortable: this.isManualSort ? this.manualSort?.includes('programName') : true },
        { key: "edit", label: "", class: "edit-col" },
    ];

    private incompleteAnswersFields: any[] = [
        { key: "patientName", label: "Patient", sortable: false },
        { key: "patientGender", label: "Gender", sortable: true, },
        { key: "rma", label: "RMA #", sortable: true },
        { key: "ipledge", label: "IPledge #" },
        { key: "dispenseBeforeDate", label: "Dispense before Date" },
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "storeName", label: "Store", sortable: false },
        // { key: "rxWorkflowStatus", label: "Rx Status", sortable: true },
        { key: "orderCreated", formatter: this.dateFormatter, sortable: true, },
        { key: "rxId", label: "Rx Id", sortable: false },
        {
            key: "fillDate",
            label: "Fill Date",
            sortable: true,
            sortDirection: "desc",
            formatter: this.dateFormatter
        },
        { key: "patientPay", label: "Patient Pay", sortable: false, },
        { key: "programName", label: "Program", sortable: true },
        { key: "edit", label: "", },
    ];

    get voidAndExpiredCount(): number {
        return this.prescriptions.length - this.prescriptionsToShow.length;
    }

    created() {
        this.isSortable = this.mode != 'print';

        if (this.fieldsKeysToRemove?.length) {
            this.rxFields = this.rxFields.filter(col => !this.fieldsKeysToRemove.some(key => key == col.key));
        }

        this.handleAdditionalFields();

        if (this.fieldsToRename?.length) {
            this.fieldsToRename.forEach(ren => {
                let field = this.rxFields.find(col => col.key == ren.key);
                if (field) field.label = ren.label;

            });
        }
    }

    @Watch('additionalFields')
    private handleAdditionalFields() {
        const addFieldKey = "_internal_add_field";

        //Remove all additional fields
        this.rxFields = this.rxFields.filter(f => !f[addFieldKey]);

        //Add each additional field
        if (!this.additionalFields?.length) return;

        //Mark these as additional so we can remove them on updates
        const addFields = this.additionalFields.map(af => {
            const addField = { ...af };
            addField.field[addFieldKey] = true;
            return addField;
        })

        const editCol = this.rxFields.find(col => col.key == 'edit');
        this.rxFields = this.rxFields.filter(col => col.key != 'edit');

        const fieldsWithIndex = addFields.filter(col => col.index != null);
        if (fieldsWithIndex?.length) {
            fieldsWithIndex.forEach(col => {
                this.rxFields.splice(col.index + 1, 0, col.field);
            });
        }

        const fieldsWithNoIndex = addFields.filter(col => col.index == null).map(col => col.field);
        if (fieldsWithNoIndex?.length)
            this.rxFields = this.rxFields.concat(fieldsWithNoIndex);

        this.rxFields.push(editCol);
    }

    get selectedWithNoRemainingQuantity(): boolean {
        for (let i = 0; i < this.prescriptionsSelected.length; i++) {

            let rx = this.prescriptionsSelected[i];
            console.log("rx remainig: ", rx.rxID, rx.totalAllowedToDispense - rx.totalDispensed);
            if (rx.totalAllowedToDispense - rx.totalDispensed <= 0) {
                return true;
            }
        }
        return false;
    }

    DetermineFieldsByMode(mode: string) {
        if (mode == "IncompleteAnswers") {
            return this.incompleteAnswersFields;
        } else {
            return this.rxFields;
        }
    }

    get _tableEmptyText(): string {
        return this.tableEmptyText || "No Prescriptions to Show";
    }

    get actionSpecified() {
        return this.showAction('verify') || this.showAction('edit') || this.showAction('delete');
    }

    @Watch("sortDesc", { immediate: true })
    sortDirChange(_val: any, _oldVal: any) {
    }

    @Watch('prescriptions')
    changePrescriptions() {
        this.rxForBatch = [];
        this.prescriptionsSelected = [];
    }

    get prescriptionsToShow(): Array<Prescription> {
        return this.prescriptions.map(rx => new Prescription(rx))
            .filter(rx => this.alwaysShowVoid || this.showVoidRxs || (!rx.isVoid && !rx.isExpired));
    }

    @Watch('selectedProblem')
    autoProblemQuestions() {
        this.problemNote = new PrepopulateProblemQuestions().prePopulateProblemQuestions(this.selectedProblem);
    }

    dateFormatter(value: Date, _key: string, _item: any) {
        const dt = moment.utc(value);
        if (dt.isValid()) return dt.format("L");
        return "";
    }

    showAction(action: string) {
        return this.actionButtons?.some(a => a == action);
    }

    remove(rxRow: Prescription) {
        this.$emit('prescriptionRemoved', rxRow);
    }

    getPrescriptionDetailsRoute(rxRow: Prescription) {
        return {
            name: 'PrescriptionDetails'
            , params: {
                storeID: rxRow.storeID,
                rxNumber: rxRow.rxNumber,
                rfNumber: (rxRow.rfNumber || 0)
            }
        };
    }

    getRouteWithRxArgument(routeName: string, rxRow: Prescription) {
        return {
            name: routeName
            , params: {
                orderID: this.orderID,
                storeID: rxRow.storeID,
                rxNumber: rxRow.rxNumber,
                rfNumber: (rxRow.rfNumber || 0)
            }
        };
    }

    getVerifyActionButtonsClass(rxRow: Prescription) {
        return {
            'text-info': (rxRow.pV2ID || 0) == 0,
            'text-success': (rxRow.pV2ID || 0) > 0,
            'text-warning': (rxRow.dispenseErrorId || 0) > 0
        };
    }

    colorCodeTable(rx: Prescription, type: any) {
        if (this.colorCodes) {
            return this.colorCodes(rx, type);
        }
        if (!rx || type !== 'row' || !this.colorCodePrescriptions)
            return;
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-danger strikethrough';
        else if (rx.isExpired) return 'table-danger';
        else if (!rx.isRefillableStatus)
            return 'table-dark';
        else if (moment(rx.fillDate).isAfter(moment(), 'day'))
            return 'table-warning';
    }

    isFuture(date: any) {
        return moment(date).isAfter(moment(), 'day');
    }

    tooltipText(rxRow: Prescription) {
        let text: string;
        if ((rxRow.dispenseErrorId || 0) > 0)
            text = 'Error reported';
        else if ((rxRow.pV2ID || 0) > 0)
            text = 'Verified';
        else
            text = 'Verify';

        return text;
    }

    saveCase() {
        let newCaseNote: CaseNote | any = {
            id: 0,
            patientId: this.patient.id,
            problem: this.selectedProblem,
            error: false,
            note: this.problemNote,
            resolvedBy: 'false',
            acceptedBy: 'false'
        };
        axios.post('/Case/', {
            caseProblem: newCaseNote,
            prescriptions: this.prescriptionsSelected
        })
            .then(response => {
                swal.fire({
                    title: `Case Number`,
                    text: response.data.id,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                });
            })
            .catch(error => {
                console.warn(error);
            });
    }

    @Watch('selected') buttonSelect() {
        this.prescriptionsSelected = this.selected;
    }

    @Watch('prescriptionsSelected') inputSelectedRxs(newSelectedRxs: Array<Prescription>) {
        this.$emit('selection-changed', newSelectedRxs);
    }

    isSamePrescriptionOrder(rx1: Prescription, rx2: Prescription) {
        return rx1.storeID == rx2.storeID && rx1.rxNumber == rx2.rxNumber &&
            rx1.rfNumber == rx2.rfNumber && rx1.orderId == rx2.orderId;
    }

    //Take only the max RfNumber from group of StoreId - RxNbr
    openBatch(rxForBatch: Array<Prescription>) {
        if (this.selectedWithNoRemainingQuantity) {
            // Show your alert here.
            this.$notification(NotificationOptions.notificationOptionsPreset('Cannot create a new batch refill, selected prescriptions have no refills available.', NotificationOptions.NotificationTypes.warning));

            return;
        }

        if (this.refillBatchMode) {
            if (this.prescriptionsSelected?.length) {
                const sameStoreRx = (rx: any, rx2: any) => {
                    return rx.storeID == rx2.storeID && rx.rxNumber == rx2.rxNumber;
                };

                const findMaxRfInStoreRxGroup = (rx1: Prescription, rx2: Prescription): Prescription => {
                    return (rx1.rfNumber || 0) > (rx2.rfNumber ?? 0) ? rx1 : rx2;
                };

                //Find the distinct StoreId - RxNumber combinations in the selection
                const distinctStoreRxList = this.prescriptionsSelected.filter((rx, i, rxs) => {
                    const i2 = rxs.findIndex(rx2 => sameStoreRx(rx, rx2));
                    return i2 == i;
                }).map(rx => {
                    return { storeID: rx.storeID, rxNumber: rx.rxNumber };
                });

                //Find the prescription with the max RfNumber for each distinct StoreId - RxNumber combination
                this.rxForBatch = distinctStoreRxList.map(ids => {
                    const group = this.prescriptionsToShow.filter(rx => sameStoreRx(rx, ids));
                    return Object.assign(new Prescription(), group.reduce(findMaxRfInStoreRxGroup));
                });
            } else {
                this.rxForBatch = rxForBatch;
            }
        } else {
            if (this.prescriptionsSelected?.length)
                this.rxForBatch = this.prescriptionsSelected;
            else if (rxForBatch?.length)
                this.rxForBatch = rxForBatch;
            else
                this.rxForBatch = this.prescriptions;
        }

        this.$bvModal.show('rx-modal');
    }

    batchFinished(rx?: Prescription) {
        this.$bvModal.hide('rx-modal');
        this.$emit('batchFinished', rx);
    }

    openSubscriptionForm(rx: Prescription) {
        this.rxToSubscribe = rx;
        this.$bvModal.show('rx-subscription');
    }

    openUnsuppressForm(rx: Prescription) {
        this.rxToUnsuppress = rx;
        this.$bvModal.show('rx-unsuppress');
    }

    closeSubscriptionModal() {
        this.$bvModal.hide('rx-subscription');
        this.$emit('newSubscriptionSaved');
    }

    showSubscribeButton(rx: Prescription) {
        return this.showAction('subscribe') && rx.isAvailableForAutorefill;
    }

    showSuppressButton(rx: Prescription) {
        return this.showAction('suppress') && rx.suppression != null;
    }

    getSuppressionTitle(rx: Prescription) {
        return `${rx.suppression.reason} on ${new Date(rx.suppression.created).toLocaleDateString(navigator.language, {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
        })}`;
    }

    confirmUnsuppress() {
        this.$bvModal.hide('rx-unsuppress');
        var supp = this.rxToUnsuppress.suppression;

        supp.active = false;

        axios.post("/Prescription/Suppression", supp).then((_response: any) => {
            this.$notification(
                NotificationOptions.successSaveNotificationPreset(
                    `Prescription reminders resumed for ${this.rxToUnsuppress.rxID}`
                )
            );
            this.$emit('batchFinished', this.rxToUnsuppress);
        })
    }

    confirmSuppress() {
        this.$bvModal.hide('rx-suppress');
        var ctr = 0;
        this.prescriptionsSelected.forEach((rx: Prescription, _index: number, array: Prescription[]) => {
            var supp = {
                storeId: rx.storeID,
                rxNumber: rx.rxNumber,
                active: true,
                reason: this.suppressReason
            };
            axios.post("/Prescription/Suppression", supp).then((_response: any) => {
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset(
                        `Prescription reminders paused for ${rx.rxID}`
                    )
                );
                ctr++;
                if (ctr == array.length) {
                    this.$emit('batchFinished', rx);
                }
            });
        });
    }

    getAddOns(AddOnsList: OrderOTCItem) {
        if (AddOnsList != null) {
            let a = '<strong> Add Ons </strong>'
            Object.entries(AddOnsList).forEach(
                ([_key, value]) =>
                    a += '<br /> ' + value.otcItemName + ': ' + value.quantity + (value.quantity == 1 ? ' pc' : ' pcs')
            )
            return a;
        }
        return '';
    }

    showRxTransfer() {
        const contents = this.prescriptionsSelected.map(rx => {
            return { medicationName: rx.drugName, rxNumber: rx.rxNumber } as unknown as RxTransferContents
        });
        if (this.rxTransferComponent) this.rxTransferComponent.startingContents = contents;
    }

    transferOut() {
        return this.prescriptionsSelected;
    }

    selectRow(item: Prescription, _index: number, _event: Event) {
        if (!this.showSelectCheckox) return;
        if (!item.isActive) return;
        const foundIdx = this.prescriptionsSelected.findIndex(value => value.rxID === item.rxID);
        if (foundIdx === -1) {
            this.prescriptionsSelected.push(item);
        } else {
            this.prescriptionsSelected.splice(foundIdx, 1);
        }
    }

}

export class PrescriptionListColumn {
    public key?: string;
    public label?: string;
    public sortable?: boolean;
    public class?: string;
    public sortDirection?: string;
    public formatter?: Function;
}

