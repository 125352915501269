export interface Voicemail{
    id: number;
    blobName?: string;
    received?: Date;
    transcript?: string;
    triagedTo?: string;
    triaged?: Date;
    triagedBy?: string;
    completed?: Date;
    completedBy?: string;
    patientId?: number;
    team?: string;
}

export enum Team{
    untriaged = 0,
    // infusionTeam = 1,
    compoundingTeam = 2,
    pharmacistTeam = 3,
    // painManagementTeam = 4,
    // emergingTherapiesTeam = 5,
    priorAuthorizationTeam = 6,
    rxClarificationResponse = 7,
    patientPrescriberComplaint = 8,
}
export interface VoicemailGroupCount{
name?: string;
count?: number;
}

export class Voicemail{
    constructor(obj?: Voicemail){

        if (obj) Object.assign(this, obj);
    }
}
