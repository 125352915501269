
import { Component, Vue } from "vue-property-decorator";
import { DatePicker } from "element-ui";
import SearchComponent from "@/components/SearchComponent.vue";
import { Program } from "@/models/Program";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import moment from "moment";
import { BvTableCtxObject } from "bootstrap-vue";
import axios, { AxiosRequestConfig } from "axios";
import { Prescription, RefillStatusCode } from "@/models/Prescription";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import numeral from "numeral";

@Component({
    name: "ProgramDashboard",
    components: {
        [DatePicker.name]: DatePicker,
        SearchComponent
    },
})
export default class ProgramDashboard extends Vue {
    private program: Program = new Program();

    private toDate: any = moment().toDate();
    private fromDate: any = moment().subtract(7, "days").toDate();

    protected fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "prescriber", formatter: this.prescriberFormatter, sortable: true },
        { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col" },
        { key: "patient.addressState", label: "Patient State", sortable: true, },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        { key: "writtenDate", formatter: this.shortDate, sortable: true, },
        { key: "fillDate", formatter: this.shortDate, sortable: true, },
        { key: "patientPay", formatter: this.moneyFormat, sortable: true },
        { key: "programName", label: "Program", sortable: true },
        { key: "state" },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" }
    ];

    get programId(): number {
        return this.program?.id ?? 0;
    }

    get emptyText(): string {
        if (this.programId == 0) return "You must select a program";
        return `No Prescriptions for ${this.program.name} between ${moment(this.fromDate).format("L")} and ${moment(this.toDate).format("L")}`;
    }

    get tableFilter(): AxiosRequestConfig {
        return {
            params: {
                programId: this.programId,
                fromDate: this.fromDate,
                toDate: this.toDate
            }
        };
    }

    private prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    private patientFormatter(pt: Patient): string {
        pt = new Patient(pt);
        return pt.displayNamesForPerson();
    }

    private shortDate(d: any): string {
        if (d == null) return "";
        return moment(d).format("L");
    }

    private moneyFormat(d?: number): string {
        if (d == null) return "";
        return numeral(d).format("$0,0.00");
    }

    async tableProvider(ctx: BvTableCtxObject) {
        if (this.programId === 0) return [];
        const uri = ctx.apiUrl + "";
        const config = ctx.filter as AxiosRequestConfig;
        try {
            const resp = await axios.get<Prescription[]>(uri, config);
            return resp.data.map(rx => new Prescription(rx));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
            return [];
        }
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        if (rx.status == RefillStatusCode.TransferOut) return "table-info";
        else if (rx.status == RefillStatusCode.Void) return "table-dark";
        else if (!rx.isRefillableStatus) return "table-danger";
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }

}
