<template>

    <div :class="{ 'pager-bar': true, 'no-stick': noStick }">
        <div class="d-flex align-items-center">
            <b-form v-if="showPageSizeSelector" inline>
                <label class="mr-2 mb-0" for="pager-bar-page-size">Page Size</label>
                <b-select id="pager-bar-page-size" :value="perPage" size="sm" :options="pageSizeOptions"
                    @change="$emit('change-page-size', $event)" />
            </b-form>
            <slot name="left" />
        </div>

        <div class="flex-fill">
            <b-pagination v-if="!hidePager" :value="value" :total-rows="totalRows" :per-page="perPage" align="center"
                @input="$emit('input', $event)" />
        </div>

        <div class="d-flex align-items-center flex-row-reverse">
            <slot name="right" />
            <div v-if="showTotal">
                {{ totalRows }} total {{ totalRows !== 1 ? itemName + "s" : itemName }}
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "BottomPagerBar",
    props: {
        totalRows: Number,
        perPage: Number,
        value: Number,
        showTotal: Boolean,
        showPageSizeSelector: Boolean,
        hidePager: Boolean,
        noStick: Boolean,
        itemName: {
            type: String,
            default: "item"
        }
    },
    data() {
        return {
            startingPerPage: this.perPage
        }
    },
    computed: {
        pageSizeOptions() {
            const defaults = [25, 50, 100, 250];

            if (this.startingPerPage && !defaults.includes(this.startingPerPage)) {
                defaults.push(this.startingPerPage);
            }

            defaults.sort((a, b) => a - b);

            return defaults;
        }
    }
}

</script>

<style scoped lang="scss">
.pager-bar {
    position: sticky;
    bottom: 0;
    z-index: 20;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    min-height: 50px;
    border-top: 1px solid rgba(0, 0, 0, .05);
    box-shadow: inset 0px 7px 6px -6px rgba(0, 0, 0, .1);
    margin: 0 -15px -10px -15px;
    padding: 10px 15px 10px 15px;

    .b-pagination {
        margin-bottom: 0;
    }

    &.no-stick {
        position: inherit;
    }

    .d-flex {
        gap: 8px;
    }
}
</style>