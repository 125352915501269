
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SearchComponent from "@/components/SearchComponent.vue";
import { Drug } from "@/models/Drug/Drug";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import moment from "moment";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { AuditPrescriptionDocument, BillReference } from "@/models/Audit";
import NavbarToggleButton from "@/components/Navbar/NavbarToggleButton.vue";
import AuditFaxComponent from "@/components/Audit/AuditFaxComponent.vue";
import ImageComponent from "@/components/Prescription/ImageComponent.vue";
import PDFObject from "pdfobject";
@Component({
    name: "AuditPrescriptionComponent",
    components: { SearchComponent, NavbarToggleButton, AuditFaxComponent, ImageComponent },
})
export default class AuditPrescriptionComponent extends Vue {
    @Prop({ type: Number }) private id!: number;
    @Prop({ default: {} as Array<BillReference> }) private rxList!: BillReference[];
    @Prop({ type: Number }) private audStatus!: number;
    @Prop({ type: Number }) private storeId!: number;
    private BRNRecord: BillReference = new BillReference();
    private clawRecord: any = [];
    private ndRecord: any = [];
    private rxRecord: any = [];
    private opened: any = [];
    private allRxRecord!: BillReference[];
    private billClawback: BillReference = new BillReference();
    private clawAmount: number = 0;
    private note = "";
    private rxNumberAsString: string = "";
    private auditNumberAsString: string = "";
    private hasDocu: boolean = false;
    private processingAdds: number = 0;

    created() {
        if (this.rxList.length != 0) {
            this.allRxRecord = this.rxList;
            this.regroupPrescription();
        }
    }

    regroupPrescription() {
        if (this.allRxRecord != undefined) {
            this.allRxRecord.map((o: BillReference) => {
                o.brnString = o.storeId + "-" + o.rxNumber + "-" + o.rfNumber;
            });
            if (this.audStatus > 2) {
                this.rxRecord = this.allRxRecord.filter((b) => b.status == 0);
                this.clawRecord = this.allRxRecord.filter((b) => b.status == 2);
                this.ndRecord = this.allRxRecord.filter((b) => b.status == 1);
            } else {
                this.rxRecord = this.allRxRecord;
                this.clawRecord = [];
                this.ndRecord = [];
            }
            this.$emit("hasClaw", this.clawRecord.length != 0 ? true : false);
            this.$emit("status", this.audStatus);
            this.$emit("hasUncategorized", this.rxRecord.length != 0 ? true : false);
            this.isGenerateEnable();
        }
    }

    newRx() {
        this.BRNRecord = new BillReference();
        this.$bvModal.show("prescription-modal");
    }

    addPrescription() {
        this.BRNRecord.auditId = this.id;
        if (this.BRNRecord.rxNumber == undefined || this.BRNRecord.rxNumber == 0) {
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    "you did not select a prescription",
                    NotificationOptions.NotificationTypes.danger
                )
            );
            return;
        }
        this.processingAdds++;
        axios
            .post<Array<BillReference>>(`/AuditTracking/rx-list`, this.BRNRecord)
            .then((res) => {
                this.allRxRecord = res.data;
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        "Prescription Added Successfully",
                        NotificationOptions.NotificationTypes.success
                    )
                );
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while saving Prescription.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            })
            .finally(() => {
                this.processingAdds--;
            });
    }

    async setNoDiscrepancies(bill: BillReference) {
        axios
            .post<Array<BillReference>>(`/AuditTracking/no-disc`, bill)
            .then((res) => {
                this.allRxRecord = res.data;
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while saving no discrepancies.", {
                    err,
                    response: err.response,
                });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            });
    }
    openClawbackModal(bill: BillReference) {
        this.billClawback = bill;
        this.clawAmount = 0;
        this.note = "";
        this.$bvModal.show("clawback-modal");
    }
    addClawback() {
        if (this.clawAmount == 0) {
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    "Enter a clawback amount",
                    NotificationOptions.NotificationTypes.danger
                )
            );
            return;
        }
        if (this.note == "") {
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    "Input the note for the clawback",
                    NotificationOptions.NotificationTypes.danger
                )
            );
            return;
        }
        this.billClawback.clawAmount = this.clawAmount;
        this.billClawback.note = this.note;
        axios
            .post<Array<BillReference>>(`/AuditTracking/clawback`, this.billClawback)
            .then((res) => {
                this.allRxRecord = res.data;
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        "Prescription Successfully added a clawback",
                        NotificationOptions.NotificationTypes.success
                    )
                );
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while saving clawback.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            });
    }
    async revertRx(bill: BillReference) {
        axios
            .post<Array<BillReference>>(`/AuditTracking/revert-rx`, bill)
            .then((res) => {
                this.allRxRecord = res.data;
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        "Prescription Successfully revert as Uncategorized",
                        NotificationOptions.NotificationTypes.success
                    )
                );
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while saving reverting the prescription.", {
                    err,
                    response: err.response,
                });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            });
    }
    genRx() {
        if (this.audStatus == 1) {
            this.$emit("updateToSubmitted");
        }
        const config: AxiosRequestConfig = {
            params: { filename: this.id + ".pdf", auditId: this.id },
            responseType: "blob",
        };
        axios
            .get<any>("AuditTracking/get-global-document-filename", config)
            .then((res) => {
                const objectUrl = URL.createObjectURL(res.data);
                window.open(objectUrl, "_blank");
            })
            .catch((err) => {
                if (err.response.status == "404") {
                    this.$notification(
                        NotificationOptions.error("ERROR: Global Document does not exists")
                    );
                } else {
                    console.error("Error while getting global documents.", {
                        err,
                        response: err.response,
                    });
                    this.$notification(
                        NotificationOptions.errorSaveNotificationPreset(err.response)
                    );
                }
            });
    }

    openUploadDocu(bill: BillReference) {
        this.rxNumberAsString = bill.brnString;
        this.auditNumberAsString = bill.auditId.toString();
        this.$bvModal.show("audit-fax");
    }

    @Watch("audStatus")
    changeStatus(newVal: number, oldVal: number) {
        this.regroupPrescription();
    }

    async deleteRx(bill: BillReference) {
        await this.$bvModal
            .msgBoxConfirm("Are you sure you want to delete this prescription?", {
                title: "Confirm",
                okVariant: "success",
                centered: true,
            })
            .then((value) => {
                if (value) {
                    axios
                        .post<Array<BillReference>>(`/AuditTracking/delete-rx`, bill)
                        .then((res) => {
                            this.allRxRecord = res.data;
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset(
                                    "Prescription Deleted Successfully",
                                    NotificationOptions.NotificationTypes.success
                                )
                            );
                            this.regroupPrescription();
                        })
                        .catch((err) => {
                            console.error("Error while deleting prescription.", {
                                err,
                                response: err.response,
                            });
                            this.$notification(
                                NotificationOptions.errorSaveNotificationPreset(err.response)
                            );
                        });
                }
            })
            .catch((err) => {
                console.log("Error caught in showConfirmationModal()");
                console.error(err);
            });
    }

    async toggle(rxString: string) {
        console.log("AuditPrescriptionComponent");
        const config: AxiosRequestConfig = { params: { rxString: rxString, auditId: this.id } };
        axios
            .get("AuditTracking/get-documents-per-rx", config)
            .then((res) => {
                this.allRxRecord.map((o: BillReference) => {
                    if (o.brnString == rxString) {
                        let a = Array<AuditPrescriptionDocument>();
                        res.data.map((b: any) => {
                            a.push(new AuditPrescriptionDocument(b));
                        });

                        o.fileName = a;
                    }
                });
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while saving Prescription.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            });

        const index = this.opened.indexOf(rxString);
        if (index > -1) {
            this.opened.splice(index, 1);
        } else {
            this.opened.push(rxString);
        }
    }

    async viewPreviewDocument(file: AuditPrescriptionDocument, rxString: string) {
        const config: AxiosRequestConfig = {
            params: { rxString: rxString, filename: file.fileName.split("/")[1], auditId: this.id },
            responseType: "blob",
        };
        axios
            .get<any>("AuditTracking/get-support-document-filename", config)
            .then((res) => {
                this.allRxRecord.map((o: BillReference) => {
                    if (o.brnString == rxString) {
                        const objectUrl = URL.createObjectURL(res.data);
                        const options = {
                            pdfOpenParams: {
                                view: "FitW",
                                pagemode: "thumbs",
                                toolbar: 1,
                                scrollbar: 0,
                            },
                        };
                        PDFObject.embed(objectUrl, "#pdf_" + rxString, options);
                    }
                });
                this.regroupPrescription();
            })
            .catch((err) => {
                console.error("Error while viewing a document.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
            });
    }

    isGenerateEnable() {
        if (!this.hasDocu) {
            this.allRxRecord.map((o: any) => {
                if (!this.hasDocu) {
                    const config: AxiosRequestConfig = {
                        params: { rxString: o.brnString, auditId: this.id },
                    };
                    axios
                        .get<Array<string>>("AuditTracking/get-documents-per-rx", config)
                        .then((res) => {
                            if (res.data != undefined) {
                                this.hasDocu = true;
                            }
                        })
                        .catch((err) => {
                            console.error("Error while saving Prescription.", {
                                err,
                                response: err.response,
                            });
                            this.$notification(
                                NotificationOptions.errorSaveNotificationPreset(err.response)
                            );
                        });
                }
            });
        }
    }

    pdfContainerID(brnString: string): string {
        return "pdf_" + brnString;
    }
}
