
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import HasLabel from '@/models/HasLabel'

    @Component({
        name: "EntitySelectionList",
    })
    export default class EntitySelectionList extends Vue {
        @Prop({ default: null }) private list!: Array<any>;
        @Prop({ default: null }) private optionLabel!: Function;
        @Prop({ default: null }) private customId!: Function;

        private modelObject: any = {};

        removeItem(item: any){
            this.$emit('remove-entity', item);
        }

        getId(object:any){
            let id = object?.id || 0;
            if(this.customId)
                id = this.customId(object);

            return id;
        }

        printLabel(o: any) {
            o = this.toModelType(o);
            if(this.optionLabel){
                return this.optionLabel(o);
            }
            else if (o instanceof HasLabel)
                return this.toModelType(o).getLabel()
            else return (o ? o.id : '')
        }

        toModelType(o:any){
            if(this.modelObject instanceof HasLabel)
                return Object.assign(this.modelObject.constructor(), o);
            else return o;
        }
    }

