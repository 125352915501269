import { render, staticRenderFns } from "./ActiveQueuePage.vue?vue&type=template&id=33c38d40&scoped=true"
import script from "./ActiveQueuePage.vue?vue&type=script&lang=ts"
export * from "./ActiveQueuePage.vue?vue&type=script&lang=ts"
import style0 from "./ActiveQueuePage.vue?vue&type=style&index=0&id=33c38d40&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c38d40",
  null
  
)

export default component.exports