
import Vue from 'vue';
    import { Component } from 'vue-property-decorator';
import { DatePicker } from "element-ui";
import SearchComponent from '@/components/SearchComponent.vue';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import { Prescription } from '@/models/Prescription';
import { Store } from '@/models/Store';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { AxiosError } from 'axios';

@Component({
    name: "PV1Queue",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        PrescriptionsListComponent,
        Checkbox
    },
})
export default class PV1Queue extends Vue {
        protected prescriptions: Array<Prescription> = [];
    private storeFilter: Store = new Store();

    protected isLoading = false;
    protected actionButtons = ['pv1'];
    protected showOpenDispense = false;

    mounted() {
        this.load();
    }

    async load() {
        try {
            this.isLoading = true;
            const response = await this.$http.get<Prescription[]>('api/prescription/pv1-queue');
            this.prescriptions = response.data;
        } catch (err) {
            const error = err as AxiosError;
            console.error("Error while loading PV1 queue.", { error, response: error?.response });
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.isLoading = false;
        }
    }

    get filteredPrescriptions() {
        let scripts = this.prescriptions;
        if (this.storeFilter?.id) {
            scripts = scripts.filter(p => p.storeID == this.storeFilter.id);
        }
        if (!this.showOpenDispense) {
            scripts = scripts.filter(p => !p.dispenseErrorId);
        }
        return scripts;
    }

    get openErrorCount() {
        return this.prescriptions?.filter(p => !this.storeFilter?.id || p.storeID == this.storeFilter.id)
            .filter(p => p.dispenseErrorId).length;
    }
}

