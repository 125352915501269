
    import { Component, Ref, Vue } from "vue-property-decorator";
    import axios, { AxiosRequestConfig } from "axios";
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import EnumSelector from "@/components/EnumSelector.vue";
    import moment from "moment";
    import { EDIOrder, EDIOrderStatus } from "@/models/EDI/EDIOrder";
    import { BTable, BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
    import SearchComponent from "@/components/SearchComponent.vue";
    import { Store } from "@/models/Store";
    import { Vendor } from "@/models/Vendor";
    import CreatePurchaseOrderForm from "@/pages/EDI/CreatePurchaseOrderForm.vue";

    @Component({
        name: "EdiOrderingPage",
        components: {
            CreatePurchaseOrderForm,
            SearchComponent,
            EnumSelector,
        },
    })
    export default class EDIOrdering extends Vue {
        private storeFilter: Store = new Store();
        private vendorFilter: Vendor = new Vendor();
        private selectedStatus: string | null = null;

        protected vendors: Vendor[] = [];
        protected stores: Store[] = [];

        protected ediOrderStatus = EDIOrderStatus;

        private errorMessage: string = "";

        @Ref('ordering-table') readonly orderingTable!: BTable;

        get errorDisplay(): string {
            if (this.errorMessage === "") return "No Orders Found";
            return `ERROR: ${this.errorMessage}`;
        }

        get tableFields(): BvTableFieldArray {
            return [
                { key: "status", label: "Status", formatter: (a: EDIOrderStatus) => EDIOrderStatus[a], sortable: true },
                {
                    key: "storeId",
                    label: "Store",
                    formatter: (id: number) => this.stores?.find(s => s.id === id)?.name ?? id,
                    sortable: true
                },
                {
                    key: "vendorId",
                    label: "Vendor",
                    formatter: (id: number) => this.vendors?.find(v => v.id === id)?.name ?? id,
                    sortable: true
                },
                { key: "poNumber", label: "PO Number" },
                { key: "created", label: "Created", formatter: (d: any) => moment(d).format("L"), sortable: true },
                { key: "createdBy", label: "Created By" },
                { key: "edit", label: "" },
            ];
        }

        get tableFilter(): AxiosRequestConfig {
            return {
                params: {
                    status: this.selectedStatus,
                    storeId: this.storeFilter?.id ?? 0,
                    vendorId: this.vendorFilter?.id ?? 0
                }
            } as AxiosRequestConfig;
        }

        async tableProvider(ctx: BvTableCtxObject): Promise<EDIOrder[]> {
            const uri = ctx.apiUrl + "";
            const config = ctx.filter as AxiosRequestConfig;
            try {
                const resp = await axios.get<EDIOrder[]>(uri, config);
                this.errorMessage = "";
                return resp.data.map(a => new EDIOrder(a));
            } catch (err) {
                this.errorMessage = err.response?.data?.message;
                this.$notification(NotificationOptions.error(err));
                return [];
            }
        }

        modalHide(response: any) {
            console.log("modalHide response:", response);

            if (response instanceof EDIOrder) {
                this.$bvModal.hide('create-purchase-order');
                this.orderingTable.refresh();
            }

        }
    }
