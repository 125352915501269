import { TextMessageTemplates } from "./TextMessageTemplates";

export interface Configuration {
    refillTextDays: number;
    oldScriptRefillTextDays: number;
    requireExplicitVerificationPV1: boolean;
    requireExplicitVerificationPV2: boolean;
    autoRefillPromptStatement: string;
    autoRefillPromptStatementSpanish: string;
    autoRefillPromptStatementRussian: string;
    autoRefillPromptStatementSomali: string;
    autoRefillPromptStatementArabic: string;
    autoRefillPromptStatementChineseSimplified: string;
    autoRefillPromptStatementVietnamese: string;
    autoRefillPromptStatementFarsi: string;
    autoRefillPromptStatementKorean: string;
    autoRefillPromptStatementRomanian: string;
    autoRefillPromptStatementSwahili: string;
    autoRefillPromptStatementBurmese: string;
    autoRefillPromptStatementNepali: string;
    autoRefillPromptStatementAmharic: string;
    autoRefillPromptStatementPashto: string;
    autoCaseAppNewPatientCreation: boolean;
    autoCaseTextsNoValidNumbers: boolean;
    autoCaseTextsPatientContactFailure: boolean;
    autoCaseTextsFailAutoRefill: boolean;
    partialPaymentTextThresholdAmount: number;
    npcTextThresholdAmount: number;
    infinitrakApiKey: string;
    disableMedGuidePrinting: boolean;
    inventoryReasons: string[];
    textMessageTemplates: TextMessageTemplates;
    readyForPickupTexts: boolean;
    disableWebNotifications: boolean;
    orderCancellationReasons: string[];
    requireLotsOnInventoryBatches: boolean;
    inventoryBatchSubmitEndpoint: string;
}

export class Configuration {
    constructor(obj?: Configuration) {
        this.refillTextDays = 0;
        this.oldScriptRefillTextDays = 0;
        this.requireExplicitVerificationPV1 = false;
        this.requireExplicitVerificationPV2 = false;
        this.autoRefillPromptStatement = "";
        this.autoRefillPromptStatementSpanish = "";
        this.autoRefillPromptStatementRussian = "";
        this.autoRefillPromptStatementSomali = "";
        this.autoRefillPromptStatementArabic = "";
        this.autoRefillPromptStatementChineseSimplified = "";
        this.autoRefillPromptStatementVietnamese = "";
        this.autoRefillPromptStatementFarsi = "";
        this.autoRefillPromptStatementKorean = "";
        this.autoRefillPromptStatementRomanian = "";
        this.autoRefillPromptStatementSwahili = "";
        this.autoRefillPromptStatementBurmese = "";
        this.autoRefillPromptStatementNepali = "";
        this.autoRefillPromptStatementAmharic = "";
        this.autoRefillPromptStatementPashto = "";
        this.autoCaseAppNewPatientCreation = false;
        this.autoCaseTextsNoValidNumbers = false;
        this.autoCaseTextsPatientContactFailure = false;
        this.autoCaseTextsFailAutoRefill = false;
        this.partialPaymentTextThresholdAmount = 0;
        this.npcTextThresholdAmount = 0;
        this.infinitrakApiKey = "";
        this.disableMedGuidePrinting = false;
        this.textMessageTemplates = new TextMessageTemplates();
        this.readyForPickupTexts = false;
        this.disableWebNotifications = false;
        this.requireLotsOnInventoryBatches = false;
        this.inventoryBatchSubmitEndpoint = "";

        if (obj) {
            Object.assign(this, obj);
        }

        if (!this.inventoryReasons) {
            this.inventoryReasons = [
                "New Inventory",
                "Stock Check"
            ];
        }

        if (!this.textMessageTemplates) {
            this.textMessageTemplates = new TextMessageTemplates();
        }

        if (!this.orderCancellationReasons) {
            this.orderCancellationReasons = [];
        }
    }

    toString(): string {
        return JSON.stringify(this);
    }

    typeOf(name: string): string {
        // using a default populated Configuration object here so that when we change the values in the inputs
        // it doesn't think that the type has changed. (eg. number type going blank will then switch to string type)
        const conf = new Configuration();
        type configType = keyof typeof conf;
        const val = conf[name as configType];
        const valType = typeof val;

        if (valType != "object") return valType;
        if ((val as any) instanceof Date) return 'Date';

        return valType;
    }
}
