
    import axios from 'axios';
    import moment from "moment";
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import SearchComponent from '@/components/SearchComponent.vue';

    import { QueueItem } from '@/models/QueueItem';
    import { PatientDocument } from '@/models/PatientDocument';
    import { Patient } from '@/models/Patient';

    @Component({
        name: "PatientDocumentsForm",
        components: {
            SearchComponent
        }
    })
    export default class PatientDocumentsForm extends Vue {
        @Prop({ default: 'patient_documents_modal' }) modalId!: string;
        @Prop({ default: false, type: Boolean }) listOnly!: boolean;
        @Prop() private patient!: Patient;
        @Prop() private queueItem!: QueueItem | null;
        @Prop({ default: false, type: Boolean }) private selectable!: boolean;
        private selectedPatient: Patient = new Patient();
        private newDocument: File = new File([], "");
        private newDocumentCreated: PatientDocument = new PatientDocument();
        private fileName: string = '';
        private isUploading = false;
        private patientDocuments = [] as PatientDocument[];
        private selectedImageIds: number[] = [];

        get fields(): any[] {
            let resp = [];
            if (this.selectable) {
                resp.push(
                    { label: "Select", key: "selected" },
                );
            }
            resp.push(
                { label: "File Name", key: "fileName", sortable: true },
                {
                    label: "Uploaded On",
                    key: "created",
                    sortable: true,
                    formatter: (d: any) => moment.utc(d).local().format("L LT")
                },
                { label: "Uploaded By", key: "createdBy", sortable: true },
                { key: "view", label: "Actions" }
            );
            return resp;
        }

        get disableSave() {
            if (this.mode == 'new')
                return !(this.newDocument.size > 0 && this.isValidFileName);
            else if (this.mode == 'associate')
                return !(this.patientID && this.isValidFileName && this.queueItem?.id);
        }

        get emptyText(): string {
            return this.patient?.id ? 'No documents for this patient.' : 'Select a patient.';
        }

        get patientID(): string | number | null {
            return this.patient?.id || this.selectedPatient?.id;
        }

        get mode(): string | null {
            if (!this.patient?.id && this.queueItem)
                return 'associate';
            else if (this.patient?.id && !this.queueItem)
                return 'new';
            else return null;
        }

        get isValidFileName(): boolean {
            const regex = /^[\w,\s-]+$/;
            const validFileName: boolean = regex.test(this.fileName?.trim());
            return validFileName;
        }

        get fileNameInputClass(): string {
            const val = this.isValidFileName;
            return val ? "has-success" : "has-danger";
        }

        created() {
            console.log('Pat docs form created');
            this.loadDocs();
        }

        @Watch("selectedImageIds")
        @Emit("selectedImageIdsUpdate")
        updateIds(newValue: number[], _oldValue: number[]) {
            return newValue;
        }

        @Watch('patientID')
        loadDocs() {
            if (!this.patientID) return;

            axios.get<Array<PatientDocument>>(`/patient/${this.patientID}/get-documents/`)
                .then(res => {
                    if (res.data?.length)
                        this.patientDocuments = res.data.map(doc => new PatientDocument(doc));
                })
                .catch(err => {
                    console.error("Error while loading patient documents.", { err, response: err.response });
                });
        }

        submit() {
            if (this.mode == 'associate')
                this.associateDocument();
            else if (this.mode == 'new')
                this.saveDocument();
        }

        saveDocument() {
            if (this.disableSave) return;

            // Upload custom table if there is any
            const formData = new FormData();

            formData.append('document', this.newDocument);
            formData.append('fileName', this.fileName.trim());
            const errorMsg = "Error while uploading new patient's document.";

            this.isUploading = true;
            axios.post<PatientDocument>(`/patient/${this.patientID}/add-document/`
                , formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    if (response.data?.id) {
                        this.resetForm(response.data);
                        this.loadDocs();
                    } else console.error(errorMsg, response);
                })
                .catch(err => {
                    console.error(errorMsg, { err, response: err.response });
                })
                .finally(() => {
                    this.isUploading = false;
                });
        }

        associateDocument() {
            if (this.disableSave) return;

            const errorMsg = "Error while associating selected document to the patient.";
            const pd = new PatientDocument();
            pd.fileName = this.fileName.trim();

            this.isUploading = true;
            axios.post<PatientDocument>(`/patient/${this.patientID}/associate-document/${this.queueItem?.id}`, pd)
                .then(response => {
                    if (response.data?.id) {
                        this.resetForm(response.data);
                        this.$bvModal.hide(this.modalId);
                    } else console.error(errorMsg, response);
                })
                .catch(err => {
                    console.error(errorMsg, { err, response: err.response });
                })
                .finally(() => {
                    this.isUploading = false;
                });
        }

        resetForm(resData: PatientDocument) {
            this.$notification(NotificationOptions.successSaveNotificationPreset('Patient Document'));
            this.newDocumentCreated = new PatientDocument(resData);
            this.newDocument = new File([], "");
            this.fileName = '';
            this.$emit('on-save', resData);
        }

        openDocument(pd: PatientDocument) {
            axios.get(`image/${pd.imageId}`, { responseType: "blob" })
                .then(res => {
                    const objectUrl = URL.createObjectURL(res.data);
                    window.open(objectUrl, '_blank');
                })
                .catch(err => {
                    console.error("Error while getting image to show.", { err, response: err.response });
                });
        }

        close() {
            this.$emit('close');
        }
    }

