import { render, staticRenderFns } from "./PrescriptionOnHoldQueue.vue?vue&type=template&id=347041ff&scoped=true"
import script from "./PrescriptionOnHoldQueue.vue?vue&type=script&lang=ts"
export * from "./PrescriptionOnHoldQueue.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "347041ff",
  null
  
)

export default component.exports