
    import { Component, Prop, Ref, Vue } from "vue-property-decorator";
    import { Patient } from "@/models/Patient";
    import { BTable, BvTableCtxObject } from "bootstrap-vue";
    import Axios, { AxiosRequestConfig } from "axios";
    import OnlineOrderDetailsModal from "@/components/Patient/OnlineOrderDetailsModal.vue";
    import { ExternalPatientOrder, ExternalPatientOrderSource, sortOrders } from "@/models/PatientWebOrder";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";

    @Component({
        name: "OnlineOrdersModal",
        computed: {
            ExternalPatientOrderSource() {
                return ExternalPatientOrderSource;
            }
        },
        components: {
            OnlineOrderDetailsModal,
        }
    })
    export default class OnlineOrdersModal extends Vue {
        @Prop({ default: 'online-orders-modal' }) private modalId!: string;
        @Prop({ required: true }) private patient!: Patient;
        @Ref('onlineOrdersTable') private onlineOrdersTable!: BTable;
        private selectedOrder?: ExternalPatientOrder = {} as ExternalPatientOrder;
        private fields = [
            { key: "id", label: "" },
        ];

        get ctxFilter() {
            return {
                id: this.patient.id
            };
        }

        select(items: any[]) {
            this.selectedOrder = items[0];
            if (this.selectedOrder) {
                this.$bvModal.show('online-order-details-modal');
            }
        }

        detailsHide() {
            this.selectedOrder = undefined;
            this.onlineOrdersTable.clearSelected();
        }

        close() {
            this.$bvModal.hide(this.modalId);
        }

        ctxProvider(ctx: BvTableCtxObject, callback: Function) {
            if (ctx.apiUrl == null) return null;

            const uri = ctx.apiUrl;
            const config: AxiosRequestConfig = {
                params: ctx.filter
            };
            Axios.get<ExternalPatientOrder[]>(uri, config)
                .then(resp => {
                    const objs = resp.data.map(i => new ExternalPatientOrder(i));
                    const sorted = objs.sort(sortOrders);
                    callback(sorted);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    callback([]);
                });
            return null;
        }
    }
