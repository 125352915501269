
import { Component, Prop, Vue, VModel, Emit, Watch } from "vue-property-decorator";
import { Patient } from "@/models/Patient";
import Axios, {AxiosError} from "axios";
import { PatientPlan } from "@/models/PatientPlan";
import { Plan } from "@/models/Plan";
import { CombinedPlan } from "@/models/CombinedPlan";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { EligibilityResponse, EligibilityResults } from "@/models/EligibilityResults";

@Component({
  name: "EligibilityComponent",
})
export default class EligibilityComponent extends Vue {
  @VModel({ default: null }) private patient!: Patient;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  private cardholderId: string = "";
  private combinedPlans: CombinedPlan[] = [];
  private results: EligibilityResults = new EligibilityResults();
  private combinedFields: any[] = [
    { key: "plan.bin", label: "BIN" },
    { key: "plan.pcn", label: "PCN" },
    { key: "patientPlan.memberId", label: "Member Id" },
    { key: "patientPlan.groupId", label: "Group Id" },
    { key: "patientPlan.cardholderId", label: "Cardholder Id" },
    { key: "patientPlan.relationshipCode", label: "Relationship Code" },
  ];

  @Emit("eligibilityCheck")
  eligibilityCheck() {
    return this.sendRequest;
  }

  @Watch("patient")
  patientChanged(patNew: Patient, patOld:Patient){
    if(patNew.id != patOld.id){
      this.clear();
    }
  }

  sendRequest() {
    const url = `Insurance/Eligibility/Surescripts/${this.patient.id}`;
    const cardholderId = this.cardholderId;
    Axios.get(url, { params: { cardholderId: cardholderId } })
      .then((response) => {
        this.results = new EligibilityResults(response.data);
      })
      .catch(err => {
        this.$notification(NotificationOptions.error(err));
      });
  }

  planClicked(result:EligibilityResponse){
    console.log(JSON.stringify(result));
    let selectedPlan = new CombinedPlan(null);
    selectedPlan.plan = new Plan();
    selectedPlan.plan.bin = result.bin;
    selectedPlan.plan.pcn = result.pcn;
    selectedPlan.plan.surescriptsPayerId = result.surescriptsPayerId;
    selectedPlan.patientPlan = new PatientPlan();
    selectedPlan.patientPlan.cardholderId = result.cardholderId;
    selectedPlan.patientPlan.groupId = result.groupNumber;
    selectedPlan.patientPlan.personCode = result.personCode;
    this.$emit('plan-clicked', selectedPlan);
  }

  clear() {
    this.combinedPlans = [];
    this.cardholderId = "";
    this.results = new EligibilityResults();
  }
}
