import HasID from "@/models/HasID";
import HasLabel from "@/models/HasLabel";
import TextUtils from "@/util/TextUtils";

export interface PatientPlan extends HasID {
    patientId: number | string,
    planId: number | string,
    memberId: number | string,
    groupId: number | string,
    cardholderId: number | string,
    personCode: string,
    relationshipCode: RelationshipCode,
    helpdeskNumber: string,
    coverageType: string;
    effectiveDate: Date,
    terminationDate: Date,
    active: boolean;
    rank?: number | null;
}

export enum RelationshipCode {
    NotSpecified = 0,
    Cardholder,
    Spouse,
    Child,
    Other,
}

export class PatientPlan extends HasLabel {
    constructor (ptPlan?: PatientPlan) {
        super();
        this.groupId = '';
        this.cardholderId = '';
        this.personCode = '';
        this.relationshipCode = RelationshipCode.Cardholder;
        if (ptPlan) {
            Object.assign(this, ptPlan);
        }
        if (ptPlan?.active === null || ptPlan?.active === undefined) this.active = true;
    }
    toString(): string {
        if (this.cardholderId) {
            return `Group: ${this.groupId}, Card: ${this.cardholderId}`;
        }
        return "";
    }

    get relationshipDesc():string {
        return PatientPlan.RelationshipToString(this.relationshipCode);
    }

    public static RelationshipToString(num:RelationshipCode):string{
        if(isNaN(num) || num == null) return '';
        let res = RelationshipCode[num];
        res = TextUtils.camelCaseToNormal(res);

        return res;
    }
}

export enum PriorAuthorizationTypeCode {
    NotSpecified = 0,
    PriorAuthorization,
    MedicalCertification,
    EPSDT,
    ExemptionFromCopayAndOrCoinsurance,
    ExemptionFromRx,
    FamilyPlanningIndicator,
    TANF,
    PayerDefinedExemption,
    EmergencyPreparedness

}

export enum SubmissionClarificationCode {

    NoOverride = 1,
    OtherOverride = 2,
    VacationSupply = 3,
    LostDamagedPrescription = 4,
    TherapyChange = 5,
    StarterDose = 6,
    MedicallyNecessary = 7,
    ProcessCompoundForApprovedIngredients = 8,
    Encounters = 9,
    MeetsPlanLimitations = 10,
    CertificationOnFile = 11,
    DMEReplacementIndicator = 12,
    // PayerRecognizedEmergencyDisasterAssistanceRequest = 13,
    LongTermCareLeaveofAbsence = 14,
    LongTermCareReplacementMedication = 15,
    LongTermCareEmergencyBoxKitOrAutomatedDispensingMachine = 16,
    LongTermCareEmergencySupplyRemainder = 17,
    LongTermCarePatientAdmitReadmitIndicator = 18,
    SplitBilling = 19,
    Plan340B = 20,
    LTCDispensing14DaysOrLessNotApplicable = 21,
    LTCDispensing7days = 22,
    LTCDispensing4days = 23,
    LTCDispensing3days = 24,
    LTCDispensing2days = 25,
    LTCDispensing1day = 26,
    LTCDispensing4Then3Days = 27,
    LTCDispensing2Then2Then3Days = 28,
    LTCDispensingDailyAnd3DayWeekend = 29,
    LTCDispensingPerShiftDispensing = 30,
    LTCDispensingPerMedpassDispensing = 31,
    LTCDispensingPRNOnDemand = 32,
    LTCDispensing7DayOrLessCycleNotOtherwiseRepresented = 33,
    LTCDispensing14DaysDispensing = 34,
    LTCDispensing8To14DayDispensingMethodNotListedAbove = 35,
    LTCDispensingDispensedOutsideShortCycle = 36,

    PrescriberIDSubmittedIsValidAndPrescribingRequirementsHaveBeenValidated = 42,
    PrescribersDEAIsActiveWithDEAAuthorizedPrescriptiveRight = 43,

    PrescribersDEAIsAValidHospitalDEAWithSuffixAndHasPrescriptiveAuthorityForThisDrugDEASchedule = 45,
    PrescribersDEAHasPrescriptiveAuthorityForThisDrugDEASchedule = 46,
    ShortenedDaysSupplyFill = 47,
    FillSubsequentToAShortenedDaysSupplyFill = 48,
    PrescriberDoesNotCurrentlyHaveAnActiveType1NPI = 49,
    PrescribersActiveMedicareFeeForServiceEnrollmentStatusHasBeenValidated = 50,
    PharmacysActiveMedicareFeeForServiceEnrollmentStatusHasBeenValidated = 51,
    PrescribersStateLicenseWithPrescriptiveAuthorityHasBeenValidated = 52,
    PrescriberNPIActiveAndValid = 53,
    CMSOtherAuthorizedPrescriberOAP = 54,
    Other = 99,
}
