
import Vue from "vue";
import { AxiosResponse } from "axios";
import { Component, Watch, Prop, VModel } from "vue-property-decorator";
import { DatePicker } from "element-ui";
import SearchComponent from "@/components/SearchComponent.vue";
import { Store } from "@/models/Store";
import { Drug } from "@/models/Drug/Drug";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import Axios from "axios";
import { Note } from "@/models/Note";

import { Surplus } from "@/models/Surplus";
import { Vendor } from "@/models/Vendor";
import NotesList from "@/components/Note/NotesList.vue";
import NotesForm from "@/components/Note/NotesForm.vue";
import { Inventory } from "@/models/Inventory";
import { EntityType } from "@/models/EntityType";

@Component({
    name: "SurplusReportFormComponent",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        NotesList,
        NotesForm,
    },
})
export default class AddSurplusReportFormComponent extends Vue {
    //Surplus ID
    @Prop({ default: 0 }) private value!: number;
    @VModel() private item!: Surplus;
    get surplusID(): number {
        return this.value;
    }

    private surplus: Surplus = new Surplus();
    private store: Store = new Store();
    private drug: Drug = new Drug();
    private vendor: Vendor = new Vendor();

    protected isLoading = false;
    private isSaving = false;
    private isSubmitting = false;

    private notes: Array<Note> = Array<Note>();
    private surplusReportEntityType = EntityType.SurplusReports;
    private netInventory: Inventory = new Inventory();

    created() {
        if (this.item?.id ?? 0 > 0) {
            this.fetchDrug(this.item.packageId);
            this.surplus = this.item;
            this.fetchNotes();
        }
        this.fetchStore(this.item.storeId);
    }

    get actionName() {
        let res = "";
        // if (this.surplus.id==0)
        //     res = 'New';
        res = this.item.action;
        return res;
    }

    get disableFields(): boolean {
        const isTransfer = this.surplus.action == "Detail";
        return isTransfer;
    }

    fetchDrug(packageID: number) {
        if (!packageID) return;

        this.$http
            .get(`/Drug/${packageID}`)
            .then((response) => {
                const drug: Drug = response.data;
                this.drug = new Drug(packageID, drug);
            })
            .catch((error) => {
                console.error("Problem while getting drug details.", {
                    error,
                    response: error?.response,
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    fetchStore(storeId: number) {
        this.$http
            .get(`/Store/${storeId}`)
            .then((response) => {
                this.store = new Store(response.data);
            })
            .catch((error) => {
                console.error("Problem while getting store details.", {
                    error,
                    response: error?.response,
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async fetchNotes() {
        const res = await this.$http
            .get<Array<Note>>(`/note/${this.surplus.id}/${this.surplusReportEntityType}`)
            .catch((err) => this.catchError(err, "Error loading notes."));

        if (res?.data?.length) this.notes = res.data.map((n: Note) => new Note(n));
    }

    catchError(err: any, errorMsg: string): AxiosResponse {
        console.error(errorMsg, { err, response: err?.response });
        return {} as AxiosResponse;
    }

    clearForm() {
        this.surplus = new Surplus();
        this.store = new Store();
        this.drug = new Drug();
        this.vendor = new Vendor();
    }

    backToSuplusDashboard() {
        this.$bvModal.hide("surplus-report-form");
    }

    // submit request
    get disableSubmit(): boolean {
        return false;
    }

    get isBusy(): boolean {
        return this.isSaving || this.isSubmitting;
    }

    loadSurplus() {
        if (!this.surplus) return;

        this.isLoading = true;
        this.$http
            .get<Surplus>(`SurplusReport/getSurplusById/${this.surplus.id}`)
            .then((res) => {
                this.surplus = new Surplus(res.data);
            })
            .catch((err) => {
                console.error("Error while loading Surplus Report GetById", {
                    err,
                    response: err?.response,
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    checkInventoryAndSave() {

        this.surplus.packageId = Number(this.drug.packageID);
        this.surplus.storeId = Number(this.store.id);
        this.surplus.vendorId = Number(this.vendor.id);
        if(this.actionName == "Transfer"){
            this.submitSurplusRequest();
            return;
        }
        if (this.surplus.packageId > 0 && this.surplus.quantity > 0) {
            Axios.get<Array<Inventory>>(`/drug/${this.drug?.packageID}/Inventory`)
                .then((response) => {
                    this.netInventory =
                        response.data.filter((value) => value.storeId == this.store.id)[0] ??
                        new Inventory();
                    if (this.netInventory && (this.netInventory.quantity ?? 0 - this.netInventory.allocated ?? 0) < this.surplus.quantity) {
                        this.$bvModal.msgBoxOk(
                            `Surplus quantity that you entered is larger than your net inventory for that NDC`
                        );
                    }

                    this.submitSurplusRequest();
                })
                .catch((error) => {
                    console.error("There was an error while getting Inventory", {
                        error,
                        response: error?.response,
                    });
                });
        }
    }

    submitSurplusRequest() {
        if (this.actionName == "New") {
            this.surplus.id = 0;
            Axios.post("SurplusReport/add-surplus", this.surplus)
                .then((surplusReport) => {
                    this.$notification(
                        NotificationOptions.successSaveNotificationPreset("Surplus Report")
                    );
                    this.clearForm();
                    this.backToSuplusDashboard();
                    this.$emit("refreshSurplusLists");
                })
                .catch((err) => {
                    this.$notification(
                        NotificationOptions.error("Unable to Add New Surplus Report")
                    );
                });
        } else if (this.actionName == "Transfer") {
            if (!this.surplus.packageId) {
                this.$bvModal.msgBoxOk(`Unable to get Drug Details, Try again later!`);
                return;
            }
            Axios.post("SurplusReport/transfer-surplus", this.surplus)
                .then((surplusReportTransfer) => {
                    this.$notification(
                        NotificationOptions.successSaveNotificationPreset("Transfer Surplus Report")
                    );
                    this.clearForm();
                    this.backToSuplusDashboard();
                    this.$emit("refreshSurplusLists");
                })
                .catch((err) => {
                    this.$notification(
                        NotificationOptions.error("Unable to Transfer Surplus Report")
                    );
                });
        }
    }

    onNoteSaved() {
        this.fetchNotes();
    }
}
