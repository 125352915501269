import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { RefillRequestBatchItem } from "./RefillRequestBatchItem";
import TextUtils from "@/util/TextUtils"

export interface RefillRequestBatch extends HasID, TrackedObject {
    items : Array<RefillRequestBatchItem>;
    rxCount?: number;
    status: RefillRequestBatchStatus;
}
export enum RefillRequestBatchStatus {
    Proccessing, FinishedSuccessfully, WithErrors
}
export class RefillRequestBatch {
    constructor(obj?:RefillRequestBatch){
        if(obj) {
            Object.assign(this, obj);
            this.items = obj.items?.map(i => new RefillRequestBatchItem(i)) || new Array<RefillRequestBatchItem>();
        }
    }

    get statusName():string {
        let res = RefillRequestBatchStatus[this.status];
        res = TextUtils.camelCaseToNormal(res);
        return res;
    }

    get statusStyleVariant():string {
        if(this.status == RefillRequestBatchStatus.FinishedSuccessfully) return 'success';
        else if(this.status == RefillRequestBatchStatus.Proccessing) return 'info';
        else if(this.status == RefillRequestBatchStatus.WithErrors) return 'danger';
        else return 'black';
    }
}