<template>

    <div>
        <div class="d-flex" style="gap: 15px;">
            <b-form-datepicker size="sm" :value="date" @input="handleDateChange" :disabled="disabled" :min="disallowPast ? new Date() : undefined" />
            <b-form-timepicker size="sm" :value="time" @input="handleTimeChange" minutes-step="15" :disabled="disabled" />
        </div>
        <div v-if="showPresets && !disabled" class="d-flex justify-content-center">
            <b-button size="sm" @click="addHours(1)">+ 1 hour</b-button>
            <b-button v-for="h in [2,4,8,24]" size="sm" @click="addHours(h)" :key="h">
                + {{ h }} hours
            </b-button>
        </div>
    </div>

</template>

<script>
import moment from "moment";

export default {
    name: "DateTimeInput",
    props: {
        value: {
            type: [Date, Object]
        },
        showPresets: Boolean,
        disabled: Boolean,
        disallowPast: Boolean
    },
    computed: {
        date() {
            return moment(this.value).toDate();
        },
        time() {
            return moment(this.value).format("HH:mm:ss");
        }
    },
    methods: {
        handleDateTimeChange(newDate, newTime) {
            if (!newDate) newDate = this.date ?? new Date();
            if (!newTime) newTime = this.time ?? "12:00:00";

            const combinedDateTime = moment(newDate).set({
                hour: parseInt(newTime.split(':')[0]),
                minute: parseInt(newTime.split(':')[1]),
                second: parseInt(newTime.split(':')[2])
            });

            this.$emit("input", combinedDateTime.toDate());
        },
        handleDateChange(newDate) {
            this.handleDateTimeChange(newDate, this.time);
        },
        handleTimeChange(newTime) {
            this.handleDateTimeChange(this.date, newTime);
        },
        addHours(hoursToAdd) {
            const combinedDateTime = moment(this.date).set({
                hour: parseInt(this.time.split(':')[0]),
                minute: parseInt(this.time.split(':')[1]),
                second: parseInt(this.time.split(':')[2])
            });

            combinedDateTime.add(hoursToAdd, "hours");

            this.$emit("input", combinedDateTime.toDate());
        }
    }
}


</script>
