

import { Component, Ref, Prop, Watch, Vue } from "vue-property-decorator";
import SearchComponent from '@/components/SearchComponent.vue';
import { DatePicker } from 'element-ui';
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import moment from "moment";
import { PeriodicInventory, PeriodicInventoryItem } from "@/models/PeriodicInventory";
import { Program } from "@/models/Program";
import { Store } from "@/models/Store";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import axios from 'axios';
import { Drug } from "@/models/Drug/Drug";
import HelpPopup from "@/components/HelpPopup.vue";
import BottomPagerBar from "@/components/BottomPagerBar.vue";
import { InventoryLocation } from "@/models/InventoryLocation";

@Component({
    name: "PeriodicInventoryDetailsPage",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        NewClearSaveButtons,
        HelpPopup,
        BottomPagerBar
    }
})
export default class PeriodicInventoriesPage extends Vue {

    @Prop() protected id?: string;

    protected store: Store | null = null;
    protected isComplete: boolean = true;
    protected startDate: Date | null = null;
    protected supervisingPharmacist: string | null = null;
    protected active: boolean = true;
    protected closeEntries: Array<any> = [];
    protected showAllCloseEntries = false;
    protected entrySearchTerms = "";
    protected location = new InventoryLocation();

    protected selectedEntry: PeriodicInventoryItem | null = null;
    protected selectedEntryDrug = new Drug();

    protected entryPage = 1;

    protected stickyProgram = false;
    protected stickyLocation = false;

    protected inventoryLoading = false;
    protected closeLoading = false;
    protected inventorySaving = false;

    protected coveragePercentage: number | null = null;

    protected activeDrugsOnly: boolean = true;

    @Ref("barcode") protected barcode: any;
    @Ref("entryBlock") protected entryBlock: any;

    mounted() {
        this.loadHasInventoryLocations();
        this.loadInventory();
    }

    private entries: Array<PeriodicInventoryItem> = [];

    get entryFields() {
        return [
            { key: "id" },
            { key: "program", formatter: (value: Program) => value?.name },
            { key: "drugName" },
            { key: "ndC11", label: "NDC" },
            { key: "quantity" },
            { key: "location.name", label: "Location", hidden: !this.hasInventoryLocations },
            { key: "created" },
            { key: "actions", label: "" }
        ].filter(i => i.hidden !== true)
    }

    get closeFields() {
        return [
            { key: "program", formatter: (value: Program) => value?.name },
            { key: "drugName" },
            { key: "location.name", label: "Location", hidden: !this.hasInventoryLocations },
            { key: "current" },
            { key: "new" },
            {
                key: "diff",
                label: "Difference",
                formatter: (_: any, __: any, item: any) => new Intl.NumberFormat("en-US", {
                    signDisplay: "exceptZero"
                } as any).format(item.new - item.current)
            }
        ].filter(i => i.hidden !== true)
    }

    get isNew() {
        return !parseInt(this.id ?? "0");
    }

    get actionButtonTitles(): NewClearSaveTitles {
        return {
            save: !this.isNew && this.active ? 'Save Inventory' : '',
            clear: !this.isNew && this.active ? 'Close Inventory' : '',
            new: this.isNew ? "Create Inventory" : "",
            cancel: !this.isNew && !this.active ? "Print Attestation" : ""
        };
    }

    get entryActionButtonTitles(): NewClearSaveTitles {
        return { new: "Add Inventory Entries...", clear: "", save: "", cancel: "" };
    }

    showEntryModal() {
        this.resetSelectedEntry();
        this.$bvModal.show("entryModal");
    }

    resetSelectedEntry() {
        this.selectedEntry = {
            id: 0,
            program: this.stickyProgram ? this.selectedEntry?.program : new Program(),
            units: "",
            location: this.stickyLocation ? this.selectedEntry?.location : new InventoryLocation(),
            created: new Date(),
            createdBy: "",
            updated: new Date(),
            updatedBy: ""
        } as PeriodicInventoryItem;
        this.selectedEntryDrug = new Drug();
    }

    dateTimeFormatter(d: any) {
        return moment(d).format("LLL");
    }

    async removeEntry(entry: PeriodicInventoryItem) {
        const shouldContinue = await this.$bvModal.msgBoxConfirm("Are you sure you want to remove this entry?", {
            title: 'Remove Entry',
            okVariant: 'danger',
            okTitle: 'Remove Entry',
            cancelTitle: 'Cancel',
            centered: true,
            noFade: true
        });

        if (!shouldContinue) return;

        try {
            var response = await axios.delete(`/PeriodicInventories/entry/${entry.id}`);
            this.entries = await response.data;
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }

        this.getCoveragePercentage();
    }

    async saveEntry(event: any) {
        event.preventDefault();

        try {
            this.selectedEntry!.packageId = this.selectedEntryDrug.drugId;
            this.selectedEntry!.drugSource = this.selectedEntryDrug.source;
            this.selectedEntry!.periodicInventoryId = parseInt(this.id ?? "0");

            const response = await axios.post<Array<PeriodicInventoryItem>>("/PeriodicInventories/entry/", this.selectedEntry);

            this.entries = response.data;

            this.$notification(NotificationOptions.successSaveNotificationPreset("Periodic Inventory Entry"));

            this.resetSelectedEntry();

            this.barcode?.focus();
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }
    }

    setupNewInventory() {
        this.store = null;
        this.isComplete = true;
        this.startDate = new Date();
    }

    @Watch("id")
    async loadInventory() {
        if (this.isNew) {
            this.setupNewInventory();
            return;
        }

        try {
            this.inventoryLoading = true;
            const response = await axios.get(`/PeriodicInventories/${this.id}`);

            this.entries = response.data.entries;
            this.handleInventoryData(response.data.inventory);

            this.getCoveragePercentage();
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.inventoryLoading = false;
        }
    }

    get inventoryData() {
        return {
            id: this.id,
            store: this.store,
            isComplete: this.isComplete,
            startDate: this.startDate,
            supervisingPharmacist: this.supervisingPharmacist,
            active: this.active,
            location: this.location
        }
    }

    async createInventory() {
        try {
            if (!this.inventoryData.startDate) {
                this.$notification(NotificationOptions.error("You must select a start date."));
                return;
            }

            this.inventoryLoading = true;

            const response = await axios.post<PeriodicInventory>("/PeriodicInventories", this.inventoryData);

            const newId = response.data.id;

            this.$router.replace(`/maintenance/periodic-inventories/${newId}`);

            this.$notification(NotificationOptions.successSaveNotificationPreset("Periodic Inventory"));
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.inventoryLoading = false;
        }
    }

    async saveInventory(silent: boolean) {
        try {
            if (!this.inventoryData.startDate) {
                this.$notification(NotificationOptions.error("You must select a start date."));
                return;
            }

            this.inventorySaving = true;
            await axios.post<PeriodicInventory>("/PeriodicInventories", this.inventoryData);

            if (!silent) {
                this.$notification(NotificationOptions.successSaveNotificationPreset("Periodic Inventory"));
            }

            this.getCoveragePercentage();
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.inventorySaving = false;
        }
    }

    async closeInventory(event: any) {
        event.preventDefault();

        const msg = "Are you sure you want to close the inventory? This action will update the store inventory.";

        const shouldContinue = await this.$bvModal.msgBoxConfirm(msg, {
            title: 'Close Inventory',
            okVariant: 'danger',
            okTitle: 'Close Inventory',
            cancelTitle: 'Cancel',
            centered: true,
            noFade: true
        });

        if (!shouldContinue) return;

        try {
            this.closeLoading = true;

            const response = await axios.post<PeriodicInventory>(`/PeriodicInventories/close/${this.id}`);

            this.handleInventoryData(response.data);

            this.$notification(NotificationOptions.successSaveNotificationPreset("Periodic Inventory"));

            this.$bvModal.hide("closeModal");
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        } finally {
            this.closeLoading = false;
        }
    }

    @Watch("selectedEntry.barcode")
    async handleBarcode() {
        if (!this.selectedEntry?.barcode?.trim()) return;

        try {
            const response = await axios.get(`/PeriodicInventories/entry/barcode/${this.selectedEntry?.barcode}`);

            if (!response.data) {
                this.$notification(NotificationOptions.error("Nothing found for provided barcode"));
                return;
            }

            if (this.stickyProgram) {
                response.data.program = this.selectedEntry.program;
            }

            this.selectedEntry = {
                ...this.selectedEntry,
                ...response.data
            };

            if (response.data.packageId !== this.selectedEntryDrug.packageID) {
                this.selectedEntryDrug = new Drug(response.data.packageId);
                this.selectedEntryDrug.source = response.data.drugSource;
            }
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }
    }

    get filteredCloseEntries() {
        if (this.showAllCloseEntries) return this.closeEntries;
        return this.closeEntriesWithChanges;
    }

    async showCloseModal() {
        this.$bvModal.show("closeModal");

        try {
            this.closeEntries = [];
            this.closeLoading = true;

            await this.saveInventory(true);

            const response = await axios.get(`/PeriodicInventories/close/${this.id}`);

            this.closeEntries = response.data;
        } catch (error) {
            this.$bvModal.hide("closeModal");
            this.$notification(NotificationOptions.error(error));
        }
        finally {
            this.closeLoading = false;
        }
    }

    get closeEntriesCount() {
        return this.closeEntries?.length ?? 0;
    }

    get closeEntriesWithChanges() {
        return this.closeEntries.filter(ce => ce.current !== ce.new);
    }

    get closeEntriesWithChangesCount() {
        return this.closeEntriesWithChanges?.length ?? 0;
    }

    get closeEntryModeOptions() {
        return [
            { text: `Changes (${this.closeEntriesWithChangesCount})`, value: false },
            { text: `All (${this.closeEntriesCount})`, value: true }
        ];
    }

    handleInventoryData(data: any) {
        this.store = data.store ?? this.store;
        this.active = data.active ?? this.active;
        this.startDate = data.startDate ?? this.startDate;
        this.supervisingPharmacist = data.supervisingPharmacist ?? this.supervisingPharmacist;
        this.isComplete = data.isComplete ?? this.isComplete;
        this.location = data.location ?? this.location;
    }

    closeRowClass(item: any, type: any) {
        if (!item || type !== "row") return;

        if (item.new > item.current) return 'table-success';
        if (item.new < item.current) return 'table-danger';

        return '';
    }

    get filteredEntries() {
        if (!this.entrySearchTerms?.trim()) return this.entries;

        const searchTerms = this.entrySearchTerms.toLowerCase().split(" ");

        return this.entries.filter(e => searchTerms.every(t =>
            e.drugName?.toLowerCase().includes(t) ||
            e.program?.name.toLowerCase().includes(t) ||
            e.quantity?.toString().includes(t) ||
            e.createdBy?.toLowerCase().includes(t) ||
            e.ndC11?.includes(t)
        ));
    }

    storeLabel(object: Store) {
        return object?.id ? object.name : "";
    }

    @Watch("entryPage")
    resetEntryTableScrollPosition() {
        this.entryBlock.scrollIntoView({ behavior: "smooth" });
    }

    @Watch("entrySearchTerms")
    resetEntryPage() {
        this.entryPage = 1;
    }

    @Watch("selectedEntryDrug")
    handleDrugChange() {
        if (this.selectedEntryDrug.billingUnit !== this.selectedEntry!.units) {
            this.selectedEntry!.units = this.selectedEntryDrug.billingUnit;
        }
    }

    async savePDF() {
        const jspdf = await import(/* webpackChunkName: "jspdf" */ "jspdf");
        const autotable = await import(/* webpackChunkName: "jspdf" */ "jspdf-autotable");

        const doc = new jspdf.default();

        doc.setFontSize(18);
        doc.text(`Periodic Inventory Report (Id: ${this.id})`, 14, 22);
        doc.setFontSize(10.5);
        doc.setTextColor(100);

        doc.text(`Store: ${this.store!.name}`, 14, 30);
        doc.text(`Start Date: ${moment(this.startDate).format("LL")}`, 14, 36);
        doc.text(`Supervising Pharmacist:`, 90, 30);
        doc.text(this.supervisingPharmacist, 90, 36);

        autotable.default(doc, {
            head: [["Id", "Program", "Drug Name", "NDC", "Package", "Qty", "Created", "Created By"]],
            body: this.entries.map(e => [
                e.id,
                e.program?.name,
                e.drugName,
                e.ndC11,
                e.packageId,
                e.quantity,
                this.dateTimeFormatter(e.created),
                e.createdBy]) as Array<any>,
            startY: 43,
            styles: { fontSize: 8 }
        });

        doc.text("_".repeat(40), 14, (doc as any).lastAutoTable.finalY + 10);
        doc.text("Signature", 14, (doc as any).lastAutoTable.finalY + 15);
        doc.text("_".repeat(25), 120, (doc as any).lastAutoTable.finalY + 10);
        doc.text("Date", 120, (doc as any).lastAutoTable.finalY + 15);

        doc.save(`PeriodicInventoryReport-${this.id}.pdf`);
    }

    async getCoveragePercentage() {
        try {
            const response = await axios.get<number | null>(`/PeriodicInventories/coverage-percentage/${this.id}`);

            if (!response.data) {
                this.coveragePercentage = null;
                return;
            }

            this.coveragePercentage = response.data;
        } catch {
            console.log("error getting coverage percentage");
        }
    }

    hideEntryModal() {
        this.getCoveragePercentage();
    }

    get totalEntryCount() {
        return this.entries?.length ?? null;
    }

    get filteredEntryCount() {
        return this.filteredEntries?.length ?? null;
    }

    get totalDrugsCount() {
        return new Set(this.entries.map(e => e.drugSource.toString() + e.packageId)).size;
    }

    get filteredDrugsCount() {
        return new Set(this.filteredEntries.map(e => e.drugSource.toString() + e.packageId)).size;
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }
}

