
import Vue from 'vue'
import { GroupOfCasesByDoctor, PrescriberFollowUpCase, FaxType } from '@/models/PrescriberFollowUpCase'
import { Prescriber } from '@/models/Prescriber';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import DoctorFollowUpCaseForm from '@/components/Doctor/DoctorFollowUpCaseForm.vue'
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import { DatePicker } from "element-ui";
import moment from 'moment';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import BottomPagerBar from '@/components/BottomPagerBar.vue';

@Component({
    name: "DoctorFollowUpQueue",
    components: {
        DoctorFollowUpCaseForm, SearchComponent,
        EnumSelector, [DatePicker.name]: DatePicker, Checkbox,
        BottomPagerBar
    },
})
export default class DoctorFollowUpQueue extends Vue {
    private caseModalId = 'followup-case-form';
    private isLoading = false;
    private items: Array<PrescriberFollowUpCase> = new Array<PrescriberFollowUpCase>();
    private selectedCase: PrescriberFollowUpCase = new PrescriberFollowUpCase();
    private selectedDoctor: Prescriber = new Prescriber();
    private faxTypeEnum = FaxType;
    private selectedFaxType: FaxType | null = null;
    protected startDate?: string = "";
    protected endDate?: string = "";
    protected selectedMode: string = "Queue";

    private fields: any[] = [
        { key: "patientName", label: "Patient", sortable: true },
        { key: "requestName", label: "Request", sortable: true },
        { key: "statusName", label: "Status", sortable: true },
        { key: "prescriberName", label: "Prescriber Name", sortable: true },
        { key: "created", label: "Created", sortable: true },
        { key: "updated", label: "Updated", sortable: true },
        { key: "actions", label: '' },
    ];
    private sortBy: string = 'created';

    private pageSize = 50;
    private pageNumber = 1;

    get startRowIndex() {
        return this.pageNumber * this.pageSize - this.pageSize;
    }

    get endRowIndex() {
        const calcIndex = this.startRowIndex + this.pageSize;
        if (calcIndex > this.filteredItems.length) {
            return this.filteredItems.length;
        }
        return calcIndex;
    }

    get totalRows() {
        return this.filteredItems.length;
    }

    @Watch("filteredItems")
    resetPaging() {
        this.pageNumber = 1;
    }

    created() {
        this.loadItems();
    }

    get filteredItems() {
        if (!this.startDate) return this.items;
        return this.items.filter(i => new Date(i.created) >= moment(this.startDate).toDate() &&
            new Date(i.created) < moment(this.endDate).add(1, "day").toDate());
    }

    @Watch("selectedDoctor")
    @Watch("selectedFaxType")
    @Watch("selectedMode")
    loadItems() {
        this.isLoading = true;
        this.$http.get<Array<PrescriberFollowUpCase>>(`/Prescriber/follow-up-queue/${this.selectedDoctor?.id || 0}/${this.selectedFaxType || ''}`,
            {
                params: {
                    allStatuses: this.selectedMode === "All"
                }
            }
        )
            .then(res => {
                this.items = res.data.map(i => new PrescriberFollowUpCase(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                const errorMsg = 'Error while loading prescribers follow-up cases.';
                console.error(errorMsg, { err, response: err?.response })
            })
            .finally(() => {
                this.isLoading = false;
            })
    }

    closeCaseModalAndReload() {
        this.$bvModal.hide(this.caseModalId);
        this.loadItems();
    }

    openDetails(selectedItem: PrescriberFollowUpCase) {
        this.selectedCase = selectedItem;
        this.$bvModal.show(this.caseModalId);
    }

    filterByPrescriber(prescriberId: number) {
        const prescriber = new Prescriber();
        prescriber.id = prescriberId;
        this.selectedDoctor = prescriber;
    }

    filterByType(type: number) {
        this.selectedFaxType = type;
    }

    @Watch("startDate")
    handleEndDate() {
        if (this.startDate && !this.endDate)
            this.endDate = moment().toISOString();
        if (!this.startDate)
            this.endDate = "";
    }

    @Watch("endDate")
    handleStartDate() {
        if (this.endDate && !this.startDate)
            this.startDate = this.endDate;
        if (!this.endDate)
            this.startDate = "";
    }

    createdToday() {
        this.startDate = new Date().toDateString();
        this.endDate = new Date().toDateString(); 
    }

    createdYesterday() {
        this.startDate = moment().subtract(1, 'day').toString();
        this.endDate = moment().subtract(1, 'day').toString();
    }

    createdThisWeek() {
        this.startDate = moment().startOf('week').toString();
        this.endDate = moment().startOf('week').add(1, 'week').toString();
    }

    createdLastWeek() {
        this.startDate = moment().startOf('week').subtract(1, 'week').add(1, 'day').toString();
        this.endDate = moment().startOf('week').subtract(1, 'week').add(1, 'week').toString();
    }

}
