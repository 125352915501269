<template>

    <b-form-group>
        <label>
            <component :is="boldLabel ? 'strong' : 'span'">
                {{ label }}
            </component>
            <HelpPopup v-if="help">
                <p>{{ help }}</p>
            </HelpPopup>
        </label>

        <b-form-textarea v-if="type === 'textarea'" :value="value" @input="$emit('input', $event)" rows="3" />
        <b-form-input v-else-if="type == 'string'" :value="value" @input="$emit('input', $event)" />
        <b-form-input v-else-if="type == 'number'" :value="value" @input="$emit('input', $event)" type="number" />
        <b-checkbox switch v-if="type == 'boolean'" size="lg" :checked="value" @change="$emit('input', $event)" />

        <div v-if="type === 'string-array'">
            <b-table :items="value.map((ir, ix) => ({ index: ix, value: ir }))" :fields="[
                { key: 'value', label: '' },
                { key: 'actions', label: '', class: 'actions' }
            ]" class="mb-0 hide-header" small bordered>
                <template #cell(value)="{ item, index }">
                    <b-input v-model="editingRowData" v-if="editingRowIndex === index" :value="item.value" autofocus />
                    <template v-else>{{ item.value }}</template>
                </template>
                <template #cell(actions)="{ item, index }">
                    <div class="d-flex" style="gap: 6px;">
                        <template v-if="editingRowIndex !== index">
                            <b-button variant="link" @click.prevent="startTableEdit(item, index)" title="Edit">
                                <b-icon icon="pencil" variant="primary" />
                            </b-button>
                            <b-button variant="link" @click.prevent="removeTableItem(index)" title="Remove">
                                <b-icon icon="x-circle-fill" variant="danger" />
                            </b-button>
                        </template>
                        <template v-else>
                            <b-button variant="link" title="Save" @click.prevent="saveTableEdit(index)">
                                <b-icon icon="save" variant="primary" />
                            </b-button>
                            <b-button variant="link" title="Cancel Edit" @click.prevent="cancelTableEdit">
                                <b-icon icon="x-lg" variant="" />
                            </b-button>
                        </template>
                    </div>
                </template>
            </b-table>

            <b-button size="sm" variant="primary" @click="addTableItem">
                {{ addLabel ?? "Add" }}
            </b-button>
        </div>

        <TextMessageTemplatesField v-if="type === 'text-message-templates'" v-model="value" />
    </b-form-group>

</template>

<script>

import HelpPopup from "@/components/HelpPopup.vue";
import TextMessageTemplatesField from "./TextMessageTemplatesField.vue";

export default {
    name: "ConfigurationField",
    components: {
        HelpPopup,
        TextMessageTemplatesField
    },
    props: {
        label: String,
        boldLabel: Boolean,
        help: String,
        type: String,
        value: {},
        addLabel: String
    },
    methods: {
        startTableEdit(item, index) {
            this.editingRowIndex = index;
            this.editingRowData = item.value;
        },
        removeTableItem(index) {
            const array = [...this.value];
            array.splice(index, 1);
            this.$emit('input', array);
        },
        addTableItem() {
            const cleanedArray = this.value.filter((a) => a.trim());
            cleanedArray.push("");
            this.$emit('input', cleanedArray);
            this.startTableEdit({ value: "" }, cleanedArray.length - 1);
        },
        saveTableEdit(index) {
            const array = [...this.value];
            const newValue = this.editingRowData?.trim();
            if (!newValue) {
                array.splice(index, 1);
            } else {
                array.splice(index, 1, newValue);
            }
            this.$emit('input', array);
            this.editingRowIndex = -1;
        },
        cancelTableEdit() {
            const editingIndex = this.editingRowIndex;
            const editingValue = this.value[editingIndex];
            if (!editingValue) {
                this.removeTableItem(editingIndex);
            }
            this.editingRowIndex = -1;
        },
        updateTextMessageTemplateValue(templateName, fieldName, value) {
            const newValue = { ...this.value };
            if (!newValue[templateName]) newValue[templateName] = {};
            newValue[templateName][fieldName] = value;
            this.$emit('input', newValue);
        }
    },
    data() {
        return {
            editingRowData: undefined,
            editingRowIndex: -1
        }
    }
}

</script>

<style scoped lang="scss">
table.hide-header ::v-deep(thead) {
    display: none;
}

table ::v-deep(.actions) {
    width: 50px;
}
</style>