
import NotificationsList from "@/components/Notifications/NotificationsList.vue";
import { Component, Vue } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import { User } from "@/models/User";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    components: {
        NotificationsList,
        Multiselect
    },
})
export default class NotificationListPage extends Vue {

    mounted() {

    }

    private refreshKey = "";
    private selectedView = "active";
    private adminNotification: any = {};
    private adminNotificationRecipients = [];
    private adminNotificationKeep = true;
    private users: User[] = [];

    get isAdmin(): boolean {
        return this.$user?.isAdmin;
    }

    get selectViewOptions() {
        return [
            { text: "Active", value: 'active' },
            { text: "Archive", value: 'inactive' }
        ];
    }

    async readAll() {
        try {
            await this.$http.post("/notification/mark-all-read");
        } catch {

        } finally {
            this.refreshKey = new Date().toString();
        }
    }

    async archiveAll() {
        try {
            await this.$http.post("/notification/archive-all");
        } catch {

        } finally {
            this.refreshKey = new Date().toString();
        }
    }

    async createAdminNotification(e?: any) {

        e?.preventDefault();

        try {
            await this.$http.post("/notification/create", {
                notification: {
                    icon: "bell",
                    target: this.adminNotification.target,
                    title: this.adminNotification.title,
                    body: this.adminNotification.body,
                    category: "Admin",
                    completed: false,
                    phi: false
                },
                recipients: this.adminNotificationRecipients
            });

            this.$notification(NotificationOptions.success("Notification was scheduled"));

            if (!this.adminNotificationKeep)
                this.resetAdminNotifiation();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

    }

    resetAdminNotifiation() {
        this.adminNotification = {
            target: "/reports/notifications"
        };
        this.adminNotificationRecipients = [];
    }

    showSendModal() {
        this.resetAdminNotifiation();

        if (!this.users.length) this.loadUsers();

        this.$bvModal.show("send-modal");
    }

    async loadUsers() {
        try {
            const resp = await this.$http.get<User[]>("/user");
            this.users = resp.data;
        } catch {

        }
    }

}

