

import { Component, Vue } from 'vue-property-decorator';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Patient } from '@/models/Patient';
import { Order } from '@/models/Order';
import SearchComponent from '@/components/SearchComponent.vue';
import PatientDemographicsComponent from '@/components/Patient/PatientDemographicsComponent.vue';
import HelpPopup from "@/components/HelpPopup.vue";
import PatientRecentlyViewed from "@/components/Patient/PatientRecentlyViewed.vue";

@Component({
    name: "MergePatientsPage",
    components: { PatientDemographicsComponent, SearchComponent, HelpPopup, PatientRecentlyViewed }
})
export default class MergePatientsPage extends Vue {
    private sourcePatient = new Patient();
    private targetPatient = new Patient();
    private sourcePatientOrders = new Array<Order>();
    private hideSourceRecentList = true;
    private hideTargetRecentList = true;

    get disableSubmit(): boolean {
        return !this.sourcePatient?.id || !this.targetPatient?.id || this.sourcePatientHaveActiveOrders;
    }

    get sourcePatientHaveActiveOrders(): boolean {
        return !!this.sourcePatientOrders?.length;
    }

    get userCanMerge() {
        return this.$user?.isPatientsManager;
    }

    mounted() {
        const routeSourceId = this.$route.params.sourcePatientId;
        const routeTargetId = this.$route.params.targetPatientId;

        if (routeSourceId) {
            this.fetchSourcePatientOrders(Object.assign(new Patient(), { id: routeSourceId }));
        }

        if (routeTargetId) {
            this.loadTargetPatient(Object.assign(new Patient(), { id: routeTargetId }));
        }
    }

    updated() {
        console.log('sourcePatientHaveActiveOrders', this.sourcePatientHaveActiveOrders);
        console.log('sourcePatientOrders', this.sourcePatientOrders);
    }

    loadPatientPromise(patientID: number | string): Promise<Patient | string> {
        return new Promise<Patient | string>((resolve, reject) => {
            this.$http.get<Patient>(`/Patient/${patientID}`)
                .then(res => {
                    if (res.data?.id) resolve(res.data);
                    else reject('Patient Not Found');
                })
                .catch(err => {
                    let errorMsg = "Error loading Source Patient Orders";
                    console.error(errorMsg, { err, response: err?.response });
                    reject(errorMsg);
                })
        })
    }

    private loadingSourcePatient = false;
    fetchSourcePatientOrders(sourcePatient: Patient) {
        if (!sourcePatient?.id) return;

        this.sourcePatientOrders = new Array<Order>();
        this.loadingSourcePatient = true;
        this.loadPatientPromise(sourcePatient.id)
            .then(res => {
                if ((res as Patient)?.id) {
                    this.sourcePatient = new Patient(res as Patient);

                    this.$http.get<Array<Order>>(`/Patient/${sourcePatient.id}/orders/`)
                        .then(res => {
                            if (res.data?.length)
                                this.sourcePatientOrders = res.data.map(o => new Order(o));
                        })
                        .catch(err => {
                            console.error("Error loading Source Patient Orders", { err, response: err?.response });
                        })
                        .finally(() => {
                            this.loadingSourcePatient = false;
                        })
                }
            })
    }

    private loadingTargetPatient = false;
    loadTargetPatient(targetPatient: Patient) {
        if (!targetPatient) return;

        this.loadingTargetPatient = true;
        this.loadPatientPromise(targetPatient.id)
            .then(res => {
                if ((res as Patient)?.id) this.targetPatient = new Patient(res as Patient);
            })
            .finally(() => {
                this.loadingTargetPatient = false;
            })
    }

    confirmDialog() {
        this.$bvModal.msgBoxConfirm("This action is not reversible. Are you sure?", {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.submitMerge();
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
    }

    private isMerging = false;

    submitMerge() {
        if (!this.sourcePatient?.id || !this.targetPatient?.id) return;

        this.isMerging = true;
        let errorMsg = 'Error while merging patients.'
        this.$http.post<Patient>(`/patient/merge-patients/${this.sourcePatient.id}/${this.targetPatient.id}`)
            .then(res => {
                if (res.data?.id) {
                    this.targetPatient = new Patient(res.data);
                    this.sourcePatient = new Patient();
                    this.$notification(NotificationOptions.notificationOptionsPreset("Source patient was successfully merged into target patient and removed from database.", NotificationOptions.NotificationTypes.success));
                } else {
                    this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                    console.error(errorMsg, { res, response: res?.data });
                }
            })
            .catch(err => {
                if (err?.response?.status == 403) errorMsg = "You don't have permissions to execute this functionality.";

                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, { err, response: err?.response });
            })
            .finally(() => this.isMerging = false)
    }

    swap() {
        [this.sourcePatient, this.targetPatient] = [this.targetPatient, this.sourcePatient];
    }

    async addQueue() {

        try {

            if (!this.sourcePatient?.id || !this.targetPatient?.id) {
                this.$notification(NotificationOptions.error("Must select a source and target patient for the merge request"));
                return;
            }

            if (this.sourcePatient.id === this.targetPatient.id) {
                this.$notification(NotificationOptions.error("Source and target patient can't be the same record"));
                return;
            }

            const request = `/patient/mergequeue/${this.sourcePatient.id}/${this.targetPatient.id}`;
            await this.$http.post(request);

            this.$notification(NotificationOptions.notificationOptionsPreset("Patient merge request added", NotificationOptions.NotificationTypes.success));

            this.sourcePatient = new Patient();
            this.targetPatient = new Patient();

        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

    }
}
