<template>

    <b-table :fields="fields" :items="tableItems" :foot-clone="!readonly">
        <template #cell(lot)="data">
            <b-input size="sm" :value="data.value" @input="handleInput({ lot: $event }, data.index)"
                :readonly="readonly" />
        </template>
        <template #cell(expiry)="data">
            <b-input size="sm" type="date" :value="data.value" @input="handleInput({ expiry: $event }, data.index)"
                :readonly="readonly" />
        </template>
        <template #cell(quantity)="data">
            <b-input size="sm" type="number" :value="data.value" @input="handleInput({ quantity: $event }, data.index)"
                :readonly="readonly" />
        </template>
        <template #cell(actions)="data">
            <template v-if="data.index !== tableItems.length - 1">
                <b-button variant="link" @click.prevent="removeItem(data.index)" title="Remove" tabindex="-1">
                    <b-icon icon="x-circle-fill" variant="danger" />
                </b-button>
            </template>
        </template>

        <template #foot(quantity)>
            <b-badge v-if="totalQuantity" :variant="quantityRemainingBadgeVariant">
                Remaining: {{ quantityRemaining }}
            </b-badge>
            <div v-else></div>
        </template>
        <template #foot()>
            <div></div>
        </template>
    </b-table>

</template>

<script>

export default {
    name: "InventoryLotEntryComponent",
    props: {
        value: Array,
        totalQuantity: Number,
        readonly: Boolean
    },
    computed: {
        fields() {
            return [
                { key: "lot", label: "Lot Number" },
                { key: "expiry", label: "Expiration" },
                { key: "quantity", label: "Quantity", class: "qty-col" },
                { key: "actions", label: "", class: "actions-col", hidden: this.readonly }
            ].filter(f => f.hidden !== true)
        },
        tableItems() {
            if (this.readonly) return this.value;
            return [
                ...this.value,
                { }
            ]
        },
        totalEnteredQuantity() {
            return this.value.map(i => Number(i.quantity ?? 0)).reduce((p, a) => p + a, 0);
        },
        quantityRemaining() {
            return this.totalQuantity - this.totalEnteredQuantity;
        },
        quantityRemainingBadgeVariant() {
            if (this.quantityRemaining > 0) return "warning";
            if (this.quantityRemaining < 0) return "danger";
            return "success";
        }
    },
    emits: ["input"],
    methods: {
        handleInput(newData, index) {
            const newItems = [
                ...this.value
            ];

            newItems[index] = { ...newItems[index], ...newData };

            if (this.itemIsEmpty(newItems[index])) {
                newItems.splice(index, 1);
            }

            this.$emit('input', newItems);
        },
        itemIsEmpty(item) {
            if (item.lot) return false;
            if (item.expiry) return false;
            if (item.quantity) return false;
            return true;
        },
        removeItem(index) {
            const newItems = [
                ...this.value
            ];

            newItems.splice(index, 1);

            this.$emit('input', newItems);
        }
    }
}

</script>

<style scoped>
::v-deep(th) {
    padding: 0 !important;
    padding-right: 6px !important;
    font-size: .9em !important;
    font-weight: normal !important;
}

::v-deep(td) {
    padding: 6px 0 !important;
    padding-right: 6px !important;
}

::v-deep(td:last-child) {
    padding-right: 0 !important;
}

::v-deep(.qty-col) {
    width: 200px;
}

::v-deep(.actions-col) {
    width: 40px;
}

::v-deep(.badge) {
    margin-top: 6px;
    font-size: .95em;
    display: block;
}

::v-deep(input[readonly]) {
    background: white;
    color: black;
}
</style>