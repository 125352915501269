
    import SearchComponent from "@/components/SearchComponent.vue";
    import { Component, Vue } from "vue-property-decorator";
    import axios from "axios";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import EnumSelector from "@/components/EnumSelector.vue";
    import moment from "moment";
    import numeral from "numeral";
    import { EDIOrder, EDIOrderStatus, OrderableDrug, EDIOrderLineItem } from "@/models/EDI/EDIOrder";
    import { Store } from "@/models/Store";
    import { Vendor } from "@/models/Vendor";

    @Component({
        name: "EDIOrderDetails",
        components: {
            EnumSelector,
            SearchComponent,
        },
    })
    export default class EDIOrderDetails extends Vue {
        private isLoading = false;
        protected ediOrderStatus = EDIOrderStatus;
        private orderableDrug: OrderableDrug = new OrderableDrug();
        private quantityToOrder: number | null = null;

        private order: EDIOrder = new EDIOrder();

        protected selectedStatus: string | null = null;
        private fields: any[] = [
            { key: "ndC11", label: "NDC", sortable: true },
            { key: "drugName", label: "Drug Name", sortable: true },
            { key: "unitPrice", label: "Unit Price", formatter: this.moneyFormatter, sortable: true },
            { key: "packagesOrdered", label: "Packages Ordered", sortable: true },
            {
                key: "extendedPrice",
                label: "Extended Price",
                formatter: this.moneyFormatter,
                sortable: true,
            },
            { key: "packagesReceived", label: "Packages Received", sortable: true },
            { key: "packagesInvoiced", label: "Packages Invoiced", sortable: true },
            {
                key: "invoicedPrice",
                label: "Invoiced Price",
                formatter: this.moneyFormatter,
                sortable: true,
            },
        ];
        private store: Store = new Store();
        private vendor: Vendor = new Vendor();

        get statusName() {
            return EDIOrderStatus[this.order.status];
        }

        moneyFormatter(value: number) {
            if (value == null) {
                return "";
            }
            return numeral(value).format("$0,0.00");
        }

        created() {
            this.loadData();
        }

        beforeDestroy() {
        }


        loadData() {
            this.isLoading = true;
            axios
                .get<EDIOrder>(`EDIOrdering/${this.$route.params.id}`)
                .then((res) => {
                    this.order = new EDIOrder(res.data);
                    axios
                        .get<Store>(`Store/${this.order.storeId}`)
                        .then((res) => {
                            this.store = new Store(res.data);
                        })
                        .catch((err) => {
                            this.$notification(NotificationOptions.error(err));
                        });
                    axios
                        .get<Vendor>(`Vendor/${this.order.vendorId}`)
                        .then((res) => {
                            this.vendor = new Vendor(res.data);
                        })
                        .catch((err) => {
                            this.$notification(NotificationOptions.error(err));
                        });
                })
                .catch((err) => {
                    this.$notification(NotificationOptions.error(err));
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        formattedDate(value: Date): string {
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY");
        }

        get totalExtendedPrice() {
            return this.order?.lineItems.reduce((pv, nv) => pv + nv.extendedPrice, 0);
        }

        get totalPackagesOrdered() {
            return this.order?.lineItems.reduce((pv, nv) => pv + nv.packagesOrdered, 0);
        }

        get totalPackagesReceived() {
            return this.order?.lineItems.reduce((pv, nv) => pv + (nv.packagesReceived ?? 0), 0);
        }

        get totalPackagesInvoiced() {
            return this.order?.lineItems.reduce((pv, nv) => pv + (nv.packagesInvoiced ?? 0), 0);
        }

        get totalInvoicedPrice() {
            return this.order?.lineItems.reduce((pv, nv) => pv + (nv.invoicedPrice ?? 0), 0);
        }

        async submitOrder() {
            try {
                await axios.post<EDIOrder>(`/EdiOrdering/Submit`, this.order);
                // this.order = new EDIOrder(resp.data);
                this.loadData();
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
            }
        }


        async cancelOrder() {
            try {
                await axios.post(`/EdiOrdering/Cancel/${this.order.id}`);
                this.loadData();
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
            }
        }

        async receiveOrder() {
            try {
                await axios.post<EDIOrder>(`/EdiOrdering/Receive`, this.order);
                // this.order = new EDIOrder(resp.data);
                this.loadData();
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
            }
        }

        async markInvoiced() {
            try {
                await axios.post(`/EdiOrdering/Invoiced`, this.order);
                this.loadData();
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
            }
        }

        addItem() {
            const item = EDIOrderLineItem.fromOrderableDrug(this.orderableDrug, this.quantityToOrder);
            this.order.lineItems.push(item);
        }

        clearModal() {
            this.orderableDrug = new OrderableDrug();
            this.quantityToOrder = 0;
        }

    }
