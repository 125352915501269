import HasID from "@/models/HasID";
import HasLabel from "@/models/HasLabel";
import { Patient } from "@/models/Patient";
import { Prescription } from "@/models/Prescription";
import { Prescriber } from "@/models/Prescriber";
import { Store } from "@/models/Store";
import { Drug } from "@/models/Drug/Drug.ts";
import { Program } from "@/models/Program";

export interface Escript extends HasID {
    messageId: string;
    messageType: string;
    sentTime: Date;
    relatesToMessageId: string;
    xmlString: string;
    prescriberOrderNumber: string;
    tertiaryIdentifier: string;
    created: Date;
    createdBy: string;
    smartSig: SmartSig;
    preferredNdc: string;
}

export interface SmartSig {
    sigText: string;
    responseMessage: string;
    action: string;
    dose: number;
    doseUnit: string;
    route: string;
    frequency: string;
    quantity: number;
    quantityUnit: string;
    daysSupply: number;
    reasonForUse: string;
    patientNotes: string;
    note: string;
    alternativeNdcList: string[];
}

export interface EscriptResponse {
    eScript: Escript;
    message: EscriptResponseMessage;
    preferredDrugObj?: Drug;
}

export interface EscriptResponseMessage {
    allergies: any[];
    benefitCoordinations: any[];
    drugs: any;
    kind: string;
    patient: Patient;
    pharmacy: any;
    prescriber: Prescriber;
    prescription: Prescription;
    supervision: any;
    urgency: EscriptUrgency;
    programObj: Program;
    storeObj: Store;
    patientObj: Patient;
    prescriberObj: Prescriber;
    drugObj: Drug;
    cancel: boolean;
    exception:any;
}

export enum EscriptUrgency {
    Standard = 0,
    Urgent = 1
}

export class Escript extends HasLabel {
    constructor(obj?:Escript) {
        super();
        if (obj) Object.assign(this, obj);
    }
    toString(): string {
        return `${this.messageType}`;
    }

    get isCancelRequest(): boolean {
        return this.messageType == "RxCancelRequest";
    }

    get daySupply():number{
        if (this.smartSig.daysSupply) return this.smartSig.daysSupply;
        if (this.smartSig.dose) return this.smartSig.quantity / this.smartSig.dose;
        return NaN;
    }
}

