
    import { Component, Vue } from "vue-property-decorator";
    import SearchComponent from "@/components/SearchComponent.vue";
    import { Store } from "@/models/Store";
    import { Patient } from "@/models/Patient";
    import { BvTableCtxObject } from "bootstrap-vue";
    import Axios, { AxiosRequestConfig } from "axios";
    import { Order } from "@/models/Order";
    import { Prescription } from "@/models/Prescription";
    import moment from "moment";

    @Component({
        name: "PickupQueuePage",
        components: { SearchComponent }
    })
    export default class PickupQueuePage extends Vue {
        private storeFilter: Store = new Store();

        private fields = [
            { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true },
            { key: "drugs", label: "Drug", sortable: true },
            { key: "totalCopay", label: "Total Copay" },
            { key: "updated", label: "Ready Since", formatter: (d: any) => moment(d).format("L"), sortable: true },
            { key: "edit", label: "" },
        ]

        get tableFilter() {
            return {
                storeId: this.storeFilter?.id,
            }
        }

        patientFormatter(pt: Patient): string {
            pt = new Patient(pt);
            return pt.displayNamesForPerson();
        }

        drugFormatter(scripts: Prescription[]): string {
            const drugNames = scripts.map(rx => rx.productNameShort);
            return drugNames.join('\n');
        }

        copayFormatter(scripts: Prescription[]): number {
            return scripts.reduce((previousValue, currentValue) => previousValue + currentValue.patientPay, 0);
        }

        orderDetailsRoute(item: Order): any {
            return {
                name: "OrderPickup",
                params: {
                    orderId: item.id
                }
            };
        }
        patientDashboardRoute(item: Order): any {
            return {
                name: "PatientDashboard",
                params: {
                    id: item.patientID
                }
            };
        }

        ctxProvider(ctx: BvTableCtxObject, callback: Function) {
            if (ctx.apiUrl == null) return null;
            const uri = ctx.apiUrl;
            const config: AxiosRequestConfig = {
                params: ctx.filter
            };
            Axios.get<Order[]>(uri, config)
                .then(resp => {
                    let orders = resp.data;
                    let tableItems = orders.map((o: any) => {
                        o.drugs = this.drugFormatter(o.prescriptions);
                        o.totalCopay = this.copayFormatter(o.prescriptions);
                        return o;
                    });
                    callback(tableItems);
                })
                .catch(_err => {
                    callback([]);
                });
            return null;
        }
    }
