import { DrugStatus } from "@/models/Drug/Drug";
import HasLabel from "@/models/HasLabel";
import HasID from "@/models/HasID";

export enum DrugSource {
    GsddFederal = 0,
    NonGsddProduct = 1,
    CompoundProduct = 2,
}

export interface DrugProduct extends HasID {
    source: DrugSource;
    drugId: number;
    ndc: string;
    packageSize: number;
    productNameShort: string;
    activeStatus: DrugStatus.DrugStatusEnum;
    licenseType: string;
    billingUnit:string;
    quantityOnHand?:number;
}

export abstract class DrugProduct extends HasLabel {
    constructor(obj?: DrugProduct) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get id(): number {
        return this.drugId;
    }

    toString(): string {
        return `${this.ndc} - ${this.productNameShort}${this.quantityOnHand ? ' - QTY:'+this.quantityOnHand : ''}`;
    }
}
