import HasID from "@/models/HasID";
import HasLabel from "@/models/HasLabel";

export interface AcceptanceRecord extends HasID {
    // Do not set this.
    id: number;
    configurationId: number;
    patientId: number;
    // Do not set this.  We cannot save a signature for the patient through cphub frontend.
    signatureData?: string;
    orderId?: number;
    // Do not set this. This is done on db save.
    created: Date;
    // Do not set this. This is done on db save.
    createdBy: string;
}

export class AcceptanceRecord extends HasLabel {
    constructor(obj?: AcceptanceRecord) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (!this.id) return "";
        return `${this.id} - ${this.signatureData}`;
    }
}
