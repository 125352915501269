import TrackedObject from "./TrackedObject";
import HasID from "./HasID";

export interface CaseNote extends TrackedObject, HasID {
    patientId: number;
    problem: string;
    error: boolean;
    resolved: Date;
    resolvedBy: string;
    accepted: Date;
    acceptedBy: string;
}

export interface CaseDetail extends CaseNote {
    lastName: string;
    firstName: string;
    middleName: string;
    dateOfBirth: Date;
    phoneNumber: string;
    cellNumber: string;
}

export const types = [
    'Dropped Order/RX Never Went Out',
    'HSA Account Was Billed',
    'Incorrect Directions',
    'Incorrect Dosage Form',
    'Incorrect Drug',
    'Incorrect Price Or Misquoted Price',
    'Incorrect Quantity',
    'Incorrect Recipient',
    'Incorrect Strength',
    'Package Is Missing',
    'Package Sent To Wrong Address',
    'Package Was Damaged',
    'Package Delayed',
    'Refund Medication Copay',
    'Patient Issue',
];
