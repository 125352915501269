
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import Axios from 'axios';

import { Prescription } from '@/models/Prescription';
import { Store } from '@/models/Store';

import SearchComponent from '@/components/SearchComponent.vue';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import BottomPagerBar from '@/components/BottomPagerBar.vue';

@Component({
    name: "ReversalQueuePage",
    components: {
        SearchComponent,
        PrescriptionsListComponent,
        BottomPagerBar
    },
})
export default class ExpiredOnOrderPage extends Vue {
    private prescriptions: Array<Prescription> = [];
    private storeFilter: Store = new Store();
    private loading: boolean = false;
    private pageNumber = 1;
    private pageSize = 25;

    created() {
        this.search();
    }

    async search() {
        try {
            this.loading = true;
            const response = await Axios.get(`/Prescription/ExpiredOnOrder/${this.storeFilter?.id || ''}`);
            this.prescriptions = response.data;
        }
        catch (error) {
            console.warn("Error while fetching prescriptions", error);
        }
        finally {
            this.loading = false;
        }
    }

    get pagedPrescriptions() {
        const startIndex = this.pageSize * this.pageNumber - this.pageSize;
        const endIndex = startIndex + this.pageSize;
        return this.prescriptions.slice(startIndex, endIndex);
    }

    @Watch("prescriptions")
    @Watch("pageSize")
    resetPageNumber() {
        this.pageNumber = 1;
    }
}

