import TrackedObject from "./TrackedObject";

export interface Surplus  extends TrackedObject {
    id?:number
    storeId: number;
    vendorId?:number
    vendorName?:string
    manufacturer?:string;
    packageId: number;
    drugName?:string;
    ndc?:string;
    quantity: number;
    unitCost:number
    totalCost:number;
    expirationDate: Date;
    created:Date;
    createdBy:string;
    updated:Date;
    updatedBy:string;
    action:string;
}

export class Surplus implements Surplus{
    constructor(obj?:Surplus){
        if(obj) Object.assign(this, obj);
    }
}
