import  TrackedObject from "./TrackedObject";
import  HasID from "./HasID";

export interface Questionary extends HasID, TrackedObject {
    drugCategoryID? : number
    title? : string
}

export class Questionary{
    private questions : Array<Questionary.Question> = [];

    enumerateQuestions():Array<Questionary.Question>{
        this.questions?.forEach((q:Questionary.Question, index:number) => {
            q.indexNumber = index;
        });
        return this.questions;
    }

    pushNewQuestion(q:Questionary.Question):Array<Questionary.Question>{
        this.questions.push(q);
        return this.enumerateQuestions();
    }

    removeQuestion(q:Questionary.Question):Array<Questionary.Question>{
        this.questions = this.questions.filter(q2 => q2.indexNumber != q.indexNumber);
        return this.enumerateQuestions();
    }

    prepareQuestions():void{
        this.questions = this.questions.filter(q => q.questionText);
        this.enumerateQuestions();
    }

    set answers(answers:Array<Questionary.Answer>){
        this.questions.forEach((q:Questionary.Question) => {
            const answer = answers.find(a => a.questionID == q.id);
            q.answer = answer 
                || new Questionary.Answer(q.id);
        })
    }

    get answers():Array<Questionary.Answer>{
        return this.questions.map((q:Questionary.Question) => q.answer);
    }

    get orderedQuestionsAsc(): Array<Questionary.Question> {
        let  res = this.questions?.sort((q1:Questionary.Question,q2:Questionary.Question)=> {
            return q1.indexNumber - q2.indexNumber;
        });
        res = res.map(q => {
            const qres = Object.assign(new Questionary.Question(), q);
            qres.answer = qres.answer || new Questionary.Answer(q.id);
            return qres;
        });
        return res || [];
    }

    get amountOfQuestions():number {
        return this.questions?.length || 0;
    }
}

export namespace Questionary {
    export interface Question extends HasID, TrackedObject {
        questionaryID : number
        indexNumber : number
        ufi : string
        type : string
        questionText : string
        answer:Answer;
    }
    export class Question{
        constructor(){
            this.answer = new Questionary.Answer(this.id);
        }
    }

    export interface Answer extends HasID, TrackedObject {
        id : number
        questionID? : number
        storeID? : number
        rxNumber? : number
        rfNumber? : number
        value : string
    }
    export class Answer{
        constructor(questionID?:number|string){
            this.questionID = Number(questionID ?? 0);
        }
    }
}