
import { Vue, Component, Watch, Ref } from "vue-property-decorator";
import Card from "@/components/Cards/Card.vue";
import PriorAuthorizationDetails from "@/components/PriorAuthorization/PriorAuthorizationDetails.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import moment from 'moment';
import { PriorAuthorizationStatusCode, PriorAuthorization, CoverMyMedsCallback } from '@/models/PriorAuthorization';
import { Prescription } from '@/models/Prescription';
import BottomPagerBar from "@/components/BottomPagerBar.vue";

@Component({
    components: {
        Card,
        PriorAuthorizationDetails,
        EnumSelector,
        BottomPagerBar
    }
})
export default class PriorAuthorizationListPage extends Vue {
    created() {
        this.fetchPriorAuthorizations();
    }

    get totalPas(): number {
        return this.priorAuthorizations.length;
    }

    private paCodes = PriorAuthorizationStatusCode;

    private isLoading = false;
    private selectedStatus: string | null = null;
    private selectedManufacturer: string = "All";
    private selectedPa: PriorAuthorization = {} as PriorAuthorization;
    private filterQuery: string | null = "";

    private priorAuthorizations: Array<PriorAuthorization> = [];

    private pageSize = 100;
    private pageNumber = 1;

    @Ref("tableBlock") protected tableBlock: any;

    private fields: any[] = [
        {
            key: "prescriber",
            label: "Prescriber"
        },
        {
            key: "patient",
            label: "Patient Name"
        },
        { key: "prescription", label: "Rx Id", formatter: (p: Prescription) => `${p.storeID}-${p.rxNumber}-${p.rfNumber}` },
        { key: "prescription.productNameShort", label: "Drug Name", },
        { key: "created", label: "Initiated", formatter: (d: any) => moment.utc(d).local().format("l LT"), sortable: true },
        { key: "updated", label: "Next Touch", formatter: this.formatNextTouch, sortable: true },
        { key: "latestCallback", label: "CMM Event", formatter: this.cmmEventFormatter, },
        { key: "statusName", label: "Status" },
        { key: "completeReason", label: "Reason", },
    ];

    formatDate(d: Date) {
        return moment(d).format("L");
    }

    formatNextTouch(d: Date, key: string, item: PriorAuthorization): string {
        const daysToAdd = item.status === PriorAuthorizationStatusCode.SubmittedToInsurance ? 7 : 1;
        return moment.utc(d).local().add(daysToAdd, "day").format("l LT");
    }

    cmmEventFormatter(cb: CoverMyMedsCallback) {
        return cb?.event_key ? cb.event_key : cb?.error ?? "";
    }

    get totalRows() {
        return this.filteredPriorAuthorizations?.length ?? 0;
    }

    get filteredPriorAuthorizations() {
        const allManufact = this.selectedManufacturer === "All";
        const allStatus = !this.selectedStatus && this.selectedStatus != "0";
        const queryTerms = this.filterQuery?.toLowerCase().split(" ");
        const hasQueryTerms = (this.filterQuery?.trim().length ?? 0) > 0;

        return this.priorAuthorizations.filter(item => {
            const manufact = allManufact || item.drugManufacturer == this.selectedManufacturer;
            const status = allStatus || item.status.toString() == this.selectedStatus;
            const filterMatch = !hasQueryTerms || queryTerms!.every((qt: string) =>
                item.patient?.firstName?.toLowerCase().includes(qt) ||
                item.patient?.lastName?.toLowerCase().includes(qt) ||
                item.prescription?.drugName?.toLowerCase().includes(qt) ||
                item.prescription?.packageID?.toString().includes(qt) ||
                item.completeReason?.toLowerCase().includes(qt) ||
                item.prescription.rxNumber?.toString().includes(qt) ||
                item.statusName.toLowerCase().includes(qt) ||
                item.prescriber?.lastName?.toLowerCase().includes(qt) ||
                item.prescriber?.firstName?.toLowerCase().includes(qt) ||
                this.cmmEventFormatter(item.latestCallback).includes(qt));

            return manufact && status && filterMatch;
        });
    }

    resetPage() {
        this.pageNumber = 1;
    }

    @Watch("pageNumber")
    resetTableScrollPosition() {
        this.tableBlock?.scrollIntoView({ behavior: "smooth" });
    }

    async fetchPriorAuthorizations() {
        try {
            const url = `/PriorAuthorization/`;

            this.isLoading = true;
            const response = await this.$http.get<Array<PriorAuthorization>>(url);

            this.priorAuthorizations = response.data?.map(pa => new PriorAuthorization(pa));
            this.resetPage();
        } catch (err) {
            console.error("Error loading PAs.", err);
        }

        this.isLoading = false;
    }

    get manufacturers() {
        return ["All", ...this.priorAuthorizations.map(p => p.drugManufacturer).filter(this.onlyUnique).sort()];
    }

    get manufacturersByStatus() {
        // Hover text for manufacturers status.
        const groupedList = this.priorAuthorizations.reduce((group: any, pa) => {
            const { status } = pa;
            group[status] = group[status] ?? [];
            group[status].push(pa);
            return group;
        }, {});

        const group = Object.getOwnPropertyNames(groupedList).reduce((grp: any, status: any) => {
            grp[status] = groupedList[status].reduce((group: any, pa: any) => {
                const { drugManufacturer } = pa;
                if (!group.hasOwnProperty(drugManufacturer)) {
                    group[drugManufacturer] = 0;
                }
                group[drugManufacturer]++;
                return group;
            }, {})
            return grp;
        }, {});

        return Object.getOwnPropertyNames(group).map((status: any) => {
            let manus = Object.getOwnPropertyNames(group[status]).sort();
            return { status: Number(status), text: manus.map((name: any) => `${name}: ${group[status][name]}`).join(' <br/> ') }
        });
    }

    private statusToIgnore = [
        PriorAuthorizationStatusCode.Abandoned,
        PriorAuthorizationStatusCode.Approved,
        PriorAuthorizationStatusCode.Denied,
        PriorAuthorizationStatusCode.Cancelled
    ];

    countByStatus(status: PriorAuthorizationStatusCode): number {
        const res = this.priorAuthorizations.filter(pa => pa.status == status)?.length || 0;
        return res;
    }

    get paStatusList(): Array<PriorAuthorizationStatusCode> {
        const res = new Array<PriorAuthorizationStatusCode>();

        for (const st in PriorAuthorizationStatusCode)
            if (Number(st) >= 0 && !this.statusToIgnore.some(st2 => st2 == Number(st)))
                res.push(Number(st) as PriorAuthorizationStatusCode);

        return res;
    }

    getManufacturersByStatusHoverText(status: PriorAuthorizationStatusCode): string {
        const res = this.manufacturersByStatus.find((m: any) => m.status == status)?.text;
        return res || '';
    }

    onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    selectPa(item: PriorAuthorization) {
        this.selectedPa = item;
        this.$bvModal.show("pa-details-modal");
    }
}
