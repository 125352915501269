
import axios from 'axios';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';

import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import { RelationshipCode } from '@/models/PatientPlan';

import { Plan } from '@/models/Plan';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { PharmacyBenefitManager } from '@/models/PharmacyBenefitManager';
import { OrderAuditConfiguration } from "@/models/OrderAudit";
import { EntityType } from "@/models/EntityType";

@Component({
    name: 'PlanDetailsPage',
    components: { SearchComponent, Checkbox, NewClearSaveButtons, EnumSelector },
})
export default class PlanDetailsPage extends Mixins(DisableAutocompleteMixin) {
    @Prop({
        default: () => {
            return {
                save: "Save Changes",
                new: "New Plan",
                clear: "Clear",
            };
        }
    }) protected buttonTitles!: any;
    @Prop({ default: false }) private createNewPlan!: any;

    private pbm: PharmacyBenefitManager = new PharmacyBenefitManager();
    private plan: Plan = new Plan();
    protected orderAuditConfig: OrderAuditConfiguration = new OrderAuditConfiguration();
    protected providerTypeEnums = Plan.ProviderType;
    protected searchValue = "";
    protected displayName = "";
    private newMode = false;
    private isOffline: boolean = false;
    protected flagOptions = Plan.flagOptions;
    protected relationshipCodes = RelationshipCode;
    private codesInventoryCSV: File = new File([], "");
    private csvErrors: Array<string> = [];
    private fatalCSVError = "";

    created() {
        if (this.createNewPlan)
            this.createPlan();
    }

    get disablePBM(): boolean | null {
        console.log("user: ", this.$user);
        let disable = this.disableFields;
        disable = !this.$user.isManager || disable;
        return disable;
    }

    get disableFields(): boolean | null {
        return (this.plan?.id || 0) == 0 && !this.newMode;
    }

    get isFormCompleted() {
        const b1 = !this.disableFields && !!(this.plan.name && this.plan.bin);
        const validOfflineCopay = this.isOffline && (this.plan.offlineCopay || this.plan.offlineCopay == 0)
            || !this.isOffline && !this.plan.offlineCopay;

        return b1 && validOfflineCopay;
    }

    async binSet() {
        const config = { params: { bin: this.plan.bin } };
        let pbm = (await axios.get<PharmacyBenefitManager>("api/PharmacyBenefitManager/BIN", config)).data;
        this.pbm = new PharmacyBenefitManager(pbm);
    }

    clearConfirmation() {
        this.clearForm();
    }

    createPlan() {
        this.newMode = true;
        this.isOffline = false;
        this.plan = new Plan();
        this.pbm = new PharmacyBenefitManager();
        this.orderAuditConfig = new OrderAuditConfiguration();
    }

    clearForm() {
        this.newMode = false;
        this.isOffline = false;
        this.plan = new Plan();
        this.pbm = new PharmacyBenefitManager();
        this.orderAuditConfig = new OrderAuditConfiguration();
    }

    savePlan() {
        this.plan.offlineCopay = this.isOffline ? (this.plan.offlineCopay || 0) : null;
        if (this.pbm?.id as number) this.plan.pbmId = this.pbm.id as number;
        else this.plan.pbmId = null as unknown as number;
        axios.post<Plan>('/Plan', this.plan)
            .then(response => {
                if (response.data.id) {
                    this.plan = new Plan(response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Plan"));
                    if (!this.codesInventoryCSV?.size) {
                        this.closeNewForm();
                    } else this.uploadCodes();
                    this.orderAuditConfig.entityType = EntityType.Plan;
                    this.orderAuditConfig.entityId = this.plan.id;
                    axios.post<OrderAuditConfiguration>("/api/OrderAuditConfig", this.orderAuditConfig)
                        .then(response => {
                            if (response.data.id) {
                                this.orderAuditConfig = new OrderAuditConfiguration(response.data);
                            }
                        })
                        .catch(err => this.$notification(NotificationOptions.error(err)));
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Plan", error));
            });

    }

    closeNewForm() {
        if (this.createNewPlan) {
            this.$emit("created", this.plan);
            this.plan = new Plan();
        }
    }

    onPlanChanged(value: Plan) {
        this.plan = value;
        this.isOffline = this.plan.offlineCopay != null;
        axios.get<OrderAuditConfiguration>(`/api/OrderAuditConfig/Plan/${this.plan.id}`)
            .then(configsResponse => {
                if (configsResponse.data) {
                    this.orderAuditConfig = new OrderAuditConfiguration(configsResponse.data);
                }
            })
        if (this.plan.pbmId) {
            axios.get<PharmacyBenefitManager>(`/PharmacyBenefitManager/${this.plan.pbmId}`)
                .then(pbmResponse => {
                    this.pbm = new PharmacyBenefitManager(pbmResponse.data);
                });
        }
    }

    uploadCodes() {
        if (!this.codesInventoryCSV?.size) return;

        const formData = new FormData();
        formData.append('csvFile', this.codesInventoryCSV);
        const errorIconMsg = 'Click error details for more information.'
        this.csvErrors = [];
        this.fatalCSVError = "";
        axios.post<{ codesCount: number, errors: any }>(`/plan/${this.plan.id}/codes-inventory/`, formData
            , { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(response => {
            this.csvErrors = response.data?.errors;
            if (response.data.codesCount === 0) {
                this.$notification(NotificationOptions.notificationOptionsPreset(
                    'CSV processed, but no new plan codes were inserted. '
                    + (this.errorsWereFound ? errorIconMsg : "All introduced plan codes were already registered in the database before or file is empty.")
                    , NotificationOptions.NotificationTypes.warning));
            } else if (response.data?.codesCount > 0) {
                this.updateCodesAvailableCount();
                if (this.errorsWereFound)
                    this.$notification(NotificationOptions.notificationOptionsPreset(
                        `CSV processed, ${response.data.codesCount} new codes inserted, but some errors were found. ` + errorIconMsg
                        , NotificationOptions.NotificationTypes.warning
                    ));
                else
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Plan Codes in CSV file"));
            }

            if (!this.errorsWereFound) {
                this.closeNewForm();
            }

        })
            .catch(err => {
                this.$notification(NotificationOptions.notificationOptionsPreset('Error while loading plan codes. ' + errorIconMsg, NotificationOptions.NotificationTypes.danger));
                this.fatalCSVError = err.message + ": " + err.response?.data?.substring(0, 200) + "...";
                console.log(err, { err, response: err?.response });
            })
    }

    updateCodesAvailableCount() {
        this.$http.get<Plan>(`plan/${this.plan.id}`)
            .then(res => {
                this.plan.inventoryQuantityAvailable = res.data?.inventoryQuantityAvailable;
                this.plan.inventoryLastUpdate = res.data?.inventoryLastUpdate;
            })
    }

    showErrors() {
        this.$bvModal.show('csv-errors');
    }

    downloadTemplate() {
        axios.get(`plan/codes-inventory-template`, { responseType: "blob" })
            .then(res => {
                const fileURL = URL.createObjectURL(res.data);
                //var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'InventoryCodesTemplate.csv');
                document.body.appendChild(fileLink);

                fileLink.click();
            })
            .catch(err => {
                console.error("Error while inventory codes template.", { err, response: err.response });
            })
    }

    get errorsWereFound(): boolean {
        return this.csvErrors?.length > 0 || this.fatalCSVError?.length > 0;
    }

} // close export of class
