
    import Vue from 'vue';
    import SearchComponent from '@/components/SearchComponent.vue';
    import {LockHandler, Lock} from '@/mixins/LockHandler';
    import Axios, {AxiosError} from 'axios';
    import {Component, Watch, Mixins, Ref} from 'vue-property-decorator';
    import Card from "@/components/Cards/Card.vue";
    import {Patient, PatientProgram} from '@/models/Patient';
    import { DatePicker } from "element-ui";
    import OrdersListComponent from '@/components/Order/OrdersListComponent.vue';
    import { Store } from '@/models/Store';
    import { Order } from '@/models/Order';
    import { Prescription, DAWCode, OriginCode, RefillStatusCode } from '@/models/Prescription';

     @Component({
        name: "RxAuditVerificationPage",
        components: {
            SearchComponent,
            [DatePicker.name]: DatePicker,
            OrdersListComponent
        },
    })

    export default class RxAuditVerificationPage extends Vue {
        private orders: Array<Order> = [];
        private searchedOrder: Order | null = null;
        private searchString: string = "";
        private timeout: any = null;

        @Watch('patient') onPatientChanged(value: Patient | null, oldValue: Patient | null) {
        }

        created() {
            if (this.routeStoreId) {
                this.storeFilter.id = this.routeStoreId;
                this.loadOrders();

            }

            else {
                Axios.get(`/Store/`).then(response => {
                    this.storeFilter = response.data[0];
                }).then(() => {
                    this.loadOrders();
                });
            }
        }

        mounted() {
            window.scrollTo(0, 0);
        }

        get routeStoreId(): number {
            let res = 0;
            res = parseInt(this.$route.params.storeId);
            if (isNaN(res))
                return 0;
            return res;
        }

        loadOrders() {
            //Get patient's prescriptions and format the data to be shown in the grid.
            Axios.get(`/Order/pending-pv2`, {
                params: {
                    patientId: null,
                    prescriberId: null,
                    programId: null,

                    storeID: null,
                    rxNumber: null,
                    rfNumber: null,

                    orderStoreId: this.storeFilter?.id
                }
            })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        rxID(prescription: Prescription | null): string {
            if (!prescription) return "";
            return `${prescription.storeID}-${prescription.rxNumber}-${prescription.rfNumber}`;
        }

        private storeFilter: Store = {} as Store;
        storeLabel(object: Store) {
            if (object?.id)
                return object.name;
            else '';
        }

        clearFilters() {
            this.searchString = "";
            this.storeFilter = {} as Store;
            this.loadOrders();
            console.log(this.$refs.searchString);
        }

        awaitEndOfTyping() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.searchFiltered();
            }, 250);
        }

        searchFiltered() {
            clearTimeout(this.timeout);
            Axios.get(`/Order/pending-search/`, {
                params: {
                    storeId: this.storeFilter.id,
                    searchTerm: this.searchString
                }
            })
                .then(response => {
                    this.orders = response.data;
                    if (this.orders.length < 1) {
                        this.$notification({
                            message: "No Order Found",
                            timeout: 1000,
                            icon: "now-ui-icons ui-1_bell-53",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "danger"
                        });
                    }
                }).catch(error => {
                    console.warn(error);
                });
        }
    }

