import HasID from "./HasID";

export interface History extends HasID {
    oldValue: string;
    newValue: string;
    updated: Date;
    updatedBy: string;
}

export function historyDiff(history: History): string[] {
    const jsondiffpatch = require("jsondiffpatch").create({
        propertyFilter: function(name: string) {
            if (name == "Updated") return false;
            if (name == "UpdatedBy") return false;
            return true;
        }
    });

    let oldValue: any;
    if (history.oldValue) oldValue = JSON.parse(history.oldValue)[0];
    const newValue = JSON.parse(history.newValue)[0];
    const delta = jsondiffpatch.diff(oldValue, newValue);
    return delta;
}
