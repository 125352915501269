
import { Component, Watch, Mixins } from 'vue-property-decorator';
import Axios from 'axios';
import moment from 'moment';
import SearchComponent from '@/components/SearchComponent.vue';
import { Store } from "@/models/Store"
import { Patient } from "@/models/Patient";
import { Prescription } from "@/models/Prescription";
import { Payment } from "@/models/Payment";
import { Order } from "@/models/Order";
import { Program } from "@/models/Program";
import { LockHandler } from '@/mixins/LockHandler';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    name: "PatientWebPartialPaymentQueue",
    components: {
        SearchComponent,
    },
})
export default class PatientWebPartialPaymentQueue extends Mixins(LockHandler) {
    private partialPaymentQueue: Order[] = [];
    private storeFilter: Store = {} as Store;
    private programFilter: Program | null = null;
    private fields = [
        { key: "id", label: "Order ID", sortable: true },
        { key: 'patient', label: 'Patient Name', formatter: this.patientName, sortable: true },
        { key: 'patient.dateOfBirth', label: 'Date of Birth', formatter: this.convertDate, sortable: true },
        { key: 'program', label: 'Program', sortable: true, sortByFormatted: true, formatter: (_: any, __: any, item: Order) => this.getProgramName(item) },
        { key: 'created', label: 'Order Date', formatter: this.convertDate, sortable: true },
        { key: 'prescriptions', label: 'Total Copay', formatter: this.totalCopayFormat, sortByFormatted: this.totalCopay, sortable: true },
        { key: 'payments', label: 'Payment Total', formatter: this.totalPaymentFormat, sortByFormatted: this.totalPayment, sortable: true },
        { key: 'actions', label: '', class: "actions" }
    ];

    created() {
        Axios.get(`/Store/`).then(response => {
            this.storeFilter = response.data[0];
        }).then(() => {
            this.getUnprocessedOnlineRefills();
        })
    }

    getProgramName(item: Order) {
        let programNames = item?.prescriptions?.filter(i => !!i.programName)?.map(i => i.programName) ?? [];
        if (programNames.length == 0) return '';
        return programNames[0];
    }

    @Watch('storeFilter')
    @Watch('programFilter')
    getUnprocessedOnlineRefills() {
        Axios.get<Array<Order>>(`/Order/partial-payment-queue`).then(response => {
            this.partialPaymentQueue = response.data.map(o => new Order(o));
            if (this.storeFilter) {
                this.partialPaymentQueue = this.partialPaymentQueue.filter((order => order.storeID == this.storeFilter.id));
            }
            if (this.programFilter) {
                console.log("programFilter =", this.programFilter);
                console.log("partialPaymentQueue = ", this.partialPaymentQueue);
                this.partialPaymentQueue = this.partialPaymentQueue.filter((order => this.getProgramName(order) == this.programFilter!.name));
            }
        });
    }

    hoverRow() {
        return "hoverRow";
    }

    rowClicked(object: Order) {
        return window.location.href = "/cphub/patient-dashboard/" + object.patientID;
    }

    convertDate(date: string) {
        return moment.utc(date).format("L");
    }

    convertAmount(amt: number) {
        return '$' + amt.toFixed(2);
    }

    searchLabel(object: Store) {
        if (object?.id)
            return object.name;
        else '';
    }

    patientName(object: Patient) {
        return object.firstName + " " + object.lastName;
    }

    programName(object: Prescription[]) {
        if (object.length > 0) {
            return object[0].programName;
        } else {
            return "Unknown";
        }
    }

    totalCopayFormat(object: Prescription[]) {
        return this.totalCopay(object).toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
        });
    }

    totalCopay(object: Prescription[]): Number {
        var totalCopay = 0;

        object.forEach(p => {
            totalCopay += p.patientPay;
        });

        return totalCopay;
    }

    totalPaymentFormat(object: Payment[]) {
        return this.totalPayment(object).toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
        });
    }

    totalPayment(object: Payment[]) {
        let totalPayment = 0;

        let uniquePayments = [...new Map(object.map(item => [item.chargeId, item])).values()];

        uniquePayments.forEach(p => {
            totalPayment += p.authorizedAmount;
        });

        return totalPayment;
    }

    async resendText(order: Order) {

        try {
            const result = await this.$bvModal.msgBoxConfirm('Are you sure you want to resend a partial payment text for this order?', {
                title: 'Resend Partial Payment Text',
                okVariant: 'primary',
                centered: true,
                noFade: true
            });

            if (!result) return;

            const response = await Axios.post(`/Order/partial-payment-resend/${order.id}`);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

    }

}
