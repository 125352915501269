
import { InventoryParLevelViewModel } from "@/models/InventoryParLevel";
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchComponent from '@/components/SearchComponent.vue';
import { Drug } from "@/models/Drug/Drug";
import { Store } from "@/models/Store";
import { Program } from "@/models/Program";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { toRaw } from "vue";
import { InventoryLocation } from "@/models/InventoryLocation";

const anyLocation = Object.assign(new InventoryLocation(), { id: null, name: "Any Location" });

@Component({
    name: "PeriodicInventoryDetailsPage",
    components: {
        SearchComponent
    }
})
export default class InventoryParLevelsComponent extends Vue {

    @Prop() protected drug?: Drug;

    private items: InventoryParLevelViewModel[] = [];
    private sortBy = "inAlert";
    private sortDesc = true;

    private selectedItem: InventoryParLevelViewModel | null = null;
    private selectedDrug: Drug = new Drug();
    private selectedStore: Store = new Store();
    private selectedProgram: Program = new Program();
    private selectedLocation: InventoryLocation = new InventoryLocation();
    private loading = true;
    private loadError = false;

    mounted() {
        this.loadHasInventoryLocations();
        this.loadItems();
    }

    get fields() {
        return [
            { key: "inAlert", sortable: true, class: "in-alert-col", label: "Alert" },
            { key: "drugName" },
            { key: "storeName" },
            { key: "programName" },
            { key: "locationName", hidden: !this.hasInventoryLocations },
            { key: "unitsOnHand" },
            { key: "daysOnHand" },
            { key: "actions", label: "", class: "actions-col" }
        ].filter(f => f.hidden !== true)
    }

    rowClass(item: InventoryParLevelViewModel, type: string) {
        if (!item || type !== 'row') return
        if (item.inAlert) return 'table-danger in-alert-row'
    }

    private add() {
        this.selectedItem = {
            id: 0,
            created: new Date(),
            createdBy: "",
            updated: new Date(),
            updatedBy: ""
        };
        this.selectedDrug = this.drug ?? new Drug();
        this.selectedStore = new Store();
        this.selectedProgram = new Program();
        this.selectedLocation = anyLocation;

        this.$bvModal.show("par-level-modal");
    }

    private edit(item: InventoryParLevelViewModel) {
        // @ts-ignore
        this.selectedItem = structuredClone(toRaw(item));
        this.selectedDrug = new Drug(item.drugId);
        this.selectedStore = Object.assign(new Store(), { id: item.storeId });
        this.selectedProgram = Object.assign(new Program(), { id: item.programId });
        this.selectedLocation = Object.assign(new InventoryLocation(), { id: item.locationId, name: item.locationName });
        this.$bvModal.show("par-level-modal");
    }

    async loadItems() {
        try {
            this.loadError = false;
            this.loading = true;
            const response = await this.$http.get<InventoryParLevelViewModel[]>("/inventory/par-levels");

            this.items = response.data;
        } catch (err) {
            this.loadError = true;
            this.$notification(NotificationOptions.error(err));
        } finally {
            this.loading = false;
        }
    }

    get filteredItems() {
        if (this.drug) {
            return this.items.filter(i => i.drugSource === this.drug?.source && i.drugId === this.drug?.drugId);
        }
        return this.items;
    }

    async deleteItem(item: InventoryParLevelViewModel) {
        try {
            const confirm = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete this Inventory PAR Level?",
                {
                    title: "Delete Inventory PAR Level",
                    noFade: true,
                    centered: true,
                    okTitle: "Delete",
                    okVariant: "danger"
                });

            if (!confirm) return;

            await this.$http.delete("/inventory/par-level/" + item.id);
            this.loadItems();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async saveItem(e?: any) {
        e?.preventDefault();

        try {

            const errorMsgOptions = { title: "Error", centered: true, noFade: true };

            if (!this.selectedDrug?.drugId) {
                this.$bvModal.msgBoxOk("You must select a drug.", errorMsgOptions);
                return;
            }

            if (!this.selectedStore?.id) {
                this.$bvModal.msgBoxOk("You must select a store.", errorMsgOptions);
                return;
            }

            if (!this.selectedItem!.unitsOnHand && !this.selectedItem!.daysOnHand) {
                this.$bvModal.msgBoxOk("You must enter a value for Units on Hand or Days on Hand.", errorMsgOptions);
                return;
            }

            this.selectedItem!.drugSource = this.selectedDrug.source;
            this.selectedItem!.drugId = this.selectedDrug.id;
            this.selectedItem!.storeId = this.selectedStore.id as number;
            this.selectedItem!.programId = this.selectedProgram?.id;
            this.selectedItem!.unitsOnHand = !this.selectedItem!.unitsOnHand ? null : this.selectedItem!.unitsOnHand;
            this.selectedItem!.daysOnHand = !this.selectedItem!.daysOnHand ? null : this.selectedItem!.daysOnHand;
            this.selectedItem!.locationId = this.selectedLocation.id;

            await this.$http.post("/inventory/par-level", this.selectedItem);

            this.loadItems();

            this.$bvModal.hide("par-level-modal");
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    addLocationOptions(locations: Array<InventoryLocation>, params: { searchTerm: string }) {
        if (params.searchTerm?.trim()) return locations;
        return [
            anyLocation,
            Object.assign(new InventoryLocation(), { id: 0, name: "No Location Specified" }),
            { heading: true, label: "Locations" },
            ...locations
        ];
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await this.$http.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }

}

