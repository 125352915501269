
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import axios from 'axios';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Lock, LockHandler } from '@/mixins/LockHandler';
import { Prescriber, PrescriberContact } from '@/models/Prescriber';
import { Program } from '@/models/Program';
import NameSearchComponent from '@/components/NameSearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import AddressFormFields from '@/components/AddressFormFields.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import NotesList from '../Note/NotesList.vue';
import NotesForm from '../Note/NotesForm.vue';
import { EntityType } from "@/models/EntityType";
import NotificationTypes = NotificationOptions.NotificationTypes;

@Component({
    name: 'DoctorFormComponent',
    components: { NameSearchComponent, NewClearSaveButtons, AddressFormFields, SearchComponent, NotesForm, NotesList },
})
export default class DoctorFormComponent extends Mixins(LockHandler, DisableAutocompleteMixin) {

    @Prop() private buttonTitles!: any;
    @Prop({ type: Boolean, default: false }) private createNewDoctor!: boolean;
    @Prop() private escriptResponsePrescriber?: Prescriber;

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        if (this.createNewDoctor)
            this.createPrescriber();
    }

    beforeDestroy() {
        console.log(`%c Destroying ${this.$options.name}`, "color: red");
    }

    private surescriptsDoctor: Prescriber = new Prescriber();
    private doctor: Prescriber = new Prescriber();
    private selectedProgram: Program = new Program();

    private doctorLock: Lock | null = null;

    private newMode = false;
    private doctorEntityType = EntityType.Prescriber;

    refreshNotesList() {
        if (this.$refs.notesList instanceof NotesList)
            this.$refs.notesList.loadNotes();
    }

    get disableFields(): boolean {
        const lockedForUser = !this.doctorLock;
        const noEntityLoaded = !(this.doctor && this.doctor.id);

        // Disable fields if
        // ** its locked for the user
        // ** or if there is no doctor loaded and is not in New object Mode.
        return (lockedForUser || noEntityLoaded) && !this.newMode;
    }

    private nameIntroduced = "";
    private displayName = "";

    @Watch('doctor') onDoctorChanged(value: Prescriber, oldValue: Prescriber) {
        if (!value) {
            this.displayName = "";
            return;
        }
        const doctor = value;
        if (!value.id) {
            this.doctor.updateNamesForPersonFromDisplayName(this.displayName);
        } else {
            if (!oldValue || oldValue.id != doctor.id) {
                this.fetchPrescriber(doctor);
            }
        }
        this.displayName = value.displayNamesForPerson();
    }

    fetchPrescriber(prescriber: Prescriber) {
        this.addLockWithURL(`/Prescriber/${prescriber.id}/lock`, 60000)
            .then(result => {
                console.log(result);
                this.doctorLock = result;
            })
            .catch(error => {
                if (error.response && error.response.status == 418) {
                    // doctor locked
                    console.log('Doctor is locked');
                    console.log(error.response);
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Doctor is locked by ${lockedBy} until ${expires}.`);
                }
            })
            .finally(() => {
                axios.get(`/Prescriber/${prescriber.id}`)
                    .then(response => {
                        this.doctor = Object.assign(new Prescriber(), response.data);
                        this.selectedProgram.id = this.doctor?.programId || 0;
                        this.displayName = this.doctor.displayNamesForPerson();
                        this.nameIntroduced = this.doctor.displayNamesForPerson();
                        this.newMode = false;
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            });
    }

    surescriptsSelected(value: any) {
        this.doctor = new Prescriber(value);
        (this.doctor.id as any) = undefined;
        this.displayName = this.doctor.displayNamesForPerson();
        this.nameIntroduced = this.doctor.displayNamesForPerson();
    }

    personUpdate(value: any) {
        this.doctor = value;
    }

    nameChanged(val: string) {
        this.nameIntroduced = val;
    }

    undoChanges() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
            title: 'Confirm',
            okVariant: 'danger',
            centered: true,
        })
            .then(value => {
                if (!value) return;
                if (this.doctorLock && this.doctorLock.refreshURL) {
                    this.releaseLockAtURL(this.doctorLock.refreshURL);
                    this.doctorLock = null;
                }
                this.doctor = new Prescriber();
                this.newMode = false;
                this.selectedProgram = new Program();
                this.displayName = '';
            })
            .catch(err => {
                console.log("Error caught in undoChanges()");
                console.error(err);
            });
    }

    createPrescriber() {
        this.newMode = true;
        this.displayName = '';
        this.doctor = new Prescriber();
        if (this.escriptResponsePrescriber) {
            Object.assign(this.doctor, this.escriptResponsePrescriber);
            this.displayName = this.doctor.displayNamesForPerson();
            this.nameIntroduced = this.doctor.displayNamesForPerson();
        }
    }

    savePrescriber() {
        if (!this.doctor) {
            console.warn("We have no doctor/prescriber to save, get a new one?");
            this.createPrescriber();
        }
        const doctor: Prescriber = this.doctor as Prescriber;
        doctor.updateNamesForPersonFromDisplayName(this.displayName);

        const data = doctor;
        data.programId = (this?.selectedProgram?.id as number);
        axios.post<{ prescriber: Prescriber, warnings: Array<string> }>('/Prescriber', data)
            .then(response => {
                this.doctor = Object.assign(new Prescriber(), response.data.prescriber);
                if (response.data.warnings.length > 0) {
                    for (const warning of response.data.warnings) {
                        this.$notification(NotificationOptions.notificationOptionsPreset(warning, NotificationTypes.warning));
                    }
                }
                this.$notification(NotificationOptions.successSaveNotificationPreset('Doctor'));

                if (this.createNewDoctor) {
                    this.$emit("created", this.doctor);
                }
            })
            .catch(error => {
                console.warn(error);
                this.$notification(NotificationOptions.errorSaveNotificationPreset('Doctor'));
                // probably do some smart error handling here at some point.
            });
    }

    get selectedProgramId() {
        return this.selectedProgram?.id || 0;
    }

    optionLabel(option: Prescriber) {
        return "ID: " + option.id + " " + option.lastName + ", " + option.firstName;
    }

    get isFormValid() {
        return !!(this.nameIntroduced);
    }

    programLabel(object: Program) {
        return object.name ? `Program - Abbr: ${object.name} - ${object.abbreviation}` : '';
    }

    emitClose() {
        if (this.createNewDoctor) {
            this.$emit("close");
        }
    }

    removeContact(contact: PrescriberContact) {
        this.doctor.contacts = this.doctor.contacts.filter(c => c !== contact);
    }

    private selectedContact: PrescriberContact | null = null;
    private selectedContactIndex: number = -1;

    editContact(contact: PrescriberContact, index: number) {
        // @ts-ignore
        this.selectedContact = structuredClone(contact);
        this.selectedContactIndex = index;
        this.$bvModal.show("office-contact-modal");
    }

    updateContact() {
        this.doctor.contacts.splice(this.selectedContactIndex, 1, this.selectedContact!);
    }

    addContact() {
        this.selectedContact = new PrescriberContact();
        this.selectedContactIndex = this.doctor.contacts.length;
        this.$bvModal.show("office-contact-modal");
    }

    get selectedContactIsValid() {
        if (!this.selectedContact) return false;
        if (!this.selectedContact.lastName?.trim()) return false;
        if (!this.selectedContact.firstName?.trim()) return false;
        return true;
    }

} // close export of class
