
    import { Component, Vue } from "vue-property-decorator";
    import SearchComponent from "@/components/SearchComponent.vue";
    import { Store } from "@/models/Store";
    import Axios, { AxiosRequestConfig } from "axios";
    import moment from "moment/moment";
    import { Patient } from "@/models/Patient";
    import { Order } from "@/models/Order";
    import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";

    @Component({
        name: "VerifiedNotShippedQueue",
        components: { SearchComponent }
    })
    export default class VerifiedNotShippedQueue extends Vue {
        private storeFilter: Store = new Store();

        private currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

        get tableFilter(): AxiosRequestConfig {
            return {
                params: {
                    storeId: this.storeFilter?.id ?? 0
                }
            } as AxiosRequestConfig;
        }

        get tableFields(): BvTableFieldArray {
            return [
                { key: "id", label: "Order Id", sortable: true },
                {
                    key: "patient",
                    label: "Patient",
                    formatter: this.patientFormatter,
                    sortable: true,
                    sortByFormatted: true,
                },
                { key: "drugs", label: "Drug", formatter: this.drugFormatter, sortable: true, sortByFormatted: true, },
                { key: "totalCopay", label: "Total Copay", formatter: this.totalCopayFormatter },
                {
                    key: "updated",
                    label: "Ready Since",
                    formatter: (d: any) => moment(d).format("L LT"),
                    sortable: true
                },
                { key: "edit", label: "", },
            ];
        }

        async tableProvider(ctx: BvTableCtxObject) {
            const uri = ctx.apiUrl + "";
            const config = ctx.filter as AxiosRequestConfig;
            try {
                const resp = await Axios.get<Order[]>(uri, config);
                return resp.data.map(a => new Order(a));
            } catch (err) {
                return [];
            }
        }

        patientFormatter(pt: Patient): string {
            pt = new Patient(pt);
            return pt.displayNamesForPerson();
        }

        drugFormatter(_value: undefined, _key: string, item: Order): string {
            return item?.prescriptions?.map((a: any) => a.drugName)?.join(", ") ?? "";
        }

        totalCopayFormatter(_value: undefined, _key: string, item: Order): string {
            return this.currencyFormatter.format(item.prescriptions.reduce((pay, rx) => pay + rx.patientPay, 0));
        }

        orderDetailsRoute(item: Order): any {
            return {
                name: "PatientDashboard",
                params: {
                    id: item.patientID
                }
            };
        }
    }
