
import Card from '@/components/Cards/Card.vue';
import { Component, Vue } from 'vue-property-decorator';
import DispenseErrorListComponent from '@/components/DispensingError/DispenseErrorListComponent.vue'

@Component({
    name: "ReportedErrorsPage",
    components: {
        DispenseErrorListComponent
    }
})
export default class ReportedErrorsPage extends Vue {

    get mode() {
        if (this.onlyAssignedErrors) return "assigned";
        return "default";
    }

    get onlyAssignedErrors(): boolean {
        return Boolean(this.$route.params.onlyAssigned);
    }

}
