
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import { RefillRequestBatch, RefillRequestBatchStatus } from '@/models/RefillRequestBatch';
import { Prescription } from '@/models/Prescription';
import { RefillRequestBatchItem, RefillRequestBatchItemStatus } from '@/models/RefillRequestBatchItem';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';

@Component({
    name: 'RefillRequestBatchDetails',
    components: {
        PrescriptionsListComponent
    }
})
export default class RefillRequestBatchDetails extends Vue {
    @Prop() private value!:RefillRequestBatch;
    private isLoading = false;

    private items:Array<RefillRequestBatchItem> = new Array<RefillRequestBatchItem>();
    private fields = [
        { key: "rxID", label: "RX"},
        { key: "statusName", label: "Status"},
        { key: "updated", label: "Updated"},
    ]

    created(){
        this.loadData();
    }
    
    @Watch('value')
    loadData() {
        if(!this.value.id) return;
        
        this.isLoading = true;
        this.$http.get<RefillRequestBatch>(`RefillRequestBatch/${this.value.id}`)
        .then(res => {
            this.items = res.data?.items.map(i => new RefillRequestBatchItem(i)) || new Array<RefillRequestBatchItem>();
        })
        .catch(err => {
            console.error('Error loading elligible rxs for refill request', {err, response: err.response});
        })
        .finally(() => {
            this.isLoading = false;
        })
    }
    
    retry(){
        if(!this.value.id) return;
        
        this.isLoading = true;
        this.$http.post(`RefillRequestBatch/retry/${this.value.id}`)
        .then(res => {
            this.$notification(NotificationOptions.notificationOptionsPreset('Retrying Items with Errors', NotificationOptions.NotificationTypes.success));
            this.loadData();
        })
        .catch(err => {
            console.error('Error when retrying', {err, response: err.response});
        })
        .finally(() => {
            this.isLoading = false;
        })
    }

    get showRetry():boolean {
        return this.value.status == RefillRequestBatchStatus.WithErrors;
    }
}
