export enum SpeciesCode {
    Human,
    Dog,
    Cat,
    Ferret,
    Goat,
    Horse,
    Pig,
    Rabbit,
    Reptile,
    Rodent,
    Sheep,
    Other,
}
