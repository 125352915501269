import HasID from "@/models/HasID";
import {Note} from "@/models/Note";
import {Patient} from "@/models/Patient";
import {Prescription} from "@/models/Prescription";

export interface ElectronicPriorAuthorization extends HasID {
    lastMessageId: string;
    storeId: number;
    rxNumber: number;
    rfNumber: number;
    referenceId: string;
    questionSetId: string;
    priorityIndicator: PriorityIndicator;
    status: ElectronicPriorAuthStatus;
    caseId: string;
    effective: Date;
    expiration: Date;
    deadline: Date;
    expectedResponse: Date;
    note: string;
    attachmentId: number;
    authorizationNumber: string;
    appealNote: string;
    questionSet: EpaQuestionSet;
    notes: Note[];
    appealSupported: boolean;
    patient: Patient;
    prescription: Prescription;
    drugManufacturer: string;
}

export interface EpaQuestionSet extends HasID {
    electronicPriorAuthorizationId: number;
    title: string;
    description: string;
    contactPhone: string;
    requiresAttachment: boolean;
    attachmentNotes: string;
    questions: EpaQuestion[];
}

export interface EpaQuestion extends HasID {
    electronicPriorAuthorizationId: number;
    questionSetId: string;
    sequenceNumber: number;
    text: string;
    nextQuestionId: string;
    selectMultiple?: boolean;
    choices: EpaChoice[];
    answer: EpaAnswer;
}

export interface EpaAnswer extends HasID {
    electronicPriorAuthorizationId: number;
    questionSetId: string;
    questionId: string;
    choiceId: string | null;
    text: string | null;
}

export interface EpaChoice extends HasID {
    electronicPriorAuthorizationId: number;
    questionId: string;
    sequenceNumber: number;
    text: string;
    allowsAdditionalText: AllowsAdditionalText;
    questionSetId: string;
    nextQuestionId: string;
    lowerBoundComparisonValue: number;
    upperBoundComparisonValue: number;
    lowerBoundComparisonOperator: string;
    upperBoundComparisonOperator: string;
}

export enum AllowsAdditionalText {
    Required,
    Optional,
    NotAllowed
}

export enum ElectronicPriorAuthStatus {
    Unknown = 0,
    Initiated = 1,
    QuestionsNeedAnswers = 2,
    AnswersSubmitted = 3,
    Approved = 4,
    Denied = 5,
    Canceled = 6,
    RejectedCanAppeal = 7,
    InProcess = 8,
    CancelApproved = 9,
    CancelRejected = 10,
    Closed = 11,
    PartiallyDenied = 12,
}

export enum PriorityIndicator {
    Standard,
    Expedited
}
