import HasID from "./HasID";

export interface Note extends HasID {
    body: string;
    entityType: number;
    entityId: number | string;
    created: Date;
    createdBy: string;
    deleted: Date | null;
    deletedBy: string | null;
    visibility: NoteVisibility;
    canView: boolean;
    pinned: boolean;
    parentNoteId?: number;
}

export class Note implements Note{
    constructor(obj?:Note){
        if(obj) Object.assign(this, obj);
    }
}

export enum NoteVisibility {
    AllUsers = 0,
    Pharmacist = 1
}
