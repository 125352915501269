
import { Component, Vue } from 'vue-property-decorator';
import CounterIndicator from "@/components/Dashboard/CounterIndicator.vue"
import { DashboardStatus } from "@/models/DashboardStatus";
import moment from "moment/moment";

@Component({
    components: {
        CounterIndicator,
    }
})
export default class DispenseStatusComponent extends Vue {
    get status(): DashboardStatus {
        if (this.$root.$data.dashboardData) {
            return this.$root.$data.dashboardData as DashboardStatus;
        }
        return new DashboardStatus();
    }
    getWarningLimit(counterName: string): number | null {
        const val = this.status?.getConfigValue(counterName) || '';
        if (isNaN(parseFloat(val))) return null;

        return parseFloat(val);
    }

    getOldestPickUpLimit(_counterName: string): number | null {
        const oldestPickups = this.status?.dispenseStatus.map(ds => moment(ds.oldestPickUp).toDate());
        if (!oldestPickups) return null;
        let isBeyondLimit = false;
        const limitDate = moment().add(-5, 'days').toDate();
        oldestPickups.forEach(element => {
            if (element < limitDate) {
                isBeyondLimit = true;
                return;
            }
        });
        if (isBeyondLimit) return Number.MIN_VALUE;
        return null;
    }
}
