
    import axios from 'axios';
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Table, TableColumn } from "element-ui";
    import { PMPStatus } from '@/models/PMPStatus';

    @Component({
        name: "PMPStatusPage",
        components: {  }
    })
  export default class PMPStatusPage extends Vue {
        private pmpstatus?: PMPStatus[] = [new PMPStatus()];


        private pmpstatusFields = [
            { key: 'stateID', label: 'State', sortable: true },
            { key: 'frequency', label: 'Frequency', sortable: true },
            { key: 'dateSubmitted', label: 'Last Submission', sortable: true }
        ];


    created() {
        this.getPMPStatus();
    }

    getPMPStatus() {
         axios.get(`/Prescription/pmpstatus`)
            .then(response => {
                this.pmpstatus = response.data; 
            })
            .catch(error => {
                console.error('Error while getting PMP Status reports', {error, response:error?.response})
            });
    }


  }

