
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
    import EnumSelector from '@/components/EnumSelector.vue';
    import { ClaimRejects } from '@/data/ClaimRejects';
    import PatientPlansComponent from '@/components/Insurance/PatientPlansComponent.vue';
    import CoverMyMedsResponseComponent from "@/components/PriorAuthorization/CoverMyMedsResponseComponent.vue";
    import { Prescription, RefillStatusCode } from '@/models/Prescription';
    import { Claim } from '@/models/Claim';
    import { CoverMyMedsResponse, PriorAuthorization } from "@/models/PriorAuthorization";
    import BillingDetailsComponent from "@/components/Insurance/BillingDetailsComponent.vue";
    import { PatientPlan, PriorAuthorizationTypeCode, SubmissionClarificationCode } from '@/models/PatientPlan';
    import { Patient } from '@/models/Patient';
    import { PriceSchedule } from '@/models/PriceSchedule';
    import { Plan } from "@/models/Plan";
    import { CombinedPlan } from "@/models/CombinedPlan";
    import PatientPlanEditComponent from "@/components/Insurance/PatientPlanEditComponent.vue";
    import SearchComponent from '@/components/SearchComponent.vue';
    import moment from "moment";
    import { BModal, BvModalEvent } from "bootstrap-vue";
    import EligibilityComponent from '@/components/Insurance/EligibilityComponent.vue';
    import { isNull } from 'util';
    import ClaimComponent from "@/components/Claims/ClaimComponent.vue";

    @Component({
        name: "InsuranceBillingComponent",
        components: {
            ClaimComponent,
            EnumSelector,
            PatientPlansComponent,
            CoverMyMedsResponseComponent,
            BillingDetailsComponent,
            PatientPlanEditComponent,
            SearchComponent,
            EligibilityComponent
        },
    })
    export default class InsuranceBillingComponent extends Vue {
        @Ref("edit-patient-plan-modal") readonly editPlanModal!: BModal;
        @Prop({default: null}) private prescription!: Prescription;
        @Prop({default: null}) private newMode!: boolean;
        @Prop({default: false}) private disabled!: boolean;
        @Prop({default: false, type: Boolean}) private readOnly!: boolean;
        @Prop({default: false}) private billOnlyMultilfills!: boolean;
        @Prop({default: null}) private drugLicenseType!: string;
        @Prop({default: null}) private patient!: Patient;
        @Prop({default: null}) private defaultPriceSchedule!: PriceSchedule;
        @Prop({default: false}) private hideMargin!: boolean;
        private cmmResponse: CoverMyMedsResponse = {
            actions: {}
        } as CoverMyMedsResponse;

        private combinedPlanToAdd: CombinedPlan | null = null;
        private detailsClaim: Claim = {} as Claim;
        private showOverrides: boolean = false;
        private showdispenseError: boolean = false;

        private paOverride: string = "";
        private uacOverride: string = "";
        private icd10: string = "";
        private tsn: string = "";
        private paTypeCode: string = "";
        private durPpsOverrides: { reasonForServiceCode: string, professionalServiceCode: string, resultOfServiceCode: string, levelOfEffort: string; }[] = [];
        private submissionClarificationCode: string = "";

        // noinspection JSUnusedLocalSymbols
        private paTypeCodes = PriorAuthorizationTypeCode;
        // noinspection JSUnusedLocalSymbols
        private submissionClarificationCodes = SubmissionClarificationCode;

        private billedClaims: Claim[] = [];
        private selectedPlan: CombinedPlan | null = null;
        private priceSchedules: Array<PriceSchedule> = [];
        private rxPriceSchedule: PriceSchedule = new PriceSchedule();
        // noinspection JSMismatchedCollectionQueryUpdate
        private priceSchedulesOptions: Array<any> = [];
        private selPriceScheduleId: any = null;
        private processingInsurance = false;
        private newStoreID: number | null = null;
        private rejects: Array<any> = ClaimRejects.codes;
        private patientPlanForEdit: PatientPlan | null = null;
        private editCopay = false;
        private availableCombinedPlans: CombinedPlan[] = [];

        get totalPrice(): number {
            if (!this.prescription) return 0;
            let totalPrice = 0;

            if (this.billedClaims.length > 0) {
                let totalPlanPaid = this.billedClaims.reduce((a, b) => {
                    return a + b.planPaid + b.voucherPaid;
                }, 0);
                totalPrice = totalPlanPaid + this.prescription.patientPay;
            } else {
                totalPrice = this.prescription.patientPay;
            }

            return totalPrice == 0 ? this.prescription.totalPrice : totalPrice;
        }

        get margin(): number {
            if (!this.prescription) return 0;
            if (this.billedClaims.length > 0) {
                let totalPlanPaid = this.billedClaims.reduce((a, b) => {
                    return a + b.planPaid + b.voucherPaid;
                }, 0);
                return Number((totalPlanPaid + this.prescription.patientPay - this.prescription.cost - this.prescription.tax).toFixed(2));
            } else {
                return Number((this.prescription.patientPay - this.prescription.cost - this.prescription.tax).toFixed(2));
            }
        }

        get claimIsPaid(): boolean {
            return this.billedClaims?.some(cl => cl.transactionStatus == "Paid");
        }

        get isRelayReject(): boolean {
            return this.billedClaims?.some(cl => cl.rejectCodeList?.some(r => r == 'NN') && cl.responseMessage?.indexOf('*NRX*') >= 0);
        }

        get isTSNReject(): boolean {
            return this.billedClaims?.some(cl => cl.rejectCodeList?.some(r => r == 'EK'));
        }

        get disableButtons(): boolean {
            const invalidPrescription = (!this.prescription.storeID && !this.prescription.rxNumber && !(this.prescription.rxNumber || this.prescription.rxNumber == 0) && !this.newMode);
            //const noPlans = !(this.availableCombinedPlans?.length);
            return invalidPrescription || this.disabled || (this.prescription?.hasBeenShipped && !this.newMode);
        }

        get disableReversalButton(): boolean {
            if (this.prescription?.status == RefillStatusCode.AwaitingInsurance) return false;
            return this.disableButtons;
        }

        get disableAdditionalBillingOptions(): boolean {
            const invalidPrescription = (!this.prescription.storeID && !this.prescription.rxNumber && !(this.prescription.rxNumber || this.prescription.rxNumber == 0) && !this.newMode);
            //const noPlans = !(this.availableCombinedPlans?.length || this.newMode);
            return invalidPrescription || this.disabled || (this.prescription?.hasBeenShipped && !this.newMode);
        }

        get is510k(): boolean {
            return ((this.drugLicenseType == '510(k)' || this.drugLicenseType == 'Unapproved Drug' || this.drugLicenseType == 'Dietary Supp') && this.billedClaims && this.billedClaims.reduce(function (i, c) {
                if (c.transactionStatus == "Paid") return i + 1; else return i;
            }, 0) > 0);
        }

        get disableBilling(): boolean {
            if (this.disableButtons) {
                return true;
            }
            return this.is510k || this.isRelayReject || this.isTSNReject || this.processingInsurance;
        }

        get disableIfMultiFill(): boolean {
            return this.billOnlyMultilfills && !(this.selectedPlan?.plan.doesAllowsMultifill || this.selectedPlan?.plan.isOffline);
        }

        get billPlanToolTipText(): string {
            let res = '';
            if (this.disableIfMultiFill)
                res = 'Multifills not allowed for selected plan';

            return res;
        }

        showEditModal() {
            this.patientPlanForEdit = new PatientPlan(this.selectedPlan?.patientPlan);
        }

        disablePlan() {
            if (!this.prescription || !this.selectedPlan) return;
            this.selectedPlan.patientPlan.active = false;
            Axios.post<CombinedPlan[]>("api/Plan/UpdatePatientPlan", this.selectedPlan.patientPlan)
                .then(resp => {
                    this.availableCombinedPlans = resp.data.filter(cp => cp.patientPlan.active)
                        .map(cp => new CombinedPlan(cp));
                    this.selectedPlan = this.availableCombinedPlans
                        .find(cp => cp.patientPlan.id == this.selectedPlan?.patientPlan.id)
                    ?? this.availableCombinedPlans.length > 0 ? this.availableCombinedPlans[0] : new CombinedPlan(null);
                })
                .catch(err=> {
                    this.$notification(NotificationOptions.error(err));
                });
        }

        saveEditedPatientPlan(event: BvModalEvent) {
            event.preventDefault();
            Axios.post<CombinedPlan[]>("Plan/UpdatePatientPlan", this.patientPlanForEdit)
                .then(resp => {
                    this.availableCombinedPlans = resp.data.filter(cp => cp.patientPlan.active).map(cp => {
                        return new CombinedPlan(cp);
                    });
                    this.selectedPlan = this.availableCombinedPlans.find(cp => cp.patientPlan.id == this.selectedPlan?.patientPlan.id) ?? null;
                    this.editPlanModal.hide();
                })
                .catch((err: AxiosError) => {
                    console.warn("error on saving edited ptplan:", err, err.message, err.response);
                });
        }

        clearEditedPatientPlan() {
            this.patientPlanForEdit = new PatientPlan();
        }

        created() {
            if (this.prescription) {
                this.fetchInsuranceInformation();
            }
        }

        saveOverride(billedClaim: Claim) {
            console.log("saveOverride!");
            Axios.post<{ claim: Claim, rx: Prescription; }>("Insurance/UpdateClaim", billedClaim)
                .then(resp => {
                    billedClaim = resp.data.claim;
                    const rx = Object.assign(new Prescription(), resp.data.rx);
                    console.log("responseClaim = ", resp.data);
                    this.$emit('pricing-updated', rx);

                })
                .catch((err: AxiosError) => {
                    this.$notification(NotificationOptions.error(err));
                    console.warn("updateClaim error: ", err.message);
                    this.fetchPatientClaims();
                })
                .finally(() => {
                    this.editCopay = false;
                });
        }

        fetchRxPriceShedule(priceScheduleID?: number) {
            if (!priceScheduleID && !this.prescription?.priceScheduleID) return;
            priceScheduleID = priceScheduleID || this.prescription?.priceScheduleID;
            Axios.get<PriceSchedule>(`PriceSchedule/${priceScheduleID}`)
                .then(response => {
                    this.rxPriceSchedule = response.data;
                })
                .catch(error => {
                    console.error('Error while pulling prescription Rx Schedule', {error, response: error.response});
                });
        }

        addDur() {
            const newDur = {
                reasonForServiceCode: "",
                professionalServiceCode: "",
                resultOfServiceCode: "",
                levelOfEffort: ""
            };
            this.durPpsOverrides.push(newDur);
        }

        @Watch("prescription")
        prescriptionUpdate(rxNew: Prescription, rxOld: Prescription) {
            const rxID1 = rxNew.rxID;
            const rxID2 = rxOld.rxID;

            if (rxID1 != rxID2) {
                this.fetchInsuranceInformation();
            }
        }

        @Watch("prescription.patientID")
        patientIDUpdate(newPatId: number | null, oldPatId: number | null) {

            if (newPatId != oldPatId) {
                this.fetchInsuranceInformation();
            }
        }

        fetchInsuranceInformation() {
            this.clearComponent();
            this.fetchPatientClaims();
            this.fetchPatientPlans();
            this.fetchRxPriceShedule();
        }

        @Emit("save-with-plan")
        savePrescriptionWithPlan() {
            if (!this.prescription || !this.selectedPlan) return;
            // if (this.billedClaims[this.billedClaims.length-1]?.planName == "CP Cash") {
            //     this.$notification(NotificationOptions.notificationOptionsPreset("Cannot bill a secondary after cash out.", NotificationOptions.NotificationTypes.danger));
            //     return;
            // }
            const planId = Number(this.selectedPlan.patientPlan.id || 0);
            this.processingInsurance = true;
            return (rx: Prescription, errored: boolean = false) => {
                if (errored) {
                    this.processingInsurance = false;
                    return;
                }
                this.billInsurance(planId, rx)
            };
        }

        CashOutTransaction(){
            if (!this.selPriceScheduleId) return;

            if(this.showdispenseError){
                Axios.post(`/Store/${this.newStoreID}/transfer`, this.prescription)
                .then(response => {
                    const transferredPrescription = Object.assign(new Prescription(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Prescription Transferred"));
                    this.cashOutRx(this.selPriceScheduleId, transferredPrescription);
                    this.$emit('rx-transfer-refresh', transferredPrescription);
                    return;
                })
                .catch((error: AxiosError) => {
                    if (error) {
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Internal Transfer", error));
                        console.error("Internal Transfer - ", { error, response: error?.response });
                    }
                });
                return;
            }
            this.savePrescriptionWithCashOut();
        }

        @Emit("save-with-plan")
        savePrescriptionWithCashOut() {
            this.processingInsurance = true;
            return (rx: Prescription, errored: boolean = false) => {
                if (errored) {
                    this.processingInsurance = false;
                    return;
                }
                this.cashOutRx(this.selPriceScheduleId, rx);
            }
        }

        cashOutRx(selPriceScheduleId: number, prescription: Prescription | null = null) {
            let rx: Prescription = this.prescription;
            if (prescription) {
                rx = prescription;
            }

            if (!rx.hasFullId || !selPriceScheduleId) return;

            const errorMsg = "Error while Cashing Out";
            this.processingInsurance = true;
            Axios.post<{ claim: Claim, prescription: Prescription, pa: PriorAuthorization; }>(`Insurance/${rx.rxID}/CashOut/${selPriceScheduleId}`)
                .then(response => {
                    if (!isNull(response.data?.claim)) {
                        const claim = response.data?.claim;
                        this.billedClaims.push(claim);
                        this.paOverride = "";
                        this.uacOverride = "";
                        this.paTypeCode = "";
                        this.submissionClarificationCode = "";
                        this.durPpsOverrides = [];
                        this.showOverrides = false;

                        this.cmmResponse = response.data?.pa?.responses[0];

                        console.log("Insurance Billing saved", response.data);
                    }

                    const updatedRx = response.data?.prescription;
                    if (updatedRx?.priceScheduleID) {
                        this.fetchRxPriceShedule(updatedRx.priceScheduleID);
                    }
                    // Update totals on insurance billing
                    this.$emit('onClaimsLoaded', this.billedClaims, updatedRx);

                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    console.error(errorMsg, {err, response: err.response});
                })
                .finally(() => {
                    this.processingInsurance = false;
                });
        }

        billInsurance(planId: string | number, prescription: Prescription | null = null) {
            let rx: Prescription = this.prescription;
            if (prescription) {
                rx = prescription;
            }
            this.processingInsurance = true;
            const url = `Insurance/BillInsurance/${rx.storeID}/${rx.rxNumber}/${rx.rfNumber}/${planId}`;
            const data = this.durPpsOverrides;
            const config: AxiosRequestConfig = {
                params: {
                    PaTypeCode: this.paTypeCode,
                    PaOverride: this.paOverride,
                    ClarificationCode: this.submissionClarificationCode,
                    tsn: this.tsn,
                    UacOverride: this.uacOverride,
                    icd10: this.icd10,
                },
            };
            Axios.post<{ claim: Claim, prescription: Prescription, pa: PriorAuthorization; }>(url, data, config)
                .then(response => {
                    if (!isNull(response.data.claim)) {

                        const claim = response.data?.claim;
                        if (!this.billedClaims.find(c => c.id == claim.id)) {
                            this.billedClaims.push(claim);
                        }
                        this.paOverride = "";
                        this.uacOverride = "";
                        this.paTypeCode = "";
                        this.submissionClarificationCode = "";
                        this.durPpsOverrides = [];
                        this.showOverrides = false;

                        this.cmmResponse = response.data?.pa?.responses[0];

                        //this.$notification(NotificationOptions.successSaveNotificationPreset("Insurance Billing"));
                        console.log("Insurance Billing saved", response.data);
                    } else {
                        this.$notification(NotificationOptions.notificationOptionsPreset("Duplicate of Paid Claim", NotificationOptions.NotificationTypes.info));
                        //this.$notification(NotificationOptions.successSaveNotificationPreset("Insurance Billing"));
                    }

                    const updatedRx = response.data?.prescription;
                    // Update totals on insurance billing
                    this.$emit('onClaimsLoaded', this.billedClaims, updatedRx);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    // this.$notification(NotificationOptions.errorSaveNotificationPreset("Insurance Billing"));
                    console.error('Error while billing insurance', {err, response: err.response});
                })
                .finally(() => {
                    this.processingInsurance = false;
                });
        }

        reverseInsuranceBilling(claim: Claim) {
            if (!this.prescription) return;

            this.processingInsurance = true;
            Axios.post(`Insurance/ReverseInsuranceBilling`, claim)
                .then(response => {
                    this.fetchPatientClaims();
                    const updatedRx = response.data?.prescription;
                    //this.$notification(NotificationOptions.notificationOptionsPreset('Claim was reversed successfuly', NotificationOptions.NotificationTypes.success));
                    console.log('Claim was reversed successfuly', response.data);

                    // Update totals on insurance billing
                    this.$emit('onClaimsLoaded', this.billedClaims, updatedRx);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(`Error reversing the claim. ${err.response.data}`, NotificationOptions.NotificationTypes.danger));
                    console.error("Error while reversing a claim", {err, response: err.response});
                })
                .finally(() => {
                    this.processingInsurance = false;
                });
        }

        clearComponent() {
            this.availableCombinedPlans = [];
            this.billedClaims = [];
            this.selectedPlan = null;
            this.cmmResponse = {actions: {}} as CoverMyMedsResponse;
            this.durPpsOverrides = [];
            this.paOverride = "";
            this.uacOverride = "";
            this.paTypeCode = "";
            this.submissionClarificationCode = "";
            //this.patient = new Patient();
            var comp = this.$refs["eligibility-component"] as EligibilityComponent
            if (comp) comp.clear();
        }

        selectPlan(cp: CombinedPlan) {
            this.selectedPlan = cp;
        }

        fetchPatientPlans() {
            if (!this.prescription?.patientID) return;
            Axios.get<{ plans: Plan[], patientPlans: PatientPlan[]; }>(`Insurance/${this.prescription.patientID}`)
                .then(response => {

                    this.availableCombinedPlans = response.data.patientPlans.filter(pp => pp.active).map(pp => {
                        return new CombinedPlan({
                            patientPlan: new PatientPlan(pp),
                            plan: new Plan(response.data.plans.find(p => p.id == pp.planId))
                        } as CombinedPlan);
                    });

                    this.selectedPlan = this.availableCombinedPlans[0];
                })
                .catch(err => {
                    console.error('Error while getting Patient Plans', {err, response: err?.response});
                });
        }

        fetchPatientClaims() {
            if (!this.prescription.hasFullId) {
                this.billedClaims = new Array<Claim>();
                this.$emit('onClaimsLoaded', this.billedClaims, this.prescription);
                return;
            }

            Axios.get<Array<Claim>>(`Insurance/GetClaims/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}`)
                .then(response => {
                    this.billedClaims = response.data;
                    if (this.billedClaims.length > 0) {
                        this.fetchPriorAuthorizations();
                    }
                    this.$emit('onClaimsLoaded', this.billedClaims, this.prescription);
                })
                .catch(err => {
                    console.error('Error while getting Rx Claims', {err, response: err?.response});
                });
        }

        fetchPriorAuthorizations() {
            Axios.get<PriorAuthorization>(`PriorAuthorization/ByPrescription/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}`)
                .then(resp => {
                    if (resp.data?.responses?.length > 0)
                        this.cmmResponse = resp.data.responses[0];
                });
        }

        openCashOutModal() {
            Axios.get<Array<PriceSchedule>>(`PriceSchedule`)
                .then(response => {
                    this.priceSchedules = response.data.map(ps => Object.assign(new PriceSchedule(), ps));
                    if (this.priceSchedules.length) {
                        this.selPriceScheduleId = this.defaultPriceSchedule?.id ?? '';
                        this.priceSchedulesOptions = this.priceSchedules.map(ps => ps.toDropDownOption());
                        this.$bvModal.show('cashout-modal');
                    } else
                        this.$notification(NotificationOptions.notificationOptionsPreset("No Price Schedules have been configured", NotificationOptions.NotificationTypes.danger));
                })
                .catch(err => {
                    console.error('Error while getting price schedules', {err, response: err?.response});
                });
        }

        getRejectReason(reject: string): string {
            return this.rejects.find(r => r.Code == reject.trim())?.Value ?? this.rejects.find(r => r.Code == parseInt(reject, 10))?.Value ?? "Contact IT.";
        }

        dateFormatter(value: Date) {
            return moment.utc(value).format("L");
        }

    }

