
import { Inventory } from '@/models/Inventory';
import { Store } from '@/models/Store';
import { Program } from '@/models/Program';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Table, TableColumn } from 'element-ui';
import axios from 'axios';
import SearchComponent from '@/components/SearchComponent.vue';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import InventoryHistory from "@/components/Inventory/InventoryHistory.vue";
import { DrugProduct } from "@/models/Drug/DrugProduct";
import { InventoryLocation } from '@/models/InventoryLocation';

@Component({
    name: "InventoryComponent",
    components: {
        InventoryHistory,
        SearchComponent,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    }
})
export default class InventoryComponent extends Vue {

    @Prop({ default: 0 }) private drugSource!: Number;
    @Prop() private packageId!: string;
    @Prop() private billingUnit!: string;
    @Prop() private inventories!: Inventory[];

    mounted() {
        this.getReasonOptionsFromConfiguration();
        this.loadHasInventoryLocations();
    }

    protected showHistory: boolean = false;

    private showForm: boolean = false;
    private store: Store = new Store();
    private program: Program = new Program();

    private inventory: Inventory = new Inventory();
    protected reasonSelection: string | null = null;
    protected location = new InventoryLocation();
    protected useForFilling = false;

    private reasonOptions: any[] = [];

    async getReasonOptionsFromConfiguration() {
        try {
            const response = await this.$http.get<Array<String>>("/Configuration/InventoryReasons");
            if (response.data && !response.data.length) return;
            this.reasonOptions = [
                { value: null, text: 'Select a reason' },
                ...(response.data ? response.data : ['New Inventory', 'Stock Check'])
            ];
        } catch {
            // ignore
        }
    }

    get inventoryFields() {
        const fields = [
            { key: "programName", label: "Program" },
            { key: "storeName", label: "Store" },
            { key: "reason", label: "Reason", visible: !!this.inventories.filter(i => i.reason).length },
            { key: "location.name", label: "Location", visible: this.hasInventoryLocations },
            { key: "quantity" },
            { key: "allocated" },
            { key: "net", label: "Net", formatter: this.netFormatter },
            { key: "actions", label: "" },
        ];

        return fields.filter(f => f.visible !== false);
    }

    get drug(): DrugProduct {
        return {
            drugId: Number(this.packageId),
            source: this.drugSource,
        } as DrugProduct;
    }

    netFormatter(value: any, key: number, item: Inventory) {
        return (item.quantity || 0) - (item.allocated || 0);
    }

    submitInformation() {
        this.inventory.drugSource = Number(this.drugSource);
        this.inventory.packageId = Number(this.packageId);
        this.inventory.billingUnit = this.billingUnit;
        this.inventory.storeId = Number(this.store.id);
        this.inventory.programId = Number(this.program.id);
        this.inventory.reason = this.reasonSelection;
        this.inventory.location = this.location;
        this.inventory.useForFilling = this.useForFilling;

        const updateInventory = this.inventories.find(item => (item.location?.id ?? 0) == (this.inventory.location?.id ?? 0) &&
            item.storeId == this.inventory.storeId &&
            (item.programId || 0) == (this.inventory.programId || 0));
        if (updateInventory) {
            this.inventory.id = updateInventory.id;
        }
        axios.post<Inventory>('/Inventory/', this.inventory)
            .then(response => {
                if (response.data?.id) {
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Inventory"));
                    this.$emit("update");
                }
                this.clearForm();
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory"));
                console.error("Error while updating inventory.", { error, response: error?.response });
            })
            .finally(() => {
                this.closeForm();
            });
    }

    viewHistory(item: any) {
        this.showHistory = true;
        this.store = new Store({ id: item.storeId, name: item.storeName } as Store);
        this.program = new Program({ id: item.programId, name: item.programName } as Program);
    }

    updateInventory(item: Inventory) {
        this.showForm = true;
        this.store.id = item.storeId;
        this.program.id = Number(item.programId || 0);
        this.inventory.quantity = item.quantity;
        this.location = new InventoryLocation();
        this.location.id = item.location?.id ?? 0;
        this.useForFilling = item.useForFilling;
    }

    deleteInventoryConfirmDialog(item: Inventory) {
        this.$bvModal.msgBoxConfirm(`This inventory record associated to this drug will get deleted. Are you sure?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.deleteInventory(item);
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
    }

    deleteInventory(item: Inventory) {
        if (!item?.id) return;

        this.$http.delete(`inventory/${item.id}`)
            .then(res => {
                if (res.data) {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Inventory record successfully deleted.", NotificationOptions.NotificationTypes.success));
                    this.$emit('removed');
                }
            })
            .catch(err => {
                const errorMsg = "Not possible to delete inventory record.";
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, { err, response: err?.response });
            });
    }

    openForm() {
        this.clearForm();
        this.showForm = true;
    }

    closeForm() {
        this.clearForm();
        this.showForm = false;
    }

    clearForm() {
        this.inventory = new Inventory();
        this.store = new Store();
        this.program = new Program();
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }

}
