

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Consultation } from '../../models/Consultation';
import Radio from "src/components/Inputs/Radio.vue";
import { Patient } from "@/models/Patient";
import { Store } from "@/models/Store";
import axios from 'axios';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import SearchComponent from '../SearchComponent.vue';
import { Prescription } from '@/models/Prescription';

@Component({
    name: "NewConsultationFormComponent",
    components: {
        [Radio.name]: Radio,
        SearchComponent
    }
})
export default class NewConsultationFormComponent extends Vue {
    @Prop() private patient!: Patient;
    @Prop() private store!: Store;

    private newConsultation: Consultation = new Consultation();

    private prescription: Prescription | null = null;

    created() {
        this.newConsultation.storeId = this.store.id as number;
        this.newConsultation.status = 1;
        this.newConsultation.patientId = this.patient.id;
        this.newConsultation.note = "";
    }

    private saveWasClicked = false;
    saveNewConsultation() {
        this.saveWasClicked = true;
        axios.post('/Consultation/', {...this.newConsultation, rxNumber: this.prescription?.rxNumber }).then(() => {
            this.$emit('updateList');
        }).catch(error => {
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Consultation"));
            console.log("Error creating consultation", { error, response: error?.response })
        });
    }
}
