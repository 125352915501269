import { Patient } from "./Patient";
import TrackedObject from "./TrackedObject";

export interface EmergencyContact extends TrackedObject {
    id: number | null,
    patientId: number,
    contactPatientId: number,
    active: boolean,
    hipaaAuthorized: boolean,
    patient: Patient,
    contact: Patient,
}

export class EmergencyContact implements EmergencyContact {
    constructor(obj?: EmergencyContact) {
        if (obj) Object.assign(this, obj);
    }
}