export interface OptStatus{
    optIn: Date | null;
    optOut: Date | null;
}

export enum PhoneOptStatus{
    unknown,
    optIn,
    optOut
}
