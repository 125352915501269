

import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import moment from "moment";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    name: "MergePatientsPage",
    components: {}
})
export default class MergePatientsQueuePage extends Vue {

    private items = [];
    private loading = true;

    mounted() {
        this.getQueue();
    }

    get userCanMerge() {
        return this.$user?.isPatientsManager;
    }

    get fields() {
        const fields: Array<{ key: string, label?: string, class?: string }> = [
            { key: "sourcePatientId" },
            { key: "sourcePatientName" },
            { key: "targetPatientId" },
            { key: "targetPatientName" },
            { key: "created" }
        ];

        if (this.userCanMerge) {
            fields.push({ key: "actions", label: "", class: "actions" });
        }

        return fields;
    }

    async getQueue() {
        try {
            this.loading = true;
            const response = await axios.get("/patient/mergequeue");
            this.items = response.data;
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        } finally {
            this.loading = false;
        }
    }

    formatDate(value: string) {
        return moment(value).format("LLL");
    }

    async removeItem(queueItem: any) {
        try {
            let response = await this.$bvModal.msgBoxConfirm('Are you sure you want to remove this item from the merge queue?', {
                title: 'Confirm Remove from Merge Queue',
                okVariant: 'danger',
                centered: true,
                noFade: true
            });

            if (!response) return;

            response = await axios.delete(`/patient/mergequeue/${queueItem.id}`);

            this.items = response.data;
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

}


