import HasID from "../HasID";
import HasLabel from "../HasLabel";
import { Store } from "../Store";
import TrackedObject from "../TrackedObject";
import { Vendor } from "../Vendor";
import { DrugSource } from "@/models/Drug/DrugProduct";

export interface EDIOrder extends HasID, TrackedObject {
    vendorId: number;
    vendor: Vendor;
    poNumber: string;
    storeId: number;
    store: Store;
    status: EDIOrderStatus;
    anticipatedArrivalDate: Date;
    lineItems: Array<EDIOrderLineItem>;
}

export interface OrderableDrug {
    drugSource: DrugSource;
    packageId: number;
    drugName: string;
    ndC11: string;
    packageSize: number;
    unitOfMeasure: string;
    cost: number;
}

export class OrderableDrug extends HasLabel {
    constructor(obj?: OrderableDrug) {
        super();
        this.ndC11 = "";
        this.drugName = "";
        this.packageSize = 0;
        this.unitOfMeasure = "";
        this.drugSource = DrugSource.GsddFederal;
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        return `${this.ndC11} - ${this.drugName} ${this.packageSize}${this.unitOfMeasure}`;
    }
}

export interface EDIOrderLineItem extends HasID, TrackedObject {
    drugSource: number;
    packageId: number;
    ndC11: string;
    drugName: string;
    unitPrice: number;
    packagesOrdered: number;
    packagesReceived: number | null;
    packagesInvoiced: number | null;
}

export class EDIOrderLineItem {
    constructor(obj?: any) {
        if (obj) Object.assign(this, obj);
    }

    static fromOrderableDrug(obj: OrderableDrug, qty: number | null): EDIOrderLineItem {
        const li = new EDIOrderLineItem();
        li.drugSource = obj.drugSource;
        li.packageId = obj.packageId;
        li.ndC11 = obj.ndC11;
        li.drugName = obj.drugName;
        li.unitPrice = obj.cost;
        li.packagesOrdered = qty ?? 0;
        return li;
    }

    get extendedPrice(): number {
        return this.unitPrice * this.packagesOrdered;
    }

    get invoicedPrice(): number | null {
        if (this.packagesInvoiced == null) {
            return null;
        }
        return this.unitPrice * this.packagesInvoiced;
    }
}

export class EDIOrder {
    constructor(obj?: any) {
        this.lineItems = new Array<EDIOrderLineItem>();
        this.vendor = new Vendor();
        this.store = new Store();
        this.poNumber = "";
        if (obj) {
            Object.assign(this, obj);
            this.lineItems = this.lineItems.map((l) => new EDIOrderLineItem(l));
        }
    }
}

export enum EDIOrderStatus {
    Draft,
    Submitted,
    Confirmed,
    Received,
    Invoiced,
    Complete,
    Cancelled
}

