
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import TextUtils from "@/util/TextUtils";

    @Component({
        name: "EnumSelector",
        components: {
        }
    })
    export default class EnumSelector extends Vue {
        @Prop() private value!: any;

        @Prop({ default: false, type: Boolean }) private required!: boolean;
        @Prop({ default: "", type: String }) private label: string | undefined;
        @Prop({ default: false, type: Boolean }) private disabled!: boolean;
        @Prop({default: false, type: Boolean }) private clearable!: boolean;
        @Prop() private enumCodes!: any;
        /**
        * @displayNames: Its an array of objects of type {enum: Enum, name:string}
        * which allows us to manually indicate custom Names for a specific set of Enums.
        */
        @Prop() private displayNames!: Array<any>;

        /**
        * @elementsToDiscard: Array of Enums to indicate which enums
        * we would like to discard from the list.
        */
        @Prop() private elementsToDiscard!: Array<any>;
        @Prop(Number) readonly labelCols: number | undefined;

        private enumFullDisplayName: Array<any> = [];

        get object(): string {
            //Receive enum, return Display Name
            const res = this.enumFullDisplayName.find(el => el.enum == this.value);
            return res?.fullDisplayName ?? "";
        }

        set object(val: string) {
            //Receive Display Name, return enum
            const enumNameEl:any = this.enumFullDisplayName.find((p:any) => p.fullDisplayName == val);
            this.$emit('input', enumNameEl?.enum);
        }

        get objects(): string[] {
            return this.stringsFromEnum(this.enumCodes);
        }

        stringsFromEnum(value: any): string[] {
            const strings: string[] = [];
            this.enumFullDisplayName = [];
            for (const n in value) {
                if (typeof value[ n ] === "number" && typeof n === "string") {
                    const discard = this.elementsToDiscard?.some(el => el == value[ n ]);
                    if (!discard) {
                        let name = '';
                        const dispName = this.displayNames?.find(dis => dis.enum == value[ n ]);
                        if (dispName)
                            name = dispName?.name;
                        else
                            name = TextUtils.camelCaseToNormal(n);

                        const fullDisplayName = `${value[ n ]} - ${name}`;
                        strings.push(fullDisplayName);
                        this.enumFullDisplayName.push({ enum: value[ n ], fullDisplayName });
                    }
                }
            }
            return strings;
        }

    }
