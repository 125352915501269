import HasID from './HasID';
import HasAddress from './HasAddress';
import TrackedObject from './TrackedObject';
import HasLabel from './HasLabel';
import { User } from './User';
import { TextMessageTemplates } from './TextMessageTemplates';
import { InventoryLocation } from './InventoryLocation';

export interface Store extends HasID, HasAddress, TrackedObject {
    name: string;
    phoneNumber: string;
    faxNumber: string;
    emailAddress: string;
    webAddress: string;
    npiNumber: string;
    mailNPINumber: string;
    pidNumber: string;
    fedIDNumber: string;
    storeNumber: string;
    storeID: string;
    deaNumber: string;
    ncpdp: string;
    allowOutOfState: boolean;
    capabilities: StoreCapabilities;
    active: boolean;
    allowedStates:string[];
    defaultPrinter: string;
    brandingImageId?: number;
    brandingName: string;
    brandingPhone: string;
    alternatePrinterEndpoint: string;
    useAlternatePrinterEndpoint: boolean;
    pharmacistOfRecord: User;
    zplPrinterQueue: string;
    parataQueue: string;
    textMessageTemplates: TextMessageTemplates;
    disableReadyForPickupTexts: boolean;
    parLevelAlertRecipients: string[];
    defaultFillingLocation: InventoryLocation;
}

export enum StoreCapabilities {
    TwoStepShipping = 1 << 0,
    UseUSPSInState = 1 << 1,
}

export class Store extends HasLabel {

    constructor(obj?: Store) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }

        if (!this.defaultFillingLocation) {
            this.defaultFillingLocation = new InventoryLocation();
        }

        if (!this.textMessageTemplates) {
            this.textMessageTemplates = new TextMessageTemplates();
        }

        if (!this.parLevelAlertRecipients) {
            this.parLevelAlertRecipients = [];
        }
    }

    toString(): string {
        if (this.name) return `${this.id} - ${this.name}`;
        return '<div class="font-weight-light">Store Name</div>';
    }

    get fullAddress(): string {
        return `${this.address1 || ""} ${this.address2 || ""}, ${this.addressCity || ""}, ${this.addressState || ""} ${this.addressZip || ""}`;
    }

    static get flagOptions(): Array<string> {
        return Object.keys(StoreCapabilities)
            .filter(val => !isNaN(Number(val)))
            .map(k => StoreCapabilities[Number(k)]);
    }

    get selectedCapabilities(): string[] {
        const ret: string[] = [];
        if (this.capabilities) {
            Store.flagOptions.forEach((o: any) => {
                if (this.capabilities & (StoreCapabilities[o] as any)) ret.push(o);
            });
        }
        return ret;
    }

    set selectedCapabilities(vals: string[]) {
        this.capabilities = 0;
        vals.forEach((flag: any) => {
            const flagVal: any = StoreCapabilities[flag];
            this.capabilities |= flagVal;
        });
    }

    get selectedCapabilitiesEnums(): Array<StoreCapabilities> {
        return this.selectedCapabilities?.map<StoreCapabilities>((f: any) => (StoreCapabilities[f] as any));
    }
}
