
import { Component, Vue, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { Patient } from '../../models/Patient';
import { IRSStatement } from '../../models/IRSStatement';
import { DatePicker } from 'element-ui'
import moment from 'moment'

@Component({
    name: "IRSReportComponent",
    components: {
        [DatePicker.name]: DatePicker
    }
})
export default class IRSReportComponent extends Vue {
    @Prop() patient!: Patient;
    private dateObj = new Date();
    private startDate: Date = new Date("01/01/" + (this.dateObj.getFullYear() - 1).toString());
    private endDate: Date = new Date("12/31/" + (this.dateObj.getFullYear() - 1).toString());
    private deliveryMethod: string = "";
    private sendTo: string = "";

    private pdfSource: string | null = null;
    private isSending = false;
    private canSendEmail = false;


    created() {
        console.log(this.patient);
        this.getIRSReportEmailFromConfig();
    }

    get sendToOptions() {
        return [
            {
                value: this.patient.emailAddress,
                text: this.patient.emailAddress,
                visible: this.canSendEmail && !!this.patient.emailAddress?.trim()
            },
            {
                value: this.patient.phoneNumber,
                text: this.patient.phoneNumber,
                visible: !!this.patient.phoneNumber?.trim()
            },
            {
                value: this.patient.cellNumber,
                text: this.patient.cellNumber,
                visible: !!this.patient.cellNumber?.trim()
            }
        ].filter(i => i.visible !== false);
    }

    async viewReport() {
        const url = `reports/embeddedIRS/${this.patient.id}/${moment(this.startDate).format("YYYY-MM-DD")}/${moment(this.endDate).format("YYYY-MM-DD")}`;
        this.pdfSource = URL.createObjectURL((await axios.get(url, { responseType: "blob" })).data);
    }

    async sendReport() {
        if (this.isSending) return;

        let data: IRSStatement = new IRSStatement;

        data.patientId = this.patient.id as number;
        data.startDate = this.startDate;
        data.endDate = this.endDate;
        data.deliveryMethod = this.deliveryMethod;
        data.sendTo = this.sendTo;

        try {
            this.isSending = true;
            await axios.post(`/reports/IRSStatement/Send`, data);

            this.$notification({
                message: "IRS Statement Sent Successfully",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
            });
        } catch {
            this.$notification({
                message: "IRS Statement Sending Failed",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
            });
        }

        this.isSending = false;
    }

    async getIRSReportEmailFromConfig() {
        try {
            const response = await this.$http.get<boolean>("/Configuration/IRSReportEmail");
            this.canSendEmail = response.data;
        } catch {
            // ignore
        }

        this.sendTo = this.sendToOptions[0].value;
    }
}
