
import { Component, Emit, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { Prescription } from "@/models/Prescription";
import { Drug } from "@/models/Drug/Drug";
import { Identifier } from "@/models/GSDD";
import SearchComponent from "@/components/SearchComponent.vue";
import Axios from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Claim } from "@/models/Claim";
import { PriorAuthorization } from "@/models/PriorAuthorization";
import EquivalentDrugsComponent from "@/components/Drug/EquivalentDrugsComponent.vue";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
import { CompoundDrug } from "@/models/Drug/CompoundDrug";

@Component({
    name: "DrugChangeModal",
    components: { EquivalentDrugsComponent, SearchComponent }
})
export default class DrugChangeModal extends Vue {
    @VModel({ type: Boolean, default: false }) visible!: boolean;
    @Prop({ default: 'drug-change-modal' }) private id!: string;
    @Prop({ type: Prescription, default: new Prescription() }) private prescription!: Prescription;
    @Prop() private drugDetails!: DrugProduct;
    private newDrug: DrugProduct = new Drug();
    private newDrugDetails: DrugProduct = new Drug();

    private loadingNewDrugDetails = false;

    private processingNewBilling = false;

    get newNdcOrUpc(): string {
        const newNdc = this.newDrugDetails?.ndc;
        return newNdc?.length > 0 ? newNdc : this.newUpc;
    }

    get upc(): string {
        if (this.drugDetails?.source != DrugSource.GsddFederal) return '';
        const drug = this.drugDetails as Drug;
        let upc = drug?.packageDetail?.packageIdentifier?.filter((pi: Identifier) => pi.identifierType == 8);
        return upc?.length > 0 ? upc[0].identifier : '';
    }

    get newUpc(): string {
        if (this.newDrugDetails.source != DrugSource.GsddFederal) return '';
        const drug = this.newDrugDetails as Drug;
        let upc = drug?.packageDetail?.packageIdentifier?.filter((pi: Identifier) => pi.identifierType == 8);
        return upc?.length > 0 ? upc[0].identifier : '';
    }

    get newPackageSize(): string {
        return this.newDrugDetails?.packageSize ? `${this.newDrugDetails?.packageSize} ${this.newDrugDetails?.billingUnit}` : '';
    }

    @Watch('newDrug')
    newDrugUpdate(newVal: DrugProduct, oldVal: DrugProduct) {
        if (newVal.drugId == oldVal.drugId) return;
        const packId = newVal.drugId
        if (packId == undefined) return;
        this.loadingNewDrugDetails = true;
        Axios.get<DrugProduct>(`/Drug/${packId}`, { params: { drugSource: newVal.source, }, })
            .then(drugResponse => {
                if (drugResponse.data.source == DrugSource.GsddFederal) {
                    this.newDrugDetails = new Drug(packId, drugResponse.data);
                }
                if (drugResponse.data.source == DrugSource.NonGsddProduct) {
                    this.newDrugDetails = new NonGSDDDrug(packId, drugResponse.data);
                }
                if (drugResponse.data.source == DrugSource.CompoundProduct) {
                    this.newDrugDetails = new CompoundDrug(packId, drugResponse.data);
                }
            })
            .catch(err => this.$notification(NotificationOptions.error(err)))
            .finally(() => this.loadingNewDrugDetails = false);
    }

    @Emit()
    changeDrug(newDrug: string) {
        const url = `/Prescription/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}/ChangeDrug`;
        this.processingNewBilling = true;
        return Axios.post<{ claim: Claim, prescription: Prescription, pa: PriorAuthorization; }[]>(url, this.newDrug)
            .then(resp => {
                this.$notification(NotificationOptions.successSaveNotificationPreset("Drug Change"));
                return resp;
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                return err;
            })
            .finally(() => this.processingNewBilling = false);
    }

    equivalentDrugSelected(packageId: number) {
        this.newDrug = new Drug(packageId);
        this.newDrugDetails = new Drug(packageId);
    }
}
