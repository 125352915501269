
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import { InventoryBatch } from '@/models/InventoryBatch';
import { Store } from '@/models/Store';
import { Vendor } from '@/models/Vendor';
import { Drug } from '@/models/Drug/Drug';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Program } from '@/models/Program';
import { Inventory } from '@/models/Inventory';
import InventoryLotEntryComponent from './InventoryLotEntryComponent.vue';
import { InventoryLocation } from '@/models/InventoryLocation';

@Component({
    name: "InventoryBatchFormComponent",
    components: {
        SearchComponent,
        InventoryLotEntryComponent
    },
})
export default class InventoryBatchFormComponent extends Vue {

    //Batch ID
    @Prop({ default: 0 }) private value!: number;
    @Prop({ default: 'new' }) private variant!: string;
    @Prop({ default: null }) private po!: string | null;
    @Prop({ default: false }) private hasInventoryLocations!: boolean;
    @Prop({ default: false }) private requireLots!: boolean;

    get batchID(): number {
        return this.value;
    }

    private Drug = Drug;

    private batch: InventoryBatch = new InventoryBatch();
    private newItem: InventoryBatch.InventoryBatchItem = new InventoryBatch.InventoryBatchItem();

    private store: Store = new Store();
    private vendor: Vendor = new Vendor();
    private program: Program = new Program();
    private drug: Drug = new Drug();

    private isLoading = false;
    private isSaving = false;
    private isSubmitting = false;
    private isCancelBatch: boolean | null = null;

    created() {
        this.loadBatch();
        this.batch.purchaseOrderNumber = this.po;
    }

    get fields() {
        return [
            { key: "details", label: "", hidden: !this.batch.items.find(i => i.lots) },
            { key: "shippedQuantity", label: "Shipped Qty" },
            { key: "receivedQuantity", label: "Received Qty" },
            { key: "packageSize", label: "Package Size" },
            { key: "totalShippedUnits", label: "Units Shipped" },
            { key: "totalReceivedUnits", label: "Units Received" },
            { key: "drugName", label: "Drug", class: "drug" },
            // { key: "ndc", label: "NDC", formatter: Drug.formatNDC542 },
            { key: "location.name", label: "Location", hidden: !this.hasInventoryLocations },
            { key: "actions" },
        ].filter(f => f.hidden !== true);
    }

    get shippedQuantityLabel() {
        if (this.variant == "return") return "Units Returning:";
        return "Packages Shipped:";
    }

    @Watch('newItem.shippedQuantity')
    receivedQuantityChanged() {
        this.newItem.receivedQuantity = this.newItem.shippedQuantity;
    }

    colorCodeTable(row: InventoryBatch.InventoryBatchItem, type: string) {
        if (!row || type !== 'row') return;

        if (!row.packageID) return 'table-danger';
    }

    async loadBatch() {
        if (!this.batchID) return;

        try {
            this.isLoading = true;

            const res = await this.$http.get<InventoryBatch>(`/Inventory/inventory-batch/${this.batchID}`);

            this.batch = new InventoryBatch(res.data);
        } catch (err) {
            console.error('Error while loading inventory batch', { err, response: err?.response });
        } finally {
            this.isLoading = false;
        }
    }

    addProduct() {
        const isReturn = this.variant == "return";
        let recQty = this.newItem.receivedQuantity;
        let shipQty = this.newItem.shippedQuantity;
        let location = this.newItem.location;
        const lots = this.newItem.lots;
        if (isReturn) {
            shipQty /= this.drug.packageSize;
            recQty /= this.drug.packageSize;
            shipQty = Math.abs(shipQty) * -1;
            recQty = Math.abs(recQty) * -1;
            lots.forEach(l => {
                l.quantity /= this.drug.packageSize;
                l.quantity = Math.abs(l.quantity) * -1;
            })
        }
        this.newItem = new InventoryBatch.InventoryBatchItem(this.drug);
        this.newItem.receivedQuantity = recQty;
        this.newItem.shippedQuantity = shipQty;
        this.newItem.location = location;
        this.newItem.lots = lots;
        this.batch.items.push(this.newItem);
        this.clearNewItemForm();
    }

    editProduct() {
        const isReturn = this.variant == "return";
        let recQty = this.newItem.receivedQuantity;
        let shipQty = this.newItem.shippedQuantity;
        const lots = this.newItem.lots;
        if (isReturn) {
            shipQty /= this.drug.packageSize;
            recQty /= this.drug.packageSize;
            shipQty = Math.abs(shipQty) * -1;
            recQty = Math.abs(recQty) * -1;
            lots.forEach(l => {
                l.quantity /= this.drug.packageSize;
                l.quantity = Math.abs(l.quantity) * -1;
            })
        }

        let item = this.batch.items.find(i => i.id == this.newItem.id);
        if (item) {
            item.packageID = Number(this.drug.drugId);
            item.productNameShort = this.drug.productNameShort;
            item.packageSize = this.drug.packageSize;
            item.ndc = this.drug.ndc?.replace(/-/g, "");
            item.receivedQuantity = recQty;
            item.shippedQuantity = shipQty;
            item.location = this.newItem.location;
            item.lots = lots;
        }
        this.clearNewItemForm();
    }

    setEditProduct(item: InventoryBatch.InventoryBatchItem) {
        if (!item.id) {
            this.batch.items = this.batch.items.filter(i => i.packageID != Number(item.packageID));
        }
        this.clearNewItemForm();
        this.newItem = Object.assign(new InventoryBatch.InventoryBatchItem(), item);
        this.drug = Object.assign(new Drug(), { drugId: item.packageID, source: item.drugSource });
        this.newItem.location = Object.assign(new InventoryLocation, { id: item.location?.id });
        const isReturn = this.variant == "return";
        let recQty = this.newItem.receivedQuantity;
        let shipQty = this.newItem.shippedQuantity;
        if (isReturn) {
            shipQty *= this.newItem.packageSize;
            shipQty = Math.round(Math.abs(shipQty));
            recQty *= this.newItem.packageSize;
            recQty = Math.round(Math.abs(recQty));
        }
        this.drug = Object.assign(new Drug(), {
            drugId: this.newItem.packageID,
            packageID: this.newItem.packageID,
            source: this.newItem.drugSource
        });
        this.newItem.receivedQuantity = recQty;
        this.newItem.shippedQuantity = shipQty;
        if (!this.newItem.lots) this.newItem.lots = [];
        if (isReturn) {
            this.newItem.lots.forEach(l => {
                l.quantity *= this.newItem.packageSize;
                l.quantity = Math.round(Math.abs(l.quantity));
            })
        }
        this.$bvModal.show("inventory-batch-drug-modal");
    }

    removeProduct(item: InventoryBatch.InventoryBatchItem) {
        this.batch.items = this.batch.items.filter(i => i.packageID != item.packageID);
    }

    clearNewItemForm() {
        this.drug = new Drug();
        this.newItem = new InventoryBatch.InventoryBatchItem();
        this.newItem.lots = [];
        this.newItem.location = new InventoryLocation();
        this.isCancelBatch = null;
    }

    confirmDialog(type: string, item: InventoryBatch.InventoryBatchItem) {
        const action = type == 'remove product' ? 'remove the product from' : type;
        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${action} this purchase order?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: type == 'cancel' ? 'danger' : 'info',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
            .then(value => {
                if (!value) return;
                if (type == 'submit') this.submitBatch();
                if (type == 'cancel') this.cancelBatch();
                if (type == 'remove product') this.removeProduct(item);
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
    }

    submitBatch() {
        // Save and submit
        this.saveNewBatchPromise.then((res) => {
            if (res?.id) {
                const ids = res.items.map(item => item.id);
                this.isSubmitting = true;
                this.$http.post<Array<Inventory>>(`/Inventory/inventory-batch-submit`, ids, { params: { batchId: this.batch.id } })
                    .then(res => {
                        if (res.data?.length >= 0) {
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset("Batch items successfully submitted"
                                    , NotificationOptions.NotificationTypes.success,
                                ));
                            this.clearForm();
                            this.$emit('batchSaved');
                            this.$bvModal.hide('inventory-batch-form');
                        } else
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset("Selected batch items were submitted, but no Inventories were updated"
                                    , NotificationOptions.NotificationTypes.warning,
                                ));
                    })
                    .catch(err => {
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory Batch Item"));
                        console.error('Error while submitting Inventory Batch Item', { err, response: err?.response });
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            }
        });

    }

    @Emit('batchSaved')
    async cancelBatch() {
        try {
            this.isCancelBatch = true;
            const res = await this.saveNewBatchPromise;
            if (res?.id) {
                this.clearForm();
            }
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    get entityName(): string {
        return this.variant == 'return' ? "Return Order" : "Purchase Order";
    }

    async saveNewBatch() {
        try {
            const res = await this.saveNewBatchPromise;
            if (!res?.id) return;
            this.$notification(NotificationOptions.successSaveNotificationPreset(this.entityName));
            this.clearForm();
            this.$emit("batch-saved");
        } catch (err) {
            console.log(err);
            this.$notification(NotificationOptions.error(err));
        }
    }

    clearForm() {
        this.batch = new InventoryBatch();

        this.store = new Store();
        this.vendor = new Vendor();
        this.program = new Program();

        this.clearNewItemForm();
    }

    get saveNewBatchPromise() {
        return new Promise<InventoryBatch>((resolve, reject) => {
            if (!this.isNewBatchItemValid) reject('Invalid new batch');

            this.batch.vendorID = Number(this.vendor.id);
            this.batch.storeID = Number(this.store.id);
            if (Number(this.program.id) != 0) {
                this.batch.programID = Number(this.program.id);
            }
            this.batch.isReturn = this.variant == "return";

            this.isSaving = true;
            this.$http.post<InventoryBatch>(`/Inventory/inventory-batch/${this.isCancelBatch ? 'true' : ''}`, this.batch)
                .then(res => {
                    if (Number(res.data.id) >= 0) {
                        resolve(new InventoryBatch(res.data));
                    } else {
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Purchase Order"));
                        reject('No new inventory batch created.');
                    }
                })
                .catch(err => {
                    const errorMsg = 'Error while saving new Inventory Batch Item';
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory Batch Item"));
                    console.error(errorMsg, { err, response: err?.response });
                    reject(errorMsg);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        });
    }

    get disableSubmit(): boolean {
        if (!this.vendor?.id) return true;
        if (this.batch.items.some(i => !i.packageID)) return true;
        if (!this.requireLots) return false;

        const lotsIncompleteItem = this.batch.items.find(i => !this.itemLotsAreValid(i))

        return !!lotsIncompleteItem;
    }

    get isNewBatchItemValid(): boolean {
        return !!(this.store?.id && this.vendor?.id && this.batch.items?.length > 0);
    }

    get disableSave() {
        return !this.isNewBatchItemValid || this.isSaving;
    }

    get isProductFormValid(): boolean {
        return !!(this.newItem.shippedQuantity && this.drug.drugId);
    }

    get disableAdd(): boolean {
        // Enable until quantity and drug has been selected and drug is not repeated.
        return (!this.isProductFormValid || this.batch.items.some(i => i.packageID == Number(this.drug.drugId)));
    }

    get isBusy(): boolean {
        return this.isSaving || this.isSubmitting;
    }

    addDrugModal() {
        this.clearNewItemForm();
        this.$bvModal.show("inventory-batch-drug-modal");
    }

    handleDrugModalOk() {
        if (!this.newItem.id) {
            this.addProduct();
        } else {
            this.editProduct();
        }
    }

    itemLotsAreValid(item: InventoryBatch.InventoryBatchItem) {
        if (!this.requireLots) return true;
        const missingItem = item.lots?.find(l => !l.lot?.trim() || !l.expiry || !l.quantity);
        if (missingItem) return false;
        const lotTotalQty = item.lots?.reduce((p, i) => Number(i.quantity) + p, 0);
        return lotTotalQty == item.receivedQuantity;
    }

    get disableEditModalOk() {
        if (!this.itemLotsAreValid(this.newItem)) return true;
        if (!this.newItem.id) {
            return this.disableAdd;
        }
        return !this.isProductFormValid;
    }
}
