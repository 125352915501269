
import { Prescription } from "@/models/Prescription";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "PrescriptionDetailsSummary"
})
export default class PrescriptionDetailsSummary extends Vue{
    @Prop() private value!:Prescription;

    private prescriptions:Array<Prescription> = new Array<Prescription>();
    private originalFillDate : Date | null = null;
    private lastFillDate : Date | null = null;
    
    created(){
        this.loadPrescriptions();
    }

    loadPrescriptions() {
        const patientID = this.value.patientID;
        if (!patientID) return;

        //Get patient's prescriptions and format the data to be shown in the grid.
        this.$http.get<Array<Prescription>>(`/Patient/${patientID}/prescriptions`)
            .then(response => {
                if(response.data?.length){
                    this.prescriptions = response.data.map((rx: Prescription) => Object.assign(new Prescription(), rx));
                    this.prescriptions = this.prescriptions.filter(rx => rx.isActive && rx.rxNumber == this.value.rxNumber).sort((rx1, rx2) => {
                        if(rx1 > rx2) return 1
                        else if(rx1 < rx2) return -1
                        else return 0;
                    });
                    if(this.prescriptions.length > 0){
                        const originalFillDate = this.prescriptions[0].fillDate;
                        const lastFillDate = this.prescriptions[this.prescriptions.length-1].fillDate;
                        this.originalFillDate = originalFillDate;
                        this.lastFillDate = lastFillDate;
                    }
                }
            })
            .catch(error => {
                console.log("Problem loading prescriptions", {error, response: error?.response});
            });
    }
}
