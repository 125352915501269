export enum EntityType {
    Unknown,
    PrescriptionHardCopyAnnotation,
    Patient,
    Case,
    Program,
    PriorAuthorization,
    DispenseError,
    RxTransferQueue,
    Prescriber,
    PrescriberFollowUpCase,
    ElectronicPriorAuthorization,
    DrugShortageReport,
    SurplusReports,
    GsddDrug,
    NonGsddDrug,
    CompoundDrug,
    Plan,
    PriceSchedule,
}
