<template>

    <div>

        <div class="grid">
            <div v-for="template in templates" :key="template.key" :class="{ inactive: !value[template.key]?.active }">

                <b-popover :target="'text-message-template-' + template.key" triggers="focus" placement="top"
                    custom-class="text-message-template">

                    <div v-if="template.preview">
                        <h6>Preview</h6>
                        <div class="msg-bubble">{{ template.preview }}</div>
                    </div>

                    <div v-if="template.tokens && template.tokens.length">
                        <h6>Available Tokens <small class="text-muted">Click to add</small></h6>
                        <div class="tokens">
                            <code v-for="tkn in template.tokens" :key="tkn" @click="addToken(template.key, tkn)">
                                {{ "{" + tkn + "}" }}
                            </code>
                        </div>
                    </div>

                    <div v-if="defaults">
                        <b-button href="#" variant="primary" size="sm" class="m-0" block
                            @click.prevent="updateValue(template.key, 'template', defaults[template.key].template)">
                            Load Default Template
                        </b-button>
                    </div>
                </b-popover>

                <label class="d-flex">
                    <b-checkbox switch @input="updateValue(template.key, 'active', $event)" size="sm"
                        :checked="value[template.key]?.active" :disabled="disabled" />
                    {{ template.label }}
                </label>
                <b-form-textarea :ref="'text-message-template-' + template.key"
                    :id="'text-message-template-' + template.key" :value="value[template.key]?.template"
                    @input="updateValue(template.key, 'template', $event)" spellcheck="false" :disabled="disabled" />

            </div>

        </div>

        <div v-if="false" class="mt-5">
            <code>{{ value }}</code>
        </div>

    </div>

</template>

<script>

const pharmacyTokens = ["PharmacyName", "PharmacyPhone"];
const patientTokens = ["PatientFirstName", "PatientLastName"];

const linkExample = "https://link.com/ex";

export default {
    name: "TextMessageTemplatesField",
    props: {
        value: {},
        previewData: Object,
        hideTemplates: Array,
        disabled: Boolean
    },
    async mounted() {
        const response = await this.$http.get("/Configuration/text-message-template-field-defaults");
        console.log(response);

        this.defaults = response.data.defaults;

        const values = response.data.values;

        if (values.pharmacyName) this.defaultPreviewReplacements.PharmacyName = values.pharmacyName;
        if (values.pharmacyPhone) this.defaultPreviewReplacements.PharmacyPhone = values.pharmacyPhone;
        if (values.pharmacyFax) this.defaultPreviewReplacements.PharmacyFax = values.pharmacyFax;
        if (values.pharmacyNPI) this.defaultPreviewReplacements.PharmacyNPI = values.pharmacyNPI;
        if (values.pharmacyNCPDP) this.defaultPreviewReplacements.PharmacyNCPDP = values.pharmacyNCPDP;
    },
    data() {
        return {
            defaults: null,
            defaultPreviewReplacements: {
                PharmacyName: "Pharmacy Name",
                PharmacyPhone: "(800) 555-1212",
                PharmacyFax: "(888) 555-4444",
                PharmacyNPI: "123456",
                PharmacyNCPDP: "123456",
                PatientFirstName: "John",
                PatientLastName: "Doe",
                ConfirmLink: linkExample,
                FromPrescriberMessage: " from Dr. Livingston",
                NoCostMessage: "Your medication is available at no cost to you. ",
                OrderLink: linkExample,
                NoCopayMessage: "Your refill may be available at no cost to you. ",
                Password: "Pass123@!",
                AutorefillLink: linkExample,
                DaysToGoMessage: "in 3 days",
                TrackingNumber: "961013852890",
                DeliveryDate: "Monday, June 5",
                PaymentLink: linkExample,
                Link: linkExample,
                PharmacyFullAddress: "123 Test Lane, Chicago, IL, 60000"
            }
        }
    },
    methods: {
        updateValue(templateName, fieldName, value) {
            const newValue = { ...this.value };
            if (!newValue[templateName]) newValue[templateName] = {};
            newValue[templateName][fieldName] = value;
            this.$emit('input', newValue);
        },
        getPreviewMessage(value, tokens) {
            if (value) {
                tokens.forEach(t => {
                    value = value?.replace(new RegExp(`{${t}}`, 'gi'), () =>
                        this.previewData && this.previewData[t]
                            ? this.previewData[t]
                            : this.defaultPreviewReplacements[t]
                    );
                });
            }
            return value;
        },
        addToken(key, tkn) {
            const currentValue = this.value[key]?.template ?? "";
            const textArea = this.$refs[`text-message-template-${key}`][0].$el;
            const cursorStartPosition = textArea?.selectionStart ?? currentValue.length;
            const cursorEndPosition = textArea?.selectionEnd ?? currentValue.length;
            const newValue = `${currentValue.slice(0, cursorStartPosition)}{${tkn}}${currentValue.slice(cursorEndPosition)}`;
            this.updateValue(key, 'template', newValue);

            this.$nextTick(() => {
                const newPosition = cursorStartPosition + tkn.length + 2;
                textArea.selectionStart = textArea.selectionEnd = newPosition;
                textArea.focus();
            });
        }
    },
    computed: {
        templates() {
            return [
                { label: "Welcome", key: "welcome", tokens: pharmacyTokens },
                { label: "NPC", key: "npc", tokens: [...pharmacyTokens, ...patientTokens, "ConfirmLink", "FromPrescriberMessage", "NoCostMessage"] },
                { label: "NPC Over Limit", key: "npcOverLimit", tokens: [...pharmacyTokens, ...patientTokens, "FromPrescriberMessage", "NoCostMessage"] },
                { label: "Refill", key: "refill", tokens: [...pharmacyTokens, ...patientTokens, "OrderLink", "NoCopayMessage"] },
                { label: "Out of Network", key: "outOfNetwork", tokens: [...pharmacyTokens, ...patientTokens, "ConfirmLink"] },
                { label: "Password Reset", key: "passwordReset", tokens: [...pharmacyTokens, ...patientTokens, "Password"] },
                { label: "Auto Refill", key: "autorefill", tokens: [...pharmacyTokens, ...patientTokens, "AutorefillLink", "DaysToGoMessage"] },
                { label: "Transfer", key: "transfer", tokens: [...pharmacyTokens, ...patientTokens] },
                { label: "Shipping", key: "shipping", tokens: [...pharmacyTokens, "TrackingNumber", "DeliveryDate"] },
                { label: "Partial Payment", key: "partialPayment", tokens: [...pharmacyTokens, ...patientTokens, "PaymentLink"] },
                { label: "Partial Payment Over Limit", key: "partialPaymentOverLimit", tokens: [...pharmacyTokens, ...patientTokens] },
                { label: "Awaiting Insurance", key: "awaitingInsurance", tokens: [...pharmacyTokens, ...patientTokens, "Link"] },
                { label: "Pharmacy Info", key: "pharmacyInfo", tokens: [...pharmacyTokens, "PharmacyFax", "PharmacyNPI", "PharmacyNCPDP", "PharmacyFullAddress", ...patientTokens] },
                { label: "Ready for Pickup", key: "readyForPickup", tokens: [...pharmacyTokens, "PharmacyFullAddress", ...patientTokens] }
            ].map(i => {
                const value = this.value ? this.value[i.key] : "";

                return {
                    ...i,
                    preview: this.getPreviewMessage(value?.template, i.tokens)
                }
            }).filter(i => !this.hideTemplates?.includes(i.key));
        }
    }
}

</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
    gap: 15px;
    margin-top: 10px;
}

@media screen and (min-width: 1200px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

textarea {
    transition: all .2s;
    height: 100px !important;
    max-height: 100px !important;
    line-height: 1.45 !important;
}

.inactive textarea {
    opacity: .6;
}
</style>

<style lang="scss">
.popover.text-message-template {
    max-width: 420px;

    .popover-body {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h6 {
            margin-bottom: 4px;
        }
    }

    .msg-bubble {
        background: #02ce48;
        color: white;
        padding: 8px;
        border-radius: 9px;
        border-bottom-left-radius: 0;
        font-weight: 500;
        line-height: 1.3;
        max-width: 300px;
        white-space: pre-wrap;
    }

    .tokens {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        code {
            cursor: pointer;
            background: rgb(248, 248, 248);
            border: 1px solid rgb(240, 240, 240);
            padding: 2px 3px;
            border-radius: 4px;
            transition: all .2s;

            &:hover {
                background: rgb(242, 242, 242);
                border-color: rgb(230, 230, 230);
            }
        }
    }

    &.bs-popover-bottom .popover-body {
        flex-direction: column-reverse;
    }
}
</style>