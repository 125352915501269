
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

export interface NewClearSaveTitles {
    new: string;
    clear: string;
    save: string;
    cancel: string;
}

@Component({
    name: "NewClearSaveButtons",
})
export default class NewClearSaveButtons extends Vue {
    @Prop({
        default: () => {
            return { new: "New", save: "Save", clear: "Clear", cancel: "Cancel" } as NewClearSaveTitles
        }
    }) titles!: NewClearSaveTitles;
    @Prop({ default: false }) private disableNew!: boolean;
    @Prop({ default: true }) private disableClear!: boolean;
    @Prop({ default: true }) private disableSave!: boolean;
    @Prop({ default: false }) private disableCancel!: boolean;
    @Prop() private disable!: boolean | null;

    @Prop({ type: Boolean, default: false }) private showClearModal!: boolean;
    @Prop({ default: 'Are you sure you want to clear?' }) private clearModalMessage!: string;

    @Prop({ default: false }) private disableSaveAfterClick!: boolean;

    disNew = this.disableNew;
    disClear = this.disableClear;
    disSave = this.disableSave;
    disCancel = this.disableCancel;

    created() {
        if (this.disable != null) {
            const val = this.disable
            this.disNew = !val;
            this.disClear = val || this.disableClear;
            this.disSave = val || this.disableSave;
        }
    }

    @Watch('disableNew') disableNewChanged(val: boolean) {
        this.disNew = val;
    }

    @Watch('disableClear') disableClearChanged(val: boolean) {
        this.disClear = val;
    }

    @Watch('disableSave') disableSaveChanged(val: boolean) {
        this.disSave = val;
    }

    @Watch('disableCancel') disableCancelChanged(val: boolean) {
        this.disCancel = val;
    }

    @Watch('disable')
    disableChanged(val: boolean) {
        val = val || false;
        this.disNew = !val;
        this.disClear = val || this.disableClear;
        this.disSave = val || this.disableSave;
    }

    @Emit("saveClicked")
    @Emit("save-clicked")
    saveClicked() {
        if (this.disableSaveAfterClick && !this.disSave) this.disSave = true;
    }

    @Emit("newClicked")
    @Emit("new-clicked")
    newClicked() {
        return;
    }

    @Emit("cancelClicked")
    @Emit("cancel-clicked")
    cancelClicked() {
        return;
    }

    clearClicked() {
        if (this.showClearModal) {
            this.$bvModal.msgBoxConfirm(this.clearModalMessage, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (!value) return;
                    this.$emit('clearClicked')
                    this.$emit('clear-clicked')
                })
                .catch(err => {
                    console.error("Error caught on Clear button.", err);
                });
        } else{
            this.$emit('clearClicked')
            this.$emit('clear-clicked')
        }
    }
}

