import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import HasLabel from "./HasLabel";

export interface PharmacyRecord extends  HasID, TrackedObject {
    id: number;
    name: string;
    address: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    phoneNumber: string;
    faxNumber: string;
    dea: string;
    pharmacist: string;
    isActive: boolean;
}

export class PharmacyRecord extends HasLabel{
    constructor(obj?:PharmacyRecord) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (this.name)
            return `${this.name || ''}, fax: ${this.faxNumber || ''}, phone: ${this.phoneNumber || ''}`;
        else return '';
    }
}

export interface TransferRXRecord extends  HasID, TrackedObject {
    id: number;
    storeId: number;
    rxNumber: number;
    rfNumber: number;
    patientName: string;
    drugName: string;
    isVerbalTransfer: boolean;
    storedPharmacyId:number;
    pharmacyName: string;
    transferredDate: Date;
    transferredBy: string;
    docFileName: string;
    pharmacyFax: string;
}

export class TransferRXRecord {
    constructor(obj?:TransferRXRecord) {
        if (obj) Object.assign(this, obj);
    }
}
