import { render, staticRenderFns } from "./PharmacyDetailPage.vue?vue&type=template&id=68fb04c4&scoped=true"
import script from "./PharmacyDetailPage.vue?vue&type=script&lang=ts"
export * from "./PharmacyDetailPage.vue?vue&type=script&lang=ts"
import style0 from "./PharmacyDetailPage.vue?vue&type=style&index=0&id=68fb04c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fb04c4",
  null
  
)

export default component.exports