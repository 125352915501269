
    import Vue from 'vue'
    import axios, {AxiosError, AxiosRequestConfig} from 'axios';
    import { Component, Watch } from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import SearchComponent from '@/components/SearchComponent.vue';
    import {DatePicker} from "element-ui";
    import DrugShortageReportFormComponent from '@/components/DrugShortageReportFormComponent.vue';

    import {DrugShortageReport } from '@/models/DrugShortageReport'
    import {Store} from '@/models/Store'
    import { Program } from '@/models/Program';
    import {Vendor} from '@/models/Vendor'
    import {Drug} from '@/models/Drug/Drug';
    import { Inventory } from '@/models/Inventory';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import moment from 'moment';

    @Component({
        name: "DrugShortageReportPage",
        components: {
            SearchComponent,
            Checkbox,
            DrugShortageReportFormComponent,
            [DatePicker.name]: DatePicker,
        },
    })
    export default class DrugShortageReportPage extends Vue {
        private isLoading = false;
        private items:Array<DrugShortageReport> = new Array<DrugShortageReport>();
        private item: DrugShortageReport = new DrugShortageReport();

        private storeFilter: Store = new Store();
        private programFilter: Program = new Program();
        private drugFilter: Drug = new Drug();
        private drugShortageReport: DrugShortageReport = {} as DrugShortageReport;

        private fields: any[] = [
            // { key: "id", label: 'ShortageId'},
            { key: "ndc", label: "NDC", formatter: this.ndcFormatter},
            { key: "drugName", label: "Drug"},
            { key: "manufacturer", label: "Vendor"},
            { key: "qtyOnHand", label: "Quantity OnHand" },
            { key: "qtyNeeded", label: "Quantity Needed" },
            { key: "dateOrdered", label: "Date Ordered", formatter: this.orderDateFormatter },
            { key: "estimatedArrivalDate", label: "Estimated Arrival Date", formatter: this.etaDateFormatter },
            // { key: "dateDelivered", label: "DateDelivered" },
            // { key: "backordered", label: "On Backorder" },
            { key: "created", label: "Created" },
            { key: "createdBy", label: "Created By" },
            { key: "actions", label: ''},
        ];
        private sortBy:string = 'created'
        private showSubmitted = false;
        private showRemoved = false;

        created(){
            this.loadItems();
        }

        loadItems() {
            if (!this.storeFilter || !this.storeFilter.id) {
                this.$bvModal.msgBoxOk(`Store needs to be selected`);
                return;
            }

            this.isLoading = true;
            const config: AxiosRequestConfig = {
                params: {
                    storeId: this.storeFilter.id,
                    programId: this.programFilter?.id
                }
            };
            axios.get<Array<DrugShortageReport>>('DrugShortageReport', config)
                .then(res => {
                    this.items = res.data.map(i => new DrugShortageReport(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(()=>{
                this.isLoading = false;
            });
        }

        itemSaved(){
            this.$bvModal.hide('drug-shortage-report-form');
            this.loadItems();
        }

        ndcFormatter(value: string) {
            if (value == null) {
                return value;
            }
            return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
        };

        orderDateFormatter(value: Date, key: string, item: any) {
            if (!value || item.dateOrdered > item.dateOrdered) {
                return "";
            }
            return moment.utc(value).format("L");
        }

        etaDateFormatter(value: Date, key: string, item: any){
            if (!value || item.estimatedArrivalDate > item.estimatedArrivalDate) {
                return "";
            }
            return moment.utc(value).format("L");
        }

        colorCodeTable(row : DrugShortageReport, type: string){
            if (!row || type !== 'row')
                return;
            if(row.backordered != null && row.backordered) return 'table-danger';
            else if(row.dateOrdered != null && row.dateOrdered) return 'table-success';
            else return 'table-info';
        }

        openDetails(item:DrugShortageReport){
            this.item = item;
            this.$bvModal.show('drug-shortage-report-form');
        }

        removeItem(item:DrugShortageReport){
            axios.post(`DrugShortageREport/RemoveReport/${item.id}`).finally(() => {this.loadItems();});
        }

        markDelivered(item:DrugShortageReport){
            axios.post(`DrugShortageReport/Delivered/${item.id}`).finally(() => {this.loadItems();});
        }

        newDrugShortageReport(){
            this.item = new DrugShortageReport();
            this.item.qtyOnHand = 0;
            this.$bvModal.show('drug-shortage-report-form');
        }
    }
