
import { Component, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DatePicker } from "element-ui";
import SearchComponent from '@/components/SearchComponent.vue';
import OrdersListComponent from '@/components/Order/OrdersListComponent.vue';
import { Store } from '@/models/Store';
import { Order } from '@/models/Order';
import { Printer } from '@/models/Printer';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { debounce } from 'throttle-debounce';

const pendingStorageKey = "verification_queue_showPending";
const selectedStoresStorageKey = "verification_queue_selectedStores";
const scriptFormat = /\d+-\d+-\d+/;

@Component({
    name: "VerificationQueuePage",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        OrdersListComponent,
        Multiselect,
    },
})
export default class VerificationQueuePage extends Vue {
    private orders: Array<Order> = [];
    private searchString: string = "";
    private voidTrackingNumber: string = "";
    private ReprintId: string = "";
    private printers: Array<Printer> = [];
    private selectedPrinter: Printer = {} as Printer;

    protected stores: Array<Store> = [];
    protected selectedStores: Array<Store> = [];
    protected showPending: boolean = false;
    private showPrinters = false;

    async created() {
        this.showPending = this.persistentShowPending;
        this.selectedStores = this.persistentSelectedStores;

        const stores = await Axios.get<Store[]>(`/Store/`);
        this.stores = stores.data.map(s => new Store(s));

        if (this.routeStoreId) {
            const routeStore = this.stores.find(s => s.id === this.routeStoreId);
            if (routeStore) this.selectedStores = [routeStore];
        }

        this.loadOrders();
        this.loadPrinters();
    }

    mounted() {
        window.scrollTo(0, 0);
    }

    get persistentShowPending(): boolean {
        const objStr = localStorage.getItem(pendingStorageKey);
        if (!objStr) return false;
        return JSON.parse(objStr);
    }

    get persistentSelectedStores(): Store[] {
        const objStr = localStorage.getItem(selectedStoresStorageKey);
        if (!objStr) return [];
        return JSON.parse(objStr);
    }

    @Watch('showPending')
    showPendingUpdate(val: any) {
        localStorage.setItem(pendingStorageKey, JSON.stringify(val));
    }

    @Watch('selectedStores')
    selectedStoresUpdate(val: any) {
        localStorage.setItem(selectedStoresStorageKey, JSON.stringify(val));
    }

    async loadPrinters() {
        try {
            if (!this.showPrinters) return;
            const response = await Axios.get<Printer[]>('/Printer');
            this.printers = response.data;
            this.selectedPrinter = JSON.parse(localStorage.getItem("CPHubVerifyPrinter") as string) as Printer ?? this.printers[0];
        } catch (error) {
            console.warn(error);
        }
    }

    selectPrinter(printer: Printer) {
        localStorage.setItem("CPHubVerifyPrinter", JSON.stringify(printer));
        this.selectedPrinter = printer;
    }

    get filteredOrders() {
        let orders = this.orders;

        orders = orders.filter(o => this.selectedStores.find(s => s.id === o.storeID));

        return orders;
    }

    async loadOrders() {
        try {
            const config: AxiosRequestConfig = {
                params: {
                    searchTerm: this.searchString.length ? this.searchString : this.showPending ? '%' : '',
                },
            };
            const response = await Axios.get<Order[]>(`/Order/pending-pv2-search/`, config);

            this.orders = response.data.map(o => new Order(o));

            if (this.orders.length === 1 && this.searchString.match(scriptFormat)) {
                this.$router.push({
                    name: 'OrderVerification',
                    params: { orderId: response.data[0].id.toString() },
                });
            }

            if (this.searchString && !this.orders.length) {
                this.$notification({
                    message: "No Order Found",
                    timeout: 1000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                });
            }
        } catch (err) {
            console.warn(err);
        }
    }

    private throttledSearch: Function = debounce(250, this.loadOrders);

    get routeStoreId(): number {
        const res = parseInt(this.$route.params.storeId);
        if (isNaN(res)) return 0;
        return res;
    }

    async voidShipment() {
        if (!this.voidTrackingNumber) return;

        try {
            await Axios.post(`/Shipping/VoidLabel/${this.voidTrackingNumber}`);
            this.$notification(NotificationOptions.notificationOptionsPreset("Successfully voided shipment!", NotificationOptions.NotificationTypes.success));
        } catch (err) {
            const error = err as AxiosError;
            this.$notification(NotificationOptions.notificationOptionsPreset("Failed to void shipment!", NotificationOptions.NotificationTypes.danger));
            console.log("Failed to void shipment!", { error, response: error?.response });
        }
    }

    async reprintLabel() {
        try {
            const resp = await Axios.get<number>("Order/OrderId", { params: { query: this.ReprintId } });
            const orderId = resp.data;
            this.$router.push({ name: "OrderVerification", params: { orderId: orderId.toString() } });
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }
    }

    clearFilters() {
        this.searchString = "";
        this.selectedStores = [];
        this.loadOrders();
    }

    adjustExpressShipdate() {
        if (!this.selectedStores?.length) return;

        this.selectedStores.forEach(async s => {
            try {
                const storeId = s.id;
                await Axios.post(`Shipping/CloseExpress/${storeId}`);
                this.$notification(NotificationOptions.notificationOptionsPreset("Ship dates Successfully Adjusted", NotificationOptions.NotificationTypes.success));
            } catch (error) {
                const err = error as AxiosError;
                this.$notification(NotificationOptions.notificationOptionsPreset("Not able to adjust Ship dates", NotificationOptions.NotificationTypes.danger));
                console.log("Error while adjusting ship dates", { err, response: err?.response });
            }
        })
    }

}

