import { DashboardShipmentSummary } from "./DashboardShipmentSummary";
import { DashboardTotalQuantities } from "./DashboardTotalQuantities";
import { Voicemail, VoicemailGroupCount } from "@/models/Voicemail";
import { Store } from "@/models/Store";

export class DashboardStatus {
    dispenseStatus: Array<DashboardStatus.DashboardDispenseStatus>;
    processingStatus: Array<DashboardStatus.DashboardProcessingStatus>;
    processingStatusCounts: DashboardStatus.DashboardProcessingStatusCounts;
    salesRecordsByStore: DashboardStatus.SalesStatus;
    salesRecordsByState: DashboardStatus.SalesStatus;
    bucketStatus: DashboardStatus.BucketStatus;
    shipmentSummaryReport: DashboardShipmentSummary;
    totalQuantities: DashboardTotalQuantities;
    printQueueStatus: Array<DashboardStatus.PrintQueueStatus>;
    voiceMailDetail: Array<Voicemail>;
    voicemailGroupCount: Array<VoicemailGroupCount>;
    storeList: Array<Store>;

    configs: Array<DashboardStatus.ConfigProp>;

    constructor(obj?: DashboardStatus) {
        this.dispenseStatus = new Array<DashboardStatus.DashboardDispenseStatus>();
        this.processingStatus = new Array<DashboardStatus.DashboardProcessingStatus>();
        this.processingStatusCounts = new DashboardStatus.DashboardProcessingStatusCounts();
        this.salesRecordsByStore = new DashboardStatus.SalesStatus();
        this.salesRecordsByState = new DashboardStatus.SalesStatus();
        this.bucketStatus = new DashboardStatus.BucketStatus();
        this.shipmentSummaryReport = new DashboardShipmentSummary();
        this.totalQuantities = new DashboardTotalQuantities();
        this.printQueueStatus = new Array<DashboardStatus.PrintQueueStatus>();
        this.voiceMailDetail = new Array<Voicemail>();
        this.voicemailGroupCount = new Array<VoicemailGroupCount>();
        this.storeList = new Array<Store>();
        this.configs = new Array<DashboardStatus.ConfigProp>();
        if (obj) {
            Object.assign(this, obj);
            Object.assign(this.shipmentSummaryReport, new DashboardShipmentSummary(obj.shipmentSummaryReport));
            Object.assign(this.totalQuantities, new DashboardTotalQuantities(obj.totalQuantities));
        }
    }

    getConfigValue(configName: string): string | null {
        if (!this.configs?.length) return null;

        return this.configs?.find(c => c.configName == configName)?.value || null;
    }

    stores: string[] = ['Schaumburg', 'Ohio', 'Florida', 'Indiana', 'Texas', 'Aggregate'];
    totalRevenues: Record<string, number | null> = {};

    calculateTotalRevenueForEachStore(data: any[]): void {
        for (const store of this.stores) {
            const storeData = data.filter((item: any) => item.storeName === store);
            const totalRevenue = storeData.reduce((acc: number, item: any) => acc + item.totalRevenue, 0);
            this.totalRevenues[store] = totalRevenue;
        }
    }

    getCountByStoreId(counts: Array<DashboardStatus.CountByLocation>, storeId: number): number | undefined {
        const countObj = counts.find(item => item.storeId === storeId);
        return countObj ? countObj.count : undefined;
    }

    getCountByStoreName(counts: Array<DashboardStatus.CountByLocation>, storeName: string): number | undefined {
        const countObj = counts.find(item => item.storeName === storeName);
        return countObj ? countObj.count : undefined;
    }

    getDispenseCounts(propName: string, valuePropName: string | null = null): Array<DashboardStatus.CountByLocation> {

        const res = this.dispenseStatus.map(ds =>
            Object.assign(new DashboardStatus.CountByLocation(), {
                storeId: ds.storeId
                , storeName: ds.storeName
                , count: (ds as any)[propName]
                , value: valuePropName != null ? (ds as any)[valuePropName] : null
            })
        );

        return res;
    }

    get getDispenseUnearnedTotal(): number {
        return this.dispenseStatus.reduce((total, s) => {
            return total + s.printQueueRevenue + s.outOfStockRevenue + s.printedNotFilledRevenue + s.filledNotVerifiedRevenue + s.verifiedNotShippedRevenue
        }, 0.0);
    }

    getProcessingStatus(propName: string): Array<DashboardStatus.CountByLocation> {

        const res = this.processingStatus.map(ds =>
            Object.assign(new DashboardStatus.CountByLocation(), {
                storeId: ds.storeId
                , storeName: ds.storeName
                , otherClassification: ds.otherClassification
                , count: (ds as any)[propName]
            })
        );

        return res;
    }

    getProcessingStatusCount(propName: string): number {
        return (this.processingStatusCounts as any)[propName] as number;
    }
}

export namespace DashboardStatus {
    export class DashboardDispenseStatus {
        storeId = 0;
        storeName: string | null = null;
        printQueue = 0;
        printQueueRevenue = 0.0;
        outOfStock = 0;
        outOfStockRevenue = 0.0;
        printedNotFilled = 0;
        printedNotFilledRevenue = 0.0;
        printedNotFilled48Hrs = 0;
        filledNotVerified = 0;
        filledNotVerifiedRevenue = 0.0;
        filledNotVerified48Hrs = 0;
        verifiedToday = 0;
        verifiedTodayRevenue = 0.0;
        verifiedNotShipped = 0;
        verifiedNotShippedRevenue = 0.0;
        pv1Queue = 0;
        pv1QueueRevenue = 0.0;
        pickupQueue = 0;
        pickupQueueRevenue = 0.0;

        // Moved FROM PROCESSING PANEL
        orderVerifiedNotShippedQueueCount = 0;
        pickUpOrders = 0;
        oldestPickUp = new Date();
        orderAuditQueueCount = 0;

    }

    export class DashboardProcessingStatus {
        storeId = 0;
        storeName: string | null = null;
        otherClassification: string | null = null;

        // Status Items
        insuranceQueueCount = 0;
        allergyQueueCount = 0;
        onlineOrdersQueueCount = 0;
        rxTransferQueueCount = 0;
        openCasesQueueCount = 0;
        consultationsQueueCount = 0;
        pendingErrorsQueueCount = 0;
        pendingFollowUpQueueCount = 0;  // ** DR FOLLOW UP QUEUE
        autoRefillQueueCount = 0;
        consignmentQueueCount = 0;
        pendingBillingQueueCount = 0;
        // ** VOICE MAIL
        partialPaymentQueueCount = 0;
        reversalQueueCount = 0;
        priorAuthorizationQueueCount = 0;
        patientMergeRequestQueueCount = 0;
        // ** RETURNED PACKAGES
        // ** PATIENT ASSISTANCE PROGRAM QUEUE
    }

    export class DashboardProcessingStatusCounts {

        // Status Items
        insuranceQueueCount = 0;
        allergyQueueCount = 0;
        rxTransferQueueCount = 0;
        pendingErrorsQueueCount = 0;
        pendingFollowUpQueueCount = 0;  // ** DR FOLLOW UP QUEUE
        autoRefillQueueCount = 0;
        consignmentQueueCount = 0;
        pendingBillingQueueCount = 0;
        // ** VOICE MAIL
        partialPaymentQueueCount = 0;
        reversalQueueCount = 0;
        priorAuthorizationQueueCount = 0;
        patientMergeRequestQueueCount = 0;
        expiredOnOrderQueueCount = 0;
        // ** RETURNED PACKAGES
        // ** PATIENT ASSISTANCE PROGRAM QUEUE
    }

    export class SalesRecord {
        name: string | null = null
        new = 0;
        refill = 0;
        totalRx = 0;
        patPay = 0;
        planPaid = 0;
        cost = 0;
        margin = 0;
        shippedOrders = 0;
        shippedRx = 0;
    }

    export class SalesStatus {
        totalNew = 0;
        totalRefill = 0;
        totalTotalRx = 0;
        totalPatPay = 0;
        totalPlanPaid = 0;
        totalCost = 0;
        totalMargin = 0;
        totalShippedOrders = 0;
        totalShippedRxs = 0;

        sales: Array<DashboardStatus.SalesRecord>;

        constructor() {
            this.sales = new Array<DashboardStatus.SalesRecord>();
        }
    }

    export class BucketStatus {
        totalEscript = 0;
        totalImage = 0;
        totalProgramTransfer = 0;
        totalTotal = 0;

        buckets: Array<DashboardStatus.BucketStatusRecord>;

        constructor() {
            this.buckets = new Array<DashboardStatus.BucketStatusRecord>();
        }

    }

    export class BucketStatusRecord {
        name: string | null = null;
        escript = 0;
        image = 0;
        programTransfer = 0;
        total = 0;
    }

    export class PrintQueueStatus {
        programId: number = 0;
        programName: string | null = null;
        printQueueRowRecord: Array<DashboardStatus.PrintQueueStatusRecord>;

        constructor() {
            this.printQueueRowRecord = new Array<DashboardStatus.PrintQueueStatusRecord>();
        }
    }

    export class PrintQueueStatusRecord {
        storeId: number = 0;
        storeName!: string;
        resCount!: string;
        printQueueCount: number = 0;
        stalePrintQueueCount: number = 0;
        outOfStockCount: number = 0;
        dateDays!: number;
    }

    export class ConfigProp {
        configName: string | null = null
        value: string | null = null
    }

    export class CountByLocation {
        storeId = 0;
        storeName: string | null = null;
        otherClassification: string | null = null;
        count = 0;
        value: number | null = null;

        toString(): string {
            const dollar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
            if (!this.count) {
                return '';
            }
            return `${this.storeName || this.otherClassification}: ${this.count} ${this.value != null ? '- ' + dollar.format(this.value) : ''}`;
        }
    }
}
