<template>
    <b-badge :variant="variant" v-b-tooltip.hover :title="tooltipTime" :class="size">
        <div class="d-flex" style="gap: 5px;">
            <i :class="icon"></i>
            <template v-if="!iconOnly">
                {{ relativeTime }}
            </template>
        </div>
    </b-badge>
</template>

<script>
import moment from "moment";

export default {
    name: "PromisedTimeSummary",
    props: {
        value: {
            type: [Date, Object, String]
        },
        iconOnly: Boolean,
        size: String,
        infoOnly: Boolean
    },
    computed: {
        momentDate() {
            if (!this.value) return undefined;
            return new moment(this.value);
        },
        relativeTime() {
            return this.momentDate?.fromNow();
        },
        formattedTime() {
            return this.momentDate?.format("LLLL");
        },
        tooltipTime() {
            if (this.iconOnly) {
                return `${this.relativeTime} (${this.formattedTime})`;
            }
            return this.formattedTime;
        },
        variant() {
            if (this.infoOnly) return "neutral";

            if (!this.momentDate) return "primary";
            if (this.momentDate.isBefore()) return "danger";
            if (this.momentDate.diff(moment(), "hours") <= 4) return "warning";

            return "primary";
        },
        icon() {
            return `fa fa-fw ${this.variant === "danger" ? 'fa-warning' : 'fa-clock'}`;
        }
    },
}

</script>

<style scoped>
.badge {
    font-size: .9em;
    cursor: default;
}

.badge.sm {
    font-size: .7em;
}

.badge.lg {
    font-size: 1.15em;
}

i {
    font-size: 1.2em;
}
</style>