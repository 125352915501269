<template>
    <component :is="baseComponent" :to="link.path ? link.path : '/'" :class="{ active: isActive }" tag="li">
        <a v-if="isMenu" href="#" class="nav-link sidebar-menu-item" :aria-expanded="!collapsed" data-toggle="collapse"
            @click.prevent="collapseMenu">
            <i :class="link.icon" />
            <p>
                {{ link.name }}
                <b class="caret" />
            </p>
        </a>

        <collapse-transition>
            <div v-if="$slots.default || this.isMenu" v-show="forceOpen || !collapsed">
                <ul class="nav links__nav">
                    <slot />
                </ul>
            </div>
        </collapse-transition>

        <slot v-if="children.length === 0 && !$slots.default && link.path" name="title">
            <component :is="elementType(link, false)" :to="link.path" :class="{ active: link.active }" class="nav-link"
                :target="link.target" :href="link.path" @click.native.exact="linkClick"
                @click.ctrl.native.prevent="toggleFavorite(link)">
                <template v-if="addLink">
                    <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
                    <span :class="{ 'sidebar-normal': true, 'has-subtitle': link.subtitle }">
                        <small v-if="link.subtitle" class="sidebar-subtitle">
                            {{ link.subtitle }}
                        </small>
                        <span>{{ link.name }}</span>
                    </span>
                </template>
                <template v-else>
                    <i :class="link.icon" />
                    <p>{{ link.name }}</p>
                </template>
            </component>
        </slot>
    </component>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
    name: "SidebarItem",
    components: {
        CollapseTransition
    },
    provide() {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild
        };
    },
    inject: {
        addLink: { default: null },
        removeLink: { default: null },
        autoClose: {
            default: true
        }
    },
    props: {
        menu: {
            type: Boolean,
            default: false
        },
        link: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    path: "",
                    children: []
                };
            }
        },
        forceOpen: Boolean
    },
    data() {
        return {
            children: [],
            collapsed: true
        };
    },
    computed: {
        baseComponent() {
            return this.isMenu || this.link.isRoute ? "li" : "router-link";
        },
        linkPrefix() {
            if (this.link.name) {
                const words = this.link.name.split(" ");
                return words.map(word => word.substring(0, 1)).join("");
            }
            return ``;
        },
        isMenu() {
            return this.link.name == "Favorites" || this.children.length > 0 || this.menu === true;
        },
        isActive() {
            if (this.$route && this.$route.path) {
                const matchingRoute = this.children.find(c =>
                    this.$route.path.startsWith(c.link.path)
                );
                if (matchingRoute !== undefined) {
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
        if (this.addLink) {
            this.addLink(this);
        }
        if (this.link.collapsed !== undefined) {
            this.collapsed = this.link.collapsed;
        }
        if (this.isActive && this.isMenu) {
            this.collapsed = false;
        }
    },
    destroyed() {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        if (this.removeLink) {
            this.removeLink(this);
        }
    },
    methods: {
        addChild(item) {
            const index = this.$slots.default.indexOf(item.$vnode);
            this.children.splice(index, 0, item);
        },
        removeChild(item) {
            const tabs = this.children;
            const index = tabs.indexOf(item);
            tabs.splice(index, 1);
        },
        elementType(link, isParent = true) {
            if (link.isRoute === false) {
                return isParent ? "li" : "a";
            } else {
                return "router-link";
            }
        },
        linkAbbreviation(name) {
            const matches = name.match(/\b(\w)/g);
            return matches.join("");
        },
        linkClick() {
            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
            this.$emit("link-click");
        },
        collapseMenu() {
            this.collapsed = !this.collapsed;
        },
        collapseSubMenu(link) {
            link.collapsed = !link.collapsed;
        },
        toggleFavorite(link) {
            let favorites = JSON.parse(window.localStorage.getItem("nav-favorites") ?? "[]");

            if (favorites === false) favorites = [];

            if (favorites.some(f => f.path === link.path || f === link.path)) {
                favorites = favorites.filter(f => f.path !== link.path && f !== link.path);
            } else {
                favorites.push(link.path);
            }

            window.localStorage.setItem("nav-favorites", JSON.stringify(favorites));

            this.$emit("toggle-favorite", favorites);
        }
    }
};
</script>
<style>
.sidebar-menu-item {
    cursor: pointer;
}

.sidebar ul.links__nav {
    margin-top: 0;
    padding-top: 10px;
}

.sidebar-subtitle {
    font-weight: 800;
    letter-spacing: .5px;
    display: block;
}

.sidebar-normal.has-subtitle {
    line-height: 1.4 !important;
}
</style>
