
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import moment from 'moment';
import { Prescription } from '@/models/Prescription';
import { ReportExporter } from '@/mixins/ReportExporter';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import SearchComponent from '@/components/SearchComponent.vue';
import { Program } from "@/models/Program";
import { BvTableField } from "bootstrap-vue";

@Component({
    name: "DispensedPage",
    components: {
        [DatePicker.name]: DatePicker,
        Checkbox, SearchComponent,
    },
})
export default class DispensedPage extends Mixins(ReportExporter) {

    // noinspection JSUnusedGlobalSymbols
    created() {
        this.getReport();
    }

    //filters
    private fromDate: Date = moment().subtract(14, 'd').toDate();
    private toDate: Date = moment().toDate();
    private program: Program = new Program();

    //table settings
    private isLoading: boolean = false;
    protected sortBy: any = null;
    private reportItems: any[] = [];

    private moneyFormatter = (value: any, _key: any, _item: any) => {
        if (value && !isNaN(Number(value))) return `$${value?.toFixed(2)}`;
        return "$0.00";
    };
    private ndcFormatter = (value: string) => {
        return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
    };
    private prescriptionIdFormatter = (value: Prescription) => {
        return value.billingReferenceNumber;
    };
    private calculateRefillDue = (item: any) => {
        // (days supply - 21) for first fill, (days supply - 14) on subsequent fills
        let daysToAdd = item.prescription.daySupply - 14;
        if (item.prescription.rfNumber == 0) daysToAdd -= 7;

        let day = moment.utc(item.dateOfService).add(daysToAdd, "days");
        return day.format('L');
    };

    private dateFormatter = (value: string) => {
        return moment.utc(value).format('L');
    };

    private reportFields: Array<({ key: string } & BvTableField)> = [
        { label: "Prescription", key: "prescription", formatter: this.prescriptionIdFormatter, sortable: true },
        { label: "Group Id", key: "groupId", sortable: true },
        { label: "Cardholder Id", key: "cardholderId" },
        { label: "Name", key: "name", sortable: true },
        { label: "Date of Birth", key: "dateOfBirth", formatter: this.dateFormatter, sortable: true },
        { label: "Date of Service", key: "dateOfService", formatter: this.dateFormatter, sortable: true },
        { label: "Refill Due", key: "refillDue", sortable: true },
        { label: "NDC", key: "ndc", formatter: this.ndcFormatter, sortable: true },
        { label: "Drug Name", key: "productNameShort" },
        { label: "Quantity", key: "quantity" },
        { label: "Day Supply", key: "daySupply" },
        { label: "Price Pre-Adjustment", key: "preAdjustedPrice", formatter: this.moneyFormatter, },
        { label: "Shipping", key: "dispensingFee", formatter: this.moneyFormatter },
        { label: "TTB", key: "topTierBenefits", formatter: this.moneyFormatter, },
        { label: "Price Submitted", key: "price", formatter: this.moneyFormatter },
        { label: "Plan Paid", key: "planPaid", formatter: this.moneyFormatter },
        { label: "Program", key: "program.name", sortable: true },

    ];

    get pdfFields(): Array<any> {
        return this.reportFields.map(f => {
            return { label: f.label, key: f.key, formatter: f.formatter };
        });
    }

    //paging
    private pageSize: number = 200; //What will be the standard page size?
    private pageNbr: number = 0;

    @Watch('fromDate')
    @Watch('toDate')
    @Watch('pageSize')
    filterFromFirstPage() {
        this.pageNbr = 0;
        this.getReport();
    }

    loadMore() {
        this.pageNbr++;
        this.getReport();
    }

    loadPrevious() {
        this.pageNbr--;
        this.getReport();
    }

    get dataCount(): number {
        return this.reportItems?.length ?? 0;
    }

    //data fetch
    getReport() {
        this.isLoading = true;
        this.getReportPromise(this.pageNbr, this.pageSize)
            .then(response => {
                this.reportItems = response?.data ?? [];
            })
            .catch(err => {
                let errorMsg = "Error loading dispense report";
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, { err, response: err?.response });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getReportPromise(pageNumber: number | null = null, pageSize: number | null = null): Promise<any> {
        const config = {
            params: {
                fromDate: moment(this.fromDate).startOf('day').utc().toDate(),
                toDate: moment(this.toDate).startOf('day').utc().toDate(),
                programId: this.program?.id,
            },
        };
        let pagingArguments = pageSize === null ? "" : `pageSize=${pageSize}`;
        pagingArguments += pageNumber === null ? "" : `&pageNumber=${pageNumber}`;
        const reportPath = `/prescription/Dispensed?${pagingArguments}`;
        return new Promise<any>((resolve, reject) => {
            this.$http.get(reportPath, config)
                .then(res => {
                    let dat = res?.data ?? [];
                    dat = dat.map((d: any) => {
                        d.refillDue = this.calculateRefillDue(d);
                        return d;
                    });
                    res.data = dat;
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    get isDisabled(): boolean {
        return this.isLoading;
    }

    // button methods
    protected pdfData: any[] = [];
    protected loadingPDF = false;

    savePDF() {
        this.loadingPDF = true;
        this.getReportPromise()
            .then(res => {
                this.pdfData = res.data;
                this.$nextTick(() => {
                    this.ExportPDF("Dispensed Report", "#pdf-table");
                });
            })
            .catch(err => {
                let errorMsg = "Error loading dispense report to export to PDF";
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, { err, response: err?.response });
            })
            .finally(() => {
                this.loadingPDF = false;
            });
    }

    protected loadingCSV = false;

    saveCSV() {
        this.loadingCSV = true;
        this.getReportPromise()
            .then(res => {
                this.ExportCSV("Dispensed Report", res.data, this.reportFields);
            })
            .catch(err => {
                let errorMsg = "Error loading dispense report to export to CSV";
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, { err, response: err?.response });
            })
            .finally(() => {
                this.loadingCSV = false;
            });
    }

    allClicked() {
        this.fromDate = moment("01/01/2000", "MM/dd/yyyy").toDate();
        this.toDate = moment().add(1, "days").toDate();
    }
}
