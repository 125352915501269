<template>

    <div :class="{ 'small-layout': small, 'scroll': scroll }">
        <div class="notification-list">
            <div v-for="un in userNotifications"
                :class="{ unread: !un.read && !un.notification.completed && !un.archived }" :key="un.id">
                <i :class="'icon fa fa-fw fa-' + un.notification.icon" />
                <div class="primary d-flex flex-fill">
                    <div class="content">
                        <RouterLink :to="un.notification.target" class="title"
                            @click.native="(!un.read && markRead(un.id)) && $emit('navigate')">
                            {{ un.notification.title }}
                        </RouterLink>
                        <div class="body text-muted flex-fill">{{ un.notification.body }}</div>
                    </div>
                    <div class="metadata">
                        <small class="text-muted">{{ formatDate(un.notification.created) }}</small>
                        <b-badge variant="primary" class="mb-0">{{ un.notification.category }}</b-badge>
                    </div>
                </div>
                <div v-if="!small && !un.archived" class="actions">
                    <b-button v-if="!un.read" size="sm" title="Mark as Read" variant="outline-primary"
                        @click="markRead(un.id)">
                        <i class="fa fa-fw fa-eye" />
                    </b-button>
                    <b-button v-if="un.read" size="sm" title="Mark as Unread" variant="outline-primary"
                        @click="markUnread(un.id)">
                        <i class="fa fa-fw fa-eye-slash" />
                    </b-button>
                    <b-button size="sm" title="Archive" variant="outline-warning" @click="archive(un.id)">
                        <i class="fa fa-fw fa-archive" />
                    </b-button>
                </div>
            </div>
        </div>

        <BottomPagerBar v-if="!small && userNotifications.length" v-model="pageNumber" :total-rows="totalCount"
            :per-page="pageSize" class="p-3" show-total item-name="notification">
            <template #left>
                <slot name="actions" />
            </template>
        </BottomPagerBar>

        <div v-if="loading" class="text-center my-2">
            <div>
                <b-spinner class="align-middle" />
            </div>
            <strong>Loading Notifications...</strong>
        </div>

        <div v-if="loadError" class="p-3 text-center text-muted">
            Error loading notifications
        </div>

        <div v-if="!loading && !loadError && !userNotifications.length" class="text-muted text-center p-3">

            <template v-if="unreadOnly">
                You have no unread notifications
            </template>
            <template v-else>
                No Notifications To Display
            </template>

        </div>

        <div v-if="viewAllLink" class="text-center">
            <b-button to="/reports/notifications" @click.native="$emit('navigate')" block variant="primary" size="sm"
                class="mb-1">
                <strong>View All Notifications...</strong>
            </b-button>
        </div>

    </div>

</template>

<script>
import moment from "moment";
import BottomPagerBar from "../BottomPagerBar.vue";

export default {
    name: "NotificationsList",
    components: {
        BottomPagerBar
    },
    props: {
        small: Boolean,
        unreadOnly: Boolean,
        scroll: Boolean,
        viewAllLink: Boolean,
        mode: { type: String, default: "active" },
        pageSize: {
            default: 50
        }
    },
    mounted() {
        this.load();

        this.$watch("$root.$data.notificationCount", () => {
            if (this.mode === "active" && this.pageNumber === 1) {
                this.load();
            }
        });
    },
    data() {
        return {
            userNotifications: [],
            loadError: null,
            loading: true,
            pageNumber: 1,
            totalCount: 0
        }
    },
    watch: {
        mode() {
            this.pageNumber = 1;
            this.load();
        },
        pageNumber() {
            this.load();
        }
    },
    methods: {
        async load() {
            try {
                this.loadError = false;
                this.loading = true;

                const config = {
                    params: {
                        unreadOnly: this.unreadOnly,
                        pageSize: this.pageSize,
                        pageNumber: this.pageNumber - 1
                    }
                };
                const response = await this.$http.get(`/notification/${this.mode == 'inactive' ? 'archived' : 'active'}`, config);

                this.userNotifications = response.data.items;
                this.totalCount = response.data.count;
            } catch {
                this.userNotifications = [];
                this.totalCount = 0;
                this.loadError = true;
            } finally {
                this.loading = false;
            }
        },
        async archive(id) {
            try {
                await this.$http.post("/notification/archive/" + id);
            } catch (ex) {
                console.error(ex)
            } finally {
                this.load();
            }
        },
        async markRead(id) {
            try {
                await this.$http.post("/notification/mark-read/" + id);
            } finally {
                this.load();
            }
        },
        async markUnread(id) {
            try {
                await this.$http.post("/notification/mark-unread/" + id);
            } finally {
                this.load();
            }
        },
        formatDate(date) {
            return new moment.utc(date).fromNow();
        }
    }
}

</script>

<style scoped lang="scss">
.notification-list>div {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 10px 0;

    .title {
        font-weight: 500;
        font-size: 1.1em;
        color: black;
    }

    .body {
        font-size: 1em;
    }

    .icon {
        min-width: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: .95em;
        line-height: 2.2;
    }

    .actions {
        display: flex;
        gap: 5px;
    }

    .btn {
        margin: 0;
    }

    &.unread {
        .title {
            font-weight: 900;
        }

        .icon {
            background: #2a5788;
            color: white;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex-grow: 1;
    gap: 1px;
}

.metadata {
    display: flex;
    gap: 5px;
    align-items: center;
}

.small-layout {
    .primary {
        flex-direction: column;
    }

    .content {
        flex-direction: column;
        gap: 0;
        line-height: 1.5;
        font-size: .9em;
    }

    .notification-list>div {
        margin-bottom: 6px;
    }
}

.scroll {
    margin: -8px;

    .notification-list {
        max-height: clamp(150px, 45vh, 800px);
        overflow-y: auto;

        padding: 6px;
    }
}
</style>
