
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { Plan } from '@/models/Plan';
import { PatientPlan, RelationshipCode } from '@/models/PatientPlan';
import { PatientInsuranceUpload } from '@/models/PatientInsuranceUpload';
import SearchComponent from '@/components/SearchComponent.vue';
import { Patient } from '@/models/Patient';
import PatientPlansComponent from '@/components/Insurance/PatientPlansComponent.vue'
import { PatientInsuranceUpdateBase } from '@/models/PatientInsuranceUpdateBase';
import { InsuranceUpdateRequest } from '@/models/InsuranceUpdateRequest';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import 'viewerjs/dist/viewer.css';
import { component as Viewer } from 'v-viewer';
//Vue.use(Viewer)

@Component({
    name: "PatientInsuranceUploads",
    components: {
        SearchComponent,
        PatientPlansComponent,
        Viewer
    },
})

export default class PatientInsuranceUploadsQueue extends Vue {
    private items: Array<PatientInsuranceUpdateBase> = new Array<PatientInsuranceUpdateBase>();
    private unprocessedInsurances: Array<PatientInsuranceUpload> = new Array<PatientInsuranceUpload>();
    private updateRequests: Array<InsuranceUpdateRequest> = new Array<InsuranceUpdateRequest>();

    private plans: Plan[] = [];
    private addedPlan: Plan = new Plan();
    private addedPatientPlan: PatientPlan = {} as PatientPlan;
    private relationshipCodes = RelationshipCode;
    private currentPatientId: number | string = "";
    private patient: Patient = new Patient();

    viewerOptions() {
        return {
            inline: true,
            debug: true,
            button: false,
            title: false,
            transition: false,
            toolbar: {
                zoomIn: 1,
                zoomOut: 1,
                reset: 1,
                prev: 1,
                next: 1,
                rotateLeft: 1,
                rotateRight: 1,
                flipHorizontal: 1,
                flipVertical: 1,
                oneToOne: 0,
                play: {
                    show: 0,
                    size: 'large'
                }
            }
        };
    }

    created() {
        this.loadData();
    }

    refreshItems() {
        this.items = new Array<PatientInsuranceUpdateBase>();
        this.items = (this.unprocessedInsurances as Array<PatientInsuranceUpdateBase>).concat(this.updateRequests);
        console.log('items', this.items)
        this.items.sort((a, b) => {
            return new Date(a.updated).getTime() - new Date(b.updated).getTime();
        });
    }

    loadData() {
        this.getUnprocessedInsuranceUploads();
        this.getUnprocessedInsuranceUpdateRequests();
    }

    getUnprocessedInsuranceUploads() {
        this.$http.get<Array<PatientInsuranceUpload>>(`/PatientInsuranceUpload/Unprocessed`)
            .then(response => {
                console.log('PatientInsuranceUpload', response.data);
                this.unprocessedInsurances = response.data.map(i => new PatientInsuranceUpload(i));
                this.refreshItems();
            })
            .catch(error => {
                console.error("Error while loading patient insurance uploads.", { error, response: error?.response });
            });
    }

    getUnprocessedInsuranceUpdateRequests() {
        this.$http.get<Array<InsuranceUpdateRequest>>(`/PatientInsuranceUpload/unprocessed-insurance-update-requests`)
            .then(response => {
                console.log('unprocessed-insurance-update-requests', response.data);
                this.updateRequests = response.data.map(i => new InsuranceUpdateRequest(i));
                this.refreshItems();
            })
            .catch(error => {
                console.error("Error while loading insurance updates requests.", { error, response: error?.response });
            });
    }

    convertDate(date: string|Date) {
        return moment(date).local().format("L");
    }

    getAge(date: Date) {
        //return moment.utc(date).local().fromNow()
        return moment.utc(date).fromNow()
    }

    toggleInfo(insurance: PatientInsuranceUpdateBase) {
        this.unprocessedInsurances.forEach(ui => {
            (this.$refs['panel-' + ui.id] as any)[0].show = false;
        });

        let newPat = new Patient();
        newPat.id = insurance.patKey;
        this.patient = newPat;

        ((this.$refs[`viewer-${insurance.id}`] as any)[0])!.rebuildViewer();
    }

    @Watch("viewer")
    patientChanged(patNew: Patient, patOld: Patient) {
        if (patNew.id != patOld.id) {
            this.clear();
        }
    }

    processInsurance(item: PatientInsuranceUpdateBase) {
        const id = item.id;
        if (item.fromPortal == "BrandPortal") {
            this.$http.post(`/PatientInsuranceUpload/process-update-request/${id}`)
                .then(_response => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Processed Successfully", NotificationOptions.NotificationTypes.success))
                    this.getUnprocessedInsuranceUpdateRequests();
                }).catch(error => {
                const errorMsg = "Error Processing Insurance: " + error.response?.data.substring(0, 100);
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger))
                console.error("Error while processing uploaded patient insurance", {
                    error,
                    response: error?.response
                });
            })
        } else {
            this.$http.post(`/PatientInsuranceUpload/Process/` + id)
                .then(_response => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Processed Successfully", NotificationOptions.NotificationTypes.success))
                    this.getUnprocessedInsuranceUploads();
                }).catch(error => {
                const errorMsg = "Error Processing Insurance: " + error.response?.data.substring(0, 100);
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger))
                console.error("Error while processing uploaded patient insurance", {
                    error,
                    response: error?.response
                });
            })
        }
    }

    clear() {
        this.unprocessedInsurances = new Array<PatientInsuranceUpload>();
    }

}
