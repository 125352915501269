
import {Component, Prop, Vue} from "vue-property-decorator";
import {Patient} from "@/models/Patient";
import axios from "axios";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import NewClearSaveButtons, {NewClearSaveTitles} from "@/components/NewClearSaveButtons.vue";
import NotificationTypes = NotificationOptions.NotificationTypes;

@Component({
    name: "ResetPatientAppPasswordComponent",
    components: {NewClearSaveButtons},
})
export default class ResetPatientAppPasswordComponent extends Vue {
    @Prop() private patient!: Patient;

    private isSubmitting = false;

    updatePassword() {
        if (this.isSubmitting) return;
        this.isSubmitting = true;
        axios
            .post<string>(`Patient/reset-patientApp-password`, this.patient)
            .then((res) => {
                if (res.data.length) {
                    this.$notification(NotificationOptions.notificationOptionsPreset(res.data, NotificationTypes.warning));
                } else {
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Password Reset"));
                }
                this.$emit("close");
            })
            .catch((err) => {
                console.warn(err);
                this.$notification(NotificationOptions.error(err));
            })
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    private get _buttonTitles() {
        let res: NewClearSaveTitles;
        res = {
            save: "Reset Password",
            clear: "",
            new: "",
            cancel: "Cancel",
        };
        return res;
    }

    get isFormValid() {
        return !this.isSubmitting;
    }
}
