
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { Order, OrderStatus, isPromisedApplicable } from '@/models/Order';
import { BvTableFieldArray, BTable } from "bootstrap-vue";
import moment from "moment";
import { DispenseError } from '@/models/DispenseError';
import PromisedTimeSummary from "@/components/Order/PromisedTimeSummaryComponent.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import Axios from 'axios';

@Component({
    name: "OrdersListComponent",
    components: {
        PromisedTimeSummary
    },
})
export default class OrdersListComponent extends Vue {
    @Prop({ default: {} as Array<Order> }) private orders!: Order[];
    @Prop({ default: false, type: Boolean }) protected hideActionsColumn!: boolean;
    @Prop({ default: false, type: Boolean }) private expandable!: boolean;
    @Prop({ default: false, type: Boolean }) protected mergable!: boolean;
    @Prop() private mode!: string;
    @Prop({ default: false }) protected audit!: Boolean;
    @Prop({ default: false }) protected busy!: Boolean;

    @Ref("order-list-table") protected readonly table!: BTable;

    get items(): any[] {
        return this.orders.map((o: any) => {
            if (o.status == OrderStatus.Canceled) o._rowVariant = "danger";
            else if (o.status == OrderStatus.Shipped) o._rowVariant = "info";
            else if (o.status == OrderStatus.ReadyForPickup) o._rowVariant = "warning";

            return o;
        });
    }

    get hasPromised(): boolean {
        return !!this.items?.find(i => isPromisedApplicable(i.status, i.promisedDate));
    }

    isPromisedApplicable = isPromisedApplicable;

    get fields(): BvTableFieldArray {
        const fields = [];

        fields.push({ key: "id", label: "Order Id", });
            fields.push({key:"partnerId", label:"Central Fill"})
        if (this.mode === "verify") {
            fields.push({ key: "patientName", label: "Patient", class: "patient-col", sortable: true });
        }
        fields.push({
            key: "created",
            label: "Date",
            formatter: (d: any) => moment.utc(d).format("L"),
            sortable: true,
        });

        if (this.hasPromised) {
            fields.push({ key: "promisedDate", label: "Promised", sortable: true });
        }

        fields.push({ key: "createdBy", label: "Created By", formatter: (c: any) => c.split("@")[0] });
        fields.push({ key: "rxRfs", label: "Rx-Refill", });
        fields.push({ key: "storeName", label: "Store", });
        fields.push({ key: "status", formatter: this.orderStatusName });
        fields.push({ key: "action", label: "", class: "action-col", });
        return fields;
    }

    orderStatusName(enumNbr: number): string {
        return OrderStatus[enumNbr];
    }

    formattedDate(value: Date | null): string {
        if (value != null) {
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY");
        }
        return "";
    }

    rowClicked(item: any, _index: number, _event: Event) {
        if (this.mode == 'verify') {
            this.$router.push({ name: 'OrderVerification', params: { orderId: item.id } })
        }
        if (!this.expandable) return;

        const prescriptions: any[] = item.prescriptions;

        if (!prescriptions?.length) {
            this.$set(item, "_showDetails", !item._showDetails);
            return;
        }

        const requests: Promise<void>[] = [];
        prescriptions.filter(p => p.rxId).forEach(prescription => {
            requests.push(Axios.get<DispenseError[]>(`DispenseError/by-rxid/${prescription.rxId}`)
                .then(response => {
                    const dispenseErrors: DispenseError[] = response.data.map((err: DispenseError) => Object.assign(new DispenseError(), err));
                    if (!dispenseErrors?.length) return;

                    const totalErrorCount = dispenseErrors.length;
                    const unresolvedErrors = dispenseErrors.filter(e => !e.resolved);
                    const unresolvedErrorCount = unresolvedErrors.length;

                    prescription.hasUnresolvedErrors = !!unresolvedErrorCount;

                    prescription.error = unresolvedErrors
                        .map(err => `(${moment.utc(err.updated).format("MM/DD/yy hh:mm:ss a")}) ${err.reason}: ${err.errorTypeDesc}`)
                        .join('\n');

                    const resolvedErrorCount = totalErrorCount - unresolvedErrorCount;

                    if (resolvedErrorCount) {
                        prescription.error += `(Hiding ${resolvedErrorCount} resolved dispense error${resolvedErrorCount > 1 ? 's' : ''})`;
                    }
                })
                .catch(error => {
                    console.error("Error while getting dispense error", {
                        error,
                        response: error?.response
                    });
                }));
        });
        Promise.all(requests).finally(() => {
            this.$set(item, "_showDetails", !item._showDetails);
        });
    }

    OrderStatus = OrderStatus;

    async overridePriorAuth(orderId: number) {
        try {
            const msg = `Are you sure you want to override the Awaiting Prior Authorization status for this order?`;
            const confirmed = await this.$bvModal.msgBoxConfirm(msg, {
                title: 'Override Awaiting Prior Authorization',
                okVariant: 'info',
                okTitle: 'Override',
                cancelTitle: 'Cancel',
                centered: true,
                noFade: true
            });

            if (!confirmed) return;

            await Axios.post(`/Order/override-pa/${orderId}`);

            this.$emit("change");
        } catch (error) {
            this.$notification(NotificationOptions.error(error));
        }
    }
}

