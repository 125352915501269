
    import { Component, Emit, Vue } from "vue-property-decorator";
    import SearchComponent from "@/components/SearchComponent.vue";
    import { Program } from "@/models/Program";
    import { EDIOrder, EDIOrderLineItem, OrderableDrug } from "@/models/EDI/EDIOrder";
    import axios from "axios";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { DrugSource } from "@/models/Drug/DrugProduct";
    import { BvTableFieldArray } from "bootstrap-vue";

    @Component({
        name: "CreatePurchaseOrderForm",
        components: { SearchComponent }
    })
    export default class CreatePurchaseOrderForm extends Vue {
        private order: EDIOrder = new EDIOrder();

        private program: Program = new Program();

        private drug: OrderableDrug = new OrderableDrug();
        private quantity: number = 0;

        private fields: BvTableFieldArray = [
            { key: "drugName" },
            { key: "drugSource", formatter: value => DrugSource[value].toString() },
            { key: "packagesOrdered" },
            { key: "ndC11", label: "NDC" },
            { key: "unitPrice" }
        ];

        addProduct() {
            let item = EDIOrderLineItem.fromOrderableDrug(this.drug, this.quantity);
            this.order.lineItems.push(item);
            this.quantity = 0;
            this.drug = new OrderableDrug();
        }

        @Emit('order-saved')
        async saveOrder() {
            try {
                this.order.storeId = this.order.store.id as number;
                this.order.vendorId = this.order.vendor.id as number;
                const resp = await axios.post<EDIOrder>("/EdiOrdering", this.order);
                console.log("resp: ", resp);
                this.$notification(NotificationOptions.successSaveNotificationPreset("EDI Order"));
                return resp.data;
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
                return err;
            }
        }
    }
