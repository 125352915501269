
    import Vue from 'vue'
    import axios, {AxiosError, AxiosRequestConfig} from 'axios';
    import { Component, Watch } from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import SearchComponent from '@/components/SearchComponent.vue';
    import SurplusReportFormComponent from "@/components/Surplus/SurplusReportFormComponent.vue";
    import {Store} from "@/models/Store";
    import moment from 'moment';
import { Surplus } from '@/models/Surplus';

    @Component({
        name: "SurplusReportsPage",
        components: {
            SearchComponent
            , SurplusReportFormComponent
        },
    })

    export default class InventoryBatchQueuePage extends Vue {
        private isLoading = false;
        private items:Array<Surplus> = new Array<Surplus>();
        private surplus:Surplus = new Surplus();
        private storeFilter: Store = new Store();
        private selectedStoreID = 0;
        private selectedSurplusID = 0;


        private fields: any[] = [
            { key: "created", label: "Created", sortable: true },
            { key: "createdBy", label: "Created By", sortable: true },
            { key: "manufacturer", label: "Manufacturer", sortable: true},
            { key: "drugName", label: "Drug Name", sortable: true},
            { key: "ndc", label: "NDC", formatter: this.ndcFormatter, sortable: true},
            { key: "quantity", label: "Quantity", sortable: true },
            { key: "unitCost", label: "Unit Cost", sortable: true },
            { key: "totalCost", label: "Total Cost", sortable: true },
            { key: "expirationDate", label: "Expiration Date", formatter: this.etaDateFormatter, sortable: true },
            // { key: "dateDelivered", label: "DateDelivered" },
            // { key: "backordered", label: "On Backorder" },


            { key: "actions", label: ''},
        ];

        private sortBy:string = 'created'
        private sortDesc: boolean = true;

        created(){
           this.loadItems();
        }

        loadItems(){
            this.$bvModal.hide('surplus-report-form');
            this.isLoading = true;
            const config: AxiosRequestConfig = {params:{storeId: this.storeFilter.id}};
            axios.get<Array<Surplus>>('SurplusReport', config)
            .then(res => {
                this.items = res.data.map(i => new Surplus(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(()=>{
                this.isLoading = false;
            });
        }

        ndcFormatter(value: string) {
            if (value == null) {
                return value;
            }
            return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
        };

        etaDateFormatter(value: Date, key: string, item: any){
            if (!value || item.estimatedArrivalDate > item.estimatedArrivalDate) {
                return "";
            }
            return moment.utc(value).format("L");
        }

        addSurplusReport(){
            this.surplus.id = 0;
            this.surplus.storeId = Number(this.storeFilter.id);
            this.surplus.action = "New";
            this.$bvModal.show('surplus-report-form');
        }

        openDetails(surplus:Surplus){
            this.surplus = surplus;
            this.surplus.action="Detail";
            // this.selectedSurplusID = surplus.Id;
            this.$bvModal.show('surplus-report-form');
        }

        transferSurplus(surplus:Surplus){
            this.surplus = surplus;
            this.surplus.action="Transfer";
            // this.selectedSurplusID = surplus.Id;
            this.$bvModal.show('surplus-report-form');
        }

    }


