
    import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
    import { DashboardStatus } from '@/models/DashboardStatus';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import TextUtils from '@/util/TextUtils';

    @Component({
        name: "DashboardConfigurationPage",
        components: {
        }
    })
    export default class DashboardConfigurationPage extends Vue {
        private configs : Array<DashboardStatus.ConfigProp> = new Array<DashboardStatus.ConfigProp>();

        created() {
            this.$http.get<Array<DashboardStatus.ConfigProp>>('dashboard/configuration')
                .then(res => {
                    this.configs = res.data;
                })
                .catch(err => {
                    console.log("Error while getting dashboard configs", {err, response: err?.response});
                })
        }

        save(){
            this.$http.post<Array<DashboardStatus.ConfigProp>>('dashboard/configuration', this.configs)
                .then(res => {
                    this.configs = res.data;
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Dashboard configuration"));
                })
                .catch(err => {
                    console.log("Error while getting dashboard configs", {err, response: err?.response});
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Dashboard configuration"));
                })
        }

        inputLabel(configName:string){
            return TextUtils.camelCaseToNormal(configName);
        }
        
    }
