
    import { Component, Vue } from "vue-property-decorator";
    import { Patient } from '@/models/Patient';
    import axios from "axios";
    import PatientDemographicsComponent from "@/components/Patient/PatientDemographicsComponent.vue";
    import DoctorFollowUpCasesAlertComponent from "@/components/Doctor/DoctorFollowUpCasesAlertComponent.vue";

    @Component({
        name: "FlexEmbed",
        components: { DoctorFollowUpCasesAlertComponent, PatientDemographicsComponent }
    })
    export default class FlexEmbed extends Vue {
        protected patients: Patient[] = [];
        private errorMessage: string = "";

        get routePhoneNumber(): string {
            return this.$route.params?.phoneNumber;
        }

        showAlert(patient: Patient) {
            return patient?.alert?.length > 0 ?? false;
        }

        mounted() {
            this.fetchPatient();
        }

        async fetchPatient() {
            try {
                let patients = await axios.get<Patient[]>(`/Patient/flex/${this.routePhoneNumber}`);
                if (patients.data.length > 0) {
                    this.patients = patients.data.map(p => new Patient(p));
                    const firstId = this.patients.length > 0 ? this.patients[0].id : 0;
                    if (firstId) {
                        this.errorMessage = "";
                        this.$nextTick(() => {
                            this.$root.$emit('bv::toggle::collapse', `accordion-${firstId}`);
                        });
                    }
                }
            } catch (err) {
                this.errorMessage = err.response.data;
            }
        }
    }
